/* eslint-disable @typescript-eslint/camelcase */
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  ArrowPathIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import {
  Alert,
  Button,
  DropdownMenu,
  Label,
  Link,
  Spinner,
  Text,
  TextInput,
} from "north.js";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import "twin.macro";

import { Header, PageWrapper } from "src/shared/components";
import { MENTORSHIP_TOPICS } from "src/shared/constants/mentorship";
import { Route } from "src/shared/constants/route";
import { useMentorContext, useUserContext } from "src/shared/contexts";

import { OpenRequestsTable } from "./Table";

export const MentorOpenRequests: React.FC = () => {
  const { profile, isLoading } = useMentorContext();
  const navigate = useNavigate();
  const { info } = useUserContext();

  const [query, setQuery] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);

  const categoryFilterItems = [
    MENTORSHIP_TOPICS.map((topic) => ({
      content: (
        <div tw="w-full flex items-center justify-between">
          <Text type="body1">{topic}</Text>
        </div>
      ),
      selected: categoriesFilter.includes(topic),
      dismissOnClick: false,
      action: () =>
        categoriesFilter.includes(topic)
          ? setCategoriesFilter((prev) => prev.filter((p) => p !== topic))
          : setCategoriesFilter((prev) => [...prev, topic]),
    })),
  ];

  const profileNotCreated = !profile && !isLoading;
  const slackNotConnected = !info?.event_platform_user_id;

  return (
    <>
      <Header
        title="Open requests"
        subtitle="View and search for open requests submitted by hackers and claim them."
      />
      <PageWrapper fullWidth>
        {profileNotCreated ? (
          <>
            <Alert
              color="warning"
              icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
            >
              Oops! Looks like we haven&apos;t made a profile for you yet!
            </Alert>

            <div tw="h-24" />
            <Button
              size="lg"
              color="secondary"
              onClick={async () => {
                navigate(Route.MENTOR_PROFILE);
              }}
            >
              <Text type="button">Make Profile</Text>
            </Button>
          </>
        ) : slackNotConnected ? (
          <>
            <Alert
              color="warning"
              icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
            >
              <Link
                href="https://docs.google.com/document/d/1oh2IM-mRHjKx17uLrImOEcXKbg6SQIHI6ITLfgx9qNE"
                tw="text-text-warning!"
              >
                You&apos;re almost there! Please link your slack account to view
                and claim requests{" "}
                <span role="img" aria-label="happy emoji">
                  😊
                </span>
              </Link>
            </Alert>
          </>
        ) : (
          <div>
            <div tw="flex justify-between mb-24 lg:(flex-col gap-16)">
              <TextInput
                type="text"
                placeholder="Search request by subject, category, or date"
                leadingIcon={<MagnifyingGlassIcon tw="w-16 h-16" />}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                tw="h-48 width[500px] font-medium"
              />

              <div tw="flex">
                <DropdownMenu tw="w-full!" items={categoryFilterItems}>
                  <Button
                    color="secondary"
                    tw="w-full!  min-width[200px] h-48"
                    trailingIcon={
                      <ChevronDownIcon tw="w-16 h-16 text-text-secondary" />
                    }
                  >
                    <div tw="flex justify-around w-full align-items[center]">
                      <Text type="button" tw="text-text-secondary">
                        Filter by category
                      </Text>
                    </div>
                  </Button>
                </DropdownMenu>
                <Button
                  tw="ml-24 h-48 min-width[150px]"
                  leadingIcon={
                    triggerRefresh ? (
                      <Spinner tw="w-16! h-16!" />
                    ) : (
                      <ArrowPathIcon tw="w-16 h-16 text-text-secondary" />
                    )
                  }
                  onClick={() => {
                    setTriggerRefresh(true);
                  }}
                  disabled={triggerRefresh}
                >
                  <div tw="flex justify-around w-full align-items[center]">
                    <Text type="button" tw="text-text-secondary">
                      Refresh list
                    </Text>
                  </div>
                </Button>
              </div>
            </div>
            {categoriesFilter.length > 0 && (
              <div tw="flex flex-wrap gap-16 mb-24 items-center">
                <Text type="body1" tw="text-text-secondary">
                  Filters:{" "}
                </Text>
                {categoriesFilter.map((category) => (
                  <Label
                    size="md"
                    color="primary-light"
                    key={category}
                    tw="font-bold"
                    onClose={() =>
                      setCategoriesFilter((prev) =>
                        prev.filter((c) => c !== category)
                      )
                    }
                  >
                    {category}
                  </Label>
                ))}
              </div>
            )}
            <OpenRequestsTable
              query={query}
              triggerRefresh={triggerRefresh}
              setTriggerRefresh={setTriggerRefresh}
              categoriesFilter={categoriesFilter}
            />
          </div>
        )}
      </PageWrapper>
    </>
  );
};
