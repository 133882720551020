import { Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React, { useState } from "react";
// eslint-disable-next-line
import "twin.macro";
import styled from "styled-components";

import { useHackerContext } from "src/shared/contexts";
import { HackerStage } from "src/shared/contexts/HackerContext/types";

import WithdrawModal from "../modals/withdrawModal";

const LightBackground = styled.div`
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundLight};
  padding: 32px;
`;

export default () => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const { updateStage } = useHackerContext();

  const withdrawApplication = async () => {
    await updateStage(HackerStage.WITHDRAWN);
    setIsWithdrawModalOpen(false);
  };

  return (
    <>
      <WithdrawModal
        isOpen={isWithdrawModalOpen}
        onCancel={() => setIsWithdrawModalOpen(false)}
        onConfirm={withdrawApplication}
      />
      <LightBackground>
        <Text>
          Can&apos;t make the event? If you cannot attend, please{" "}
          <Link onClick={() => setIsWithdrawModalOpen(true)}>
            <strong>withdraw your application</strong>
          </Link>{" "}
          so that we can give your spot to someone on the waitlist.
          <br /> <br />
          If you have any questions, please feel free to reach out to{" "}
          <Link href="mailto:hello@hackthenorth.com">
            hello@hackthenorth.com
          </Link>
        </Text>
      </LightBackground>
    </>
  );
};
