/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetUserNetworkingScansQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserNetworkingScansQuery = {
  getUserNetworkingScans: Array<
    Pick<
      Types.ConnectionHistoryScans,
      | "id"
      | "event_id"
      | "scanned_at"
      | "updated_at"
      | "is_duplicate"
      | "scanning_attendee_id"
      | "scanned_attendee_id"
      | "other_attendee_name"
      | "other_attendee_role"
      | "got_scanned"
      | "badge_code"
      | "type"
      | "claim_number"
    > & { sponsor_scan?: Types.Maybe<Pick<Types.SponsorScan, "company_name">> }
  >;
};

export const GetUserNetworkingScansDocument = gql`
  query GetUserNetworkingScans {
    getUserNetworkingScans {
      id
      event_id
      scanned_at
      updated_at
      is_duplicate
      scanning_attendee_id
      scanned_attendee_id
      other_attendee_name
      other_attendee_role
      got_scanned
      badge_code
      type
      claim_number
      sponsor_scan {
        company_name
      }
    }
  }
`;

/**
 * __useGetUserNetworkingScansQuery__
 *
 * To run a query within a React component, call `useGetUserNetworkingScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNetworkingScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNetworkingScansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserNetworkingScansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserNetworkingScansQuery,
    GetUserNetworkingScansQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetUserNetworkingScansQuery,
    GetUserNetworkingScansQueryVariables
  >(GetUserNetworkingScansDocument, baseOptions);
}
export function useGetUserNetworkingScansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserNetworkingScansQuery,
    GetUserNetworkingScansQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetUserNetworkingScansQuery,
    GetUserNetworkingScansQueryVariables
  >(GetUserNetworkingScansDocument, baseOptions);
}
export type GetUserNetworkingScansQueryHookResult = ReturnType<
  typeof useGetUserNetworkingScansQuery
>;
export type GetUserNetworkingScansLazyQueryHookResult = ReturnType<
  typeof useGetUserNetworkingScansLazyQuery
>;
export type GetUserNetworkingScansQueryResult = ApolloReactCommon.QueryResult<
  GetUserNetworkingScansQuery,
  GetUserNetworkingScansQueryVariables
>;
