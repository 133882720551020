import { Spacer, Text } from "@hackthenorth/north";
import { Button, Select } from "north.js";
import React, { useCallback, useState } from "react";
import { Prompt } from "react-router-dom";

import { successToast } from "src/shared/components";

import BusCard from "./busCard";
import { BUS_LOCATIONS } from "./constants";
import { useFindBusesByLocationQuery } from "./graphql/findBusesByLocation.generated";
import { useFindMyBusesByEventQuery } from "./graphql/findMyBusesByEvent.generated";
import { useRegisterSelfToBusMutation } from "./graphql/registerSelfToBus.generated";
import { useUnregisterSelfFromBusMutation } from "./graphql/unregisterSelfToBus.generated";

interface BusComponentProps {
  isBlocking: boolean;
  setIsBlocking: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BusComponent: React.FC<BusComponentProps> = ({
  isBlocking,
  setIsBlocking,
}) => {
  const [pickupLocation, setPickupLocation] = useState<string>("");
  const [tempPickupLocation, setTempPickupLocation] = useState<string>("");

  const [dropoffLocation, setDropoffLocation] = useState<string>("");
  const [tempDropoffLocation, setTempDropoffLocation] = useState<string>("");

  const [selectedDropoff, setSelectedDropoff] = useState({
    originalDropoff: -1,
    newDropoff: -1,
  });

  const [selectedPickup, setSelectedPickup] = useState({
    originalPickup: -1,
    newPickup: -1,
  });

  const [changingBus, setChangingBus] = useState({
    pickup: false,
    dropoff: false,
  });

  const { data: myBuses } = useFindMyBusesByEventQuery({
    variables: {
      eventSlug: "hackthenorth2023",
    },
    onCompleted: () => {
      let isMounted = true;
      for (const i in myBuses?.findMyBusesByEvent) {
        if (
          myBuses?.findMyBusesByEvent[i].departure_location ===
          "University of Waterloo"
        ) {
          if (isMounted) {
            setSelectedDropoff({
              ...selectedDropoff,
              originalDropoff: myBuses?.findMyBusesByEvent[i].id,
            });
            setDropoffLocation(myBuses?.findMyBusesByEvent[i].arrival_location);
          }
        }
        if (
          myBuses?.findMyBusesByEvent[i].arrival_location ===
          "University of Waterloo"
        ) {
          if (isMounted) {
            setSelectedPickup({
              ...selectedPickup,
              originalPickup: myBuses?.findMyBusesByEvent[i].id,
            });
            setPickupLocation(
              myBuses?.findMyBusesByEvent[i].departure_location
            );
          }
        }
      }
      return () => {
        isMounted = false;
      };
    },
  });

  const { data: departureBuses, refetch: refetchDepartureBuses } =
    useFindBusesByLocationQuery({
      variables: {
        departure_location: pickupLocation,
      },
      skip: !pickupLocation,
    });

  const sortedDepartureBuses = departureBuses?.findBusesByLocation
    .slice()
    .sort((a, b) => {
      return a.departure_time.localeCompare(b.departure_time);
    });

  const { data: arrivalBuses, refetch: refetchArrivalBuses } =
    useFindBusesByLocationQuery({
      variables: {
        arrival_location: dropoffLocation,
      },
      skip: !dropoffLocation,
    });

  const sortedArrivalBuses = arrivalBuses?.findBusesByLocation
    .slice()
    .sort((a, b) => {
      return a.departure_time.localeCompare(b.departure_time);
    });

  const [registerSelfToBus] = useRegisterSelfToBusMutation();

  const [unregisterSelfToBus] = useUnregisterSelfFromBusMutation();

  const registerToPickupBus = useCallback(async () => {
    setChangingBus({ ...changingBus, pickup: false });
    if (selectedPickup.originalPickup !== -1) {
      await unregisterSelfToBus({
        variables: {
          busId: selectedPickup.originalPickup,
        },
      });
      setSelectedPickup({
        ...selectedPickup,
        originalPickup: -1,
      });
    }

    if (selectedPickup.newPickup !== -1) {
      await registerSelfToBus({
        variables: {
          busId: selectedPickup.newPickup,
        },
      });
      await refetchDepartureBuses();
      setSelectedPickup({
        ...selectedPickup,
        originalPickup: selectedPickup.newPickup,
      });
      successToast("Successfully registered to pickup bus!");
      setChangingBus({ ...changingBus, pickup: false });
      return;
    }
    await refetchDepartureBuses();
    successToast("Successfully unregistered from pickup bus!");
    setChangingBus({ ...changingBus, pickup: false });
  }, [
    changingBus,
    registerSelfToBus,
    selectedPickup,
    unregisterSelfToBus,
    refetchDepartureBuses,
  ]);

  const registerToDropoffBus = useCallback(async () => {
    if (selectedDropoff.originalDropoff !== -1) {
      await unregisterSelfToBus({
        variables: {
          busId: selectedDropoff.originalDropoff,
        },
      });
      setSelectedDropoff({
        ...selectedDropoff,
        originalDropoff: -1,
      });
    }

    if (selectedDropoff.newDropoff !== -1) {
      await registerSelfToBus({
        variables: {
          busId: selectedDropoff.newDropoff,
        },
      });
      setSelectedDropoff({
        ...selectedDropoff,
        originalDropoff: selectedDropoff.newDropoff,
      });
      await refetchArrivalBuses();
      successToast("Successfully registered to dropoff bus!");
      setChangingBus({ ...changingBus, dropoff: false });
      return;
    }

    await refetchArrivalBuses();
    successToast("Successfully unregistered from dropoff bus!");
    setChangingBus({ ...changingBus, dropoff: false });
  }, [
    changingBus,
    registerSelfToBus,
    selectedDropoff,
    unregisterSelfToBus,
    refetchArrivalBuses,
  ]);

  return (
    <>
      <Prompt
        when={isBlocking}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      <Text mods="h3 bold">Reserve your bus</Text>
      <Spacer height={24} />
      <Text mods="bold textBody">Pick-up Location (Friday, September 15)</Text>
      <Spacer height={12} />
      <Select
        fullWidth
        size="lg"
        value={pickupLocation}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e.target.value === "") {
            setSelectedPickup({ ...selectedPickup, newPickup: -1 });
          }
          setPickupLocation(e.target.value);
        }}
        disabled={!changingBus.pickup}
      >
        {BUS_LOCATIONS.map((route, i) => {
          return (
            <Select.Option key={i} value={route}>
              {route}
            </Select.Option>
          );
        })}
        <Select.Option value={""}>I don&apos;t need a bus</Select.Option>
      </Select>
      <Spacer height={24} />
      {pickupLocation !== "" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            width: "95%",
            marginBottom: "24px",
          }}
        >
          {sortedDepartureBuses?.map((count, i) => (
            <BusCard
              key={i}
              availableSeats={count.seats_available}
              departure={count.departure_time}
              arrival={count.arrival_time}
              selected={
                selectedPickup.newPickup === -1
                  ? count.id === selectedPickup.originalPickup
                  : count.id === selectedPickup.newPickup
              }
              disabled={count.seats_available === 0 || !changingBus.pickup}
              onClick={() => {
                setSelectedPickup({ ...selectedPickup, newPickup: count.id });
                setIsBlocking(true);
              }}
            />
          ))}
        </div>
      )}
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          color={changingBus.pickup ? "primary" : "secondary"}
          size="lg"
          onClick={() => {
            if (changingBus.pickup) {
              registerToPickupBus();
            } else {
              setChangingBus({ ...changingBus, pickup: true });
              setTempPickupLocation(pickupLocation);
            }
          }}
          disabled={
            changingBus.pickup &&
            selectedPickup.newPickup === selectedPickup.originalPickup
          }
        >
          {changingBus.pickup ? "Confirm" : "Edit bus route"}
        </Button>
        {changingBus.pickup && (
          <Button
            color="secondary"
            size="lg"
            onClick={() => {
              setChangingBus({ ...changingBus, pickup: false });
              setSelectedPickup({
                originalPickup: selectedPickup.originalPickup,
                newPickup: selectedPickup.originalPickup,
              });
              setPickupLocation(tempPickupLocation);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
      <Spacer height={36} />
      <Text mods="bold textBody">Drop-off Location (Sunday, September 17)</Text>
      <Spacer height={12} />
      <Select
        fullWidth
        size="lg"
        value={dropoffLocation}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          if (e.target.value === "") {
            setSelectedDropoff({ ...selectedDropoff, newDropoff: -1 });
          }
          setDropoffLocation(e.target.value);
        }}
        disabled={!changingBus.dropoff}
      >
        {BUS_LOCATIONS.map((route, i) => (
          <Select.Option key={i} value={route}>
            {route}
          </Select.Option>
        ))}
        <Select.Option value={""}>I don&apos;t need a bus</Select.Option>
      </Select>
      <Spacer height={24} />
      {dropoffLocation !== "" && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "16px",
            width: "95%",
            marginBottom: "24px",
          }}
        >
          {sortedArrivalBuses?.map((count, i) => (
            <BusCard
              key={i}
              availableSeats={count.seats_available}
              departure={count.departure_time}
              arrival={count.arrival_time}
              selected={
                selectedDropoff.newDropoff === -1
                  ? count.id === selectedDropoff.originalDropoff
                  : count.id === selectedDropoff.newDropoff
              }
              disabled={count.seats_available === 0 || !changingBus.dropoff}
              onClick={() => {
                setSelectedDropoff({
                  ...selectedDropoff,
                  newDropoff: count.id,
                });
                setIsBlocking(true);
              }}
            />
          ))}
        </div>
      )}
      <div style={{ display: "flex", gap: "5px" }}>
        <Button
          color={changingBus.dropoff ? "primary" : "secondary"}
          size="lg"
          onClick={() => {
            if (changingBus.dropoff) {
              registerToDropoffBus();
            } else {
              setChangingBus({ ...changingBus, dropoff: true });
              setTempDropoffLocation(dropoffLocation);
            }
          }}
          disabled={
            changingBus.dropoff &&
            selectedDropoff.newDropoff === selectedDropoff.originalDropoff
          }
        >
          {changingBus.dropoff ? "Confirm" : "Edit bus route"}
        </Button>
        {changingBus.dropoff && (
          <Button
            color="secondary"
            size="lg"
            onClick={() => {
              setChangingBus({ ...changingBus, dropoff: false });
              setSelectedDropoff({
                originalDropoff: selectedDropoff.originalDropoff,
                newDropoff: selectedDropoff.originalDropoff,
              });
              setDropoffLocation(tempDropoffLocation);
            }}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};
