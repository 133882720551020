import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { Dropzone, FileLink } from "src/shared/components";
import {
  PerkStatus,
  SponsorPerkType,
  SponsorPerksData,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkTextArea } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { useFileDropzone } from "../useFileDropzone";
import { PerkData } from "../usePerkState";

export const SOCIAL_MEDIA_PROMOTION_VALIDATION_FIELDS: (keyof SponsorPerksData)[] =
  ["social_media_promotion_description", "social_media_promotion_logo"];

export const SocialMediaPromotion: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const {
    dropzoneInputRef,
    fetchedFile,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(getState("social_media_promotion_logo")?.[0], (files) =>
    updateState("social_media_promotion_logo", files)
  );

  return (
    <>
      <Header
        title="Social Media Promotion"
        subtitle="This perk is for promoting your company on our social media."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              getState("social_media_promotion_status") as PerkStatus
            )}
            size="sm"
          >
            {getState("social_media_promotion_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.SOCIAL_MEDIA_PROMOTION}
        readOnly={isReadOnly}
      >
        {!isReadOnly && (
          <Dropzone
            max={1}
            acceptedFileTypes={["png", "svg"]}
            onFilesUpload={addFile}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <FileLink file={fetchedFile} deleteFile={deleteFile} />
          </>
        )}
        {error?.social_media_promotion_logo && (
          <PerkError error="This field is required" />
        )}
        {fileError && <PerkError error={fileError} />}
        <Spacer height={32} />
        <PerkTextArea
          title="Description* (Max 150 Words)"
          value={getState("social_media_promotion_description") ?? ""}
          placeholder="Ex. Summer internships are now open! Head to mycompany.com/careers and apply before August 1st!"
          isReadOnly={isReadOnly}
          wordLimit={150}
          onChange={(e) =>
            updateState("social_media_promotion_description", e.target.value)
          }
          error={
            error?.social_media_promotion_description && (
              <>This field is required</>
            )
          }
        />
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <TextDescription>
    If you would like something promoted on our social media, please provide a
    graphic and a description to be posted on our Instagram story, LinkedIn, or
    other media outlets.
  </TextDescription>
);
