import { Flex, Spacer } from "@hackthenorth/north";
import React from "react";
import { styled } from "twin.macro";

import { UnstyledLink } from "src/shared/components";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { hideSidebarStyles } from "src/shared/contexts/SidebarContext/styles";
import { useDeviceSize } from "src/shared/hooks";
import { TWText } from "src/shared/styles";
import { ArrowLeft } from "src/static/icons";

import { RSVP_DEADLINE } from "../constants";
import { BUS_DEADLINE, FLIGHT_DEADLINE } from "../travel-form/constants";

interface TopNavBarProps extends React.ComponentPropsWithoutRef<"div"> {
  currentRoute: string;
}

export const RSVPHEADER_HEIGHT = 121;
export const RSVPHEADER_TABLET_HEIGHT = 165;

const BackText = styled.p`
  ${TWText.bodyBold}
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

const HeaderText = styled.h2`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 26px;
  color: ${({ theme }) => theme.globalConstants.color.borderGrayDark};
  margin: 0;
  line-height: 1.5;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}

  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const SubtitleText = styled.p`
  ${TWText.cta}
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  line-height: 1.375;
`;

const LeftContainer = styled(Flex)`
  position: fixed;
  top: 0;
  height: ${RSVPHEADER_HEIGHT}px;
  width: 289px;
  border-right: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};
  z-index: 99;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};
  background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
  padding-left: 48px;
  padding-top: 28px;
`;

const StyledLink = styled(UnstyledLink)`
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  height: 32px;
  gap: 8px;
  transition: opacity 100ms, background 300ms;
  &:hover {
    opacity: 0.8;
  }
`;

const Container = styled(Flex)<{
  isTabletOrSmaller: boolean;
}>`
  position: fixed;
  top: 0;
  height: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? RSVPHEADER_TABLET_HEIGHT : RSVPHEADER_HEIGHT}px;

  z-index: 98; /* modal zIndex is 100 */
  background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};

  align-items: center;

  padding-left: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "16px" : "calc(289px + 48px)"};

  ${hideSidebarStyles}
`;

const Header: React.FC<TopNavBarProps> = ({ currentRoute, ...rest }) => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isTravelDetails = currentRoute === "Travel Details";

  const { responsesState } = useHackerState(
    [Field.RSVP_DEADLINE, Field.TRAVEL_OPTION],
    {}
  );

  const title = isTravelDetails
    ? "Finalize travel to Hack the North 2024"
    : "RSVP to Hack the North 2024";

  const subtitle = isTravelDetails
    ? responsesState[Field.TRAVEL_OPTION] === "flight"
      ? `Deadline to submit is ${FLIGHT_DEADLINE}`
      : `Deadline to submit is ${BUS_DEADLINE}`
    : `Deadline to RSVP is ${RSVP_DEADLINE}`;

  return isTabletOrSmaller ? (
    <Container
      isTabletOrSmaller={isTabletOrSmaller}
      {...rest}
      tw="flex items-center px-48 md:px-24"
    >
      <Flex column align="start" justify="flex-start">
        <StyledLink href={"/hacker/rsvp"}>
          <ArrowLeft />
          <BackText>Back to Home</BackText>
        </StyledLink>
        <Spacer height="4px" />
        <HeaderText>{title}</HeaderText>
        <Spacer height="4px" />
        <SubtitleText>{subtitle}</SubtitleText>
      </Flex>
    </Container>
  ) : (
    <>
      <LeftContainer>
        <StyledLink href={"/hacker/rsvp"}>
          <ArrowLeft />
          <BackText>Back to Home</BackText>
        </StyledLink>
      </LeftContainer>
      <Container {...rest} isTabletOrSmaller={isTabletOrSmaller}>
        <Flex
          column
          align="start"
          justify="flex-start"
          style={{
            transform: "translateX(-24px)",
          }}
        >
          <HeaderText>{title}</HeaderText>
          <Spacer height={4} />
          <SubtitleText>{subtitle}</SubtitleText>
        </Flex>
      </Container>
    </>
  );
};

export default Header;
