import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { notBlank } from "src/shared/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.PREFERRED_NAME,
  Field.PRONOUNS,
  Field.BIOGRAPHY,
  Field.LINKEDIN,
  Field.TIKTOK,
  Field.INSTAGRAM,
  Field.FACEBOOK,
  Field.TWITTER,
  Field.DISCORD_TAG,
  Field.GITHUB,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.PREFERRED_NAME]: notBlank,
};
