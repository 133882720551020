import { Text } from "@hackthenorth/north";
import { Checkbox, Link, Select, TextInput } from "north.js";
import React, { useState } from "react";
import { styled } from "twin.macro";

import { Icon } from "src/shared/components";
import Label from "src/shared/components/Label";
import { Field } from "src/shared/contexts/HackerContext/types";
// import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { optionsToNorthV2Options } from "src/shared/utils/react-select";

import {
  REIMBURSEMENT_CURRENCIES,
  REIMBURSEMENT_METHODS,
  VALIDATORS,
} from "./constants";
import { SectionContainer } from "./flightForm";

export type ReimbursementPropsT = {
  showErrors: boolean;
  errors: any;
  setErrors: any;
  responsesState: any;
  setResponsesState: any;
};

const REIMBURSEMENT_INFO = [
  ["Reimbursement method", "When to expect a payment", "Currencies"],
  [
    "PayPal",
    "Up to 12 weeks after the event",
    Object.keys(REIMBURSEMENT_CURRENCIES).join(", "),
  ],
  ["Interac e-Transfer*", "Up to 12 weeks after the event", "CAD"],
];

export const Reimbursement: React.FC<ReimbursementPropsT> = ({
  showErrors,
  errors,
  setErrors,
  responsesState,
  setResponsesState,
}) => {
  const [didEdit, setDidEdit] = useState({
    method: false,
    followup: false,
    acknowledgement: false,
  });

  const FOLLOWUP = {
    paypal: (
      <Label value="What Paypal account should we send the reimbursement to?">
        <StyledTextInput
          value={responsesState[Field.REIMBURSEMENT_RECIPIENT_PAYPAL]}
          placeholder="Enter your PayPal details"
          hint="Please enter an email, phone number, or username associated with your PayPal account."
          error={didEdit.followup || showErrors ? errors.followup : null}
          onChange={(e) => {
            setDidEdit({ ...didEdit, followup: true });
            setErrors({
              ...errors,
              followup: e.target.value.length
                ? ""
                : "Please enter your PayPal details.",
            });
            setResponsesState(
              Field.REIMBURSEMENT_RECIPIENT_PAYPAL,
              e.target.value
            );
          }}
        />
      </Label>
    ),
    cadETransfer: (
      <Label value="What is your email to receive an e-Transfer?">
        <StyledTextInput
          value={responsesState[Field.REIMBURSEMENT_RECIPIENT_ETRANSFER]}
          placeholder="Enter your e-Transfer email"
          hint="We will be sending an Interac e-Transfer to this email address."
          error={didEdit.followup || showErrors ? errors.followup : null}
          onChange={(e) => {
            setDidEdit({ ...didEdit, followup: true });
            setErrors({
              ...errors,
              followup: e.target.value.length
                ? ""
                : "Please enter your email address.",
            });
            setResponsesState(
              Field.REIMBURSEMENT_RECIPIENT_ETRANSFER,
              e.target.value
            );
          }}
        />
      </Label>
    ),
  };

  return (
    <SectionContainer>
      <div>
        <Text mods="big textBody">
          We offer the following reimbursement methods:
        </Text>
        <Table>
          {REIMBURSEMENT_INFO.map((row, y) =>
            row.map((entry, x) =>
              y === 0 ? (
                <TableHeader key={x}>{entry}</TableHeader>
              ) : (
                <TableBody key={x} y={y} x={x}>
                  {entry}
                </TableBody>
              )
            )
          )}
        </Table>
        <Text mods="caption grey lh-small">
          *Only available for Canadian bank account holders
        </Text>
      </div>
      <Label
        value="What is your preferred form of reimbursement?"
        caption=""
        error={
          didEdit.method || showErrors
            ? errors[Field.REIMBURSEMENT_METHOD]
            : null
        }
      >
        <Select
          fullWidth
          size="lg"
          value={responsesState[Field.REIMBURSEMENT_METHOD]}
          onChange={(e) => {
            const validator = VALIDATORS[Field.REIMBURSEMENT_METHOD];
            if (validator) {
              setErrors({
                ...errors,
                [Field.REIMBURSEMENT_METHOD]: validator(e.target.value)
                  ? ""
                  : "Please select a reimbursement method.",
              });
            }
            setResponsesState(Field.REIMBURSEMENT_METHOD, e.target.value);
          }}
          style={{ color: "rgba(31, 41, 55, 1)" }}
        >
          <Select.Option selected disabled>
            Select a reimbursement method
          </Select.Option>
          {optionsToNorthV2Options(REIMBURSEMENT_METHODS)}
        </Select>
      </Label>
      {responsesState[Field.REIMBURSEMENT_METHOD] &&
        FOLLOWUP[responsesState[Field.REIMBURSEMENT_METHOD] as string]}
      <InfoBadge color="primary-light">
        <div style={{ width: "32px", height: "32px" }}>
          <Icon name="info" size="heading" />
        </div>
        <Text mods="big medium blue lh-regular">
          If your banking/payment information changes between now and{" "}
          <b>September 13th, 2024</b>, it is your responsibility to inform us
          via email at{" "}
          <Link href="mailto:travel@hackthenorth.com">
            travel@hackthenorth.com
          </Link>
          .
        </Text>
      </InfoBadge>
      <Label
        value="Reimbursement conditions acknowledgement"
        error={
          didEdit.acknowledgement || showErrors
            ? errors[Field.REIMBURSEMENT_ACKNOWLEDGEMENT]
            : null
        }
      >
        <Checkbox
          label={
            <Text mods="big medium grey lh-small">
              I understand that I will not be reimbursed until I attend Hack the
              North. I further understand that failing to follow the rules
              stated on this page and in our{" "}
              <Link href="https://hackthenorth.com/travel-guidelines">
                Travel Guidelines
              </Link>{" "}
              may lead to my reimbursement being delayed or revoked.
            </Text>
          }
          checked={!!responsesState[Field.REIMBURSEMENT_ACKNOWLEDGEMENT]}
          onChange={(e) => {
            setDidEdit({
              ...didEdit,
              acknowledgement: true,
            });
            const validator = VALIDATORS[Field.REIMBURSEMENT_ACKNOWLEDGEMENT];
            if (validator) {
              setErrors({
                ...errors,
                [Field.REIMBURSEMENT_ACKNOWLEDGEMENT]: validator(
                  e.currentTarget.checked
                )
                  ? ""
                  : "Please acknowledge the reimbursement policy.",
              });
            }
            setResponsesState(
              Field.REIMBURSEMENT_ACKNOWLEDGEMENT,
              e.currentTarget.checked
            );
          }}
        />
      </Label>
    </SectionContainer>
  );
};

const TableHeader = styled(Text).attrs({ mods: "big bold textBody" })`
  padding: 8px;
`;
const TableBody = styled(Text).attrs({ mods: "medium grey" })<{
  y: number;
  x: number;
}>`
  padding: 8px;
  background: ${({ theme, y, x }) =>
    y % 2 === 0
      ? theme.globalConstants.color.white
      : theme.globalConstants.color.backgroundLight};
  font-size: ${({ theme, y, x }) =>
    x % 3 === 2
      ? theme.globalConstants.fontSize.caption
      : theme.globalConstants.fontSize.body}px !important;
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const InfoBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 8px;
  width: 100%;
  justify-content: space-between;

  background: ${({ theme }) =>
    theme.globalConstants.color.backgroundPrimaryLight};
`;

const StyledTextInput = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;
