/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type RegisterBadgeCodeToEventAttendeeMutationVariables = Types.Exact<{
  event_attendee_id: Types.Scalars["Float"];
  user_id: Types.Scalars["Float"];
  badge_code: Types.Scalars["String"];
}>;

export type RegisterBadgeCodeToEventAttendeeMutation = {
  registerBadgeCodeToEventAttendee: Pick<
    Types.EventAttendee,
    | "badge_code"
    | "created_at"
    | "event_id"
    | "id"
    | "role"
    | "updated_at"
    | "user_id"
  >;
};

export const RegisterBadgeCodeToEventAttendeeDocument = gql`
  mutation RegisterBadgeCodeToEventAttendee(
    $event_attendee_id: Float!
    $user_id: Float!
    $badge_code: String!
  ) {
    registerBadgeCodeToEventAttendee(
      event_attendee_id: $event_attendee_id
      user_id: $user_id
      badge_code: $badge_code
    ) {
      badge_code
      created_at
      event_id
      id
      role
      updated_at
      user_id
    }
  }
`;

/**
 * __useRegisterBadgeCodeToEventAttendeeMutation__
 *
 * To run a mutation, you first call `useRegisterBadgeCodeToEventAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBadgeCodeToEventAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBadgeCodeToEventAttendeeMutation, { data, loading, error }] = useRegisterBadgeCodeToEventAttendeeMutation({
 *   variables: {
 *      event_attendee_id: // value for 'event_attendee_id'
 *      user_id: // value for 'user_id'
 *      badge_code: // value for 'badge_code'
 *   },
 * });
 */
export function useRegisterBadgeCodeToEventAttendeeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterBadgeCodeToEventAttendeeMutation,
    RegisterBadgeCodeToEventAttendeeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterBadgeCodeToEventAttendeeMutation,
    RegisterBadgeCodeToEventAttendeeMutationVariables
  >(RegisterBadgeCodeToEventAttendeeDocument, baseOptions);
}
export type RegisterBadgeCodeToEventAttendeeMutationHookResult = ReturnType<
  typeof useRegisterBadgeCodeToEventAttendeeMutation
>;
export type RegisterBadgeCodeToEventAttendeeMutationResult =
  ApolloReactCommon.MutationResult<RegisterBadgeCodeToEventAttendeeMutation>;
export type RegisterBadgeCodeToEventAttendeeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RegisterBadgeCodeToEventAttendeeMutation,
    RegisterBadgeCodeToEventAttendeeMutationVariables
  >;
