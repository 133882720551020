import { DurationInput } from "luxon";
import { Select } from "north.js";
import React from "react";
import { OptionTypeBase, OptionsType, ValueType } from "react-select";

import { TFormatDateTime } from "./formatTime";
import { THackerAPIDatetime } from "./hackerapi";
import { Nullable } from "./typescript";

const stringToOption = (answer: string) => {
  return { label: answer, value: answer };
};

export const answerToOption = (
  answer?: Nullable<string>
): Nullable<OptionTypeBase> => {
  return answer ? stringToOption(answer) : undefined;
};

export const answersToOptions = (
  answers: string[]
): OptionsType<OptionTypeBase> => answers.map(stringToOption);

export const answersToNorthV2Options = (
  answers: string[]
): React.ReactElement[] =>
  answers.map((ans) => (
    <Select.Option key={ans} value={ans}>
      {ans}
    </Select.Option>
  ));

export const optionsToNorthV2Options = (
  options: { label: string; value: string }[]
) =>
  options
    ? options.map((op) => (
        <Select.Option value={op.value} key={op.value}>
          {op.label}
        </Select.Option>
      ))
    : [];

export const answersToOptionsNew = (
  answers: string[]
): { label: string; value: string }[] => answers.map(stringToOption);

export const optionsToAnswers = (
  options: OptionsType<OptionTypeBase> | ValueType<OptionTypeBase, true>
) => (options ? options.map((option) => option.value) : []);

export const optionsToAnswersNew = (
  options: { label: string; value: string }[]
) => (options ? options.map((option) => option.value) : []);

export const timeToOption = (
  formatTime: (t: Date, endt?: Date | null) => string,
  time?: Date | null,
  endTime?: Date | null
) => {
  return time && time !== null
    ? {
        label: formatTime(time, endTime),
        value: time,
      }
    : undefined;
};

export const timesToOptions = (
  formatTime: (t: Date, endt?: Date | null) => string,
  times: Date[]
) => times.map((time) => timeToOption(formatTime, time));

export const timeToOptionV2 = (
  formatTime: TFormatDateTime,
  start: THackerAPIDatetime,
  duration?: DurationInput
) => ({
  label: formatTime(start, duration ?? { hours: 1 }),
  value: start,
});

export const timesToOptionsV2 = (
  formatTime: TFormatDateTime,
  times: THackerAPIDatetime[]
) => times.map((time) => timeToOptionV2(formatTime, time));

export const timesToOptionsExplicitV2 = (
  formatTime: TFormatDateTime,
  times: [THackerAPIDatetime, DurationInput][]
): {
  label: string;
  value: string;
}[] => {
  if (!times || times.length === 0) return [];
  return times
    .map(([time, duration]) => timeToOptionV2(formatTime, time, duration))
    .filter((time) => time !== null) as {
    label: string;
    value: string;
  }[];
};

export const formatSelectedOptions = (options: OptionsType<OptionTypeBase>) =>
  `${options.length} selected: ${options.map(({ label }) => label).join(", ")}`;

export type Options = OptionsType<OptionTypeBase>;

export type Option = OptionTypeBase;
