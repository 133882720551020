import { Flex, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";

import Section from "./Section";

export const TOTAL_HEADER_HEIGHT_PX = 72;

const ACCEPTED_FIELDS = [
  Field.PERSONAL_RSVP_STAGE,
  Field.TRAVEL_RSVP_STAGE,
  Field.EVENT_RSVP_STAGE,
  Field.CAREER_RSVP_STAGE,
  Field.CONFIRMATION_RSVP_STAGE,
];

export type TStepperProps = {
  currentRoute: string;
};

type TRouteInfo = {
  title: string;
  to: string; // where it goes on click, defaults to the same routename as what displays the section
  completed: boolean;
};

const Stepper: React.FC<TStepperProps> = ({ currentRoute }) => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});

  const route_info: TRouteInfo[] = [
    {
      title: "Personal Information",
      to: "/hacker/personal",
      completed:
        responsesState[Field.PERSONAL_RSVP_STAGE] === PageStage.COMPLETED,
    },
    {
      title: "Travel",
      to: "/hacker/travel",
      completed:
        responsesState[Field.TRAVEL_RSVP_STAGE] === PageStage.COMPLETED,
    },
    {
      title: "Event Information",
      to: "/hacker/event",
      completed: responsesState[Field.EVENT_RSVP_STAGE] === PageStage.COMPLETED,
    },
    {
      title: "Career",
      to: "/hacker/career",
      completed:
        responsesState[Field.CAREER_RSVP_STAGE] === PageStage.COMPLETED,
    },
    {
      title: "Confirmation",
      to: "/hacker/confirmation",
      completed:
        responsesState[Field.CONFIRMATION_RSVP_STAGE] === PageStage.COMPLETED,
    },
  ];

  let currentStage = "Personal Information";
  if (responsesState[Field.CAREER_RSVP_STAGE] === PageStage.COMPLETED) {
    currentStage = "Confirmation";
  } else if (responsesState[Field.EVENT_RSVP_STAGE] === PageStage.COMPLETED) {
    currentStage = "Career";
  } else if (responsesState[Field.TRAVEL_RSVP_STAGE] === PageStage.COMPLETED) {
    currentStage = "Event Information";
  } else if (
    responsesState[Field.PERSONAL_RSVP_STAGE] === PageStage.COMPLETED
  ) {
    currentStage = "Travel";
  }

  return (
    <Container show isTabletOrSmaller={isTabletOrSmaller} aria-hidden={false}>
      <ContentContainer align="center">
        <Spacer height="5%" />
        {route_info.map((route, i) => {
          const isNotFirstStep = i !== 0;
          const current = route.title === currentRoute;
          return (
            <React.Fragment key={i}>
              {isNotFirstStep && <Line completed={route.completed} />}
              <Section
                title={route.title}
                step={i + 1}
                current={current}
                currentStage={currentStage === route.title}
                completed={route.completed}
                to={route.to}
              />
            </React.Fragment>
          );
        })}
        <Spacer width="5%" />
      </ContentContainer>
    </Container>
  );
};

export default Stepper;

const Container = styled.header<{ show: boolean; isTabletOrSmaller: boolean }>`
  display: flex;
  justify-content: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "flex-start" : "center"};
  align-items: center;
  position: relative;
  z-index: ${({ show }) => (show ? 1 : -2)};
  width: 100%;
  transition: opacity 400ms;
  opacity: ${({ show }) => (show ? 1 : 0)};
  max-width: 900px;
  padding-bottom: 24px;
  margin-left: ${({ isTabletOrSmaller }) => isTabletOrSmaller && "10px"};
`;

const ContentContainer = styled(Flex)`
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
  /**
   * Hide scrollbar but keep functionality
   * NOTE: https://stackoverflow.com/a/49278385/13796346
   */
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const Line = styled.div<{ completed: boolean }>`
  background-color: ${({ completed, theme }) =>
    completed
      ? theme.globalConstants.color.brandSecondary
      : theme.globalConstants.color.backgroundGrayDark};
  background-position: bottom;
  margin-left: 16px;
  height: 32px;
  width: 2px;
  flex-grow: 1;
`;
