import { Spacer } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";
import styled from "styled-components";

import { InfoTag } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { useDeviceSize } from "src/shared/hooks";

import { TEvent } from "./Calendar";

interface EventProps {
  isOpen: boolean;
  onClose: () => void;
  event: TEvent;
}

const eventColors = {
  "Main Event": "#43AFDE",
  Workshop: "#54C152",
  Activity: "#F59E0B",
  Meetup: "#D95858",
  Sponsor: "#BD63CF",
  "Tech Talk": "#6B7280",
  Panel: "#C4A484",
};

const eventLead = {
  "Main Event": "Event leads",
  Workshop: "Workshop instructors",
  Activity: "Activity leads",
  Meetup: "Meetup leads",
  Sponsor: "Presented by",
  "Tech Talk": "Presented by",
};

const getTime = (date: Date) => {
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "America/Toronto",
  });
  return time.charAt(0) === "0" ? time.slice(1) : time;
};

const getDay = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
};

const EventModal: React.FC<EventProps & React.HTMLAttributes<HTMLDivElement>> =
  (props) => {
    const {
      title,
      location,
      start_time,
      end_time,
      type,
      description,
      leads,
      resources,
    } = props.event;
    const isLargeMobileOrSmaller = useDeviceSize("largeMobile");

    const getSubtitle = () =>
      `${location} @ ${getTime(start_time)}${
        getDay(start_time) === getDay(end_time)
          ? ""
          : ` on ${getDay(start_time)}`
      } - ${getTime(end_time)} on ${getDay(end_time)}`;

    return (
      <StyledModal isOpen={props.isOpen} onClose={props.onClose} width="750px">
        <OuterContainer>
          <InnerContainer isLargeMobileOrSmaller={isLargeMobileOrSmaller}>
            <Heading3Text>{title}</Heading3Text>
            {isLargeMobileOrSmaller ? (
              <Spacer height={16} />
            ) : (
              <Spacer width={8} />
            )}
            <StatusBadge color={eventColors[type]}>{type}</StatusBadge>{" "}
          </InnerContainer>
        </OuterContainer>
        <Spacer height={24} />
        <SubtitleText>{getSubtitle()}</SubtitleText>
        <Spacer height={24} />
        <CaptionText>{description}</CaptionText>
        {leads.length > 0 && (
          <>
            <Spacer height={24} />
            <InnerContainer isLargeMobileOrSmaller={isLargeMobileOrSmaller}>
              <SubtitleText>{`${eventLead[type]}:`}</SubtitleText>
              <Spacer width={8} />
              {leads.map((lead, i) => (
                <FlexContainer key={`lead${i}`}>
                  <CaptionText>
                    {lead}
                    {i + 1 !== leads.length ? "," : ""}
                  </CaptionText>
                  <Spacer width={8} />
                </FlexContainer>
              ))}
            </InnerContainer>
          </>
        )}
        {resources.length > 0 && (
          <>
            <Spacer height={24} />
            <InnerContainer isLargeMobileOrSmaller={isLargeMobileOrSmaller}>
              <SubtitleText>Resources:</SubtitleText>
              <Spacer width={8} />
              {resources.map((resource, i) => (
                <FlexContainer key={`resource${i}`}>
                  <CaptionText>
                    <Link href={resource}>{resource}</Link>
                    {i + 1 !== resources.length ? "," : ""}
                  </CaptionText>
                  <Spacer width={8} />
                </FlexContainer>
              ))}
            </InnerContainer>
          </>
        )}
      </StyledModal>
    );
  };

const StyledModal = styled(Modal)`
  max-width: 100vw;
  max-height: 80vh;
  overflow: auto;
`;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 28px;
`;

const InnerContainer = styled.div<{ isLargeMobileOrSmaller: boolean }>`
  ${({ isLargeMobileOrSmaller }) =>
    isLargeMobileOrSmaller ? "" : "display: flex; align-items: center;"}
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Heading3Text = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 20px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 18px;
  `}
`;

const StatusBadge = styled(InfoTag)<{
  color: string;
}>`
  padding: 4px 10px;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.globalConstants.color.white} !important;
`;

const SubtitleText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 500;
  font-size: 16px;
`;

const CaptionText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 400;
  font-size: 16px;
`;

export default EventModal;
