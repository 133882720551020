import { Checkbox, Modal, Button } from "north.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { errorToast } from "src/shared/components/Toast";
import { useSponsorContext, useUserContext } from "src/shared/contexts";

import { SponsorOnboardingStage, OnboardingSteps } from "./data";

const TextContainer = styled.div<{
  hasConfirmation: boolean;
}>`
  max-height: 400px;
  overflow-y: scroll;

  border-bottom: ${({ hasConfirmation }) =>
    hasConfirmation ? "none" : "2px solid #dddddd"};
  margin-bottom: ${({ hasConfirmation }) => (hasConfirmation ? "0" : "16px")};
`;

const Onboarding: React.FC = () => {
  const { info } = useUserContext();
  const { company, updateSponsorCompany, refetchSponsorCompany } =
    useSponsorContext();

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (company && !company.tc_accepted && step === 0) setOpen(true);
  }, [company, step]);

  const nextStep = async () => {
    if (OnboardingSteps[step].stage === SponsorOnboardingStage.START) {
      setStep(step + 1);
      setChecked(false);
    } else if (
      OnboardingSteps[step].stage ===
      SponsorOnboardingStage.TERMS_AND_CONDITIONS
    ) {
      if (!checked) {
        errorToast("Check the box to accept the terms and conditions");
        return;
      }
      await updateSponsorCompany({
        tc_accepted: { set: true },
      });
      if (refetchSponsorCompany) await refetchSponsorCompany();
      setStep(step + 1);
      setChecked(false);
    } else if (
      OnboardingSteps[step].stage === SponsorOnboardingStage.COMPLETED
    ) {
      setOpen(false);
    }
  };

  return (
    <Modal open={open} onClose={() => {}}>
      <Modal.Header>{OnboardingSteps[step].title}</Modal.Header>
      <Modal.Body>
        <TextContainer
          hasConfirmation={OnboardingSteps[step].confirmation === null}
        >
          {OnboardingSteps[step].description(info?.name)}
        </TextContainer>
        {OnboardingSteps[step].confirmation && (
          <Checkbox
            onChange={() => setChecked(!checked)}
            checked={checked}
            label={OnboardingSteps[step].confirmation}
            size={18}
          />
        )}
      </Modal.Body>
      <Modal.Footer justify="end">
        <Button color="primary" size="lg" onClick={() => nextStep()}>
          {OnboardingSteps[step].submitBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Onboarding;
