import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { NewsletterOpportunityData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkDatePicker,
  PerkError,
  PerkTextInput,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

import Preview from "./Preview";

export const NEWSLETTER_OPPORTUNITY_VALIDATION_FIELDS: (keyof NewsletterOpportunityData)[] =
  [
    "newsletter_opportunity_name",
    "newsletter_opportunity_link",
    "newsletter_opportunity_due_date",
  ];

export const NewsletterOpportunity: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => (
  <>
    <Header
      title="Newsletter: Opportunity"
      subtitle="This perk is for showcasing company opportunities in our monthly newsletter."
      badge={
        <Label
          color={perkStatusToBadgeColor(
            (getState("newsletter_opportunity_status") ?? []) as PerkStatus
          )}
          size="sm"
        >
          {getState("newsletter_opportunity_status")}
        </Label>
      }
    />
    <PerkContainer
      description={<Description />}
      id={SponsorPerkType.NEWSLETTER_OPPORTUNITY}
      readOnly={isReadOnly}
    >
      <PerkTextInput
        title="Opportunity Type*"
        value={getState("newsletter_opportunity_name") ?? ""}
        placeholder="Job opening, scholarship, conference, fellowship, workshop, etc"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("newsletter_opportunity_name", e.target.value)
        }
        error={
          error?.newsletter_opportunity_name && <>This field is required</>
        }
      />
      <Spacer height={32} />
      <PerkTextInput
        title="Link*"
        value={getState("newsletter_opportunity_link") ?? ""}
        placeholder="A link to the opportunity"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("newsletter_opportunity_link", e.target.value)
        }
        error={
          error?.newsletter_opportunity_link && <>This field is required</>
        }
      />
      <Spacer height={32} />
      <PerkDatePicker
        title="Registration Due Date*"
        value={getState("newsletter_opportunity_due_date") ?? "YYYY-MM-DD"}
        onChange={(date) => {
          if (date) updateState("newsletter_opportunity_due_date", date);
        }}
        isReadOnly={isReadOnly}
      />
      {error?.newsletter_opportunity_due_date && (
        <PerkError error="This field is required" />
      )}
      <Spacer height={48} />
      <Preview
        link={getState("newsletter_opportunity_link") ?? ""}
        name={getState("newsletter_opportunity_name") ?? ""}
      />
    </PerkContainer>
  </>
);

const Description = () => (
  <>
    <TextDescription>
      The Hack the North newsletter is sent out to hackers in the middle of
      every month.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      All sponsors are eligible for one Opportunity each month. We ask that you
      share an opportunity our community can benefit from, including job
      openings, scholarships, conferences, etc.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Please indicate in the description the opportunity type and the due date,
      if applicable.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Once your submission is included in the upcoming month&apos;s newsletter,
      you will see this perk under the Complete section. Please allow us at
      least a week following your submission for our team to review.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Check out our{" "}
      <Link
        href="https://hackthenorth.com/sponsorship-newsletter-template.pdf"
        target="_blank"
      >
        sample newsletter template here
      </Link>{" "}
      to see an example of the layout.
    </TextDescription>
  </>
);
