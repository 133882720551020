import { DropdownMenu } from "north.js";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { useSidebarContent } from "src/shared/components/Sidebar/useSidebarContent";
import { Route } from "src/shared/constants/route";
import { useDeviceSize } from "src/shared/hooks";

export const TopBar = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const { sections } = useSidebarContent();

  const navigate = useNavigate();

  const items = sections.map((section) =>
    section.content.map((page) => ({
      content: page.name,
      action: () => {
        navigate(page.href ? page.href : Route.HACKER_RSVP);
      },
    }))
  );

  if (!isTabletOrSmaller) {
    return <Container />;
  }

  return (
    <Container>
      <StyledDropdown items={items}>
        <Icon name={"hamburger"} />
      </StyledDropdown>
    </Container>
  );
};

const StyledDropdown = styled(DropdownMenu)`
  left: 0px;
`;

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 80px;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};
  background: ${({ theme }) => theme.globalConstants.color.backgroundGray};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 36px;
  padding-right: 21px;
  z-index: 51;
`;
