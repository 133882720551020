/* eslint-disable @typescript-eslint/camelcase */
import { Button, Label, Modal, Spinner, Text } from "north.js";
import React, { useState } from "react";
import "twin.macro";

import { useLazyQuery } from "src/api/utils/useLazyQuery";
import { useMentorContext, useUserContext } from "src/shared/contexts";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { formatTime } from "src/shared/utils/formatTime";

import {
  GetMentorshipRequestQueryResult,
  GetMentorshipRequestQueryVariables,
  GetMentorshipRequestDocument,
} from "./graphql/getMentorshipRequest.generated";

import { CheckIcon } from "@heroicons/react/24/outline";

interface ClaimRequestModalProps {
  onClose: (claimedRequest: boolean) => void;
  isOpen: boolean;
  request?: MentorshipRequest;
}

export const ClaimRequestModal: React.FC<ClaimRequestModalProps> = ({
  onClose,
  isOpen,
  request,
}) => {
  const { updateRequest } = useMentorContext();
  const { id } = useUserContext();

  const [buttonState, setButtonState] = useState<
    "default" | "confirming" | "claiming" | "claimed" | "failed"
  >("default");

  const getMentorshipRequest = useLazyQuery<
    GetMentorshipRequestQueryResult["data"],
    GetMentorshipRequestQueryVariables
  >(GetMentorshipRequestDocument);

  const onExit = (claimedRequest = false) => {
    onClose(claimedRequest);
    setButtonState("default");
  };

  const handleConfirm = async () => {
    if (request) {
      setButtonState("claiming");

      const { data: requestData } = await getMentorshipRequest({
        claimId: request.id,
      });

      setButtonState("failed");

      const stage = requestData?.claims[0].stage.slug;
      if (stage !== MentorshipRequestStage.OPEN || !id) {
        setButtonState("failed");
      } else {
        setButtonState("claimed");
        await updateRequest({
          ...request,
          mentor_id: id,
          stage_slug: MentorshipRequestStage.CLAIMED,
        });
        setTimeout(() => onExit(true), 1500);
      }
    }
  };

  return (
    <Modal open={isOpen} onClose={onExit} tw="min-width[800px] p-40!">
      <Modal.Header>
        <div tw="flex flex-col">
          {request?.created_at && (
            <Text type="body2" tw="mb-16">
              Created {formatTime(request.created_at, true)}
            </Text>
          )}
          <Text type="h1">{request?.title}</Text>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div tw="flex mb-24">
          <Text type="button">Requester</Text>
          <div tw="mr-24" />
          <Text type="body1">{request?.mentee_name}</Text>
        </div>

        {(request?.category ?? []).length > 0 && (
          <div tw="flex mb-24">
            <Text type="button">
              {(request?.category?.length ?? 0) > 0 ? "Categories" : "Category"}
            </Text>
            <div tw="mr-24" />
            <div tw="flex align-items[center] flex-wrap gap-8">
              {(request?.category ?? []).map((category) => (
                <>
                  <Label size="sm" color="secondary-light" key={category}>
                    {category}
                  </Label>
                </>
              ))}
            </div>
          </div>
        )}

        <div tw="flex mb-24">
          <Text type="button">Description</Text>
          <div tw="mr-24" />
          <Text type="body1">{request?.description}</Text>
        </div>
      </Modal.Body>
      <Modal.Footer justify="end">
        {buttonState === "claimed" ? (
          <Text type="body1" tw="font-medium text-text-success mr-8">
            You have successfully claimed this request{" "}
            <span role="img" aria-label="happy emoji">
              😀
            </span>
          </Text>
        ) : buttonState === "failed" ? (
          <Text type="body1" tw="font-medium text-text-danger mr-8">
            Unfortunately, another mentor has claimed this request{" "}
            <span role="img" aria-label="sad emoji">
              😔
            </span>
          </Text>
        ) : (
          <Button size="md" color="tertiary" onClick={() => onExit()}>
            Cancel
          </Button>
        )}

        {buttonState === "default" ? (
          <Button
            size="md"
            color="primary"
            onClick={() => setButtonState("confirming")}
          >
            Claim
          </Button>
        ) : buttonState === "confirming" ? (
          <Button
            size="md"
            color="success"
            onClick={handleConfirm}
            leadingIcon={<CheckIcon tw="w-20 h-20" />}
          >
            Confirm
          </Button>
        ) : buttonState === "claiming" ? (
          <Button
            size="md"
            color="success"
            leadingIcon={<Spinner tw="w-16! h-16!" />}
          >
            Confirm
          </Button>
        ) : buttonState === "failed" ? (
          <Button size="md" color="danger" onClick={() => onExit()}>
            Dismiss
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};
