import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import { showTravel } from "../home/util";

export const TravelFormSidebar: React.FC = () => {
  const { responsesState } = useHackerState([Field.TRAVEL_OPTION], {});

  const method = showTravel(responsesState[Field.TRAVEL_OPTION]);

  return (
    <SidebarContainer>
      <Text mods="blueGrey">
        You have indicated <b>{method}</b> as your travel method to Hack the
        North 2024.
      </Text>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  padding-left: 48px;
  padding-right: 42px;
`;
