import { useUploadFileMutation } from "src/api/files/uploadFile.generated";
import { HackerAPIFile } from "src/shared/utils/hackerapi";

import { CURRENT_EVENT_SLUG } from "../constants/event";

export const useUploadFile = () => {
  const [uploadFileMutation, mutationResult] = useUploadFileMutation();

  const uploadFile = async (file: File): Promise<HackerAPIFile | null> => {
    const { data, errors } = await uploadFileMutation({
      variables: {
        type: file.type,
        eventSlug: CURRENT_EVENT_SLUG,
        file: file,
      },
    });

    const hapiFile = {
      id: data?.upload.id,
      name: data?.upload.name,
      mime_type: data?.upload.mime_type,
      ...data?.upload.download,
    } as HackerAPIFile;

    if (errors) console.error(errors);

    return hapiFile;
  };

  const uploadFiles = async (
    files: File[]
  ): Promise<(HackerAPIFile | null)[]> => {
    const hapiFiles = await Promise.all(files.map((file) => uploadFile(file)));

    return hapiFiles;
  };

  return { uploadFile, uploadFiles, mutationResult };
};
