import { Spacer, Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React, { useEffect, useState } from "react";

type TTravelInformationProps = {
  title: string;
  reimbursementAmount?: number;
};

const TravelInformation: React.FC<TTravelInformationProps> = ({
  title,
  reimbursementAmount,
}) => {
  const TRAVEL_INFORMATION: { [title: string]: JSX.Element } = {};
  const [isEligible, setIsEligible] = useState(false);
  useEffect(() => {
    if (reimbursementAmount) {
      setIsEligible(reimbursementAmount > 0);
    }
  }, [reimbursementAmount]);
  TRAVEL_INFORMATION["flight"] = (
    <>
      <Text mods="bold textBody">Flight Details</Text>
      <Spacer height={12} />
      {!isEligible && (
        <Text>
          You aren’t eligible for a flight reimbursement! Please email{" "}
          <Link href="mailto:travel@hackthenorth.com">
            <strong>travel@hackthenorth.com</strong>
          </Link>{" "}
          with your starting destination if you believe this is a mistake.
        </Text>
      )}
      {isEligible && (
        <>
          {/* <Text>
            Hack the North will be providing you with a reimbursement of up to{" "}
            <span
              style={{
                background: "#FFFA77",
                mixBlendMode: "multiply",
              }}
            >
              {"CAD$"}
              {reimbursementAmount}
            </span>{" "}
            to help cover the cost of your flights to and from the event.{" "}
          </Text> */}
          <Text>
            You will only be reimbursed if you follow the rules stated in our{" "}
            <Link href="https://hackthenorth.com/travel-guidelines">
              <strong>Travel Guidelines</strong>
            </Link>{" "}
            - these guidelines also apply for booking flights.{" "}
            <strong>
              In particular, you must be in attendance for the duration of the
              event and submit a project at Hack the North 2024 in order to
              receive your travel reimbursement.
            </strong>
          </Text>
          <Text>
            <ul>
              <li>
                If you are <strong>flying to Toronto Pearson Airport</strong>,
                Hack the North volunteers will be waiting for you to direct you
                to shuttle buses that will take you to Hack the North.{" "}
              </li>
              <li>
                If you are{" "}
                <strong>not flying to Toronto Pearson Airport</strong>, you will
                be responsible for finding your own way to Hack the North.
              </li>
            </ul>
          </Text>
          <Text>
            More information about travel reimbursements and flight details will
            be provided after you complete this RSVP.
          </Text>
        </>
      )}
    </>
  );
  TRAVEL_INFORMATION["bus"] = (
    <>
      <Text mods="bold textBody">Hack the North provided bus details</Text>
      <Spacer height={12} />
      <Text>
        Hack the North will be sending buses to certain locations. These buses
        are free to ride for all hackers and will deliver hackers to and from
        the event. The locations with Hack the North bus service include:
        <ul>
          <li>Toronto (University of Toronto, St. George Campus)</li>
          <li>Kingston (Queen&apos;s University)</li>
          <li>London (Western University)</li>
          <li>Ottawa (University of Ottawa)</li>
          <li>Windsor (University of Windsor)</li>
          <li>Ann Arbor (University of Michigan - Ann Arbor)</li>
        </ul>
      </Text>
      <Text>
        Note that these locations may change based on popularity. In particular,
        bus availability is not guaranteed and you may need to find your way to
        our event if there is no more availability on your preferred route. More
        information regarding bus schedules and signup will be provided after
        you complete this RSVP.
      </Text>
    </>
  );
  TRAVEL_INFORMATION["drive"] = (
    <>
      <Text mods="bold textBody">Drive Details</Text>
      <Spacer height={12} />
      <Text>
        Unfortunately, we will not be reimbursing gas or parking costs, but
        there are free parking locations within walking distance from campus.
        Please email{" "}
        <Link href="mailto:travel@hackthenorth.com">
          <strong>travel@hackthenorth.com</strong>
        </Link>{" "}
        for parking locations.
      </Text>
    </>
  );
  TRAVEL_INFORMATION["other"] = (
    <>
      <Text mods="bold textBody">Other Details</Text>
      <Spacer height={12} />
      <Text>
        Have a safe trip! Please let{" "}
        <Link href="mailto:travel@hackthenorth.com">
          <strong>travel@hackthenorth.com</strong>
        </Link>{" "}
        know if there’s any way we can help!
      </Text>
    </>
  );
  TRAVEL_INFORMATION["walk"] = (
    <>
      <Text mods="bold textBody">Walk Details</Text>
      <Spacer height={12} />
      <Text>
        Have a safe trip! Please let{" "}
        <Link href="mailto:travel@hackthenorth.com">
          <strong>travel@hackthenorth.com</strong>
        </Link>{" "}
        know if there’s any way we can help!
      </Text>
    </>
  );

  return TRAVEL_INFORMATION[title];
};

export default TravelInformation;
