/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type GetSignageQuestionByIdQueryVariables = Types.Exact<{
  question_id: Types.Scalars["Float"];
}>;

export type GetSignageQuestionByIdQuery = {
  getSignageQuestionById: Pick<
    Types.SignageQuestion,
    "question_id" | "title" | "workshop_name"
  > & {
    signage_answers?: Types.Maybe<Array<Pick<Types.SignageAnswer, "value">>>;
    signage_question_tags?: Types.Maybe<
      Array<Pick<Types.SignageQuestionTag, "stage">>
    >;
  };
};

export const GetSignageQuestionByIdDocument = gql`
  query GetSignageQuestionById($question_id: Float!) {
    getSignageQuestionById(question_id: $question_id) {
      question_id
      title
      workshop_name
      signage_answers {
        value
      }
      signage_question_tags {
        stage
      }
    }
  }
`;

/**
 * __useGetSignageQuestionByIdQuery__
 *
 * To run a query within a React component, call `useGetSignageQuestionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignageQuestionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignageQuestionByIdQuery({
 *   variables: {
 *      question_id: // value for 'question_id'
 *   },
 * });
 */
export function useGetSignageQuestionByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSignageQuestionByIdQuery,
    GetSignageQuestionByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSignageQuestionByIdQuery,
    GetSignageQuestionByIdQueryVariables
  >(GetSignageQuestionByIdDocument, baseOptions);
}
export function useGetSignageQuestionByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSignageQuestionByIdQuery,
    GetSignageQuestionByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSignageQuestionByIdQuery,
    GetSignageQuestionByIdQueryVariables
  >(GetSignageQuestionByIdDocument, baseOptions);
}
export type GetSignageQuestionByIdQueryHookResult = ReturnType<
  typeof useGetSignageQuestionByIdQuery
>;
export type GetSignageQuestionByIdLazyQueryHookResult = ReturnType<
  typeof useGetSignageQuestionByIdLazyQuery
>;
export type GetSignageQuestionByIdQueryResult = ApolloReactCommon.QueryResult<
  GetSignageQuestionByIdQuery,
  GetSignageQuestionByIdQueryVariables
>;
