import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import { ApiPrizeData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkTextInput,
  PerkTextArea,
  PerkSelect,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const JUDGING_TYPE = [
  { label: "Live Demos", value: "Live Demos" },
  { label: "Devpost", value: "Devpost" },
];

export const API_PRIZE_VALIDATION_FIELDS: (keyof ApiPrizeData)[] = [
  "api_prize_title",
  "api_prize_description",
  "api_prize_judging_confirmation",
];

export const ApiPrize: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => (
  <>
    <Header
      title="API Prize"
      subtitle="This perk is for having an API prize."
      badge={
        <Label
          color={perkStatusToBadgeColor(
            getState("api_prize_status") as PerkStatus
          )}
          size="sm"
        >
          {getState("api_prize_status")}
        </Label>
      }
    />

    <PerkContainer
      description={<Description />}
      id={SponsorPerkType.API_PRIZE}
      readOnly={isReadOnly}
    >
      <PerkTextInput
        title="Title*"
        value={getState("api_prize_title") ?? ""}
        placeholder="Your API prize title"
        isReadOnly={isReadOnly}
        onChange={(e) => updateState("api_prize_title", e.target.value)}
        error={error?.api_prize_title && <>This field is required</>}
      />
      <Spacer height={32} />
      <PerkTextArea
        title="Description* (max 150 words)"
        value={getState("api_prize_description") ?? ""}
        wordLimit={150}
        placeholder="Description of API prize"
        isReadOnly={isReadOnly}
        onChange={(e) => updateState("api_prize_description", e.target.value)}
        error={error?.api_prize_description && <>This field is required</>}
      />
      <Spacer height={32} />
      <PerkSelect
        fullWidth
        size="lg"
        value={getState("api_prize_judging_confirmation") ?? ""}
        onChange={(e) =>
          updateState("api_prize_judging_confirmation", e.target.value)
        }
        label="Judging Type*"
        placeholder="Judging type"
        isReadOnly={isReadOnly}
        error={
          error?.api_prize_judging_confirmation && <>This field is required</>
        }
      >
        {optionsToNorthV2Options(JUDGING_TYPE)}
      </PerkSelect>
    </PerkContainer>
  </>
);

const Description = () => (
  <>
    <TextDescription>
      If you have a public facing API, you can host an API prize where hackers
      will develop with your API. These prizes will be judged by your own
      company representatives and awarded to hackers at the end of our event. We
      will be sending out more information about sponsor judging closer to the
      event, so stay tuned!
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Note: These descriptions will be going on our Devpost page, so they should
      include prizes as well. Our Devpost page from 2023 can be found at{" "}
      <Link target="_blank" href="https://hackthenorth2023.devpost.com/">
        hackthenorth2023.devpost.com
      </Link>
      .
    </TextDescription>
  </>
);
