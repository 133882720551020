export enum BaseRoute {
  HOME = "/",
  SCHEDULE = "/schedule",
  PROFILE = "/profile",
  AUTH = "/auth",
  DOCUMENTS = "/documents",
  HELP = "/help",
  SPONSOR = "/sponsor",
  HACKER = "/hacker",
  PLAYGROUND = "/playground",
  NOT_FOUND = "/404",
  MENTOR = "/mentor",
  CONNECT_SLACK_ACCOUNT = "/link",
  VOLUNTEER = "/volunteer",
  NETWORKING_RESULT = "/qr/2024",
  NETWORKING = "/networking",
  SETTINGS = "/settings",
  SIGNAGE_HUNT = "/signage-hunt",
  TICKET = "/ticket",
}

export enum DocumentRoute {
  CODE_OF_CONDUCT = "code-of-conduct",
  PRIVACY_POLICY = "privacy-policy",
  TERMS_AND_CONDITIONS = "terms-and-conditions",
}

export enum SponsorRoute {
  PERKS = "perks",
  RECRUITMENT = "recruitment",
  PLATFORM_ONBOARDING = "platform-onboarding",
}

export enum HackerRoute {
  RSVP = "rsvp",
  PERSONAL = "personal",
  TRAVEL = "travel",
  EVENT = "event",
  CAREER = "career",
  CONFIRMATION = "confirmation",
  TRAVEL_DETAILS = "travel-details",
  HOME = "home",
  MENTORSHIP = "mentorship",
  GETTING_STARTED = "getting-started",
  JUDGING = "judging",
  FAQ = "faq",
  HARDWARE = "hardware",
  HARDWARE_ITEMS = "hardware/items/",
  HARDWARE_INSTRUCTIONS = "hardware/instructions",
  HARDWARE_REQUESTS = "hardware/requests",
}

export enum MentorRoute {
  REQUESTS = "requests",
  CLAIMED_REQUESTS = "claimed-requests",
  PROFILE = "profile",
}

export enum VolunteerRoute {
  REGISTRATION = "registration",
  EVENT_REDEMPTION = "event-redemption",
}

export enum SignageHuntRoute {
  SPACE = "space",
}

// includes all valid routes
// Not allowed to be an enum for now :(( https://github.com/microsoft/TypeScript/issues/40793
export const Route = {
  HOME: BaseRoute.HOME,
  SCHEDULE: BaseRoute.SCHEDULE,
  PROFILE: BaseRoute.PROFILE,
  AUTH: BaseRoute.AUTH,
  DOC_COC: `${BaseRoute.DOCUMENTS}/${DocumentRoute.CODE_OF_CONDUCT}`,
  DOC_TAC: `${BaseRoute.DOCUMENTS}/${DocumentRoute.TERMS_AND_CONDITIONS}`,
  DOC_PRIVACY: `${BaseRoute.DOCUMENTS}/${DocumentRoute.PRIVACY_POLICY}`,
  HELP: BaseRoute.HELP,
  NETWORKING: BaseRoute.NETWORKING,

  SPONSOR_PERKS: `${BaseRoute.SPONSOR}/${SponsorRoute.PERKS}`,
  SPONSOR_RECRUITMENT: `${BaseRoute.SPONSOR}/${SponsorRoute.RECRUITMENT}`,
  // SPONSOR_PLATFORM_ONBOARDING: `${BaseRoute.SPONSOR}/${SponsorRoute.PLATFORM_ONBOARDING}`,

  MENTOR_REQUESTS: `${BaseRoute.MENTOR}/${MentorRoute.REQUESTS}`,
  MENTOR_CLAIMED_REQUESTS: `${BaseRoute.MENTOR}/${MentorRoute.CLAIMED_REQUESTS}`,
  MENTOR_PROFILE: `${BaseRoute.MENTOR}/${MentorRoute.PROFILE}`,

  HACKER_RSVP: `${BaseRoute.HACKER}/${HackerRoute.RSVP}`,
  HACKER_PERSONAL: `${BaseRoute.HACKER}/${HackerRoute.PERSONAL}`,
  HACKER_TRAVEL: `${BaseRoute.HACKER}/${HackerRoute.TRAVEL}`,
  HACKER_EVENT: `${BaseRoute.HACKER}/${HackerRoute.EVENT}`,
  HACKER_CAREER: `${BaseRoute.HACKER}/${HackerRoute.CAREER}`,
  HACKER_CONFIRMATION: `${BaseRoute.HACKER}/${HackerRoute.CONFIRMATION}`,
  HACKER_TRAVEL_DETAILS: `${BaseRoute.HACKER}/${HackerRoute.TRAVEL_DETAILS}`,

  HACKER_HOME: `${BaseRoute.HACKER}/${HackerRoute.HOME}`,

  HACKER_MENTORSHIP: `${BaseRoute.HACKER}/${HackerRoute.MENTORSHIP}`,

  HACKER_GETTING_STARTED: `${BaseRoute.HACKER}/${HackerRoute.GETTING_STARTED}`,
  HACKER_JUDGING: `${BaseRoute.HACKER}/${HackerRoute.JUDGING}`,
  HACKER_FAQ: `${BaseRoute.HACKER}/${HackerRoute.FAQ}`,
  HACKER_HARDWARE_HOME: `${BaseRoute.HACKER}/${HackerRoute.HARDWARE_ITEMS}`,
  HACKER_HARDWARE_INSTRUCTIONS: `${BaseRoute.HACKER}/${HackerRoute.HARDWARE_INSTRUCTIONS}`,
  HACKER_HARDWARE_REQUESTS: `${BaseRoute.HACKER}/${HackerRoute.HARDWARE_REQUESTS}`,

  VOLUNTEER_REGISTRATION: `${BaseRoute.VOLUNTEER}/${VolunteerRoute.REGISTRATION}`,
  VOLUNTEER_EVENT_REDEMPTION: `${BaseRoute.VOLUNTEER}/${VolunteerRoute.EVENT_REDEMPTION}`,

  PLAYGROUND: BaseRoute.PLAYGROUND,
  NOT_FOUND: BaseRoute.NOT_FOUND,

  SIGNAGE_HUNT: BaseRoute.SIGNAGE_HUNT,
  TICKET: BaseRoute.TICKET,
};
