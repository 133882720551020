import {
  Checkbox,
  Button,
  DropdownMenu,
  Text,
  TextInput,
  Label,
  Spinner,
} from "north.js";
import React, { useEffect, useState } from "react";
import "twin.macro";

import { INTEREST_OPTIONS } from "src/shared/constants/interests";
import { MentorContextState } from "src/shared/contexts/MentorContext";
import * as Mentor from "src/shared/contexts/MentorContext/types";
import { Info } from "src/shared/contexts/UserContext";

// TODO:  consolidate pronouns into the same constants file
import { PRONOUNS } from "./constants";

import { CheckIcon } from "@heroicons/react/24/outline";

interface ProfileFormProps {
  info: Info;
  profile: Mentor.Profile;
  loading: boolean;
  updateProfile: MentorContextState["updateProfile"];
}

const PRONOUNS_LIST = Object.values(PRONOUNS);
const ProfileForm: React.FC<ProfileFormProps> = ({
  info,
  profile,
  loading,
  updateProfile,
}) => {
  const [name] = useState(info.name ?? "");
  const [topics, setTopics] = useState(profile.mentorship_topics ?? []);
  const [institution, setInstitution] = useState(profile.institution ?? "");
  const [pronouns, setPronouns] = useState(profile.pronouns ?? []);
  const [otherPronouns, setOtherPronouns] = useState(
    profile.other_pronouns ?? ""
  );

  const [buttonState, setButtonState] = useState<
    "default" | "saving" | "saved"
  >("default");
  const [didTriggerUpdate, setDidTriggerUpdate] = useState(false);

  useEffect(() => {
    if (buttonState === "default" && didTriggerUpdate) {
      setButtonState("saving");
      setDidTriggerUpdate(false);
    } else if (buttonState === "saving") {
      setTimeout(() => setButtonState("saved"), 1000);
    } else if (buttonState === "saved" && !loading) {
      setTimeout(() => setButtonState("default"), 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonState, loading]);

  console.log({ pronouns });

  const topicItems = [
    INTEREST_OPTIONS.map((topic) => ({
      content: (
        <div tw="w-full flex items-center justify-between">
          <Text type="body1" tw="font-medium">
            {topic}
          </Text>
        </div>
      ),
      selected: topics.includes(topic),
      dismissOnClick: false,
      action: () =>
        topics.includes(topic)
          ? setTopics((prev) => prev.filter((p) => p !== topic))
          : setTopics((prev) => [...prev, topic]),
    })),
  ];
  return (
    <div tw="flex flex-col max-width[700px]">
      <Text tw="mb-12" type="button">
        Full name
      </Text>
      <TextInput
        tw="mb-24 w-full font-size[16px] font-medium"
        size="md"
        placeholder="Enter your full name"
        value={name}
        disabled
      ></TextInput>
      <Text tw="mb-12" type="button">
        Company / Institution
      </Text>
      <TextInput
        tw="mb-24 w-full font-size[16px] font-medium"
        size="md"
        placeholder="Enter your company or institution"
        value={institution}
        onChange={(e) => setInstitution(e.target.value)}
      ></TextInput>{" "}
      <Text tw="mb-12" type="button">
        Preferred pronouns
      </Text>
      {PRONOUNS_LIST.map((pronoun, i) => (
        <div tw="mb-12" key={i}>
          <Checkbox
            checked={pronouns.includes(pronoun)}
            onChange={(e) => {
              return pronouns?.includes(pronoun)
                ? setPronouns((prev) => prev.filter((p) => p !== pronoun))
                : setPronouns((prev) => [...prev, pronoun]);
            }}
            label={
              <Text type="body1" tw="text-text-secondary font-medium">
                {pronoun}
              </Text>
            }
          />
        </div>
      ))}
      {pronouns?.includes(PRONOUNS.OTHER) && (
        <div tw="mb-24">
          <TextInput
            tw="w-full"
            size="md"
            value={otherPronouns}
            onChange={(e) => setOtherPronouns(e.target.value)}
            hint={
              <Text type="caption" tw="text-text-secondary font-medium">
                Separate sets of pronouns by a comma.
              </Text>
            }
          />
        </div>
      )}
      <Text tw="mb-12" type="button">
        Areas of expertise
      </Text>
      <div tw="flex flex-wrap gap-8 mb-12 items-center">
        {topics.map((topic) => (
          <Label size="sm" color="primary-light" key={topic} tw="font-bold">
            {topic}
          </Label>
        ))}
      </div>
      <DropdownMenu tw="w-full!" items={topicItems}>
        <Button color="secondary" tw="w-full! h-48">
          <div tw="flex justify-between w-full">
            <Text type="body1" tw="text-text-tertiary font-medium">
              Select areas from dropdown
            </Text>
            <ChevronDown />
          </div>
        </Button>
      </DropdownMenu>
      <div tw="h-24" />
      <div tw="flex justify-end">
        <Button
          color="primary"
          size="lg"
          leadingIcon={
            buttonState === "saving" ? (
              <Spinner tw="w-16! h-16!" />
            ) : buttonState === "saved" ? (
              <CheckIcon tw="w-20 h-20" />
            ) : null
          }
          onClick={async () => {
            setDidTriggerUpdate(true);
            await updateProfile({
              mentorship_topics: topics,
              other_pronouns: otherPronouns,
              pronouns,
              institution,
            });
          }}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

const ChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
    tw="text-text-tertiary w-12"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
    />
  </svg>
);

export default ProfileForm;
