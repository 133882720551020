import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { TechTalkProposalData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import { PerkContainer, PerkTextInput, PerkTextArea } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const TECH_TALK_VALIDATION_FIELDS: (keyof TechTalkProposalData)[] = [
  "tech_talk_proposal_title_one",
  "tech_talk_proposal_description_one",
];

export const TechTalkProposal: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => (
  <>
    <Header
      title="Tech Talk: Proposal"
      subtitle="This perk is for Tech Talk presentations that will take place during the Hack the North weekend."
      badge={
        <Label
          color={perkStatusToBadgeColor(
            (getState("tech_talk_proposal_status") ?? []) as PerkStatus
          )}
          size="sm"
        >
          {getState("tech_talk_proposal_status")}
        </Label>
      }
    />
    <PerkContainer
      description={<Description />}
      id={SponsorPerkType.TECH_TALK_PROPOSAL}
      readOnly={isReadOnly}
    >
      <PerkTextInput
        title="Title*"
        value={getState("tech_talk_proposal_title_one") ?? ""}
        placeholder="Title of first tech talk proposal"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("tech_talk_proposal_title_one", e.target.value)
        }
        error={
          error?.tech_talk_proposal_title_one && <>This field is required</>
        }
      />
      <Spacer height={32} />
      <PerkTextArea
        title="Description* (max 150 words)"
        value={getState("tech_talk_proposal_description_one") ?? ""}
        placeholder="Description of first tech talk proposal (max 150 words)"
        wordLimit={150}
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("tech_talk_proposal_description_one", e.target.value)
        }
        error={
          error?.tech_talk_proposal_description_one && (
            <>This field is required</>
          )
        }
      />
      <Spacer height={32} />
      <PerkTextInput
        title={
          <>
            Title{" "}
            <Label color="secondary-light" size="sm">
              Optional
            </Label>
          </>
        }
        value={getState("tech_talk_proposal_title_two") ?? ""}
        placeholder="Title of second tech talk proposal"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("tech_talk_proposal_title_two", e.target.value)
        }
      />
      <Spacer height={32} />
      <PerkTextArea
        title={
          <>
            Description (max 150 words){" "}
            <Label color="secondary-light" size="sm">
              Optional
            </Label>
          </>
        }
        value={getState("tech_talk_proposal_description_two") ?? ""}
        placeholder="Description of second tech talk proposal (max 150 words)"
        wordLimit={150}
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("tech_talk_proposal_description_two", e.target.value)
        }
      />
    </PerkContainer>
  </>
);

const Description = () => (
  <>
    <TextDescription>
      Tech Talks will take place from Sept 13-15, 2024, and can be about any
      tech topic. These will be live sessions anywhere from 45 minutes to an
      hour long. Hackers will vote on their favourite options, and the top few
      topics will be selected to present at Hack the North 2024.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      You have the opportunity to submit two tech talk proposals! If you are
      submitting two proposals, you must be able to present both topics during
      the Hack the North weekend, but you will only be able to present a maximum
      of one tech talk at the event.
    </TextDescription>
  </>
);
