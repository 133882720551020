import { Spacer } from "@hackthenorth/north";
import { Button, DropdownMenu, DropdownMenuSection, TextInput } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";
import "twin.macro";
import "styled-components/macro";

import { InfoTag } from "src/shared/components";
import { useDeviceSize } from "src/shared/hooks";
import { CheckIcon, FilterIcon } from "src/static/icons";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import Calendar from "./Calendar";

const EVENT_TYPES = [
  "Main Event",
  "Workshop",
  "Activity",
  "Meetup",
  "Sponsor",
  "Tech Talk",
  "Panel",
];

const Schedule: React.FC = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");

  const [day, setDay] = useState<string>("friday");
  const [query, setQuery] = useState<string>("");
  const [events, setEvents] = useState<Set<string>>(new Set(EVENT_TYPES));

  const handleFilterEvent = (event: string) => {
    events.has(event)
      ? setEvents(new Set([...Array.from(events)].filter((s) => s !== event)))
      : setEvents(new Set([...Array.from(events), event]));
  };

  const handleSelectAll = () => {
    events.size === EVENT_TYPES.length
      ? setEvents(new Set())
      : setEvents(new Set(new Set(EVENT_TYPES)));
  };

  const items: DropdownMenuSection[] = [
    [
      {
        content: (
          <EventRow>
            <Dot color="#2395C6" />
            <DropdownText>Main Event</DropdownText>
            {events.has("Main Event") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Main Event"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Main Event"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#54C152" />
            <DropdownText>Workshop</DropdownText>
            {events.has("Workshop") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Workshop"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Workshop"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#D97706" />
            <DropdownText>Activity</DropdownText>
            {events.has("Activity") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Activity"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Activity"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#CB2E2E" />
            <DropdownText>Meetup</DropdownText>
            {events.has("Meetup") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Meetup"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Meetup"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#B348C8" />
            <DropdownText>Sponsor</DropdownText>
            {events.has("Sponsor") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Sponsor"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Sponsor"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#4B5563" />
            <DropdownText>Tech Talk</DropdownText>
            {events.has("Tech Talk") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Tech Talk"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Tech Talk"),
      },
      {
        content: (
          <EventRow>
            <Dot color="#C4A484" />
            <DropdownText>Panel</DropdownText>
            {events.has("Panel") && <StyledCheckIcon />}
          </EventRow>
        ),
        selected: events.has("Panel"),
        dismissOnClick: false,
        action: () => handleFilterEvent("Panel"),
      },
      {
        content: (
          <Button onClick={() => handleSelectAll()}>
            <ButtonText>
              {events.size === EVENT_TYPES.length
                ? "Deselect all"
                : "Select all"}
            </ButtonText>
          </Button>
        ),
        dismissOnClick: false,
        action: () => {},
      },
    ],
  ];

  const handleClick = (scrollLeft: number) => {
    if (document.getElementById("schedule-container")) {
      document.getElementById("schedule-container")!.scrollLeft = scrollLeft;
    }
  };

  const handleScroll = () => {
    if (document.getElementById("schedule-container")) {
      const scrollLeft =
        document.getElementById("schedule-container")!.scrollLeft;
      if (scrollLeft < 896) {
        setDay("friday");
      } else if (scrollLeft < 3968) {
        setDay("saturday");
      } else {
        setDay("sunday");
      }
    }
  };

  return (
    <>
      <Controls>
        <div className="text-text-tertiary mb-4">
          Hacking begins at 12AM on Saturday. Starting your hack before this
          time is not permitted as outlined in Hack the North&apos;s Code of
          Conduct.
        </div>
        <div className="text-text-tertiary mb-4">
          All times are in Eastern Time (ET)
        </div>
        <OuterContainer isTabletOrSmaller={isTabletOrSmaller}>
          <FlexContainer>
            <SelectDay
              id="fri-button"
              selected={day === "friday"}
              onClick={() => {
                setDay("friday");
                handleClick(0);
              }}
            >
              <ButtonText>Friday</ButtonText>
            </SelectDay>
            <SelectDay
              id="sat-button"
              selected={day === "saturday"}
              onClick={() => {
                setDay("saturday");
                handleClick(896);
              }}
            >
              <ButtonText>Saturday</ButtonText>
            </SelectDay>
            <SelectDay
              id="sun-button"
              selected={day === "sunday"}
              onClick={() => {
                setDay("sunday");
                handleClick(3968);
              }}
            >
              <ButtonText>Sunday</ButtonText>
            </SelectDay>
          </FlexContainer>
          {isTabletOrSmaller && <Spacer height={16} />}
          <SearchAndFilterContainer>
            <TextInput
              type="text"
              placeholder="Search for events"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              leadingIcon={<MagnifyingGlassIcon className="w-4 h-4" />}
            />
            <StyledDropdown items={items}>
              <Button>
                <FlexContainer>
                  <FilterIcon />
                  <Spacer width={8} />
                  <ButtonText>Filter</ButtonText>
                </FlexContainer>
              </Button>
            </StyledDropdown>
          </SearchAndFilterContainer>
        </OuterContainer>
      </Controls>
      <Spacer height={24} />
      <Calendar
        handleScroll={handleScroll}
        shownTypes={events}
        queryFilter={query}
      />
    </>
  );
};

const Controls = styled.div`
  padding: 0 40px;
`;

const OuterContainer = styled.div<{ isTabletOrSmaller: boolean }>`
  display: flex;
  flex-direction: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "column" : "row"};
  justify-content: space-between;
  align-items: start;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;
  gap: 8px;

  @media (max-width: 360px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const SelectDay = styled(InfoTag)<{
  selected: boolean;
}>`
  padding: 4px 10px;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.globalConstants.color.backgroundPrimary
      : theme.globalConstants.color.backgroundGrayDark};
  color: ${({ theme, selected }) =>
    selected
      ? theme.globalConstants.color.textLight
      : theme.globalConstants.color.textSecondary} !important;
  cursor: pointer;
`;

const ButtonText = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 700;
  font-size: 16px;
`;

const StyledDropdown = styled(DropdownMenu)`
  z-index: 1;
`;

const EventRow = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const DropdownText = styled.p`
  margin-top: 0;
  margin-left: 8px;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 400;
  font-size: 16px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.globalConstants.color.textPrimary};
`;

export default Schedule;
