import { Spacer, Flex, UnstyledButton } from "@hackthenorth/north";
import React, { useEffect, useMemo } from "react";
import "twin.macro";
import styled from "styled-components";

import { Icon } from "src/shared/components";

import { TextInputTitle, TextDescription } from "../../TextComponents";
import { PerkError } from "../PerkError";
import { PerkTextInput } from "../PerkTextInput";

import { Button } from "north.js";

type TPerkRepresentatives = {
  names: string[];
  emails: string[];
  pronouns: string[];
  rowLimit?: number;
  isReadOnly: boolean;

  description?: React.ReactNode;

  updateNames: (val: string[]) => void;
  updateEmails: (val: string[]) => void;
  updatePronouns: (val: string[]) => void;

  resetRows: () => void;
  addRow: () => void;
  deleteRow: (i: number) => void;
};

export const PerkRepresentatives: React.FC<TPerkRepresentatives> = ({
  names,
  emails,
  pronouns,
  rowLimit = Infinity,
  isReadOnly,

  description,

  updateNames,
  updateEmails,
  updatePronouns,

  resetRows,
  addRow,
  deleteRow,
}) => {
  const rows = names.length;

  const rowArray = useMemo(() => Array.from(Array(rows).keys()), [rows]);

  useEffect(() => {
    // Reset rows when all have been deleted
    if (rows === 0) {
      resetRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <>
      <TextInputTitle style={{ fontSize: "20px" }}>
        Representatives
      </TextInputTitle>
      <Spacer height={8} />
      {description}
      <table tw="w-full border-separate border-spacing[12px] -ml-12">
        {rows > 0 && (
          <thead>
            <tr>
              <th>
                <Heading>Full name</Heading>
              </th>
              <th>
                <Heading>Email</Heading>
              </th>
              <th>
                <Heading>Pronouns</Heading>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {rowArray.map((i) => {
            return (
              <tr key={i}>
                <td>
                  <PerkTextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateNames([
                        ...names.slice(0, i),
                        e.target.value,
                        ...names.slice(i + 1),
                      ])
                    }
                    placeholder="Name"
                    isReadOnly={isReadOnly}
                    value={names?.[i] ?? ""}
                  />
                </td>
                <td>
                  <PerkTextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateEmails([
                        ...emails.slice(0, i),
                        e.target.value,
                        ...emails.slice(i + 1),
                      ])
                    }
                    placeholder="Email"
                    isReadOnly={isReadOnly}
                    value={emails?.[i] ?? ""}
                  />
                </td>
                <td>
                  <PerkTextInput
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updatePronouns([
                        ...pronouns.slice(0, i),
                        e.target.value,
                        ...pronouns.slice(i + 1),
                      ])
                    }
                    placeholder="Pronouns"
                    isReadOnly={isReadOnly}
                    value={pronouns?.[i] ?? ""}
                  />
                </td>
                <td style={{ paddingLeft: "10px" }}>
                  <UnstyledButton onClick={() => deleteRow(i)}>
                    <Icon
                      name="x"
                      width="12px"
                      height="12px"
                      strokeWidth="1px"
                    />
                  </UnstyledButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Spacer height={16} />
      <Flex align="flex-end" justify="space-between">
        <Button color="secondary" disabled={rows >= rowLimit} onClick={addRow}>
          Add representative
        </Button>
        <Spacer width={16} />
        {rows >= rowLimit && (
          <PerkError
            error={
              <>You&apos;ve reached the maximum number of representatives!</>
            }
          />
        )}
      </Flex>
    </>
  );
};

const Heading = styled(TextDescription)`
  display: flex !important;
`;
