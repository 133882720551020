export const INTEREST_OPTIONS = [
  "Hardware",
  "Design",
  "Web",
  "Frontend",
  "Backend",
  "Data Science",
  "Computer Vision",
  "Machine Learning",
  "Blockchain",
  "Android",
  "iOS",
  "Game Development",
  "Virtual Reality",
  "Augmented Reality",
  "Infrastructure",
  "Cloud Computing",
  "Security",
  "Embedded",
  "Robotics",
];
