/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type FindMyBusesByEventQueryVariables = Types.Exact<{
  eventSlug: Types.Scalars["String"];
}>;

export type FindMyBusesByEventQuery = {
  findMyBusesByEvent: Array<
    Pick<
      Types.Bus,
      | "id"
      | "departure_location"
      | "departure_time"
      | "arrival_location"
      | "arrival_time"
    >
  >;
};

export const FindMyBusesByEventDocument = gql`
  query FindMyBusesByEvent($eventSlug: String!) {
    findMyBusesByEvent(eventSlug: $eventSlug) {
      id
      departure_location
      departure_time
      arrival_location
      arrival_time
    }
  }
`;

/**
 * __useFindMyBusesByEventQuery__
 *
 * To run a query within a React component, call `useFindMyBusesByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyBusesByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyBusesByEventQuery({
 *   variables: {
 *      eventSlug: // value for 'eventSlug'
 *   },
 * });
 */
export function useFindMyBusesByEventQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindMyBusesByEventQuery,
    FindMyBusesByEventQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindMyBusesByEventQuery,
    FindMyBusesByEventQueryVariables
  >(FindMyBusesByEventDocument, baseOptions);
}
export function useFindMyBusesByEventLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindMyBusesByEventQuery,
    FindMyBusesByEventQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindMyBusesByEventQuery,
    FindMyBusesByEventQueryVariables
  >(FindMyBusesByEventDocument, baseOptions);
}
export type FindMyBusesByEventQueryHookResult = ReturnType<
  typeof useFindMyBusesByEventQuery
>;
export type FindMyBusesByEventLazyQueryHookResult = ReturnType<
  typeof useFindMyBusesByEventLazyQuery
>;
export type FindMyBusesByEventQueryResult = ApolloReactCommon.QueryResult<
  FindMyBusesByEventQuery,
  FindMyBusesByEventQueryVariables
>;
