import { Spacer } from "@hackthenorth/north";
import { TextInput } from "north.js";
import React from "react";
import styled from "styled-components";

import { TextInputTitle } from "../../TextComponents";

type TPerkTextInputProps = {
  title?: string | React.ReactNode;
  value: string;
  placeholder: string;
  isReadOnly: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  error?: React.ReactNode;
  hint?: React.ReactNode;
  disabled?: boolean;
  type?: string;
};

const StyledTextInput = styled(TextInput)`
  width: 100%;
  font-size: 14px !important;
`;

export const PerkTextInput: React.FC<TPerkTextInputProps> = ({
  title,
  value,
  placeholder,
  isReadOnly,
  onChange,
  error,
  hint = "",
  disabled = false,
  type = "text",
}) => (
  <>
    {title && (
      <>
        <TextInputTitle>{title}</TextInputTitle>
        <Spacer height={8} />
      </>
    )}
    <StyledTextInput
      size="md"
      onChange={onChange}
      placeholder={placeholder}
      readOnly={isReadOnly}
      value={value}
      error={error}
      hint={hint}
      disabled={disabled}
      type={type}
    />
  </>
);
