import { DateTime } from "luxon";

import { PerkStatus } from "../../types";

export const hackerAPIDateToDueDate = (value: string | null) => {
  if (value === null) return "";

  const date = DateTime.fromISO(value);
  const isInFiveDays = date <= DateTime.now().plus({ days: 5 });
  if (isInFiveDays) {
    return date.toRelativeCalendar();
  }

  return date.toLocaleString(DateTime.DATE_MED);
};

export const isInFiveDays = (value: string | null) => {
  if (value === null) return false;

  const date = DateTime.fromISO(value);
  return date <= DateTime.now().plus({ days: 5 });
};

export const perkStatusToBadgeColor = (status: PerkStatus) => {
  switch (status) {
    case PerkStatus.INCOMPLETE:
      return "secondary";
    case PerkStatus.APPROVED:
      return "success";
    case PerkStatus.SUBMITTED:
      return "primary";
    default:
      return "secondary";
  }
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
