import { Flex, Text } from "@hackthenorth/north";
import { Button } from "north.js";
import React from "react";

import { Header, PageWrapper } from "src/shared/components";

import QuestionCard from "./components/QuestionCard";

const SignageHunt = () => {
  const questions = [
    "What is the best part of Hack the North?",
    "What is your favourite workshop",
    "What is your favourite food at Hack the North?",
  ];

  return (
    <>
      <Header short />
      <PageWrapper>
        <Text mods="heading h1 bold">
          Signage Hunt. bro chill this aint done ivan will finish this
        </Text>
        <Text mods="textBody">
          Find posters with QR codes and scan them to share your experience at
          this year’s event, earn stickers, and more! Check the welcome booklet
          for detailed instructions.
        </Text>
        <Flex style={{ marginTop: "15px" }}>
          <Button
            color="secondary"
            size="md"
            onClick={() => {
              window.open("https://charts.hackthenorth.com/", "_blank");
            }}
          >
            Go to Charts
          </Button>
        </Flex>
        <div style={{ marginTop: "25px" }}>
          {questions.map((question) => (
            <div key={questions.indexOf(question)}>
              <QuestionCard
                idx={1}
                question_id={1}
                question_name={question}
                is_answered={questions.indexOf(question) % 2 === 0}
                response_count={0}
                responses_last_hour={0}
              />
            </div>
          ))}
        </div>
      </PageWrapper>
    </>
  );
};

export default SignageHunt;
