/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type AnswerSignageQuestionMutationVariables = Types.Exact<{
  question_id: Types.Scalars["Float"];
  answer: Types.Scalars["String"];
}>;

export type AnswerSignageQuestionMutation = {
  answerSignageQuestion: Pick<Types.SignageAnswer, "answer_id">;
};

export const AnswerSignageQuestionDocument = gql`
  mutation AnswerSignageQuestion($question_id: Float!, $answer: String!) {
    answerSignageQuestion(question_id: $question_id, answer: $answer) {
      answer_id
    }
  }
`;

/**
 * __useAnswerSignageQuestionMutation__
 *
 * To run a mutation, you first call `useAnswerSignageQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSignageQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSignageQuestionMutation, { data, loading, error }] = useAnswerSignageQuestionMutation({
 *   variables: {
 *      question_id: // value for 'question_id'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useAnswerSignageQuestionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AnswerSignageQuestionMutation,
    AnswerSignageQuestionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AnswerSignageQuestionMutation,
    AnswerSignageQuestionMutationVariables
  >(AnswerSignageQuestionDocument, baseOptions);
}
export type AnswerSignageQuestionMutationHookResult = ReturnType<
  typeof useAnswerSignageQuestionMutation
>;
export type AnswerSignageQuestionMutationResult =
  ApolloReactCommon.MutationResult<AnswerSignageQuestionMutation>;
export type AnswerSignageQuestionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AnswerSignageQuestionMutation,
    AnswerSignageQuestionMutationVariables
  >;
