import { Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { UnstyledLink } from "src/shared/components";
import { TWText } from "src/shared/styles";
import { CheckIcon } from "src/static/icons";

type TSectionProps = {
  title: string;
  // route: RouteName;
  step: number;
  current: boolean;
  completed: boolean;
  currentStage: boolean;
  to: string;
};

const Section: React.FC<TSectionProps> = ({
  title,
  current,
  completed,
  currentStage,
  step,
  to,
}) => {
  return (
    <StyledLink href={to} disabled={!(completed || currentStage)}>
      <Container
        align="center"
        justify="center"
        current={current}
        role="button"
        onClick={() => window.scrollTo(0, 0)}
      >
        <CompletedContainer completed={completed}>
          {completed ? (
            <CheckIcon color="white" width="20" />
          ) : (
            <StyledNumber>{step}</StyledNumber>
          )}
        </CompletedContainer>
        <StyledText current={current} completed={completed}>
          {title}
        </StyledText>
      </Container>
    </StyledLink>
  );
};

export default Section;

const StyledLink = styled(UnstyledLink)<{ disabled: boolean }>`
  width: 100%;
  pointer-events: ${({ disabled }) => disabled && "none"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  text-decoration: none !important;
`;

const StyledNumber = styled.p`
  ${[TWText.bodyBold]}
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  text-decoration: none !important;
`;

const Container = styled(Flex)<{ current: boolean }>`
  justify-content: flex-start;
  gap: 12px;
  cursor: pointer;
  transition: opacity 100ms, background 300ms;
  &:hover {
    opacity: 0.8;
  }
`;

const CompletedContainer = styled.span<{ completed: boolean }>`
  background: ${({ completed, theme }) =>
    completed
      ? theme.globalConstants.color.brandSecondary
      : theme.globalConstants.color.backgroundGrayDark};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  transition: transform 500ms ease 0s, padding-right 400ms ease 0s;
`;

const StyledText = styled.p.attrs({ size: "body" })<{
  current: boolean;
  completed: boolean;
}>`
  ${[TWText.bodyBold]}
  white-space: nowrap;
  color: ${({ current, completed, theme }) =>
    current
      ? theme.globalConstants.color.brandSecondary
      : completed
      ? theme.globalConstants.color.textSecondary
      : theme.globalConstants.color.textTertiary} !important;
`;
