import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { HackerRoute } from "src/shared/constants/route";
import {
  Permission,
  // MentorshipRequestContextProvider,
} from "src/shared/contexts";
import NotFound from "src/views/404";

// import { FAQ as HackerFAQ } from "./faq";
// import { GettingStarted as HackerGettingStarted } from "./getting-started";
// import HardwareHome from "./hardware/Home";
// import HardwareInstructions from "./hardware/Instructions";
// import HardwareRequests from "./hardware/Requests";
// import HackerHome from "./home";
// import { Judging as HackerJudging } from "./judging";
// import HackerMentorshipHome from "./mentorship";
import HackerRsvpCareer from "./rsvp/career";
import { CareerSponsorContextProvider } from "./rsvp/career/CareerSponsorContext";
import HackerRsvpConfirmation from "./rsvp/confirmation";
import HackerRsvpEvent from "./rsvp/event";
import HackerRsvpHome from "./rsvp/home";
import HackerRsvpPersonal from "./rsvp/personal";
import HackerRsvpTravel from "./rsvp/travel";
import HackerTravelForm from "./rsvp/travel-form";

export const HackerRoutes = () => (
  <Routes>
    <Route
      path={HackerRoute.RSVP}
      element={<HackerRsvpHome />}
      requirePermissions={[Permission.HACKER_RSVP]}
    />
    <Route
      path={HackerRoute.PERSONAL}
      element={<HackerRsvpPersonal />}
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    <Route
      path={HackerRoute.TRAVEL}
      element={<HackerRsvpTravel />}
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    <Route
      path={HackerRoute.EVENT}
      element={<HackerRsvpEvent />}
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    <Route
      path={HackerRoute.CAREER}
      element={
        <CareerSponsorContextProvider>
          <HackerRsvpCareer />
        </CareerSponsorContextProvider>
      }
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    <Route
      path={HackerRoute.CONFIRMATION}
      element={<HackerRsvpConfirmation />}
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    <Route
      path={HackerRoute.TRAVEL_DETAILS}
      element={<HackerTravelForm />}
      requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
    />
    {/* <Route
      path={HackerRoute.HOME}
      element={<HackerHome />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.HARDWARE_ITEMS}
      element={<HardwareHome />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.HARDWARE_INSTRUCTIONS}
      element={<HardwareInstructions />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.HARDWARE_REQUESTS}
      element={<HardwareRequests />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.MENTORSHIP}
      element={
        <MentorshipRequestContextProvider>
          <HackerMentorshipHome />
        </MentorshipRequestContextProvider>
      }
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.GETTING_STARTED}
      element={<HackerGettingStarted />}
    />
    <Route
      path={HackerRoute.FAQ}
      element={<HackerFAQ />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route
      path={HackerRoute.JUDGING}
      element={<HackerJudging />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    /> */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);
