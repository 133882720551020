import { useState, useEffect } from "react";
/* eslint-disable @typescript-eslint/camelcase */
import { unstable_batchedUpdates } from "react-dom";

//import { dateToFormattedString } from "src/shared/utils/date";

import { Field, TAnswers, TAnswer } from "./types";

import { useHackerContext } from "./index";

const filterClaimData = (accepted: Field[], claimData: TAnswers) =>
  Object.keys(claimData)
    .filter((field) => accepted.includes(field as Field))
    .reduce((obj, field) => {
      obj[field] = claimData[field];
      return obj;
    }, {}) as Partial<TAnswers>;

export type TSetResponsesState = (field: Field, value: TAnswers[Field]) => void;

export const useHackerState = (
  fields: Field[],
  validators: Partial<Record<Field, (value: TAnswer) => boolean>>
) => {
  const { claimData, isFetchingData, isUpdatingData } = useHackerContext();

  // Only selected fields would be included in state
  const [responsesState, setResponses] = useState<Partial<TAnswers>>(
    filterClaimData(fields, claimData)
  );

  // Flag that determines whether lock state has been re-hydrated with server data
  const [isResponseUpdated, setResponseUpdated] = useState(false);

  // Update state when claim is re-fetched
  useEffect(() => {
    if (!isFetchingData && !isUpdatingData) {
      // Batch updates so isResponseUpdated is accurate
      unstable_batchedUpdates(() => {
        setResponses(filterClaimData(fields, claimData));
        setResponseUpdated(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingData, isUpdatingData]);

  // format the hapi dates for display
  // BRADLEY (aug 9): commented out bc we moved to an actual datetime input
  /*
  useEffect(() => {
    if (fields.includes(Field.FRIDAY_FLIGHT_ARRIVAL_TIME)) {
      const val = responsesState[Field.FRIDAY_FLIGHT_ARRIVAL_TIME] as string;
      setResponses((p) => ({
        ...p,
        [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: dateToFormattedString(
          new Date(val)
        ),
      }));
    }

    if (fields.includes(Field.SUNDAY_FLIGHT_DEPARTURE_TIME)) {
      const val = responsesState[Field.SUNDAY_FLIGHT_DEPARTURE_TIME] as string;
      setResponses((p) => ({
        ...p,
        [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: dateToFormattedString(
          new Date(val)
        ),
      }));
    }
    // only want this format to run once, on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  // Updates local state
  const setResponsesState: TSetResponsesState = (field, value) => {
    if (fields.includes(field)) {
      setResponses((prevData) => ({ ...prevData, [field]: value }));
    }
  };

  const isValid = Object.entries(validators).every(([field, validator]) => {
    if (validator) {
      return validator(responsesState[field]);
    }
    return true;
  });

  return {
    responsesState,
    setResponsesState,
    isValid,
    isResponseUpdated,
  };
};
