import { useCallback, useEffect, useMemo } from "react";

import {
  UpdateClaimMutationVariables,
  useUpdateClaimMutation as useUpdateSponsorPerksMutation,
} from "src/api/claims/updateClaim.generated";
import { useSponsorContext } from "src/shared/contexts";

import { useGetSponsorPerksLazyQuery } from "./graphql/getSponsorPerks.generated";

const INVALID_CLAIM_ID = -1;

export const useSponsorPerkClaim = () => {
  const { company } = useSponsorContext();
  const [getSponsorPerks, { data: perkData, loading, error, refetch }] =
    useGetSponsorPerksLazyQuery();

  useEffect(() => {
    if (company) {
      getSponsorPerks({
        variables: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          sponsor_company_id: company.id.toString(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const refetchPerks = () => {
    if (refetch && company) {
      refetch({
        sponsor_company_id: company.id.toString(),
      });
    }
  };

  const perks = useMemo(
    () => perkData?.claims[0]?.fields_HACKTHENORTH2024_SPONSOR_PERKS.answers,
    [perkData]
  );

  const claimId = useMemo(() => perkData?.claims[0]?.id, [perkData]);

  const [updateSponsorPerkClaimMutation, { loading: updating }] =
    useUpdateSponsorPerksMutation();

  const updatePerks = useCallback(
    async (data: UpdateClaimMutationVariables["updatedData"]["answers"]) =>
      updateSponsorPerkClaimMutation({
        variables: {
          updatedData: {
            id: claimId ?? INVALID_CLAIM_ID,
            answers: data,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [claimId]
  );

  return { perks, loading, error, updatePerks, refetchPerks, updating };
};
