import { Spacer, Spinner, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Checkbox } from "north.js";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import "twin.macro";
import styled from "styled-components";

import {
  PageWrapper,
  CompanyCard,
  IMAGE_FILE_TYPES,
  Dropzone,
  FileLink,
} from "src/shared/components";
import { successToast, errorToast } from "src/shared/components/Toast";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize, useIsMounted } from "src/shared/hooks";
import { ArrowRight } from "src/static/icons";

import RSVPSidebar from "../rsvp-sidebar";

import {
  ACCEPTED_FIELDS,
  COMPANY_INTEREST_OPTIONS,
  VALIDATORS,
  GOLD_SPONSORS,
} from "./constants";
import { useFileDropzone } from "./useFileDropzone";

const CareerPage: React.FC = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isMounted = useIsMounted();
  const {
    updateResponses,
    isLoading,
    // isReadOnly,
    navigateNext,
    navigateBack,
  } = useHackerContext();

  const { responsesState, setResponsesState } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const {
    dropzoneInputRef,
    fetchedFile,
    loading: isFileLoading,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(
    responsesState[Field.RESUME]?.[0],
    (files) => {
      setResponsesState(Field.RESUME, files);
    },
    IMAGE_FILE_TYPES
  );

  const [isBlocking, setIsBlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const MAX_COMPANY_INTEREST_OPTIONS = 3;
  // const MAX_COFFEE_CHAT_OPTIONS = 1;

  useEffect(() => {
    if (isMounted) {
      navigateNext();
    }
    if (responsesState[Field.EVENT_RSVP_STAGE] !== PageStage.COMPLETED) {
      navigateBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  const onSave = async () => {
    try {
      const responses = {
        ...responsesState,
        [Field.CAREER_RSVP_STAGE]: PageStage.COMPLETED,
      };

      if (fetchedFile && !isFileLoading && !fileError) {
        responses[Field.RESUME] = fetchedFile;
      }

      const { errors } = await updateResponses(responses);

      if (errors) {
        throw new Error(
          "We weren't able to submit your answers, please try again."
        );
      }

      successToast("Answers successfully saved!");
      setSubmitting(true);
    } catch (e) {
      if (typeof e === "object") {
        errorToast((e as GraphQLError)?.message);
      } else {
        errorToast(e.message);
      }
    }
  };

  const ResumeUpload = () => (
    <div>
      <Dropzone
        label={
          <span>
            <b>Upload a file</b> or drag and drop
          </span>
        }
        acceptedFileTypes={IMAGE_FILE_TYPES}
        onFilesUpload={addFile}
        onError={onFileError}
        ref={dropzoneInputRef}
        onBlur={() => setDropzoneErrorMessage("")}
      />
      {fetchedFile && (
        <>
          <Spacer height={8} />
          <FileLink file={fetchedFile} deleteFile={deleteFile} />
        </>
      )}
    </div>
  );

  return (
    <PageWrapper>
      <Prompt
        when={isBlocking && !submitting}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      {isTabletOrSmaller && <RSVPSidebar currentRoute="Career interests" />}

      <Heading1Text>Career Interests</Heading1Text>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>

      <Heading3Wrapper>
        <Text mods="h3 heading">Resume</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text mods="textBody">
        We work with our partners and sponsors to deliver the best event
        possible. This includes providing them with information about our
        hackers so they can tailor their experiences and even send out job
        opportunities!
      </Text>
      <Spacer height={16} />
      <Checkbox
        label="I consent to provide my data to sponsors for recruitment purposes."
        checked={!!responsesState[Field.RECRUITMENT_CONSENT]}
        onChange={(e: any) => {
          setIsBlocking(true);
          setResponsesState(Field.RECRUITMENT_CONSENT, e.currentTarget.checked);
        }}
      />
      {!!responsesState[Field.RECRUITMENT_CONSENT] && (
        <div>
          <Spacer height={42} />
          <Text mods="bold textBody">Upload your resume</Text>
          <Spacer height={12} />
          <ResumeUpload />
          <Spacer height={42} />
          <Text mods="bold textBody">Additional details</Text>
          <Spacer height={12} />
          <Text mods="textBody">
            Please select all that apply. This information will be sent to
            sponsors for recruitment purposes.
          </Text>
          <Spacer height={12} />
          <Checkbox
            label="I am a Canadian citizen."
            description="Some of our sponsors are restricted to only hiring those that hold Canadian citizenship"
            checked={!!responsesState[Field.CANADIAN_CITIZEN]}
            onChange={(e: any) => {
              setIsBlocking(true);
              setResponsesState(
                Field.CANADIAN_CITIZEN,
                e.currentTarget.checked
              );
            }}
          />
          <Spacer height={12} />
          <Checkbox
            label="I am a permanent resident of Canada."
            checked={!!responsesState[Field.PERMANENT_RESIDENT]}
            onChange={(e: any) => {
              setIsBlocking(true);
              setResponsesState(
                Field.PERMANENT_RESIDENT,
                e.currentTarget.checked
              );
            }}
          />
          <Spacer height={12} />
          <Checkbox
            label="I am looking for internship opportunities."
            checked={!!responsesState[Field.LOOKING_FOR_INTERNSHIPS]}
            onChange={(e: any) => {
              setIsBlocking(true);
              setResponsesState(
                Field.LOOKING_FOR_INTERNSHIPS,
                e.currentTarget.checked
              );
            }}
          />
          <Spacer height={12} />
          <Checkbox
            label="I am looking for full-time employment opportunities."
            checked={!!responsesState[Field.LOOKING_FOR_FULLTIME]}
            onChange={(e: any) => {
              setIsBlocking(true);
              setResponsesState(
                Field.LOOKING_FOR_FULLTIME,
                e.currentTarget.checked
              );
            }}
          />
        </div>
      )}

      <Divider />
      <Heading3Wrapper>
        <Text mods="h3 heading">Companies</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text mods="bold textBody">Connect during the event</Text>
      <Spacer height={12} />
      <Text>
        Please <strong>select up to 3</strong> of our gold and silver sponsors
        that you&#8217;re interested in connecting with during Hack the North.
      </Text>
      <CardContainer>
        {COMPANY_INTEREST_OPTIONS.map(({ id, logo }) => {
          const isSelected = (
            (responsesState[Field.SPONSOR_INTEREST] as string[]) ?? []
          ).includes(id);
          const notAtMax =
            ((responsesState[Field.SPONSOR_INTEREST] as string[]) ?? [])
              .length < MAX_COMPANY_INTEREST_OPTIONS;
          return (
            <CompanyCard
              key={id}
              name={id}
              logo={logo}
              isSelected={isSelected}
              disabled={!isSelected && !notAtMax}
              onClick={() => {
                setIsBlocking(true);
                if (isSelected) {
                  setResponsesState(
                    Field.SPONSOR_INTEREST,
                    (
                      (responsesState[Field.SPONSOR_INTEREST] as string[]) ?? []
                    ).filter(function (item) {
                      return item !== id;
                    })
                  );
                } else if (notAtMax) {
                  setResponsesState(Field.SPONSOR_INTEREST, [
                    ...((responsesState[Field.SPONSOR_INTEREST] as string[]) ??
                      []),
                    id,
                  ]);
                }
              }}
            >
              {logo}
            </CompanyCard>
          );
        })}
      </CardContainer>
      <Spacer height={42} />
      <Text mods="bold textBody">Coffee chat interest</Text>
      <HelperText>
        Indicate your interest in having a coffee chat with our gold sponsors.
      </HelperText>
      <Spacer height={12} />
      {GOLD_SPONSORS.map(({ id }) => {
        return (
          <Checkbox
            key={id}
            label={id}
            checked={
              responsesState[Field.COFFEE_CHAT] !== undefined &&
              responsesState[Field.COFFEE_CHAT] !== null &&
              responsesState[Field.COFFEE_CHAT]!.includes(id)
            }
            onChange={(e: any) => {
              setIsBlocking(true);
              const prev = responsesState[Field.COFFEE_CHAT] ?? [];
              if (e.currentTarget.checked) {
                setResponsesState(Field.COFFEE_CHAT, [...prev, id]);
              } else {
                setResponsesState(
                  Field.COFFEE_CHAT,
                  prev.filter((item) => item !== id)
                );
              }
            }}
          />
        );
      })}

      <Spacer height={100} />
      <Button color="primary" disabled={isLoading} onClick={onSave} size="md">
        {isLoading ? (
          <Spinner />
        ) : (
          <FlexRow>
            <p>
              {responsesState[Field.CAREER_RSVP_STAGE] === PageStage.COMPLETED
                ? "Save"
                : "Next"}
            </p>
            <Spacer width="8px" />
            <ArrowRight width={16} />
          </FlexRow>
        )}
      </Button>
      <Spacer height={24} />
    </PageWrapper>
  );
};

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const Heading3Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 42px;
  margin-bottom: 24px;
`;

const OptionalText = styled.p`
  font-size: 12px;
  margin-left: 16px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-style: italic;
  line-height: 42px;
  text-align: center;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 42px;
  background-color: ${({ theme }) => theme.globalConstants.color.borderGray};
`;

const FlexRow = styled.div`
  align-items: center;
  display: flex;
`;

const CardContainer = styled.div`
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 24px;
`;

const HelperText = styled.p`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
`;

export default CareerPage;
