import { Spacer, Spinner } from "@hackthenorth/north";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "twin.macro";
import "styled-components/macro";

import { useGetEventScheduleQuery } from "src/views/schedule/ScheduleContext/graphql/getEventSchedule.generated";

import Event from "./Event";

interface CalendarProps {
  handleScroll: () => void;
  shownTypes: Set<string>;
  queryFilter?: string;
}

export interface TEvent {
  title: string;
  location: string;
  start_time: Date;
  end_time: Date;
  type: string;
  description: string;
  leads: string[];
  resources: string[];
  row: number;
}

const getLabels = () => {
  const labels = [];
  labels.push({ day: "Fri", hour: "5pm" });
  for (let i = 6; i < 12; i++) {
    labels.push({ day: "", hour: `${i}pm` });
  }
  labels.push({ day: "Sat", hour: "12am" });
  for (let i = 1; i < 12; i++) {
    labels.push({ day: "", hour: `${i}am` });
  }
  labels.push({ day: "", hour: "12pm" });
  for (let i = 1; i < 12; i++) {
    labels.push({ day: "", hour: `${i}pm` });
  }
  labels.push({ day: "Sun", hour: "12am" });
  for (let i = 1; i < 12; i++) {
    labels.push({ day: "", hour: `${i}am` });
  }
  labels.push({ day: "", hour: "12pm" });
  for (let i = 1; i < 12; i++) {
    labels.push({ day: "", hour: `${i}pm` });
  }
  return labels;
};

const getRows = (events: TEvent[]) => {
  const rows: number[] = [];

  events.sort(
    (a: TEvent, b: TEvent) =>
      a.start_time.getTime() - b.start_time.getTime() ||
      (a.type < b.type ? 0 : 1)
  );

  events.forEach(function (event) {
    let inserted = false;
    rows.forEach(function (row, i) {
      if (!inserted && row <= event.start_time.getTime()) {
        event.row = i;
        rows[i] = event.end_time.getTime();
        inserted = true;
      }
    });
    if (!inserted) {
      event.row = rows.length;
      rows.push(event.end_time.getTime());
    }
  });
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const isCurrentDateInRange = () => {
    const eventStartDate = new Date("2023-09-15T21:00:00Z");
    const eventEndDate = new Date("2023-09-18T04:00:00Z");
    return currentDate >= eventStartDate && currentDate <= eventEndDate;
  };

  const labels = getLabels();
  const { loading: fetchingEvents, data } = useGetEventScheduleQuery();
  let eventsData = useMemo(() => data?.eventSchedule ?? [], [data]);
  eventsData = eventsData.filter(
    (eventData) =>
      !eventData.tags.includes("archived") &&
      new Date(eventData.start_time) >= new Date("2023-09-15T21:00:00Z") &&
      new Date(eventData.start_time) <= new Date("2023-09-18T04:00:00Z")
  );

  const events: TEvent[] = [];
  for (const eventData of eventsData) {
    const eventTags = eventData.tags.filter((tag) => props.shownTypes.has(tag));
    if (eventTags.length > 0) {
      events.push({
        title: eventData.title,
        location: eventData.location,
        start_time: new Date(eventData.start_time),
        end_time: new Date(eventData.end_time),
        type: eventTags[0],
        description: eventData.description ?? "",
        leads: [], // eventData.event_leads ?? [],
        resources: eventData.links ?? [],
        row: 0,
      });
    }
  }
  getRows(events);

  const filteredEvents = events.filter(
    (event) =>
      event.title
        .toLowerCase()
        .includes(props.queryFilter?.toLowerCase() ?? "") ||
      event.description
        .toLowerCase()
        .includes(props.queryFilter?.toLowerCase() ?? "")
  );

  const getMarginLeft = () =>
    Math.round(
      ((currentDate.getTime() - new Date("2023-09-15T21:00:00Z").getTime()) *
        128) /
        3600000
    ) + 16;

  useEffect(() => {
    if (!fetchingEvents) {
      const today = new Date().getDay();
      if (today === 6 && document.getElementById("sat-button")) {
        document.getElementById("sat-button")!.click();
      } else if (today === 0 && document.getElementById("sun-button")) {
        document.getElementById("sun-button")!.click();
      }
    }
  }, [fetchingEvents]);

  useEffect(() => {
    setTimeout(() => {
      setCurrentDate(new Date());
    }, 60000);
  }, [currentDate]);

  const numRows = Math.max(...events.map((event) => event.row)) + 1;
  const maxHeight = numRows * 100 + 70;

  return (
    <Container id="schedule-container" onScroll={props.handleScroll}>
      {fetchingEvents ? (
        <Spinner />
      ) : (
        <>
          <div
            tw="sticky top-0 flex bg-white"
            style={{ zIndex: 2, height: "fit-content" }}
          >
            {labels.map(({ day, hour }, i) =>
              i === 0 ? (
                <StartDivider key={`label${i}`}>
                  {day ? <DayText>{day}</DayText> : <Spacer height={29} />}
                  <HourText>{hour}</HourText>
                </StartDivider>
              ) : i === labels.length - 1 ? (
                <EndDivider key={`label${i}`}>
                  {day ? <DayText>{day}</DayText> : <Spacer height={29} />}
                  <HourText>{hour}</HourText>
                </EndDivider>
              ) : (
                <Divider key={`label${i}`}>
                  {day ? <DayText>{day}</DayText> : <Spacer height={29} />}
                  <HourText>{hour}</HourText>
                </Divider>
              )
            )}
          </div>
          <div
            tw="z-0 absolute top-0 flex -mt-px"
            style={{ height: Math.max(1080, maxHeight) }}
          >
            {/* Render current date indicator only if the current date is within the event date */}
            {/* This prevents an "infinite" amount of white space when the current date is outside the event time */}
            {isCurrentDateInRange() && (
              <>
                <CurrentDot marginLeft={getMarginLeft()} />
                <CurrentLine marginLeft={getMarginLeft()} />
              </>
            )}

            {labels.map(({ day, hour }, i) =>
              i === 0 ? (
                <StartDivider key={`label${i}`} fullHeight={true}>
                  <Line />
                </StartDivider>
              ) : i === labels.length - 1 ? (
                <EndDivider key={`label${i}`} fullHeight={true}>
                  <Line />
                </EndDivider>
              ) : (
                <Divider key={`label${i}`} fullHeight={true}>
                  <Line />
                </Divider>
              )
            )}
          </div>
          {filteredEvents.map((event, i) => (
            <Event key={`label${i}`} event={event} />
          ))}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  padding: 0 40px;
  z-index: 0;
`;

const StartDivider = styled.div<{ fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 32px;
  margin-right: 48px;
  height: ${(props) => (props.fullHeight ? "100%" : "fit-content")};
`;

const EndDivider = styled.div<{ fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 32px;
  margin-left: 48px;
  height: ${(props) => (props.fullHeight ? "100%" : "fit-content")};
`;

const Divider = styled.div<{ fullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  width: 128px;
  height: ${(props) => (props.fullHeight ? "100%" : "fit-content")};
`;

const DayText = styled.p`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 700;
  font-size: 14px;
`;

const HourText = styled.p`
  margin-top: 0;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 400;
  font-size: 14px;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: #eeeeee;
`;

const CurrentDot = styled.div<{ marginLeft: number }>`
  position: absolute;
  width: 8px;
  height: 8px;
  margin-top: 71px;
  margin-left: ${({ marginLeft }) => marginLeft - 4}px;
  border-radius: 4px;
  background-color: #d95858;
  z-index: 3;
`;

const CurrentLine = styled.div<{ marginLeft: number }>`
  position: absolute;
  width: 2px;
  height: calc(100% - 70px);
  margin-top: 70px;
  margin-left: ${({ marginLeft }) => marginLeft - 1}px;
  background-color: #d95858;
  z-index: 3;
`;

export default Calendar;
