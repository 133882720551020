/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type RedeemEventActivityScanMutationVariables = Types.Exact<{
  event_activity_id: Types.Scalars["Float"];
  badge_code: Types.Scalars["String"];
}>;

export type RedeemEventActivityScanMutation = {
  redeemEventActivityScan: Pick<
    Types.BadgeScan,
    | "id"
    | "event_id"
    | "scanned_at"
    | "updated_at"
    | "is_duplicate"
    | "scanning_attendee_id"
    | "scanned_attendee_id"
    | "badge_code"
    | "type"
    | "claim_number"
  >;
};

export const RedeemEventActivityScanDocument = gql`
  mutation RedeemEventActivityScan(
    $event_activity_id: Float!
    $badge_code: String!
  ) {
    redeemEventActivityScan(
      event_activity_id: $event_activity_id
      badge_code: $badge_code
    ) {
      id
      event_id
      scanned_at
      updated_at
      is_duplicate
      scanning_attendee_id
      scanned_attendee_id
      badge_code
      type
      claim_number
    }
  }
`;

/**
 * __useRedeemEventActivityScanMutation__
 *
 * To run a mutation, you first call `useRedeemEventActivityScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemEventActivityScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemEventActivityScanMutation, { data, loading, error }] = useRedeemEventActivityScanMutation({
 *   variables: {
 *      event_activity_id: // value for 'event_activity_id'
 *      badge_code: // value for 'badge_code'
 *   },
 * });
 */
export function useRedeemEventActivityScanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RedeemEventActivityScanMutation,
    RedeemEventActivityScanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RedeemEventActivityScanMutation,
    RedeemEventActivityScanMutationVariables
  >(RedeemEventActivityScanDocument, baseOptions);
}
export type RedeemEventActivityScanMutationHookResult = ReturnType<
  typeof useRedeemEventActivityScanMutation
>;
export type RedeemEventActivityScanMutationResult =
  ApolloReactCommon.MutationResult<RedeemEventActivityScanMutation>;
export type RedeemEventActivityScanMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RedeemEventActivityScanMutation,
    RedeemEventActivityScanMutationVariables
  >;
