/* eslint-disable @typescript-eslint/camelcase */
import { NetworkStatus } from "@apollo/client";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button, ButtonGroup, Label, Select, Table, Text } from "north.js";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import tw, { styled } from "twin.macro";

import { Route } from "src/shared/constants/route";
import { useGetOpenMentorshipRequestsQuery } from "src/shared/contexts/MentorContext/graphql/getOpenRequests.generated";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { formatRelativeTime } from "src/shared/utils/formatTime";
import { getFieldAnswer } from "src/shared/utils/hackerapi";

import { ClaimRequestModal } from "./ClaimRequestModal";

const POLL_INTERVAL = 1000 * 30; //poll interval in ms

const TABLE_COLS = [
  {
    id: "subject",
    name: "SUBJECT",
  },
  {
    id: "created",
    name: "CREATED",
  },
  {
    id: "category",
    name: "CATEGORY",
  },
  {
    id: "view",
    name: "VIEW",
    hideLabel: true,
  },
];

type Props = {
  query: string;
  categoriesFilter: string[];
  triggerRefresh: boolean;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};
export const OpenRequestsTable: React.FC<Props> = ({
  query,
  categoriesFilter,
  triggerRefresh,
  setTriggerRefresh,
}) => {
  const navigate = useNavigate();

  const {
    data: requestData,
    loading,
    // error,
    refetch,
    networkStatus,
  } = useGetOpenMentorshipRequestsQuery({
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const refetchData = async () => {
      await refetch();
      setTriggerRefresh(false);
    };
    refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefresh]);

  const isRefetching =
    networkStatus === NetworkStatus.refetch && triggerRefresh;

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<MentorshipRequest>();

  const mentorshipRequests: MentorshipRequest[] = useMemo(
    () =>
      requestData?.claims.map((request) => ({
        id: request.id,
        title: getFieldAnswer(request.fields, "title"),
        description: getFieldAnswer(request.fields, "description"),
        category: getFieldAnswer(request.fields, "category"),
        priority: getFieldAnswer(request.fields, "priority"),
        mentee_name: getFieldAnswer(request.fields, "mentee_name"),
        reopened: getFieldAnswer(request.fields, "reopened"),
        mentor_id: getFieldAnswer(request.fields, "mentor_id"),
        mentee_id: getFieldAnswer(request.fields, "mentee_id"),
        created_at: new Date(request.created_at),
        stage_slug: request.stage.slug,
      })) ?? [],
    [requestData]
  );
  console.debug({ mentorshipRequests });

  let transformedData = mentorshipRequests;

  if (query !== "") {
    const lowercaseQuery = query.toLowerCase();
    transformedData = transformedData.filter(
      (request) =>
        request.title?.toLowerCase().includes(lowercaseQuery) ||
        request.category?.some((category) =>
          category.toLowerCase().includes(lowercaseQuery)
        ) ||
        request.mentee_name?.toLowerCase().includes(lowercaseQuery)
    );
  }
  if (categoriesFilter.length > 0) {
    transformedData = transformedData.filter((request) => {
      const filter = categoriesFilter.map((c) => c.toLowerCase());
      const categories = request.category?.map((c) => c.toLowerCase()) ?? [];
      return categories.some((c) => filter.includes(c));
    });
  }

  console.debug({ transformedData });

  const tableData = transformedData.map((request) => {
    return {
      subject: isRefetching ? (
        <div tw="h-16 bg-background-grayDark rounded-8 w-120"></div>
      ) : (
        <Text type="body1" tw="text-text-body font-medium">
          {request?.title ?? ""}
        </Text>
      ),
      created: isRefetching ? (
        <div tw="h-16 bg-background-grayDark rounded-8 w-64"></div>
      ) : (
        <Text type="caption" tw="text-text-body font-medium font-size[14px]!">
          {formatRelativeTime(request?.created_at ?? "")}
        </Text>
      ),
      category: isRefetching ? (
        <div tw="h-16 bg-background-grayDark rounded-8 w-480"></div>
      ) : (
        (request?.category ?? []).length > 0 && (
          <div tw="flex align-items[center] flex-wrap gap-8">
            {(request?.category ?? []).map((category) => (
              <>
                <Label
                  size="sm"
                  color="secondary-light"
                  key={category}
                  tw="font-bold font-size[14px]"
                >
                  {category}
                </Label>
              </>
            ))}
          </div>
        )
      ),
      view: (
        <div tw="flex justify-end">
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setCurrentRequest(request);
              setIsViewModalOpen(true);
            }}
            tw="py-4! px-16!"
          >
            <Text type="button" tw="text-text-light">
              View
            </Text>
          </Button>
        </div>
      ),
    };
  });

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const numPages = Math.ceil(tableData.length / itemsPerPage);

  console.log({ loading });
  return (
    <>
      <StyledTable
        columns={TABLE_COLS}
        data={tableData}
        horizontalPadding={16}
        verticalPadding={10}
        showFooter
        itemsPerPage={itemsPerPage}
      />
      <div tw="flex items-center justify-between mt-24">
        <div tw="flex items-center gap-16">
          <Text type="button" tw="text-text-secondary">
            Items per page:
          </Text>
          <Select
            size="md"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
            tw="font-bold text-text-secondary"
          >
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={30}>30</Select.Option>
            <Select.Option value={40}>40</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
        <ButtonGroup>
          <Button
            size="sm"
            disabled={page === 0}
            onClick={() => setPage(Math.max(0, page - 1))}
          >
            <ChevronLeftIcon tw="w-16 h-16 font-bold" />
          </Button>
          {Array.from({ length: numPages }, (_, i) => (
            <Button
              size="sm"
              color={page === i ? "primary" : "secondary"}
              onClick={() => setPage(i)}
            >
              <Text
                type="button"
                tw=" font-size[12px]!"
                css={[
                  page === i ? tw`text-text-light` : tw`text-text-secondary`,
                ]}
              >
                {i + 1}
              </Text>
            </Button>
          ))}
          <Button
            size="sm"
            disabled={page === numPages - 1}
            onClick={() => setPage(Math.min(numPages, page + 1))}
          >
            <ChevronRightIcon tw="w-16 h-16 font-bold" />
          </Button>
        </ButtonGroup>
        <ClaimRequestModal
          isOpen={isViewModalOpen}
          onClose={(claimedRequest: boolean) => {
            setIsViewModalOpen(false);
            setCurrentRequest(undefined);
            refetch();
            if (claimedRequest) {
              navigate(Route.MENTOR_CLAIMED_REQUESTS);
            }
          }}
          request={currentRequest}
        />
      </div>
    </>
  );
};

const StyledTable = styled(Table)`
  thead th > span {
    font-size: 14px;
    font-weight: 500;
  }
`;
