/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../../api/types.generated";
export type GetSponsorsInfoQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetSponsorsInfoQuery = {
  claims: Array<{
    fields_HACKTHENORTH2021_SPONSOR_PERKS: {
      answers: {
        sponsor_company_id: Pick<
          Types.NumberFieldAnswerTypeWrapper,
          "id" | "field_type" | "slug" | "value"
        >;
        website_logo_file: Pick<
          Types.FileFieldAnswerTypeWrapper,
          "id" | "field_type" | "slug"
        > & {
          value?: Types.Maybe<
            Array<
              Pick<
                Types.FileFieldAnswerType,
                "id" | "name" | "mime_type" | "expiry" | "uri"
              >
            >
          >;
        };
        tech_talk_proposal_title: Pick<
          Types.StringFieldAnswerTypeWrapper,
          "id" | "field_type" | "slug" | "value"
        >;
        tech_talk_proposal_description: Pick<
          Types.StringFieldAnswerTypeWrapper,
          "id" | "field_type" | "slug" | "value"
        >;
      };
    };
  }>;
};

export const GetSponsorsInfoDocument = gql`
  query getSponsorsInfo {
    claims(
      where: {
        pipeline: { is: { slug: { equals: "hackthenorth2021-sponsor-perks" } } }
      }
    ) {
      fields_HACKTHENORTH2021_SPONSOR_PERKS {
        answers {
          sponsor_company_id {
            id
            field_type
            slug
            value
          }
          website_logo_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          tech_talk_proposal_title {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_description {
            id
            field_type
            slug
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetSponsorsInfoQuery__
 *
 * To run a query within a React component, call `useGetSponsorsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSponsorsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorsInfoQuery,
    GetSponsorsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorsInfoQuery,
    GetSponsorsInfoQueryVariables
  >(GetSponsorsInfoDocument, baseOptions);
}
export function useGetSponsorsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorsInfoQuery,
    GetSponsorsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorsInfoQuery,
    GetSponsorsInfoQueryVariables
  >(GetSponsorsInfoDocument, baseOptions);
}
export type GetSponsorsInfoQueryHookResult = ReturnType<
  typeof useGetSponsorsInfoQuery
>;
export type GetSponsorsInfoLazyQueryHookResult = ReturnType<
  typeof useGetSponsorsInfoLazyQuery
>;
export type GetSponsorsInfoQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorsInfoQuery,
  GetSponsorsInfoQueryVariables
>;
