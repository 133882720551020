import { Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import "twin.macro";
import { Icon } from "src/shared/components";

type TPerkErrorProps = {
  error: string | React.ReactNode;
};

const Container = styled(Flex).attrs({ align: "center", row: true })`
  font-size: 12px;
  margin-top: 4px;
  gap: 8px;
  --tw-text-opacity: 1;
  color: rgba(217, 88, 88, var(--tw-text-opacity));
`;

export const PerkError: React.FC<TPerkErrorProps> = ({ error }) => (
  <Container>
    <Icon name="error-exclamation" height={16} width={16} fill="#d95858" />
    {error}
  </Container>
);
