import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";
import { usePermissionsContext, Permission } from "src/shared/contexts";
import "twin.macro";

const GENERAL_HELP_ID = "7685654e-eb5a-4b72-bf17-929e87e81e93";
const PERMISSION_BASED_HELP_DOCUMENTS: Partial<Record<Permission, string>> = {
  [Permission.SPONSOR]: "2d3b1d10-cca4-40e9-82cf-e6e8f1ca97b2",
};

const HelpHome: React.FC = () => {
  const { hasPermission } = usePermissionsContext();

  // map roles to document IDs
  const documentsToDisplay = Object.entries(PERMISSION_BASED_HELP_DOCUMENTS)
    .map(([p, id]) => (hasPermission(p as Permission) ? id : null))
    .filter((id) => id !== null) as string[];

  return (
    <PageWrapper>
      <OutlineDocument shareId={GENERAL_HELP_ID} />
      <div tw="mb-48" />
      {documentsToDisplay.map((id) => (
        <OutlineDocument key={id} shareId={id} />
      ))}
    </PageWrapper>
  );
};

export default HelpHome;
