import React from "react";

import {
  SponsorPerkType,
  SponsorPerksData,
} from "src/views/sponsor/perks/types";

import { API_PRIZE_VALIDATION_FIELDS, ApiPrize } from "./ApiPrize";
import { ApiWorkshop, API_WORKSHOP_VALIDATION_FIELDS } from "./ApiWorkshop";
import {
  CLOSING_CEREMONIES_SLIDE_VALIDATION_FIELDS,
  ClosingCeremoniesSlide,
} from "./CeremoniesSlide/ClosingCeremoniesSlide";
import {
  OPENING_CEREMONIES_SLIDE_VALIDATION_FIELDS,
  OpeningCeremoniesSlide,
} from "./CeremoniesSlide/OpeningCeremoniesSlide";
import { CoffeeChats, COFFEE_CHATS_VALIDATION_FIELDS } from "./CoffeeChats";
import {
  LightningChallenge,
  LIGHTNING_CHALLENGE_VALIDATION_FIELDS,
} from "./LightningChallenge";
import { NameAnArea, NAME_AN_AREA_VALIDATION_FIELDS } from "./NameAnArea";
import {
  NewsletterFeature,
  NEWSLETTER_FEATURE_VALIDATION_FIELDS,
} from "./NewsletterFeature";
import {
  NewsletterOpportunity,
  NEWSLETTER_OPPORTUNITY_VALIDATION_FIELDS,
} from "./NewsletterOpportunity";
import {
  NewsletterSpotlight,
  NEWSLETTER_SPOTLIGHT_VALIDATION_FIELDS,
} from "./NewsletterSpotlight";
import {
  SelectTableLocation,
  SELECT_TABLE_LOCATION_VALIDATION_FIELDS,
} from "./SelectTableLocation";
import { ShippedSwag, SHIPPED_SWAG_VALIDATION_FIELDS } from "./ShippedSwag";
import {
  SOCIAL_MEDIA_PROMOTION_VALIDATION_FIELDS,
  SocialMediaPromotion,
} from "./SocialMediaPromotion";
import {
  SPONSOR_A_MEETUP_VALIDATION_FIELDS,
  SponsorAMeetup,
} from "./SponsorAMeetup";
import {
  SponsorAnActivity,
  SPONSOR_AN_ACTIVITY_VALIDATION_FIELDS,
} from "./SponsorAnActivity";
import {
  SponsorASnack,
  SPONSOR_A_SNACK_VALIDATION_FIELDS,
} from "./SponsorASnack";
import {
  TechTalkProposal,
  TECH_TALK_VALIDATION_FIELDS,
} from "./TechTalkProposal";
import { TechTalkTime, TECH_TALK_TIME_VALIDATION_FIELDS } from "./TechTalkTime";
import {
  WebsiteFeature,
  WEBSITE_FEATURE_VALIDATION_FIELDS,
} from "./WebsiteFeature";
import { WebsiteLogo, WEBSITE_LOGO_VALIDATION_FIELDS } from "./WebsiteLogo";
import {
  WelcomeBookletBlurb,
  WELCOME_BOOKLET_BLURB_VALIDATION_FIELDS,
} from "./WelcomeBookletBlurb";

type PerkComponent =
  | typeof WebsiteLogo
  | typeof WebsiteFeature
  | typeof NewsletterSpotlight
  | typeof NewsletterOpportunity
  | typeof NewsletterFeature
  | typeof TechTalkProposal
  | typeof ApiPrize
  | typeof ApiWorkshop
  | typeof TechTalkTime
  | typeof OpeningCeremoniesSlide
  | typeof ClosingCeremoniesSlide
  | typeof CoffeeChats
  | typeof LightningChallenge;

interface PerkContent {
  component: React.ElementType;
  validationFields: (keyof SponsorPerksData)[];
}

export const getPerkComponent = (perkType: SponsorPerkType): PerkContent => {
  switch (perkType) {
    case SponsorPerkType.WEBSITE_LOGO:
      return {
        component: WebsiteLogo,
        validationFields: WEBSITE_LOGO_VALIDATION_FIELDS,
      };

    case SponsorPerkType.WEBSITE_FEATURE:
      return {
        component: WebsiteFeature,
        validationFields: WEBSITE_FEATURE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.NEWSLETTER_OPPORTUNITY:
      return {
        component: NewsletterOpportunity,
        validationFields: NEWSLETTER_OPPORTUNITY_VALIDATION_FIELDS,
      };

    case SponsorPerkType.NEWSLETTER_SPOTLIGHT:
      return {
        component: NewsletterSpotlight,
        validationFields: NEWSLETTER_SPOTLIGHT_VALIDATION_FIELDS,
      };

    case SponsorPerkType.NEWSLETTER_FEATURE:
      return {
        component: NewsletterFeature,
        validationFields: NEWSLETTER_FEATURE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.LIGHTNING_CHALLENGE:
      return {
        component: LightningChallenge,
        validationFields: LIGHTNING_CHALLENGE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SPONSOR_AN_ACTIVITY:
      return {
        component: SponsorAnActivity,
        validationFields: SPONSOR_AN_ACTIVITY_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SPONSOR_A_SNACK:
      return {
        component: SponsorASnack,
        validationFields: SPONSOR_A_SNACK_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SPONSOR_A_MEETUP:
      return {
        component: SponsorAMeetup,
        validationFields: SPONSOR_A_MEETUP_VALIDATION_FIELDS,
      };

    case SponsorPerkType.TECH_TALK_PROPOSAL:
      return {
        component: TechTalkProposal,
        validationFields: TECH_TALK_VALIDATION_FIELDS,
      };

    case SponsorPerkType.TECH_TALK_DETAILS:
      return {
        component: TechTalkTime,
        validationFields: TECH_TALK_TIME_VALIDATION_FIELDS,
      };

    case SponsorPerkType.API_PRIZE:
      return {
        component: ApiPrize,
        validationFields: API_PRIZE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.API_WORKSHOP:
      return {
        component: ApiWorkshop,
        validationFields: API_WORKSHOP_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SELECT_TABLE_LOCATION:
      return {
        component: SelectTableLocation,
        validationFields: SELECT_TABLE_LOCATION_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SOCIAL_MEDIA_PROMOTION:
      return {
        component: SocialMediaPromotion,
        validationFields: SOCIAL_MEDIA_PROMOTION_VALIDATION_FIELDS,
      };

    case SponsorPerkType.NAME_AN_AREA:
      return {
        component: NameAnArea,
        validationFields: NAME_AN_AREA_VALIDATION_FIELDS,
      };

    case SponsorPerkType.WELCOME_BOOKLET_BLURB:
      return {
        component: WelcomeBookletBlurb,
        validationFields: WELCOME_BOOKLET_BLURB_VALIDATION_FIELDS,
      };

    case SponsorPerkType.SHIPPED_SWAG:
      return {
        component: ShippedSwag,
        validationFields: SHIPPED_SWAG_VALIDATION_FIELDS,
      };

    case SponsorPerkType.OPENING_CEREMONIES_SLIDE:
      return {
        component: OpeningCeremoniesSlide,
        validationFields: OPENING_CEREMONIES_SLIDE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.CLOSING_CEREMONIES_SLIDE:
      return {
        component: ClosingCeremoniesSlide,
        validationFields: CLOSING_CEREMONIES_SLIDE_VALIDATION_FIELDS,
      };

    case SponsorPerkType.COFFEE_CHATS:
      return {
        component: CoffeeChats,
        validationFields: COFFEE_CHATS_VALIDATION_FIELDS,
      };

    default:
      throw new Error(`Unknown perk ${perkType}`);
  }
};

export const perkSlugToTitle = (slug: string): string => {
  return slug.replace(/_/g, " ");
};
