import styled from "styled-components";

export const TextDescription = styled.div`
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
`;

export const TextInputTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.globalConstants.color.borderGrayDark};
  text-transform: capitalize;
`;
