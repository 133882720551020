import { Button, Flex, JWTRole, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button as ButtonNew, Card, Label, Spinner, TextInput } from "north.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Header,
  Icon,
  Modal,
  PageWrapper,
  errorToast,
} from "src/shared/components";
import { getBadgeColor } from "src/shared/components/Sidebar";
import { BaseRoute } from "src/shared/constants/route";
import {
  useHackerContext,
  useNetworkingContext,
  useUserContext,
} from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";
import {
  isValidDiscordTag,
  isValidSocialMediaURL,
} from "src/shared/utils/validation";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";

const Networking = () => {
  const navigate = useNavigate();
  const { updateResponses, isLoading } = useHackerContext();
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, VALIDATORS);
  const { roles } = useUserContext();
  const isTabletOrSmaller = useDeviceSize("tablet");
  const [selectedField, setSelectedField] = useState(Field.INSTAGRAM);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState<string | undefined>("");
  const [modalTitle, setModalTitle] = useState("");

  const { extendedProfile, isLoading: isExtendedProfileLoading } =
    useNetworkingContext();

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(
      (selectedField === Field.DISCORD_TAG && isValidDiscordTag(value)) ||
        isValidSocialMediaURL(value, modalTitle)
    );
  }, [selectedField, value, modalTitle]);

  const isPageLoading = isLoading || isExtendedProfileLoading;

  const onSave = async () => {
    if (isValid) {
      try {
        const responses = {
          [selectedField]: value,
        };

        const { errors } = await updateResponses(responses);

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e.message);
        }
      }
    }
  };

  if (roles.includes(JWTRole.SPONSOR)) {
    return (
      <>
        <Modal
          isOpen
          title="Access denied"
          actions={
            <Button mods="primary" onClick={() => navigate(BaseRoute.HOME)}>
              Go home
            </Button>
          }
        >
          <Text>You are not permitted to view this page.</Text>
        </Modal>
        <PageWrapper pageTitle="" />
      </>
    );
  }

  return (
    <>
      <Header
        title="Networking"
        subtitle="A quick way to connect with new people at the event!"
      />
      <PageWrapper>
        {isTabletOrSmaller ? (
          <MobileModal
            isOpen={showModal}
            title={"Enter your " + modalTitle + " URL"}
          >
            <LinkInput
              size="md"
              placeholder={
                selectedField === Field.DISCORD_TAG
                  ? "your_discord_username"
                  : `https://${modalTitle.toLowerCase()}.com/your_profile_information`
              }
              error={
                selectedField === Field.DISCORD_TAG
                  ? !isValidDiscordTag(value)
                    ? "Please enter a valid Discord Tag"
                    : undefined
                  : !isValidSocialMediaURL(value, modalTitle)
                  ? `Please enter a valid ${modalTitle} URL`
                  : undefined
              }
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
            />
            <Buttons>
              <ButtonNew
                color="secondary"
                size="md"
                onClick={() => {
                  setValue("");
                  setShowModal(false);
                }}
              >
                Cancel
              </ButtonNew>
              <ButtonNew
                color="primary"
                size="md"
                onClick={() => {
                  onSave();
                  setShowModal(false);
                }}
                disabled={!isValid}
              >
                Confirm
              </ButtonNew>
            </Buttons>
          </MobileModal>
        ) : (
          <Modal
            isOpen={showModal}
            title={modalTitle}
            actions={
              <>
                <ButtonNew
                  color="tertiary"
                  size="md"
                  onClick={() => {
                    setValue("");
                    setShowModal(false);
                  }}
                >
                  Cancel
                </ButtonNew>
                <ButtonNew
                  color="primary"
                  size="md"
                  onClick={() => {
                    onSave();
                    setShowModal(false);
                  }}
                  disabled={!isValid}
                >
                  Confirm
                </ButtonNew>
              </>
            }
          >
            <LinkInput
              size="md"
              placeholder={
                selectedField === Field.DISCORD_TAG
                  ? "your_discord_username"
                  : `https://${modalTitle.toLowerCase()}.com/your_profile_information`
              }
              error={
                selectedField === Field.DISCORD_TAG
                  ? !isValidDiscordTag(value)
                    ? "Please enter a valid Discord Tag"
                    : undefined
                  : !isValidSocialMediaURL(value, modalTitle)
                  ? `Please enter a valid ${modalTitle} URL`
                  : undefined
              }
              value={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
            />
          </Modal>
        )}
        <>
          <ProfileWrapper>
            <Flex>
              {roles.map((role) => (
                <RoleLabel
                  key={role}
                  color={getBadgeColor(role as JWTRole)}
                  size="md"
                >
                  {role.charAt(0).toUpperCase() + role.slice(1)}
                </RoleLabel>
              ))}
            </Flex>
            <NameWrapper>
              <Text mods="heading h1 bold">
                {responsesState.rsvp_preferred_name}
              </Text>
              {responsesState.rsvp_preferred_pronouns?.length !== 0 && (
                <div>
                  <Text>(</Text>
                  {responsesState.rsvp_preferred_pronouns?.map(
                    (pronoun, index) => (
                      <Text key={pronoun}>
                        {pronoun}
                        {responsesState.rsvp_preferred_pronouns &&
                          index !==
                            responsesState.rsvp_preferred_pronouns?.length -
                              1 &&
                          ", "}
                      </Text>
                    )
                  )}
                  <Text>)</Text>
                </div>
              )}
            </NameWrapper>
            <SubtitleText>{responsesState.networking_biography}</SubtitleText>
            <SubduedHeader>INTERESTS</SubduedHeader>
            <SubtitleText>
              {extendedProfile?.interests
                ?.split(",")
                .map((item) => item.trim())
                .join(", ") || "None entered"}
            </SubtitleText>
            <EditButton
              color="primary"
              size="md"
              onClick={() => navigate(BaseRoute.SETTINGS)}
            >
              Edit profile information
            </EditButton>
          </ProfileWrapper>
          {isPageLoading ? (
            <Spinner />
          ) : (
            <Flex column>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.INSTAGRAM);
                  setValue(responsesState.networking_instagram_link || "");
                  setModalTitle("Instagram");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">Instagram</Text>
                  <Text>{responsesState.networking_instagram_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.FACEBOOK);
                  setValue(responsesState.networking_facebook_link || "");
                  setModalTitle("Facebook");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">Facebook</Text>
                  <Text>{responsesState.networking_facebook_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.TWITTER);
                  setValue(responsesState.networking_twitter_link || "");
                  setModalTitle("Twitter");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">Twitter</Text>
                  <Text>{responsesState.networking_twitter_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.LINKEDIN);
                  setValue(responsesState.networking_linkedin_link || "");
                  setModalTitle("LinkedIn");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">LinkedIn</Text>
                  <Text>{responsesState.networking_linkedin_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.GITHUB);
                  setValue(responsesState.networking_github_link || "");
                  setModalTitle("Github");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">Github</Text>
                  <Text>{responsesState.networking_github_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.DISCORD_TAG);
                  setValue(responsesState.networking_discord_tag || "");
                  setModalTitle("Discord Tag");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">Discord Tag</Text>
                  <Text>{responsesState.networking_discord_tag || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
              <LinkCard
                onClick={() => {
                  setSelectedField(Field.TIKTOK);
                  setValue(responsesState.networking_tiktok_link || "");
                  setModalTitle("TikTok");
                  setShowModal(true);
                }}
              >
                <LinkCardBody>
                  <Text mods="bold">TikTok</Text>
                  <Text>{responsesState.networking_tiktok_link || "-"}</Text>
                </LinkCardBody>
                <Icon name="edit" size="h2" />
              </LinkCard>
            </Flex>
          )}
        </>
      </PageWrapper>
    </>
  );
};

const RoleLabel = styled(Label)`
  height: fit-content;
`;

const LinkCardBody = styled.div`
  max-width: 85%;
  display: flex;
  flex-direction: column;
  p {
    overflow-wrap: break-word;
  }
`;

const NameWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  p:first-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const EditButton = styled(ButtonNew)`
  margin-top: 24px;
  margin-bottom: 48px;
  height: 42px !important;
  @media (max-width: 768px) {
    width: 336px !important;
  }
`;

const LinkCard = styled(Card)`
  margin: 16px 0;
  padding: 16px 24px !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
`;

const LinkInput = styled(TextInput)`
  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0 24px;
    align-items: center;
    text-align: center;
  }
`;

const MobileModal = styled(Modal)`
  width: 100%;
  height: 80vh;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 100000 !important;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideUp 0.2s;
  text-align: center;

  div {
    width: 100%;
  }

  @keyframes slideUp {
    0% {
      bottom: -60vh;
    }
    100% {
      bottom: 0;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  button {
    width: 45%;
  }
`;

const SubduedHeader = styled.p`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin: 20px 0 5px 0;
`;

const SubtitleText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`;

export default Networking;
