import { Button } from "north.js";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useUserContext } from "src/shared/contexts";
import { useDeviceSize } from "src/shared/hooks";
import { useWindowSize } from "src/shared/hooks";

import { capitalizeFirstLetter } from "../util";

import RSVPCard from "./rsvpCard";
import TicketModal, { TicketDrawer } from "./ticketModal";

interface TicketContainerProps {
  shirtType: string | undefined;
  shirtSize: string | undefined;
}

const TicketContainer: React.FC<TicketContainerProps> = ({
  shirtType,
  shirtSize,
}) => {
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleHover = () => setHovered(true);
  const handleHoverOut = () => setHovered(false);
  const onCancel = () => {
    setShowModal(false);
    handleHoverOut();
  };

  const { windowWidth } = useWindowSize();

  const isPhoneOrSmaller = windowWidth <= 525;
  const isTabletOrSmaller = useDeviceSize("tablet");

  const { info, roles, apple_wallet_url, google_wallet_url } = useUserContext();

  // trigger animation for smaller screens
  useEffect(() => {
    if (isTabletOrSmaller) {
      const timer = setTimeout(() => setHovered(true), 2000);
      return () => clearTimeout(timer);
    }
    return;
  }, [isTabletOrSmaller, hovered]);

  return (
    <Wrapper
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverOut}
      isPhoneOrSmaller={isPhoneOrSmaller}
    >
      <TicketWrap hovered={hovered}>
        <RSVPCard
          name={info?.name}
          email={info?.email}
          role={capitalizeFirstLetter(roles[0])}
          shirtType={shirtType}
          shirtSize={shirtSize}
        />
      </TicketWrap>
      <TicketBtn color="secondary" size="md">
        <ButtonText onClick={() => setShowModal(true)}>
          View my ticket
        </ButtonText>
      </TicketBtn>
      {!isPhoneOrSmaller ? (
        <TicketModal
          isOpen={showModal}
          onCancel={onCancel}
          appleWallet={apple_wallet_url}
          googleWallet={google_wallet_url}
          ticket={
            <RSVPCard
              name={info?.name}
              email={info?.email}
              role={capitalizeFirstLetter(roles[0])}
              shirtType={shirtType}
              shirtSize={shirtSize}
            />
          }
        />
      ) : (
        <TicketDrawer
          isOpen={showModal}
          onCancel={onCancel}
          appleWallet={apple_wallet_url}
          googleWallet={google_wallet_url}
          ticket={
            <RSVPCard
              name={info?.name}
              email={info?.email}
              role={capitalizeFirstLetter(roles[0])}
              shirtType={shirtType}
              shirtSize={shirtSize}
            />
          }
        />
      )}
    </Wrapper>
  );
};

export default TicketContainer;

const Wrapper = styled.div<{
  isPhoneOrSmaller: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: ${({ isPhoneOrSmaller }) => (isPhoneOrSmaller ? "24px" : "0")};
  height: 543px;
  background-color: #e5e7eb;
  border-radius: 24px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.02) inset;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.p`
  margin: 0;
  color: #0e3460;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;

const TicketWrap = styled.div<{ hovered: boolean }>`
  width: 333px;
  height: 445px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: ${({ hovered }) =>
    hovered ? "translateY(18%)" : "translateY(53%)"};
  opacity: opacity 0.5s ease;
  opacity: ${({ hovered }) => (hovered ? "1" : "0.8")};
`;

const TicketBtn = styled(Button)`
  z-index: 1;
  position: absolute;
  bottom: 24px;
`;
