import { Card, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { SponsorPerkType } from "src/views/sponsor/perks/types";

export interface PerkContainerProps
  extends React.ComponentPropsWithoutRef<"div"> {
  id: SponsorPerkType;
  description?: React.ReactNode;
  error?: boolean;
  readOnly?: boolean;
}

const PerkCard = styled(Card)<Pick<PerkContainerProps, "error" | "readOnly">>`
  background: ${({ theme }) => theme.globalConstants.color.white};
  filter: ${({ readOnly }) => (readOnly ? "brightness(95%)" : "none")};
`;

export const PerkContainer: React.FC<PerkContainerProps> = ({
  id,
  description,
  children,
  error,
  readOnly,
  ...rest
}) => {
  return (
    <div id={id} {...rest}>
      <div id={`${id}--description`}>
        {description && (
          <>
            <Spacer height={16} />
            {description}
          </>
        )}
        <Spacer height={24} />
      </div>
      <PerkCard error={error} readOnly={readOnly}>
        {children}
      </PerkCard>
      <Spacer height={32} />
    </div>
  );
};
