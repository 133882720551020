import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { notNull } from "src/shared/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.AGE_18,
  Field.GUARDIAN_NAME,
  Field.GUARDIAN_CONTACT,
  Field.WAIVER_CONSENT,
  Field.CONFIRMATION_RSVP_STAGE,
  Field.CAREER_RSVP_STAGE,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.AGE_18]: notNull,
};

export const TERMS_AND_CONDITIONS_MD = `
## Terms and Conditions
---
1. You acknowledge that your participation in any component of Hack the North 2024, including but not limited to ceremonies, workshops, activities, digital platforms, project submission and judging (collectively, the "Event") constitutes your full and unconditional agreement to and acceptance of these Terms and Conditions. The term "you" refers to you, the "Participant".

2. If you are under the age of 18, you must obtain consent from your parent or guardian to participate in the Event, and your parent or guardian is required to agree to these Terms and Conditions by having your parent or guardian fill out their contact information and sign. You acknowledge that, even as a minor, you must read and understand the content of these Terms and Conditions, and that you have the capacity and intend to be bound by the terms of these Terms and Conditions.

3. **Ownership**: You will own any developments made by you during the Event, and all rights, title, and interest in those developments, including the intellectual property rights therein, shall belong to you. However, you acknowledge that during the course of the Event, you may obtain access to products, developments, information and other materials belonging to other participants of the Event, the sponsors of the Event ("Sponsors"), and/or other third parties ("Third Party Materials"), and that nothing in these Terms and Conditions is deemed to transfer any ownership, right, title or interest in such Third Party Materials to you. Your only rights to the Third Party Materials shall be those expressly granted to you by the owner(s) of the Third Party Materials. Specifically, any APIs, software, or other technical resources provided to you by Sponsors are subject to the subscription terms and licenses associated with such APIs, software or other technical resources.

4. **Media Release**: You acknowledge that the organizers of the Event ("Organizers"), Sponsors, the University of Waterloo, and Techyon (collectively, the "Releasees"), will be recording the Event on video, photographs, audio recording and other media, and therefore, agree that:
    - The Releasees have the unrestricted right to use your likeness, image, voice, opinions, and appearance, and also any images of your projects, developments, materials and belongings made at or brought to the Event, captured through video, photographs and/or other media during the Event for the express purpose of creating promotional material (the “Images”), for the purposes of use in websites, promotional materials, publications and/or other media of any of the Releasees, whether in print or electronically (the “Materials”). The foregoing right includes permission to copyright, use, re-use, publish, and republish Images in which you may be included, intact or in part, composite or distorted in character or form, without restriction as to changes or transformations, in conjunction with your own or a fictitious name, reproduction in color or otherwise, made through any and all media now or hereafter known;
    - The Releasees shall solely own the Materials in which you or your Images, in whole or in part, may appear, including copyright interests, and you have no ownership rights therein;
    - You give all clearances, copyright and otherwise, for use of your Images, and waive any moral rights that you may have in the Materials in which you or your Images may appear. The rights granted to the Releasees herein are perpetual and worldwide. For greater certainty, your Images may continue to be used after the completion of the Event;
    - You relinquish any right that you may have to examine or approve the Materials in which you or your Images may appear or the use to which they may be applied; and
    - You hereby release, discharge and agree to save harmless each and all of the Releasees from any liability by virtue of any blurring, distortion, alteration, optical illusion, or use in composite form of the Images whether intentional or otherwise, that may occur or be produced in the recording of the Images or in any subsequent processing thereof, as well as any publication thereof, including without limitation any claims for libel or invasion of privacy.

5. **Confidential Information**: You acknowledge that the Event is intended to be an event where ideas are shared freely, and therefore acknowledge that any information that you share with other participants of the Event, the Sponsors and/or other third parties during the Event is solely at your discretion and risk. If you wish to protect your information, it is solely your responsibility to implement confidentiality and security measures with respect to the persons to whom you are disclosing your information. None of the Releasees shall have any responsibility under these Terms and Conditions or by virtue of their participation in the Event with respect to your information.

6. **Information Sharing**: You agree that the Organizers may share your registration details, LinkedIn/Github profiles, details of your hackathon project, and other information obtained from you in the course of, or relating to, the Event with the Sponsors, and acknowledge that such Sponsors may contact you during and/or after the Event. By agreeing to these Terms and Conditions and/or by participating in the Event, you are providing your express consent to communications by the Organizers and Sponsors (including email communications, both marketing and informational) respecting the products and services of the Organizers and/or Sponsors, and future events.

7. **Submissions**: All project submissions at Hack the North 2024 will fall under the two following streams:
    - "Existing Project Stream" - Hackers in the existing project stream are to work on a project started prior to the start of Hack the North 2024. Existing projects are not eligible for prize consideration.
    - "New Project Stream" - Hackers in the new project stream are to work on a project started during the hacking time at Hack the North 2024. New projects will be eligible for prize consideration.

    Submitting a project or presentation is not necessary for participating in the Event.
    Participation in the existing project stream does not require adherence to the Hack the North 2024 Submission and Judging Rules, as projects in this stream are not eligible for submission to the finalist prize pool, and therefore are disqualified from winning prizes by default.
    All project submissions and presentations to the new project stream must adhere to the Hack the North 2024 Submission and Judging Rules, the full text of which will be released at a later date before the Event. The following terms are solely intended as a sample of the submission and judging rules. They are not exhaustive and are subject to change, and should be considered void once the Hack the North 2024 Submission and Judging Rules are released.

    - You may not begin working on your project before the specified hacking time for the event. Ideation of potential project ideas is permitted.
      - REGARDING 3D PRINTING: 3D Printing before the hackathon is permitted, however NO assembly of parts is allowed to take place. You must indicate in the final Devpost submission which parts were printed prior to the event. 
    - Plagiarism is not acceptable and teams with plagiarized work will be disqualified.
    - Project submissions must include all design assets and source code created at the Event.
    - Projects made for Hack the North 2024 may not be resubmitted to other hackathons.
    - Teams may have up to four members. All members must be accepted and registered participants of Hack the North 2024. An individual may not be a member of more than one team.
    - All projects must also adhere to the Hack the North Code of Conduct. **This term applies additionally to the existing project stream.**

8. You acknowledge that if you provide the Organizers with false or misleading information (such as your identity or status as a student), or otherwise contravene the rules and obligations set out in this document, you forfeit your right to participate in the Event and/or obtain any prizes from the Event. You agree that in such a case, the Organizers may pursue any remedy in law or equity to reclaim any prize or recover any damages occurring as a result of your contravention or misrepresentation.

9. **Facilities**: You must comply with all facility and equipment rules and requirements during your participation in the Event, including all safety instructions and requirements. You are not to take unreasonable risks while using the facilities where the Event is located, including causing any other person using such facilities an unreasonable risk of harm.

10. You assume full responsibility for any damage or injury caused by you in your participation in the Event (whether to persons or property, and whether to yourself or others) and release the University of Waterloo, Techyon, all sponsors of Hack the North 2024, Hack the North volunteers, University of Waterloo staff, guests of Hack the North 2024 and all other participants in Hack the North 2024 from any liability therefore. **YOU ARE AWARE THAT YOUR PARTICIPATION IN HACK THE NORTH 2024 IS SOLELY AT YOUR OWN RISK, AND THAT THE TERMS AND CONDITIONS ARE INTENDED TO REFLECT THAT UNDERSTANDING.**

11. You acknowledge that alcohol, drugs or weapons of any kind shall not be present or consumed during the Event. Participants will act responsibly and should wear their Hack the North 2024 identification badge at all times during the Event.

12. Any Organizer of the Event reserves the right to individually, at their discretion and without limitation, remove any participant(s) from the Event with immediate effect, for any reason, including, without limitation, any real or perceived violation of these Terms and Conditions, the Hack the North Code of Conduct, any other applicable Codes of Conduct, or laws in any applicable jurisdiction.

## COVID-19
---
- You acknowledge and understand the highly contagious nature of COVID-19, and that by participating in the Event, you increase your risk of being exposed to or infected by COVID-19, as well as increase your risk of exposing or infecting others with COVID-19. 
- You acknowledge that the Organizers of the Event reserve the right to choose **whether or not** to enforce any COVID-19 related regulations at the Event at their own discretion, as long as the regulations at the Event meet, at minimum, any regulations required by the Government of Canada, Public Health Ontario, and Waterloo Region Public Health at the time of the Event. You further acknowledge that the Organizers have exercised due diligence to reduce the spread of COVID-19 at the Event, but cannot guarantee that you will not become infected with COVID-19 as a participant in the Event.
- You acknowledge that any COVID-19 regulations put in place by the Organizers may change at any time prior to the start of the Event. You further acknowledge that these changes will be communicated to all attendees prior to the start of the Event, and as such, all attendees will be expected to comply with the new regulations at the Event.
- You affirm that your participation at the Event is voluntary, and you voluntarily assume the risk that you may be exposed to or infected by COVID-19. You acknowledge that you are free to leave the Event should you feel unsafe, however, doing so does not nullify nor release you from the terms of this Agreement.
- You agree to comply with all rules, regulations, requirements, and procedures put in place by Public Health Ontario, Waterloo Region Public Health, the University of Waterloo, and the Organizers, in order to reduce the spread of COVID-19. You acknowledge that if you refuse or fail to do so, you may be removed from the Event for the safety of others.

## Code of Conduct
---
Hack the North is a community hackathon intended for collaboration and learning in the student community. Our vision is to make it easy for anyone to dream big. We value the participation of each member of the student community and want all attendees to have an enjoyable and fulfilling experience. Accordingly, all attendees are expected to show respect and courtesy to other attendees throughout the hackathon. To make clear what is expected, all attendees at this hackathon are required to conform to the following Code of Conduct. Organizers will enforce this code throughout the Event.

### Core Values
---
1. Inclusivity
    - Empowering everyone to follow their dreams and furthering their goals.
    - Making sure everyone feels that they have a seat at the table, even when they are not present; Showing respect to our peers and ensuring that everyone feels valued and heard.
2. Learning
    - Learning from each other to further grow.
    - "No one knows everything; together we know a lot": Our unique experiences shape us to who we are today. As we learn from others, we gain new insights and perspectives that further develop and enhance our own experiences.
3. Quality
    - What we gain from diverse perspectives.
    - Our differences make us strong, compassionate and competitive. These differences help us further our performance and help us to create new innovations.
4. Self-care
    - Taking care of "business" begins with first taking care of yourself: You are not the best version of yourself when you feel overworked, tired, and stressed. Everyone benefits when you are at your best - recognize when you are being stretched too thin or not being stretched enough. Remember to: Resist, rest, revitalize, repeat.
5. Feedback
    - Be generous in both giving and accepting feedback.
    - Feedback is not just about being constructive - catch people doing something right and let them know! Good feedback is kind, respectful, clear, and constructive, and focused on goals and values rather than the person itself.
6. Communication
    - Share early and ask for feedback often.
    - Be polite and friendly in all forms of communication. There is a difference between intent and impact which can be misinterpreted, especially through remote communication. Acknowledge the difference between intent and impact and be proactive in communicating with others your intent.
7. Listening
    - Listen to understand, not to respond. Be open minded, courteous, and stray from being defensive. Allow space for play, curiosity, and creative thinking.

### Expected Behaviour
- Be supportive. One of our values in Hack the North is to be generous in both giving and accepting feedback which is one of many ways you can demonstrate being supportive. We encourage our organizers and hackers to offer help if you see someone struggling and/or direct them to someone who can offer assistance
- Be inclusive. In order to accomplish our vision of allowing "anyone to dream big", we encourage all of our Sponsors, Organizers and hackers to be inclusive. This includes but is not limited to: building an environment free from cliques, avoiding slang or idioms that may not translate across different cultures and languages, and being an ally when a team member is in need.
- Be collaborative. It is part of our values to share early and ask for feedback often. As an organization, we are all-hands on deck when it comes to providing a high quality and collaborative experience. We hope that this is also the case when it comes to your hack whether it be brainstorming sessions, code review, or even cup stacking!
- Be kind. Being kind does not just involve being kind to others, but also being kind to yourself. This is aligned with our value of self-care where we emphasize: "taking care of "business" begins with first taking care of yourself". Moreover, as we use several mediums to deliver information, we encourage you to be polite and courteous in all forms of communication to others.

### Protected Grounds
- This policy prohibits harassment or discrimination based on the following grounds, and any combination of these grounds:
    - Age
    - Creed (religion)
    - Sex (including pregnancy and breastfeeding)
    - Sexual orientation
    - Gender identity
    - Gender expression
    - Disability (including mental, physical, developmental, or learning disability)
    - Race
    - Ancestry
    - Place of origin
    - Ethnic origin
    - Citizenship
    - Colour
    - Any other grounds the Organizers deem inappropriate

### Prohibited Behaviour
- Harassment
    - Treat people the way you want to be treated.
    - Is defined as a course of comments or actions that are known, or ought reasonably to be known, to be unwelcome. Whether it is involving words or actions that are known or should be known to be offensive, embarrassing, humiliating, demeaning, or unwelcome; based on one or more incidents under the ground of discrimination identified by this policy.
- Sexual and Gender-Based Harassment
    - Respectful communication and actions.
    - Every person has the right to freedom from harassment.
    - Sexual harassment is a form of harassment that can include:
      1. Unwelcome sexual; contact and remarks
      2. Leering or inappropriate staring
      3. Gender-related verbal abuse, threats, or taunting
      4. Unwelcome demands for dates
      5. Requests for sexual favours
      6. Displays of sexually offensive pictures, graffiti, or recordings
      7. Offensive jokes or comments of sexual nature about an Organizer or attendee
- Discrimination
    - Inclusive to everyone.
    - Every person has the right to inclusivity and equal treatment
    - No person shall infringe or do, directly or indirectly, any form of unequal or different treatment onto others. It is intentional or unintentional, imposing extra burdens or denying benefits that disadvantage certain groups of people.
- Abusive Behaviour and Lewd Conduct
    - Respect others.
    - Hackers shall not use any electronic device or electronic method of communication to engage in any illegal, lewd, offensive, indecent, sexual, or obscene act or expression (i.e. Facebook, Twitter, etc) that may lead to harassment, harassing or bullying.
- Any other grounds the Organizers deem inappropriate, objectionable, and/or inconsistent with the values and/or good will Hack the North wishes to promote. 

### Roles and Responsibilities
- All individuals present at Hack the North are expected to uphold and abide by this policy, by refraining from any form of harassment or discrimination, and by cooperating fully in any investigation of a harassment or discrimination complaint. Hack the North looks to its attendees and Organizers to uphold the environment of diversity and inclusivity that is valued in the organization.
- Organizers have the additional responsibility to act immediately on observations or allegations of harassment or discrimination. Organizers are responsible for creating and maintaining a harassment- and discrimination-free organization and Event, and should address potential problems before they become serious.
- If a participant engages in harassing or discriminatory behaviour, Organizers may take actions they deem appropriate, including warning the offender or expelling the offender from the Event. 

### Major League Hacking (MLH)
Hack the North is a [MLH](https://mlh.io/) member event. As part of our partnership, the MLH code of conduct also applies to all Hack the North attendees. You can read more about MLH Code of Conduct here: http://static.mlh.io/docs/mlh-code-of-conduct.pdf

## Release
---
For valuable consideration, including permission to take part in Event, the undersigned on behalf of him/herself, his or her personal representatives, assigns, heirs and next of kin:

- hereby covenants not to sue, and releases, waives, and discharges the Releasees, their owners, officers, agents, affiliates, employees, volunteers, and/or any other person or entity in any way associated with the Event, from liability for any injury to the person or property or death of the undersigned arising out of or related to use of the premises, equipment, or other facilities of the Releasees, whether caused by an act of negligence of the Releasees or otherwise; and
- hereby assumes full responsibility for any risk of bodily injury, death or property damage arising out of or related to their participation in the Event, whether occurring to the undersigned or to any other person or entity for whom the undersigned is responsible or with whom the undersigned is associated, and whether caused by an act of negligence of the Releasees or otherwise. The foregoing release includes, but is not limited to, any occurrences of personal injury, illness (including COVID-19, food-borne, or otherwise), and loss of belongings, whether by theft or otherwise. The undersigned further agrees that this instrument (the terms of which collectively are referred to as the Agreement) is intended to be as broad and inclusive as is permitted by the laws of the Province of Ontario and that if any portion thereof is held invalid, that portion shall be invalid only to the extent required by law, and the balance shall, notwithstanding, continue in full force and effect.

**I have completely read and understand the Terms and Conditions and Code of Conduct (the "Agreement") and its terms, and agree to comply with all rules and obligations set forth in this Agreement. I understand that I have given up substantial rights by signing it, and have signed it freely and voluntarily without inducement, assurance or guarantee being made to me. I confirm that I have the right to enter into this Agreement, that I am not restricted by any commitments to any other parties, and that none of the Organizers have any financial commitment or obligations to me as a result of this Agreement or the use of my Images. Prior to signing this Agreement, I have had the opportunity to ask any questions about this Agreement and Hack the North 2024.**
`;
