import { Spacer } from "@hackthenorth/north";
import React from "react";

import { WordLimitTextArea } from "src/shared/components";

import { TextInputTitle } from "../../TextComponents";
import { PerkError } from "../PerkError";

type TPerkTextAreaProps = {
  value: string;
  title: string | React.ReactNode;
  placeholder: string;
  wordLimit: number;
  isReadOnly: boolean;
  error?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
};

export const PerkTextArea: React.FC<TPerkTextAreaProps> = ({
  value,
  title,
  placeholder,
  wordLimit,
  isReadOnly,
  onChange,
  error,
}) => (
  <>
    <TextInputTitle>{title}</TextInputTitle>
    <Spacer height={8} />
    <WordLimitTextArea
      wordLimit={wordLimit}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      readOnly={isReadOnly}
      error={error}
    />
    {error && <PerkError error={error} />}
  </>
);
