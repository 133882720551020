import { Spacer } from "@hackthenorth/north";
import { Select } from "north.js";
import React from "react";

import { TextInputTitle } from "../../TextComponents";
import { PerkError } from "../PerkError";

type TPerkSelect = {
  fullWidth: boolean;
  size: "sm" | "md" | "lg";
  onChange: (e: any) => void;
  isReadOnly: boolean;
  value: string | number | string[];
  label?: string;
  error?: React.ReactNode;
  placeholder?: string;
  multiple?: boolean;
};

export const PerkSelect: React.FC<TPerkSelect> = ({
  fullWidth,
  size,
  onChange,
  value,
  isReadOnly,
  label,
  error,
  placeholder,
  children,
  multiple = false,
}) => {
  return (
    <div>
      {label && (
        <>
          <TextInputTitle>{label}</TextInputTitle>
          <Spacer height={8} />
        </>
      )}
      <Select
        fullWidth={fullWidth}
        size={size}
        onChange={onChange}
        value={value}
        disabled={isReadOnly}
        placeholder={placeholder}
        multiple={multiple}
      >
        {children}
      </Select>
      {error && <PerkError error={error} />}
    </div>
  );
};
