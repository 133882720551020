import { Text, Spacer } from "@hackthenorth/north";
import { Button, Link } from "north.js";
import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import "twin.macro";
import styled from "styled-components";

import { Divider, Icon } from "src/shared/components";
import { SOCIALS } from "src/shared/constants/social";
import { useHackerContext } from "src/shared/contexts";
import { Field, HackerStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";
import { WaiverDownload } from "src/static/icons";
import { RsvpGraphic, RejectedRsvpGraphic } from "src/static/img";
// import AppleWallet from "src/static/img/applewallet.svg";
// import GoogleWallet from "src/static/img/googlewallet.svg";

// import { BUS_DEADLINE, FLIGHT_DEADLINE } from "../travel-form/constants";
import { RSVP_DEADLINE } from "../constants";

import { Badges } from "./badges";
import Withdraw from "./shared/withdraw";
import TicketContainer from "./ticket/ticketContainer";
// import { WalletButton } from "./ticket/ticketModal";
import { TopBar } from "./topBar";
import { isTravelSubmitted, showTravel } from "./util";

const TextHolder = styled.div<{ isTabletOrSmaller: boolean }>`
  width: ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? "100%" : "70%")};
  padding: 0px
    ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? "0px" : "63px")} 0px 0px;
`;

const ACCEPTED_FIELDS = [
  Field.SHORT_SCHOOL,
  Field.RSVP_DEADLINE,
  Field.TRAVEL_OPTION,
  Field.REIMBURSEMENT_RECIPIENT_NAME,
  Field.TRAVEL_RECEIPT,
  Field.TRAVEL_PHONE_NUMBER,
  Field.SHIRT_SIZE,
  Field.SHIRT_TYPE,
];

const VOLUNTEER_APP_DEADLINE = "August 17, 2024";

const volunteer_link = (
  <Link
    href="https://forms.gle/rBuXzi1EGZYn3b819"
    target="_blank"
    rel="noopener noreferrer"
  >
    volunteers
  </Link>
);

const TITLES_MAP: Record<HackerStage, string> = {
  [HackerStage.ACCEPTED]: "You've been accepted! 🎉 Now what?",
  [HackerStage.AUTO_ACCEPTED]: "You've been accepted! 🎉 Now what?",
  [HackerStage.CONFIRMED]: "Thank you for RSVP-ing to Hack the North 2024!",
  [HackerStage.CHECKED_IN]: "Thank you for RSVP-ing to Hack the North 2024!",
  [HackerStage.WAITLISTED]: "Thank you for applying for Hack the North 2024!",
  [HackerStage.EXPIRED]: "Oh no! The deadline to RSVP has passed.",
  [HackerStage.WITHDRAWN]:
    "You've withdrawn your application for Hack the North 2024.",
  [HackerStage.NOT_ACCEPTED]: "Oops — this page isn't ready yet!",
  // [HackerStage.NOT_ACCEPTED]: "Thank you for applying to Hack the North 2024!",
};

const CONFETTI_ANIMATION_DURATION = 5000;

const Status: React.FC = () => {
  const isTabletOrSmaller = useDeviceSize("medium");
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const { navigateNext, stage } = useHackerContext();
  // const { apple_wallet_url, google_wallet_url } = useUserContext();

  const [confettied, setConfettied] = useState(
    JSON.parse(localStorage.getItem("confettied") ?? "false")
  );

  const MailContact = () => (
    <Link href="mailto:hello@hackthenorth.com">hello@hackthenorth.com</Link>
  );

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("confettied", JSON.stringify(true));
      setConfettied(true);
    }, CONFETTI_ANIMATION_DURATION);
  }, [confettied]);

  const shirtSizes = {
    XS: "Extra Small",
    S: "Small",
    M: "Medium",
    L: "Large",
    XL: "Extra Large",
    "2XL": "2 Extra Large",
    None: "N/A",
  };

  const userShirtSize: string = responsesState[Field.SHIRT_SIZE] || "None";

  const Footer = () => {
    return (
      <div>
        <Text mods="grey">Keep up to date with Hack the North:</Text>
        <StartFlex>
          {Object.entries(SOCIALS).map(([id, { icon, link }]) => {
            return (
              <SocialWrapper href={link} key={id}>
                <Icon name={icon} size="24px" />
              </SocialWrapper>
            );
          })}
        </StartFlex>
      </div>
    );
  };

  const travelNeeded = !!showTravel(responsesState[Field.TRAVEL_OPTION]);
  // const travelPlanDeadline =
  //   responsesState[Field.TRAVEL_OPTION] === "flight"
  //     ? FLIGHT_DEADLINE
  //     : BUS_DEADLINE;

  const travelSubmitted =
    travelNeeded &&
    (responsesState[Field.TRAVEL_OPTION] === "flight"
      ? isTravelSubmitted(
          responsesState[Field.REIMBURSEMENT_RECIPIENT_NAME],
          responsesState[Field.TRAVEL_RECEIPT]
        )
      : responsesState[Field.TRAVEL_PHONE_NUMBER]);

  const TEXT_MAP: Record<HackerStage, React.ReactElement | string> = {
    [HackerStage.AUTO_ACCEPTED]: (
      <>
        <Text>
          We’re excited to invite you to Hack the North 2024! We loved your
          application and look forward to having you at our event this year.
          <br />
          <br />
          Please accept your offer by completing our RSVP form by{" "}
          <b>{RSVP_DEADLINE}</b>.
        </Text>
        <Spacer height={32} />
        <Button color="primary" size="lg" onClick={navigateNext}>
          Confirm your spot
        </Button>
      </>
    ),
    [HackerStage.ACCEPTED]: (
      <>
        <Text>
          We’re excited to invite you to Hack the North 2024! We loved your
          application and look forward to having you at our event this year.
          <br />
          <br />
          Please accept your offer by completing our RSVP form by{" "}
          <b>{RSVP_DEADLINE}</b>.
        </Text>
        <Spacer height={32} />
        <Button color="primary" size="lg" onClick={navigateNext}>
          Confirm your spot
        </Button>
      </>
    ),
    [HackerStage.CONFIRMED]: (
      <>
        <Text>
          Thank you for confirming your spot at Hack the North 2024! We are
          looking forward to seeing what you build at our event! See you in
          September!
          <br /> <br />
          {travelNeeded &&
            !travelSubmitted &&
            " You will receive an email soon with instructions on how to submit your travel plan. "}
          If you have any questions, please contact <MailContact />!
        </Text>
        <Spacer height={32} />
        <StartFlex>
          {/* {travelNeeded &&
            (travelSubmitted ? (
              <Button
                color="secondary"
                size="lg"
                onClick={navigateToTravelForm}
              >
                Edit travel plan
              </Button>
            ) : (
              <Button color="primary" size="lg" onClick={navigateToTravelForm}>
                Submit your travel plan
              </Button>
            ))} */}
          <Button color="secondary" size="lg" onClick={navigateNext}>
            Edit RSVP submission
          </Button>
        </StartFlex>
        <Spacer height={32} />
        {/* <Text mods="blueGrey">
          {travelNeeded &&
            `Your travel plan must be submitted by ${travelPlanDeadline} in order to ensure that we can reimburse some of your costs. `}
        </Text> */}
      </>
    ),
    [HackerStage.CHECKED_IN]: "",
    [HackerStage.WAITLISTED]: (
      <>
        <Text>
          Thanks for taking the time to apply to Hack the North 2024. Each year,
          Hack the North receives thousands of applications, but we are not able
          to accept all of them. Though we were impressed with your application,
          we are not able to offer you acceptance as a hacker at this time.
          We&apos;ve added you to our waitlist and would still love to have you
          at our event if the opportunity arises. We will contact you by the end
          of August if we are able to offer you a spot at Hack the North 2024!
          <br />
          <br />
          Interested in being part of Hack the North 2024 in another way? We are
          also looking for {volunteer_link} to help the event run smoothly! The
          deadline to apply is {VOLUNTEER_APP_DEADLINE}.
          <br />
          <br />
          We wish you the best, and hope to see you at Hack the North in the
          future!
        </Text>
      </>
    ),
    [HackerStage.EXPIRED]: (
      <Text>
        Unfortunately your RSVP deadline has passed and your acceptance has
        expired. If you believe this is a mistake, please contact{" "}
        <MailContact />
        .
        <br /> <br />
        We wish you the best and hope we can see you at a future Hack the North!
      </Text>
    ),
    [HackerStage.WITHDRAWN]: (
      <>
        <Text>
          We&apos;re sorry to hear you won&apos;t be able to join us at Hack the
          North 2024.
          {/* Still interested in getting involved with Hack the North?
          We are also looking for {mentor_link} and {volunteer_link} to help the
          event run smoothly! The deadline to apply is {MENTOR_APP_DEADLINE} for
          mentors and {VOLUNTEER_APP_DEADLINE} for volunteers. */}
          <br />
          <br />
          We wish you the best, and hope to see you at Hack the North in the
          future!
        </Text>
      </>
    ),
    [HackerStage.NOT_ACCEPTED]: (
      <>
        <Text>
          You&apos;ll receive an email from us soon regarding the status of your
          application. Stay tuned!
          {/* Thanks for taking the time to apply to Hack
          the North 2024. Each year, Hack the North receives thousands of
          applications, but we are not able to accept all of them. Though we
          were impressed with your application, we are not able to offer you
          acceptance as a hacker at this time. */}
          <br />
          <br />
          Interested in being part of Hack the North 2024 in another way? We are
          also looking for {volunteer_link} to help the event run smoothly! The
          deadline to apply is {VOLUNTEER_APP_DEADLINE}.
          <br />
          <br />
          We wish you the best, and hope to see you at Hack the North in the
          future!
        </Text>
      </>
    ),
  };

  const canWithdraw =
    stage &&
    [
      HackerStage.ACCEPTED,
      HackerStage.AUTO_ACCEPTED,
      HackerStage.CONFIRMED,
    ].includes(stage);

  const capitalizeFirstLetter = (inputString: string | null | undefined) => {
    if (!inputString) {
      return;
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  };

  return (
    <OuterContainer>
      <TopBar />
      {stage === HackerStage.ACCEPTED && !confettied && (
        <div tw="absolute w-full h-full overflow-hidden top-0 left-0">
          <Confetti numberOfPieces={400} recycle={false} />
        </div>
      )}

      <CenterFlex isTabletOrSmaller={isTabletOrSmaller}>
        {stage && (
          <TextHolder isTabletOrSmaller={isTabletOrSmaller}>
            <Badges />
            <Text mods="h2 heading">{TITLES_MAP[stage]}</Text>
            {TEXT_MAP[stage]}
            <Spacer height={32} />
            <Divider />
            <Spacer height={32} />
            <Footer />
            <Spacer height={32} />
            {canWithdraw && <Withdraw />}
          </TextHolder>
        )}
        {stage === HackerStage.CONFIRMED ? (
          <ImgWrapper>
            <TicketContainer
              shirtType={capitalizeFirstLetter(
                responsesState[Field.SHIRT_TYPE]
              )}
              shirtSize={shirtSizes[userShirtSize]}
            />
            {/* <WalletContainer>
              <WalletButton href={apple_wallet_url} icon={AppleWallet} />
              <Spacer width="16px" />
              <WalletButton href={google_wallet_url} icon={GoogleWallet} />
            </WalletContainer> */}
          </ImgWrapper>
        ) : stage === HackerStage.ACCEPTED ||
          stage === HackerStage.AUTO_ACCEPTED ? (
          <ImgWrapper>
            <img
              src={RsvpGraphic}
              alt={"Clouds and comets against a dark background"}
              style={{ borderRadius: "28px" }}
            />
            <a href={RsvpGraphic} download={"HackTheNorthAcceptance.png"}>
              <Button color="secondary" size="lg">
                <FlexRow>
                  <WaiverDownload />
                  <Spacer width={10} />
                  Download image
                </FlexRow>
              </Button>
            </a>
          </ImgWrapper>
        ) : (
          <ImgWrapper>
            <img
              src={RejectedRsvpGraphic}
              alt={"Clouds and comets against a dark background"}
              style={{ borderRadius: "28px" }}
            />
          </ImgWrapper>
        )}
      </CenterFlex>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 80px;
  z-index: 1;
`;

const CenterFlex = styled.div<{ isTabletOrSmaller: boolean }>`
  display: flex;
  justify-content: center;
  gap: 22px;
  flex-direction: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "column-reverse" : "row"};
  align-items: ${({ isTabletOrSmaller }) =>
    isTabletOrSmaller ? "center" : "flex-start"};
`;

const StartFlex = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: start;
  gap: 22px;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
  `}
`;

const FlexRow = styled.div`
  align-items: center;
  display: flex;
`;

// const WalletContainer = styled.div`
//   display: flex;
//   justify-content: center;
// `;

const SocialWrapper = styled.a`
  &:hover {
    opacity: 80%;
  }
`;

export default React.memo(Status);
