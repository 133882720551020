import React from "react";
import "twin.macro";
import styled from "styled-components";
import { Flex } from "@hackthenorth/north";

import { Icon } from "src/shared/components";

import { Button } from "north.js";

import { IconName } from "src/shared/components/Icon";

export enum SaveState {
  SAVED = "SAVED",
  SAVING = "SAVING",
  ERROR = "ERROR",
}

interface Props {
  savingState: SaveState;
  onSubmit: () => void;
  width: number;
}

export const BOTTOM_BAR_HEIGHT = 66;

const Container = styled(Flex).attrs({
  justify: "space-between",
})<{
  width: number;
}>`
  padding: 15px 45px;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundGray};
  border-top: 1px solid ${({ theme }) => theme.globalConstants.color.borderGray};
`;

const SaveStateContainer = styled(Flex).attrs({
  align: "center",
})`
  column-gap: 16px;
`;

const SaveText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
`;

const BottomBar: React.FC<Props> = ({ savingState, onSubmit, width }) => {
  let saveText: string | null = null;
  let saveIcon: IconName;

  if (savingState === SaveState.SAVED) {
    saveText = "Changes saved";
    saveIcon = "checkmark";
  } else if (savingState === SaveState.SAVING) {
    saveText = "Saving changes";
    saveIcon = "horizontal-kebab";
  } else {
    saveText = "Uh oh! Please fix the highlighted fields";
    saveIcon = "error-circle";
  }

  return (
    <Container sidebarWidth={width}>
      <SaveStateContainer>
        <Icon name={saveIcon} height={20} width={20} />
        <SaveText>{saveText}</SaveText>
      </SaveStateContainer>
      <Button color="primary" onClick={onSubmit} size="md">
        Submit
      </Button>
    </Container>
  );
};

export default BottomBar;
