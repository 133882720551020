import { Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";
import { Navigate } from "react-router-dom";

import { PageWrapper } from "src/shared/components";
import { Route } from "src/shared/components";
import { Route as RouteConstants } from "src/shared/constants/route";
import { BaseRoute } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";

export const HomePage = () => {
  const { logIn, isAuthenticated } = useUserContext();

  return (
    <PageWrapper pageTitle="Home">
      {isAuthenticated ? (
        <Text mods="big">
          Something went wrong! You&apos;re not supposed to be here! Please
          contact support at{" "}
          <Link href="mailto:hello@hackthenorth.com">
            hello@hackthenorth.com
          </Link>
          .
        </Text>
      ) : (
        <Text>
          You&apos;re currently logged out.{" "}
          <Link onClick={logIn}>Click here to log in</Link>.
        </Text>
      )}
    </PageWrapper>
  );
};

export const ScheduleHomeRoutes = () => (
  <Route
    path={BaseRoute.HOME}
    element={<Navigate to={RouteConstants.SCHEDULE} />}
  />
);
