import { Text, Spacer } from "@hackthenorth/north";
import { Button } from "north.js";
import React from "react";

import Modal from "src/shared/components/Modal";

import { RSVP_DEADLINE } from "../../constants";

interface RsvpModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const RsvpModal: React.FC<RsvpModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Ready to submit your form?"
      actions={
        <>
          <Button color="tertiary" size="lg" onClick={onCancel}>
            No, back to RSVP
          </Button>
          <Spacer width="16px" />
          <Button color="primary" size="lg" onClick={onConfirm}>
            Yes, submit
          </Button>
        </>
      }
    >
      <Text>
        Please confirm all your information is accurate before submitting your
        RSVP. You will be able to edit it up until <b>{RSVP_DEADLINE}</b>.
      </Text>
    </Modal>
  );
};

export default RsvpModal;
