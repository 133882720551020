import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Alert, Link, Spinner } from "north.js";
import React, { useEffect, useState } from "react";

import { PageWrapper } from "src/shared/components";

import "twin.macro";
// eslint-disable-next-line import/order
import { StringParam, useQueryParams } from "use-query-params";

import { useUpdatePlatformIdMutation } from "./graphql/linkSlackToHapi.generated";

const LinkAccount: React.FC = () => {
  const [query] = useQueryParams({
    slackId: StringParam,
  });
  const { slackId } = query;
  const [updatePlatformId, { loading: updatePlatformIdLoading }] =
    useUpdatePlatformIdMutation();

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (slackId) {
      updatePlatformId({
        variables: {
          slackId,
        },
      })
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            setErrorMessage(result.errors[0].message);
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    } else {
      setErrorMessage("Invalid link data");
    }
  }, [updatePlatformId, slackId]);

  return (
    <PageWrapper fullWidth>
      <div tw="flex items-center justify-center">
        {updatePlatformIdLoading ? (
          <div tw="width[fit-content]">
            <Alert color="secondary" icon={<Spinner tw="w-20 h-20" />}>
              Loading...
            </Alert>
          </div>
        ) : errorMessage ? (
          <div tw="width[fit-content]">
            <Alert color="danger" icon={<XCircleIcon tw="w-20 h-20" />}>
              An error occured while linking your slack account! Please contact{" "}
              <Link color="secondary" href="mailto:hello@hackthenorth.com">
                hello@hackthenorth.com
              </Link>
            </Alert>
          </div>
        ) : (
          <div tw="width[fit-content]">
            <Alert color="success" icon={<CheckCircleIcon tw="w-20 h-20" />}>
              You have successfully linked your slack account!
            </Alert>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default LinkAccount;
