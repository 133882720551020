/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type FindBusesByLocationQueryVariables = Types.Exact<{
  departure_location?: Types.Maybe<Types.Scalars["String"]>;
  arrival_location?: Types.Maybe<Types.Scalars["String"]>;
}>;

export type FindBusesByLocationQuery = {
  findBusesByLocation: Array<
    Pick<
      Types.Bus,
      "id" | "departure_time" | "arrival_time" | "seats_available"
    >
  >;
};

export const FindBusesByLocationDocument = gql`
  query FindBusesByLocation(
    $departure_location: String
    $arrival_location: String
  ) {
    findBusesByLocation(
      departure_location: $departure_location
      arrival_location: $arrival_location
    ) {
      id
      departure_time
      arrival_time
      seats_available
    }
  }
`;

/**
 * __useFindBusesByLocationQuery__
 *
 * To run a query within a React component, call `useFindBusesByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBusesByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBusesByLocationQuery({
 *   variables: {
 *      departure_location: // value for 'departure_location'
 *      arrival_location: // value for 'arrival_location'
 *   },
 * });
 */
export function useFindBusesByLocationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >(FindBusesByLocationDocument, baseOptions);
}
export function useFindBusesByLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindBusesByLocationQuery,
    FindBusesByLocationQueryVariables
  >(FindBusesByLocationDocument, baseOptions);
}
export type FindBusesByLocationQueryHookResult = ReturnType<
  typeof useFindBusesByLocationQuery
>;
export type FindBusesByLocationLazyQueryHookResult = ReturnType<
  typeof useFindBusesByLocationLazyQuery
>;
export type FindBusesByLocationQueryResult = ApolloReactCommon.QueryResult<
  FindBusesByLocationQuery,
  FindBusesByLocationQueryVariables
>;
