/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type FindEventAttendeeByEmailQueryVariables = Types.Exact<{
  email: Types.Scalars["String"];
}>;

export type FindEventAttendeeByEmailQuery = {
  findEventAttendeeByEmail: Pick<
    Types.EventAttendeeInfo,
    "userId" | "eventAttendeeId" | "claimStage" | "role" | "badgeCode"
  > & {
    registrationData: Pick<
      Types.EventAttendeeRegistrationInfo,
      | "preferredName"
      | "legalName"
      | "pronouns"
      | "accessibilityNeeds"
      | "shirtType"
      | "shirtSize"
      | "shirtAccommodations"
    >;
  };
};

export const FindEventAttendeeByEmailDocument = gql`
  query FindEventAttendeeByEmail($email: String!) {
    findEventAttendeeByEmail(email: $email) {
      userId
      eventAttendeeId
      claimStage
      role
      badgeCode
      registrationData {
        preferredName
        legalName
        pronouns
        accessibilityNeeds
        shirtType
        shirtSize
        shirtAccommodations
      }
    }
  }
`;

/**
 * __useFindEventAttendeeByEmailQuery__
 *
 * To run a query within a React component, call `useFindEventAttendeeByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventAttendeeByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventAttendeeByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindEventAttendeeByEmailQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindEventAttendeeByEmailQuery,
    FindEventAttendeeByEmailQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindEventAttendeeByEmailQuery,
    FindEventAttendeeByEmailQueryVariables
  >(FindEventAttendeeByEmailDocument, baseOptions);
}
export function useFindEventAttendeeByEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindEventAttendeeByEmailQuery,
    FindEventAttendeeByEmailQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindEventAttendeeByEmailQuery,
    FindEventAttendeeByEmailQueryVariables
  >(FindEventAttendeeByEmailDocument, baseOptions);
}
export type FindEventAttendeeByEmailQueryHookResult = ReturnType<
  typeof useFindEventAttendeeByEmailQuery
>;
export type FindEventAttendeeByEmailLazyQueryHookResult = ReturnType<
  typeof useFindEventAttendeeByEmailLazyQuery
>;
export type FindEventAttendeeByEmailQueryResult = ApolloReactCommon.QueryResult<
  FindEventAttendeeByEmailQuery,
  FindEventAttendeeByEmailQueryVariables
>;
