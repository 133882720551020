import {
  TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output,
  SponsorCompanyTier,
} from "src/api/types.generated";
import { IS_PRODUCTION } from "src/shared/utils/env";

import { GetSponsorPerksQuery } from "./SponsorPerksContext/graphql/getSponsorPerks.generated";

export type SponsorPerksData =
  GetSponsorPerksQuery["claims"][0]["fields_HACKTHENORTH2024_SPONSOR_PERKS"]["answers"];

export enum SponsorPerkType {
  WEBSITE_LOGO = "website_logo",
  WEBSITE_FEATURE = "website_feature",
  NEWSLETTER_OPPORTUNITY = "newsletter_opportunity",
  NEWSLETTER_FEATURE = "newsletter_feature",
  NEWSLETTER_SPOTLIGHT = "newsletter_spotlight",
  TECH_TALK_PROPOSAL = "tech_talk_proposal",
  TECH_TALK_DETAILS = "tech_talk_details",
  API_PRIZE = "api_prize",
  API_WORKSHOP = "api_workshop",
  LIGHTNING_CHALLENGE = "lightning_challenge",
  SPONSOR_AN_ACTIVITY = "sponsor_an_activity",
  SPONSOR_A_SNACK = "sponsor_a_snack",
  SPONSOR_A_MEETUP = "sponsor_a_meetup",
  SELECT_TABLE_LOCATION = "select_table_location",
  SOCIAL_MEDIA_PROMOTION = "social_media_promotion",
  NAME_AN_AREA = "name_an_area",
  WELCOME_BOOKLET_BLURB = "welcome_booklet_blurb",
  SHIPPED_SWAG = "shipped_swag",
  OPENING_CEREMONIES_SLIDE = "opening_ceremonies_slide",
  CLOSING_CEREMONIES_SLIDE = "closing_ceremonies_slide",
  COFFEE_CHATS = "coffee_chats",
}

export const RELEASED_PERKS_PRODUCTION: SponsorPerkType[] = [
  SponsorPerkType.WEBSITE_LOGO,
  SponsorPerkType.WEBSITE_FEATURE,
  SponsorPerkType.NEWSLETTER_OPPORTUNITY,
  SponsorPerkType.NEWSLETTER_FEATURE,
  SponsorPerkType.NEWSLETTER_SPOTLIGHT,
  SponsorPerkType.TECH_TALK_PROPOSAL,
  // SponsorPerkType.TECH_TALK_DETAILS,
  SponsorPerkType.API_PRIZE,
  SponsorPerkType.API_WORKSHOP, // need timeslot
  // SponsorPerkType.SELECT_TABLE_LOCATION,
  SponsorPerkType.SOCIAL_MEDIA_PROMOTION,
  // SponsorPerkType.NAME_AN_AREA,
  SponsorPerkType.WELCOME_BOOKLET_BLURB,
  SponsorPerkType.SHIPPED_SWAG,
  SponsorPerkType.OPENING_CEREMONIES_SLIDE,
  SponsorPerkType.CLOSING_CEREMONIES_SLIDE,
  // SponsorPerkType.COFFEE_CHATS,

  SponsorPerkType.LIGHTNING_CHALLENGE, // need timeslot
  // SponsorPerkType.SPONSOR_AN_ACTIVITY, // need activities
  SponsorPerkType.SPONSOR_A_SNACK,
  // SponsorPerkType.SPONSOR_A_MEETUP, // not ready
];

// releasing all of them so we can test on staging
const RELEASED_PERKS_STAGING: SponsorPerkType[] = [
  SponsorPerkType.WEBSITE_LOGO,
  SponsorPerkType.WEBSITE_FEATURE,
  SponsorPerkType.NEWSLETTER_OPPORTUNITY,
  SponsorPerkType.NEWSLETTER_FEATURE,
  SponsorPerkType.NEWSLETTER_SPOTLIGHT,
  SponsorPerkType.TECH_TALK_PROPOSAL,
  SponsorPerkType.TECH_TALK_DETAILS,
  SponsorPerkType.API_PRIZE,
  SponsorPerkType.API_WORKSHOP,
  SponsorPerkType.SELECT_TABLE_LOCATION,
  SponsorPerkType.SOCIAL_MEDIA_PROMOTION,
  SponsorPerkType.NAME_AN_AREA,
  SponsorPerkType.WELCOME_BOOKLET_BLURB,
  SponsorPerkType.SHIPPED_SWAG,
  SponsorPerkType.OPENING_CEREMONIES_SLIDE,
  SponsorPerkType.CLOSING_CEREMONIES_SLIDE,
  SponsorPerkType.COFFEE_CHATS,

  SponsorPerkType.LIGHTNING_CHALLENGE,
  SponsorPerkType.SPONSOR_AN_ACTIVITY,
  SponsorPerkType.SPONSOR_A_SNACK,
  SponsorPerkType.SPONSOR_A_MEETUP,
];

export const RELEASED_PERKS = IS_PRODUCTION
  ? RELEASED_PERKS_PRODUCTION
  : RELEASED_PERKS_STAGING;

export const PERKS_TO_LABELS: Record<SponsorPerkType, string> = {
  [SponsorPerkType.WEBSITE_LOGO]: "Website Logo",
  [SponsorPerkType.WEBSITE_FEATURE]: "Website Feature",
  [SponsorPerkType.NEWSLETTER_OPPORTUNITY]: "Newsletter: Opportunity",
  [SponsorPerkType.NEWSLETTER_FEATURE]: "Newsletter: Feature",
  [SponsorPerkType.NEWSLETTER_SPOTLIGHT]: "Newsletter: Spotlight",
  [SponsorPerkType.TECH_TALK_PROPOSAL]: "Tech Talk: Proposal",
  [SponsorPerkType.TECH_TALK_DETAILS]: "Tech Talk: Time Selection",
  [SponsorPerkType.API_PRIZE]: "API Prize",
  [SponsorPerkType.API_WORKSHOP]: "API Workshop",
  [SponsorPerkType.LIGHTNING_CHALLENGE]: "Lightning Challenge",
  [SponsorPerkType.SPONSOR_AN_ACTIVITY]: "Sponsor an Activity",
  [SponsorPerkType.SPONSOR_A_SNACK]: "Sponsor a Snack",
  [SponsorPerkType.SPONSOR_A_MEETUP]: "Sponsor a Meetup",
  [SponsorPerkType.SELECT_TABLE_LOCATION]: "Select Table Location",
  [SponsorPerkType.SOCIAL_MEDIA_PROMOTION]: "Social Media Promotion",
  [SponsorPerkType.NAME_AN_AREA]: "Name an Area",
  [SponsorPerkType.WELCOME_BOOKLET_BLURB]: "Welcome Booklet Blurb",
  [SponsorPerkType.SHIPPED_SWAG]: "Shipped Swag",
  [SponsorPerkType.OPENING_CEREMONIES_SLIDE]: "Opening Ceremonies Slide",
  [SponsorPerkType.CLOSING_CEREMONIES_SLIDE]: "Closing Ceremonies Slide",
  [SponsorPerkType.COFFEE_CHATS]: "Coffee Chats",
};

export const PERKS_TO_DUE_DATES: Record<
  SponsorPerkType,
  keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output
> = {
  [SponsorPerkType.WEBSITE_LOGO]: "website_logo_due_date",
  [SponsorPerkType.WEBSITE_FEATURE]: "website_feature_due_date",
  [SponsorPerkType.NEWSLETTER_OPPORTUNITY]: "newsletter_opportunity_due_date",
  [SponsorPerkType.NEWSLETTER_FEATURE]: "newsletter_feature_due_date",
  [SponsorPerkType.NEWSLETTER_SPOTLIGHT]: "newsletter_spotlight_due_date",
  [SponsorPerkType.TECH_TALK_PROPOSAL]: "tech_talk_proposal_due_date",
  [SponsorPerkType.TECH_TALK_DETAILS]: "tech_talk_details_due_date",
  [SponsorPerkType.API_PRIZE]: "api_prize_due_date",
  [SponsorPerkType.API_WORKSHOP]: "api_workshop_due_date",
  [SponsorPerkType.LIGHTNING_CHALLENGE]: "lightning_challenge_due_date",
  [SponsorPerkType.SPONSOR_AN_ACTIVITY]: "sponsor_an_activity_due_date",
  [SponsorPerkType.SPONSOR_A_SNACK]: "sponsor_a_snack_due_date",
  [SponsorPerkType.SPONSOR_A_MEETUP]: "sponsor_a_meetup_due_date",
  [SponsorPerkType.SELECT_TABLE_LOCATION]: "select_table_location_due_date",
  [SponsorPerkType.SOCIAL_MEDIA_PROMOTION]: "social_media_promotion_due_date",
  [SponsorPerkType.NAME_AN_AREA]: "name_an_area_due_date",
  [SponsorPerkType.WELCOME_BOOKLET_BLURB]: "welcome_booklet_blurb_due_date",
  [SponsorPerkType.SHIPPED_SWAG]: "shipped_swag_due_date",
  [SponsorPerkType.OPENING_CEREMONIES_SLIDE]:
    "opening_ceremonies_slide_due_date",
  [SponsorPerkType.CLOSING_CEREMONIES_SLIDE]:
    "closing_ceremonies_slide_due_date",
  [SponsorPerkType.COFFEE_CHATS]: "coffee_chats_due_date",
};

export const FILE_FIELDS: (keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output)[] =
  [
    "website_logo_file",
    "newsletter_feature_image_file",
    "newsletter_spotlight_image_file",
    "social_media_promotion_logo",
    "opening_ceremonies_slide_file",
    "closing_ceremonies_slide_file",
  ];

export const DATE_FIELDS: (keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output)[] =
  [];

export const TIERS_TO_PERKS: Record<SponsorCompanyTier, SponsorPerkType[]> = {
  [SponsorCompanyTier.Partner]: [
    SponsorPerkType.NEWSLETTER_OPPORTUNITY,
    SponsorPerkType.NEWSLETTER_FEATURE,
    SponsorPerkType.NEWSLETTER_SPOTLIGHT,
  ],
  [SponsorCompanyTier.Startup]: [
    SponsorPerkType.WEBSITE_LOGO,
    SponsorPerkType.NEWSLETTER_OPPORTUNITY,
    SponsorPerkType.NEWSLETTER_FEATURE,
    SponsorPerkType.NEWSLETTER_SPOTLIGHT,
    SponsorPerkType.TECH_TALK_PROPOSAL,
    SponsorPerkType.API_PRIZE,
  ],
  [SponsorCompanyTier.Bronze]: [
    SponsorPerkType.WEBSITE_LOGO,
    SponsorPerkType.NEWSLETTER_OPPORTUNITY,
    SponsorPerkType.NEWSLETTER_FEATURE,
    SponsorPerkType.NEWSLETTER_SPOTLIGHT,
    SponsorPerkType.TECH_TALK_PROPOSAL,
    SponsorPerkType.TECH_TALK_DETAILS,
    SponsorPerkType.API_PRIZE,
    SponsorPerkType.API_WORKSHOP,
    SponsorPerkType.SOCIAL_MEDIA_PROMOTION,
    SponsorPerkType.SHIPPED_SWAG,
  ],
  [SponsorCompanyTier.Silver]: [
    SponsorPerkType.WEBSITE_LOGO,
    SponsorPerkType.WEBSITE_FEATURE,
    SponsorPerkType.NEWSLETTER_OPPORTUNITY,
    SponsorPerkType.NEWSLETTER_FEATURE,
    SponsorPerkType.NEWSLETTER_SPOTLIGHT,
    SponsorPerkType.TECH_TALK_PROPOSAL,
    SponsorPerkType.TECH_TALK_DETAILS,
    SponsorPerkType.API_PRIZE,
    SponsorPerkType.API_WORKSHOP,
    SponsorPerkType.SOCIAL_MEDIA_PROMOTION,
    SponsorPerkType.SHIPPED_SWAG,
    SponsorPerkType.SELECT_TABLE_LOCATION,
    SponsorPerkType.OPENING_CEREMONIES_SLIDE,
    SponsorPerkType.CLOSING_CEREMONIES_SLIDE,
  ],
  [SponsorCompanyTier.Gold]: [
    SponsorPerkType.WEBSITE_LOGO,
    SponsorPerkType.WEBSITE_FEATURE,
    SponsorPerkType.NEWSLETTER_OPPORTUNITY,
    SponsorPerkType.NEWSLETTER_FEATURE,
    SponsorPerkType.NEWSLETTER_SPOTLIGHT,
    SponsorPerkType.TECH_TALK_PROPOSAL,
    SponsorPerkType.TECH_TALK_DETAILS,
    SponsorPerkType.API_PRIZE,
    SponsorPerkType.API_WORKSHOP,
    SponsorPerkType.SOCIAL_MEDIA_PROMOTION,
    SponsorPerkType.SHIPPED_SWAG,
    SponsorPerkType.SELECT_TABLE_LOCATION,
    SponsorPerkType.OPENING_CEREMONIES_SLIDE,
    SponsorPerkType.CLOSING_CEREMONIES_SLIDE,
    SponsorPerkType.NAME_AN_AREA,
    SponsorPerkType.WELCOME_BOOKLET_BLURB,
    SponsorPerkType.COFFEE_CHATS,
  ],
};

export const PERK_TO_STATUS_SLUGS: Record<
  SponsorPerkType,
  keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output
> = {
  [SponsorPerkType.WEBSITE_LOGO]: "website_logo_status",
  [SponsorPerkType.WEBSITE_FEATURE]: "website_feature_status",
  [SponsorPerkType.NEWSLETTER_OPPORTUNITY]: "newsletter_opportunity_status",
  [SponsorPerkType.NEWSLETTER_FEATURE]: "newsletter_feature_status",
  [SponsorPerkType.NEWSLETTER_SPOTLIGHT]: "newsletter_spotlight_status",
  [SponsorPerkType.TECH_TALK_PROPOSAL]: "tech_talk_proposal_status",
  [SponsorPerkType.TECH_TALK_DETAILS]: "tech_talk_details_status",
  [SponsorPerkType.API_PRIZE]: "api_prize_status",
  [SponsorPerkType.API_WORKSHOP]: "api_workshop_status",
  [SponsorPerkType.LIGHTNING_CHALLENGE]: "lightning_challenge_status",
  [SponsorPerkType.SPONSOR_AN_ACTIVITY]: "sponsor_an_activity_status",
  [SponsorPerkType.SPONSOR_A_SNACK]: "sponsor_a_snack_status",
  [SponsorPerkType.SPONSOR_A_MEETUP]: "sponsor_a_meetup_status",
  [SponsorPerkType.SELECT_TABLE_LOCATION]: "select_table_location_status",
  [SponsorPerkType.SOCIAL_MEDIA_PROMOTION]: "social_media_promotion_status",
  [SponsorPerkType.NAME_AN_AREA]: "name_an_area_status",
  [SponsorPerkType.WELCOME_BOOKLET_BLURB]: "welcome_booklet_blurb_status",
  [SponsorPerkType.SHIPPED_SWAG]: "shipped_swag_status",
  [SponsorPerkType.OPENING_CEREMONIES_SLIDE]: "opening_ceremonies_slide_status",
  [SponsorPerkType.CLOSING_CEREMONIES_SLIDE]: "closing_ceremonies_slide_status",
  [SponsorPerkType.COFFEE_CHATS]: "coffee_chats_status",
};

export enum PerkStatus {
  INCOMPLETE = "Incomplete",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
}

export const PERK_STATUS_TO_LABELS: Record<PerkStatus, string> = {
  [PerkStatus.INCOMPLETE]: "Incomplete",
  [PerkStatus.SUBMITTED]: "Under Review",
  [PerkStatus.APPROVED]: "Approved",
};
