import { OperationVariables, useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) {
  const { refetch } = useQuery<TData, TVariables>(query, {
    skip: true,
  });

  const imperativelyCallQuery = (variables: TVariables) => refetch(variables);

  return imperativelyCallQuery;
}
