import React from "react";
import "twin.macro";
import styled from "styled-components";
import { Flex } from "@hackthenorth/north";

interface Props {
  badge: React.ReactNode;
  title: string;
  subtitle: string;
}

const Container = styled.div`
  padding: 38px 0;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

const TitleContainer = styled(Flex).attrs({
  row: true,
  align: "center",
  wrap: "nowrap",
  justify: "flex-start",
})`
  column-gap: 8px;
  margin-bottom: 6px;
`;

const Header: React.FC<Props> = ({ badge, title, subtitle }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
        {badge}
      </TitleContainer>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export default Header;
