/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import debounce from "lodash.debounce";
import React, { useRef, useCallback, useState } from "react";
import { OptionsType, OptionTypeBase } from "react-select";
import AsyncCreatable from "react-select/async-creatable";
import styled from "styled-components";

import {
  TDropdownTagInputProps,
  selectStyles,
} from "src/shared/components/DropdownTagInput";
import { useDynamicDropdownStyles } from "src/shared/components/DropdownTagInput/useDynamicDropdownStyles";
import { answersToOptions } from "src/shared/utils/react-select";

import { DEFAULT_CITIES } from "../constants";

const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN as string;

const StyledAsync = styled(AsyncCreatable)<TDropdownTagInputProps>`
  ${selectStyles}
`;

const GeoCoder: React.FC<TDropdownTagInputProps> = ({
  value,
  onChange,
  shortAnswer = false,
  onEnter,
  ...rest
}) => {
  const styles = useDynamicDropdownStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(
    value ? value.label : undefined
  );
  const [showValue, setShowValue] = useState<boolean>(true); //show value within input component

  const getOptions = async (query: string, callback: any) => {
    const newOptions = await axios(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?autocomplete=true&types=place&access_token=${ACCESS_TOKEN}`
    )
      .then(({ data: { features } }) =>
        features.map((feature: any) => feature.place_name)
      )
      .catch((error) => {
        console.error(error);
        return DEFAULT_CITIES;
      });
    callback(answersToOptions(newOptions));
  };

  const handleEnterPress = useCallback(
    (e: any) => {
      if (e.keyCode === 13 && onEnter) {
        onEnter();
      }
    },
    [onEnter]
  );

  const handleOptionChange = (option: OptionTypeBase) => {
    setInputValue(option.label);
    onChange(option);
    inputRef.current?.blur();
  };

  const handleInputChange = (text: string) => {
    // on input change, "open" the menu again
    setInputValue(text);
  };

  return (
    <StyledAsync
      cacheOptions
      isSearchable
      ref={inputRef}
      defaultOptions={answersToOptions(DEFAULT_CITIES)}
      value={value}
      loadOptions={debounce(getOptions, 400)}
      className="select"
      classNamePrefix="select"
      createOptionPosition="first"
      styles={styles}
      loadingMessage={() => null}
      shortAnswer={shortAnswer}
      onKeyDown={handleEnterPress}
      noOptionsMessage={() => null}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      onFocus={() => {
        setShowValue(false);
        setInputValue(value?.label || undefined);
      }}
      onBlur={() => setShowValue(true)}
      controlShouldRenderValue={showValue}
      inputValue={inputValue}
      isValidNewOption={(
        inputValue: string,
        selected: OptionsType<OptionTypeBase>,
        selectOptions: OptionsType<OptionTypeBase>
      ) =>
        !selected.some((opt) => opt.value === inputValue) &&
        selectOptions.every(
          (opt) => !opt.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      formatCreateLabel={(inputValue: string) =>
        inputValue ? `Add option "${inputValue}"` : "Add another option"
      }
      {...rest}
    />
  );
};

export default GeoCoder;
