/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type FindEventAttendeeByBadgeCodeQueryVariables = Types.Exact<{
  badge_code: Types.Scalars["String"];
}>;

export type FindEventAttendeeByBadgeCodeQuery = {
  findEventAttendeeByBadgeCode: Pick<
    Types.EventAttendeeInfo,
    "userId" | "eventAttendeeId" | "claimStage" | "role" | "badgeCode"
  > & {
    registrationData: Pick<
      Types.EventAttendeeRegistrationInfo,
      | "preferredName"
      | "legalName"
      | "pronouns"
      | "accessibilityNeeds"
      | "shirtType"
      | "shirtSize"
    >;
  };
};

export const FindEventAttendeeByBadgeCodeDocument = gql`
  query FindEventAttendeeByBadgeCode($badge_code: String!) {
    findEventAttendeeByBadgeCode(badge_code: $badge_code) {
      userId
      eventAttendeeId
      claimStage
      role
      badgeCode
      registrationData {
        preferredName
        legalName
        pronouns
        accessibilityNeeds
        shirtType
        shirtSize
      }
    }
  }
`;

/**
 * __useFindEventAttendeeByBadgeCodeQuery__
 *
 * To run a query within a React component, call `useFindEventAttendeeByBadgeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEventAttendeeByBadgeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEventAttendeeByBadgeCodeQuery({
 *   variables: {
 *      badge_code: // value for 'badge_code'
 *   },
 * });
 */
export function useFindEventAttendeeByBadgeCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FindEventAttendeeByBadgeCodeQuery,
    FindEventAttendeeByBadgeCodeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FindEventAttendeeByBadgeCodeQuery,
    FindEventAttendeeByBadgeCodeQueryVariables
  >(FindEventAttendeeByBadgeCodeDocument, baseOptions);
}
export function useFindEventAttendeeByBadgeCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindEventAttendeeByBadgeCodeQuery,
    FindEventAttendeeByBadgeCodeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FindEventAttendeeByBadgeCodeQuery,
    FindEventAttendeeByBadgeCodeQueryVariables
  >(FindEventAttendeeByBadgeCodeDocument, baseOptions);
}
export type FindEventAttendeeByBadgeCodeQueryHookResult = ReturnType<
  typeof useFindEventAttendeeByBadgeCodeQuery
>;
export type FindEventAttendeeByBadgeCodeLazyQueryHookResult = ReturnType<
  typeof useFindEventAttendeeByBadgeCodeLazyQuery
>;
export type FindEventAttendeeByBadgeCodeQueryResult =
  ApolloReactCommon.QueryResult<
    FindEventAttendeeByBadgeCodeQuery,
    FindEventAttendeeByBadgeCodeQueryVariables
  >;
