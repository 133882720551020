/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text, Spacer, Flex, Spinner } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { TextInput, Select, Checkbox, Button } from "north.js";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import "twin.macro";

import { PageWrapper } from "src/shared/components";
import { successToast, errorToast } from "src/shared/components/Toast";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize, useIsMounted } from "src/shared/hooks";
// import { answersToOptionsNew } from "src/shared/utils/react-select";
import { answersToNorthV2Options } from "src/shared/utils/react-select";
import { Nullable } from "src/shared/utils/typescript";
import {
  isEmail,
  isPhoneNumber,
  notNull,
  isWaterlooIDNumber,
} from "src/shared/utils/validation";
import { ArrowRight } from "src/static/icons";

import RSVPSidebar from "../rsvp-sidebar";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";
import SizeChart from "./SizeChart";

const PRONOUN_OPTIONS = ["they/them", "she/her", "he/him", "other"];
const SHIRT_TYPE_OPTIONS = ["Unisex", "Fitted"];
export const SHIRT_TYPE_MAP = {
  Unisex: "unisex",
  Fitted: "fitted",
};

export const SHIRT_TYPE_MAP_REVERSE = {
  null: null,
  unisex: "Unisex",
  fitted: "Fitted",
};
const UNISEX_SIZE_OPTIONS = ["XS", "S", "M", "L", "XL", "2XL"];
const FITTED_SIZE_OPTIONS = ["S", "M", "L", "XL"];
const FOOD_DIETARY_OPTIONS = [
  "Vegetarian",
  "Vegan",
  "Halal",
  "Gluten-free",
  "Nut-free",
  "Lactose intolerant",
  "Other",
];

const isEmpty = (s: Nullable<string>) => s === "" || s === null;

const PersonalPage = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isMounted = useIsMounted();
  const { updateResponses, isLoading, isReadOnly, navigateNext } =
    useHackerContext();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const [showErrors, setShowErrors] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {});
  });

  useEffect(() => {
    if (isValid && isMounted) {
      navigateNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  // Valid only if they've entered something
  const isLegalFullNameValid = !isEmpty(responsesState[Field.LEGAL_NAME]);
  const isPhoneNumberValid = isPhoneNumber(responsesState[Field.PHONE_NUMBER]);
  const isWaterlooStudentIDValid = isWaterlooIDNumber(
    responsesState[Field.WATERLOO_STUDENT_ID_NUMBER]
  );
  const isEmergencyContactNameValid = !isEmpty(
    responsesState[Field.EMERGENCY_CONTACT_NAME]
  );
  const isEmergencyContactRelationshipValid = !isEmpty(
    responsesState[Field.EMERGENCY_CONTACT_RELATIONSHIP]
  );
  const isEmergencyContactPhoneNumberValid = isPhoneNumber(
    responsesState[Field.EMERGENCY_CONTACT_PHONE_NUMBER]
  );
  const isEmergencyContactEmailValid = isEmail(
    responsesState[Field.EMERGENCY_CONTACT_EMAIL]
  );
  const isShirtTypeValid = notNull(responsesState[Field.SHIRT_TYPE]);
  const isShirtSizeValid = notNull(responsesState[Field.SHIRT_SIZE]);

  const OTHER_OPTION = "Other: ";

  const containsOther = (arr: Nullable<string[]>) => {
    if (!arr || arr.length === 0) return false;
    return arr[0].includes(OTHER_OPTION);
  };

  const getOtherVal = (arr: Nullable<string[]>) => {
    if (!arr || arr.length === 0) return "";
    return arr[0].substring(OTHER_OPTION.length);
  };

  const onSave = async () => {
    setShowErrors(true);
    if (isValid) {
      try {
        const { errors } = await updateResponses({
          ...responsesState,
          [Field.PERSONAL_RSVP_STAGE]: PageStage.COMPLETED,
        });

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        successToast("Answers successfully saved!");
        setSubmitting(true);
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e);
        }
      }
    } else {
      window.scrollTo(0, 0);
      errorToast("Please fill out all the required fields.");
    }
  };

  if (!isShirtTypeValid) {
    setResponsesState(Field.SHIRT_TYPE, "unisex");
  }
  if (!isShirtSizeValid) {
    setResponsesState(Field.SHIRT_SIZE, "XS");
  }

  return (
    <PageWrapper>
      <Prompt
        when={isBlocking && !submitting}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      {isTabletOrSmaller && <RSVPSidebar currentRoute="Personal Information" />}
      <Title>Personal Information</Title>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>
      <Spacer height={42} />

      <Heading>General</Heading>
      <QuestionWrapper>
        <Text mods="bold textBody">Full legal name</Text>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter your full legal name"
          value={responsesState[Field.LEGAL_NAME] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.LEGAL_NAME, e.target.value);
            setIsBlocking(true);
          }}
          error={
            showErrors &&
            !isLegalFullNameValid &&
            "Please enter your full legal name."
          }
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">Preferred name</Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          maxLength={32}
          placeholder="Enter your preferred name"
          value={responsesState[Field.PREFERRED_NAME] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.PREFERRED_NAME, e.target.value);
            setIsBlocking(true);
          }}
          hint={
            "Fill this out if your preferred name is different from your legal name."
          }
        />
      </QuestionWrapper>

      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">Pronouns</Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        {PRONOUN_OPTIONS.map((option, index) => (
          <CheckboxRSVP
            key={index}
            label={option}
            checked={
              index === PRONOUN_OPTIONS.length - 1
                ? containsOther(responsesState[Field.PRONOUNS])
                : (responsesState[Field.PRONOUNS] || []).includes(option)
            }
            value={option}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (index === PRONOUN_OPTIONS.length - 1) {
                if (e.target.checked) {
                  setResponsesState(Field.PRONOUNS, [
                    OTHER_OPTION,
                    ...(responsesState[Field.PRONOUNS] || []),
                  ]);
                } else {
                  setResponsesState(
                    Field.PRONOUNS,
                    (responsesState[Field.PRONOUNS] || [""]).slice(1)
                  );
                }
              } else {
                if (e.target.checked) {
                  setResponsesState(Field.PRONOUNS, [
                    ...(responsesState[Field.PRONOUNS] || []),
                    option,
                  ]);
                } else {
                  setResponsesState(
                    Field.PRONOUNS,
                    (responsesState[Field.PRONOUNS] || []).filter(
                      (e) => e !== option
                    )
                  );
                }
              }
              setIsBlocking(true);
            }}
          />
        ))}
        {containsOther(responsesState[Field.PRONOUNS]) && (
          <CheckboxTextInputWrapper>
            <TextInputRSVP
              disabled={isReadOnly}
              placeholder="Please enter pronouns"
              value={getOtherVal(responsesState[Field.PRONOUNS])}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setResponsesState(Field.PRONOUNS, [
                  OTHER_OPTION + e.target.value,
                  ...(responsesState[Field.PRONOUNS] || [""]).slice(1),
                ]);
                setIsBlocking(true);
              }}
              hint={"Separate sets of pronouns by a comma."}
            />
          </CheckboxTextInputWrapper>
        )}
      </QuestionWrapper>
      <QuestionWrapper>
        <Text mods="bold textBody">Phone number</Text>
        <Spacer height={12} />
        <TextInputRSVP
          type="tel"
          disabled={isReadOnly}
          maxLength={30}
          placeholder="Enter your phone number"
          value={responsesState[Field.PHONE_NUMBER] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.PHONE_NUMBER, e.target.value);
            setIsBlocking(true);
          }}
          hint={
            "If you’re not from Canada or the US, please also enter your country calling code. We may need to contact you by phone for urgent matters."
          }
          error={
            showErrors &&
            !isPhoneNumberValid &&
            "Please enter a valid phone number"
          }
        />
        <Spacer height={8} />
      </QuestionWrapper>
      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">Waterloo student ID number</Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          maxLength={8}
          placeholder="Enter your Waterloo student ID number"
          value={responsesState[Field.WATERLOO_STUDENT_ID_NUMBER] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.WATERLOO_STUDENT_ID_NUMBER, e.target.value);
            setIsBlocking(true);
          }}
          hint={
            "This field is only required if you are a University of Waterloo student."
          }
          error={
            showErrors &&
            !isWaterlooStudentIDValid &&
            "Please enter a valid Waterloo student ID number."
          }
        />
        <Spacer height={8} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text mods="bold textBody">Emergency contact name</Text>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter the name of your emergency contact"
          value={responsesState[Field.EMERGENCY_CONTACT_NAME] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.EMERGENCY_CONTACT_NAME, e.target.value);
            setIsBlocking(true);
          }}
          hint={"We will contact them in case of emergencies."}
          error={
            showErrors &&
            !isEmergencyContactNameValid &&
            "Please enter the name of your emergency contact."
          }
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text mods="bold textBody">Emergency contact relationship</Text>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter the type of relationship you have with your emergency contact"
          value={responsesState[Field.EMERGENCY_CONTACT_RELATIONSHIP] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(
              Field.EMERGENCY_CONTACT_RELATIONSHIP,
              e.target.value
            );
            setIsBlocking(true);
          }}
          error={
            showErrors &&
            !isEmergencyContactRelationshipValid &&
            "Please enter the type of relationship you have with your emergency contact."
          }
        />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text mods="bold textBody">Emergency contact phone number</Text>
        <Spacer height={12} />
        <TextInputRSVP
          type="tel"
          disabled={isReadOnly}
          maxLength={30}
          placeholder="Enter your emergency contact’s phone number"
          value={responsesState[Field.EMERGENCY_CONTACT_PHONE_NUMBER] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(
              Field.EMERGENCY_CONTACT_PHONE_NUMBER,
              e.target.value
            );
            setIsBlocking(true);
          }}
          hint={
            "If your emergency contact is not from Canada or the US, please also enter their country calling code."
          }
          error={
            showErrors &&
            !isEmergencyContactPhoneNumberValid &&
            "Please enter a valid phone number"
          }
        />
        <Spacer height={8} />
      </QuestionWrapper>
      <QuestionWrapper>
        <Text mods="bold textBody">Emergency contact email</Text>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter your emergency contact’s email"
          value={responsesState[Field.EMERGENCY_CONTACT_EMAIL] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.EMERGENCY_CONTACT_EMAIL, e.target.value);
            setIsBlocking(true);
          }}
          error={
            showErrors &&
            !isEmergencyContactEmailValid &&
            "Please enter a valid email"
          }
        />
        <Spacer height={8} />
      </QuestionWrapper>
      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">
            Do you require any special accommodations for our event?
          </Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter any accommodations here"
          value={responsesState[Field.ACCESSIBILITY_NEEDS] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.ACCESSIBILITY_NEEDS, e.target.value);
            setIsBlocking(true);
          }}
          hint={
            "We want to ensure Hack the North is inclusive for everyone. If there are any accommodations we should be aware of please let us know!"
          }
        />
      </QuestionWrapper>

      <Divider />
      <Heading>Swag</Heading>
      <Text mods="bold textBody">Sizing chart</Text>
      <Spacer height={12} />
      <SizeChartWrapper>
        <SizeChart unisex />
        <SizeChart />
      </SizeChartWrapper>
      <Spacer height={isTabletOrSmaller ? 0 : 24} />
      <GridContainer>
        <div>
          <Text mods="bold textBody">Shirt type</Text>
          <Spacer height={12} />
          <SelectRSVP
            error={
              showErrors && !isShirtTypeValid && "Please select an option."
            }
            disabled={isReadOnly}
            fullWidth
            size="lg"
            placeholder="Select a shirt type"
            value={
              SHIRT_TYPE_MAP_REVERSE[responsesState[Field.SHIRT_TYPE] || "null"]
            }
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (responsesState[Field.SHIRT_TYPE] !== e.target.value) {
                setResponsesState(Field.SHIRT_SIZE, null);
              }
              setResponsesState(
                Field.SHIRT_TYPE,
                SHIRT_TYPE_MAP[e.target.value]
              );
              setIsBlocking(true);
            }}
          >
            {answersToNorthV2Options(SHIRT_TYPE_OPTIONS)}
          </SelectRSVP>
        </div>
        <div>
          <Text mods="bold textBody">Shirt size</Text>
          <Spacer height={12} />
          {responsesState[Field.SHIRT_TYPE] === "unisex" ? (
            <SelectRSVP
              disabled={isReadOnly || !isShirtTypeValid}
              fullWidth
              size="lg"
              placeholder="Select a shirt size"
              value={responsesState[Field.SHIRT_SIZE] || ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setResponsesState(Field.SHIRT_SIZE, e.target.value);
                setIsBlocking(true);
              }}
              error={
                showErrors && !isShirtTypeValid && "Please select an option."
              }
            >
              {answersToNorthV2Options(UNISEX_SIZE_OPTIONS)}
            </SelectRSVP>
          ) : (
            <SelectRSVP
              disabled={isReadOnly || !isShirtTypeValid}
              fullWidth
              size="lg"
              placeholder="Select a shirt size"
              value={responsesState[Field.SHIRT_SIZE] || ""}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setResponsesState(Field.SHIRT_SIZE, e.target.value);
                setIsBlocking(true);
              }}
              error={
                showErrors && !isShirtTypeValid && "Please select an option."
              }
            >
              {answersToNorthV2Options(FITTED_SIZE_OPTIONS)}
            </SelectRSVP>
          )}
        </div>
      </GridContainer>
      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">
            Do you have any apparel accommodation requests?
          </Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        <TextInputRSVP
          disabled={isReadOnly}
          placeholder="Enter any apparel accomodation requests (ex. long sleeves)"
          value={responsesState[Field.SWAG_SHIRT_ACCOMODATIONS] as any}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setResponsesState(Field.SWAG_SHIRT_ACCOMODATIONS, e.target.value);
            setIsBlocking(true);
          }}
        />
      </QuestionWrapper>
      <Divider />
      <Heading>Food</Heading>
      <QuestionWrapper>
        <OptionalQuestionWrapper>
          <Text mods="bold textBody">
            Please select any of the following dietary restrictions you may
            have.
          </Text>
          <OptionalText>Optional</OptionalText>
        </OptionalQuestionWrapper>
        <Spacer height={12} />
        {FOOD_DIETARY_OPTIONS.map((option, index) => (
          <CheckboxRSVP
            key={index}
            label={option}
            checked={
              index === FOOD_DIETARY_OPTIONS.length - 1
                ? containsOther(responsesState[Field.FOOD_DIETARY_RESTRICTIONS])
                : (
                    responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || []
                  ).includes(option)
            }
            value={option}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (index === FOOD_DIETARY_OPTIONS.length - 1) {
                if (e.target.checked) {
                  setResponsesState(Field.FOOD_DIETARY_RESTRICTIONS, [
                    OTHER_OPTION,
                    ...(responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || []),
                  ]);
                } else {
                  setResponsesState(
                    Field.FOOD_DIETARY_RESTRICTIONS,
                    (
                      responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || [""]
                    ).slice(1)
                  );
                }
              } else {
                if (e.target.checked) {
                  setResponsesState(Field.FOOD_DIETARY_RESTRICTIONS, [
                    ...(responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || []),
                    option,
                  ]);
                } else {
                  setResponsesState(
                    Field.FOOD_DIETARY_RESTRICTIONS,
                    (
                      responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || []
                    ).filter((e) => e !== option)
                  );
                }
              }
              setIsBlocking(true);
            }}
          />
        ))}
        {/* {responsesState[Field.DIETARY_OPTIONAL_SELECTED] && ( */}
        {containsOther(responsesState[Field.FOOD_DIETARY_RESTRICTIONS]) && (
          <CheckboxTextInputWrapper>
            <TextInputRSVP
              disabled={isReadOnly}
              placeholder="Please enter restrictions"
              value={getOtherVal(
                responsesState[Field.FOOD_DIETARY_RESTRICTIONS]
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setResponsesState(Field.FOOD_DIETARY_RESTRICTIONS, [
                  OTHER_OPTION + e.target.value,
                  ...(
                    responsesState[Field.FOOD_DIETARY_RESTRICTIONS] || [""]
                  ).slice(1),
                ]);
                setIsBlocking(true);
              }}
            />
          </CheckboxTextInputWrapper>
        )}
      </QuestionWrapper>
      <Spacer height={50} />
      <FlexRow>
        <Button color="primary" disabled={isLoading} onClick={onSave} size="md">
          {isLoading ? (
            <Spinner />
          ) : (
            <FlexRow>
              <p>
                {responsesState[Field.PERSONAL_RSVP_STAGE] ===
                PageStage.COMPLETED
                  ? "Save"
                  : "Next"}
              </p>
              <Spacer width="8px" />
              <ArrowRight width={16} />
            </FlexRow>
          )}
        </Button>
        <Spacer width="32px" />
      </FlexRow>
    </PageWrapper>
  );
};

const GridContainer = styled.div<{ sizeChart?: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 20px));
  row-gap: 24px;
  column-gap: 40px;
  margin-bottom: 36px;

  ${({ theme }) => theme.mediaQueries.tablet`
    display: block;

    & > div {
      margin-top: 16px;
    }
  `}
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: 900;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  line-height: 1.5;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  margin-bottom: 8px;
  margin-top: 0;
`;

const Heading = styled.h3`
  font-size: 20px;
  font-weight: 900;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  line-height: 1.5;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  margin-bottom: 24px;
`;

const CheckboxTextInputWrapper = styled.div`
  margin-top: 8px;
  margin-left: 24px;
`;

const OptionalText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-weight: 500;
  font-style: italic;
  line-height: 1.35;
  margin-top: 4px;
  margin-left: 8px;
`;

const OptionalQuestionWrapper = styled(Flex).attrs({ align: "center" })`
  gap: 8px;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  margin-bottom: 36px;
`;

const TextInputRSVP = styled(TextInput).attrs({ size: "md" })`
  font-size: 16px !important;
  width: 100%;
`;

const SelectRSVP = styled(Select).attrs({ size: "lg" })`
  font-size: 16px !important;
`;

const CheckboxRSVP = styled(Checkbox)`
  font-size: 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 42px 0;
  background-color: ${({ theme }) => theme.globalConstants.color.borderGray};
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const SizeChartWrapper = styled(Flex).attrs({
  justify: "flex-start",
  wrap: "wrap",
})`
  column-gap: 40px;
  row-gap: 24px;
`;

export default PersonalPage;
