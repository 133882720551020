/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type HandleNetworkingScanMutationVariables = Types.Exact<{
  badge_code: Types.Scalars["String"];
}>;

export type HandleNetworkingScanMutation = {
  handleNetworkingScan: {
    hackerNetworkingPayload?: Types.Maybe<
      Pick<
        Types.HackerNetworkingPayload,
        | "name"
        | "role"
        | "bio"
        | "pronouns"
        | "school"
        | "facebookLink"
        | "instagramLink"
        | "twitterLink"
        | "linkedinLink"
        | "discordTag"
        | "githubLink"
        | "tiktokLink"
      > & {
        extendedProfile?: Types.Maybe<{
          fields_HACKTHENORTH2023_EXTENDED_PROFILES: {
            answers: {
              interests: Pick<Types.StringFieldAnswerTypeWrapper, "value">;
            };
          };
        }>;
      }
    >;
    sponsorNetworkingPayload?: Types.Maybe<
      Pick<
        Types.SponsorNetworkingPayload,
        | "attendeeName"
        | "sponsorCompanyName"
        | "sponsorCompanyId"
        | "alreadyScanned"
      >
    >;
  };
};

export const HandleNetworkingScanDocument = gql`
  mutation HandleNetworkingScan($badge_code: String!) {
    handleNetworkingScan(badge_code: $badge_code) {
      hackerNetworkingPayload {
        name
        role
        bio
        pronouns
        school
        facebookLink
        instagramLink
        twitterLink
        linkedinLink
        discordTag
        githubLink
        tiktokLink
        extendedProfile {
          fields_HACKTHENORTH2023_EXTENDED_PROFILES {
            answers {
              interests {
                value
              }
            }
          }
        }
      }
      sponsorNetworkingPayload {
        attendeeName
        sponsorCompanyName
        sponsorCompanyId
        alreadyScanned
      }
    }
  }
`;

/**
 * __useHandleNetworkingScanMutation__
 *
 * To run a mutation, you first call `useHandleNetworkingScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleNetworkingScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleNetworkingScanMutation, { data, loading, error }] = useHandleNetworkingScanMutation({
 *   variables: {
 *      badge_code: // value for 'badge_code'
 *   },
 * });
 */
export function useHandleNetworkingScanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    HandleNetworkingScanMutation,
    HandleNetworkingScanMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    HandleNetworkingScanMutation,
    HandleNetworkingScanMutationVariables
  >(HandleNetworkingScanDocument, baseOptions);
}
export type HandleNetworkingScanMutationHookResult = ReturnType<
  typeof useHandleNetworkingScanMutation
>;
export type HandleNetworkingScanMutationResult =
  ApolloReactCommon.MutationResult<HandleNetworkingScanMutation>;
export type HandleNetworkingScanMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    HandleNetworkingScanMutation,
    HandleNetworkingScanMutationVariables
  >;
