import moment from "moment-timezone";
import React from "react";
import styled from "styled-components";

import { CheckCircleInverted } from "src/static/icons";

type TProps = {
  departure: string;
  arrival: string;
  availableSeats: number;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
};

const BusCard: React.FC<TProps> = ({
  departure,
  arrival,
  availableSeats,
  selected,
  disabled,
  onClick,
}) => {
  const Card = styled.div`
    border-radius: 8px;
    border: 1px solid ${selected ? "#2395C6" : "#d1d5db"};
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    min-width: 250px;
    max-width: max(360px, 50%);
    flex: 1 0 calc(50% - 8px);

    :hover {
      background-color: ${disabled ? "" : "#f9fafb"};
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }
  `;

  const Availability = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: ${disabled && !selected
      ? "#9CA3AF"
      : availableSeats <= 5
      ? "#D97706"
      : "#54C152"};
  `;

  const TimesContainer = styled.div`
    display: flex;
    gap: 24px;
  `;

  const TimeTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: ${disabled && !selected ? "#9CA3AF" : "#6b7280"};
  `;

  const Time = styled.h3`
    font-size: 20px;
    font-weight: 900;
    ${disabled && !selected ? "color: #9CA3AF" : ""}
  `;

  const AvailableSeats = styled.div`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 8px;
    ${disabled && !selected ? "color: #9CA3AF" : ""}
  `;

  const Seats = styled.span`
    font-weight: 900;
    color: ${disabled && !selected
      ? "#9CA3AF"
      : availableSeats <= 5
      ? "#D97706"
      : "black"};
  `;

  return (
    <Card
      onClick={() => {
        if (availableSeats !== 0 && !disabled) {
          onClick();
        }
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Availability>
          {availableSeats === 0
            ? "Unavailable"
            : availableSeats <= 5
            ? "Almost out"
            : "Available"}
        </Availability>
        {selected && <CheckCircleInverted />}
      </div>
      <TimesContainer>
        <div>
          <TimeTitle>Departure Time</TimeTitle>
          <Time>
            {moment(departure).tz("America/Toronto").format("h:mm A z")}
          </Time>
        </div>
        <div>
          <TimeTitle>Expected Arrival Time</TimeTitle>
          <Time>
            {moment(arrival).tz("America/Toronto").format("h:mm A z")}
          </Time>
        </div>
      </TimesContainer>
      <div style={{ width: "100%", borderBottom: "1px solid #D1D5DB" }} />
      <AvailableSeats>
        <p>Seats Available</p>
        <Seats>{availableSeats}</Seats>
      </AvailableSeats>
    </Card>
  );
};

export default BusCard;
