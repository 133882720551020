import React, { useEffect, useMemo, useState, useRef } from "react";
import { styled } from "twin.macro";

import {
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import BottomBar, { SaveState } from "./BottomBar";
import { usePerkState } from "./usePerkState";
import { getPerkComponent } from "./utils";
import "styled-components/macro";

interface Props {
  type: SponsorPerkType;
}

const PerkDetailContainer = styled.div`
  padding: 0 38px 0 38px;
  overflow-y: scroll;
  height: 0;
  flex: 1;
  padding-bottom: 66px;
`;

const Perk: React.FC<Props> = ({ type }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [invalidFields, setInvalidFields] = useState<
    {
      [key in keyof SponsorPerksData]?: true;
    }
  >({});
  const [saveState, setSaveState] = useState<SaveState>(SaveState.SAVED);
  const containerRef = useRef<any>();

  useEffect(() => {
    containerRef.current && setContainerWidth(containerRef.current.clientWidth);
  }, []);

  const { component: Component, validationFields } = useMemo(
    () => getPerkComponent(type),
    [type]
  );
  const {
    // isSubmitted,
    isReadOnly,
    isLoading,
    submitPerk,
    getState,
    updateState,
    updateStates,
    validator,
  } = usePerkState({ perk: type });

  useEffect(
    () =>
      isLoading
        ? setSaveState(SaveState.SAVING)
        : setSaveState(SaveState.SAVED),
    [isLoading]
  );

  const onSubmitPerk = async () => {
    const fields = validator(validationFields);
    setInvalidFields(fields);
    if (Object.keys(fields).length === 0) {
      await submitPerk(validationFields);
    } else setSaveState(SaveState.ERROR);
  };

  return (
    <div tw="flex flex-col h-full">
      <PerkDetailContainer ref={containerRef}>
        <Component
          isReadOnly={isReadOnly}
          getState={getState}
          updateState={updateState}
          updateStates={updateStates}
          error={invalidFields}
        />
      </PerkDetailContainer>
      <BottomBar
        savingState={saveState}
        onSubmit={onSubmitPerk}
        width={containerWidth}
      />
    </div>
  );
};

export default Perk;
