import { Text, Spacer } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { ScrollToTop } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";
import { IS_PRODUCTION } from "src/shared/utils/env";
import { FooterImg } from "src/static/img";

import { LockedPage } from "./LockedPage";

type PageWrapperProps = ComponentPropsWithoutRef<"div"> & {
  pageTitle?: string;
  footer?: boolean;
  fullWidth?: boolean;
  stretchHeight?: boolean;
  scrollToTop?: boolean;

  // TODO: Remove after all pages have been released
  locked?: boolean;
  phase1?: boolean;
  phase2?: boolean;
  phase3?: boolean;
  hardware?: boolean;
};

const SubContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "1500")};
  width: 100%;
`;

const Container = styled.main<{ stretchHeight?: boolean; fullWidth?: boolean }>`
  width: 100%;
  display: block;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "900")};
  min-height: ${({ stretchHeight }) => (stretchHeight ? "100%" : "0")};
  background: ${({ theme }) => theme.globalConstants.color.white};
  padding: 42px 56px;

  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.smallMobile`
    padding: 20px;
  `}
`;

const PageWrapper: React.FC<PageWrapperProps> = ({
  pageTitle,
  footer = false,
  fullWidth = false,
  stretchHeight = true,
  scrollToTop = false,

  locked = false,
  phase1 = false,
  phase2 = false,
  phase3 = false,
  hardware = false,
  children,
  ...rest
}) => {
  const { isOrganizer } = useUserContext();
  return locked && IS_PRODUCTION && !isOrganizer ? (
    <LockedPage
      phase1={phase1}
      phase2={phase2}
      phase3={phase3}
      hardware={hardware}
    />
  ) : (
    <Container stretchHeight={stretchHeight} {...rest}>
      <SubContainer fullWidth={fullWidth}>
        {pageTitle && <Text mods={"heading title"}>{pageTitle}</Text>}
        {children}
        {footer && (
          <>
            <Spacer height={72} />
            <FooterImg width="100%" />
          </>
        )}
        {scrollToTop && <ScrollToTop />}
      </SubContainer>
    </Container>
  );
};

export default PageWrapper;
