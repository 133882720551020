import { Text, Flex } from "@hackthenorth/north";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { DropdownMenu, Button, Modal } from "north.js";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import tw, { styled, theme } from "twin.macro";

import { useSidebarContent } from "src/shared/components/Sidebar/useSidebarContent";
import { BaseRoute } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";
import { useDeviceSize } from "src/shared/hooks";
import "styled-components/macro";

import { JWTRole } from "@hackthenorth/north";

interface HeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  title?: string;
  subtitle?: string;
  actionButton?: React.ReactNode;
  short?: boolean;
}

export const TOPNAVBAR_HEIGHT = 120;

const Container = styled(Flex).attrs({
  align: "center",
  justify: "flex-start",
})<{ showSidebar: boolean; short: boolean }>`
  height: ${({ short }) => (short ? 0 : TOPNAVBAR_HEIGHT)}px;
  padding: 42px;

  z-index: 1; /* modal zIndex is 100 */
  background: ${({ theme }) => theme.globalConstants.color.borderLight};
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};

  ${({ theme }) => theme.mediaQueries.largeMobile`
      padding-right: 32px;
    `}
`;

const TextContainer = styled(Flex).attrs({ column: true })``;

// to do: move this styling to tailwind when working
const Title = styled(Text)`
  font-size: 24px !important;
  font-weight: 900 !important;
  line-height: 39px !important;
  color: ${({ theme }) =>
    theme.globalConstants.color.borderGrayDark} !important;
`;

const SubTitle = styled(Text)`
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 22px !important;

  color: ${({ theme }) => theme.globalConstants.color.textSecondary} !important;
`;

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  actionButton,
  short,
  ...rest
}) => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const { sections } = useSidebarContent();
  const { logOut, roles } = useUserContext();

  const navigate = useNavigate();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const closeModal = () => setShowConfirmationModal(false);

  let items = sections.map((section) =>
    section.content.map((page) => {
      const isCurrent =
        page.href === "/"
          ? window.location.pathname === page.href
          : window.location.pathname.startsWith(page.href);
      const Icon = isCurrent ? page.activeIcon : page.icon;
      return {
        content: ({ active }: { active: boolean }) => (
          <SidebarItem active={active} current={isCurrent}>
            <Icon style={{ height: 20, width: 20, marginRight: 8 }} />
            <span tw="font-bold">{page.name}</span>
          </SidebarItem>
        ),
        action: () => {
          navigate(page.href);
        },
      };
    })
  );
  if (isTabletOrSmaller) {
    items = items.concat([
      ...(!roles.includes(JWTRole.SPONSOR)
        ? [
            [
              {
                content: () => <Text>Settings</Text>,
                action: () => {
                  navigate(BaseRoute.SETTINGS);
                },
              },
            ],
          ]
        : []),
      [
        {
          content: () => <Text>Log out</Text>,
          action: () => {
            setShowConfirmationModal(true);
          },
        },
      ],
    ]);
  }

  return (
    <Container
      {...rest}
      short={{ short }}
      tw="flex items-center px-48 md:px-24"
      style={{ justifyContent: "space-between" }}
    >
      <Modal
        open={showConfirmationModal}
        onClose={closeModal}
        style={{ width: "90vw", position: "fixed", top: "25vh", left: "5vw" }}
      >
        <Modal.Header>Log out</Modal.Header>
        <Modal.Body tw="w-full">Are you sure you want to log out?</Modal.Body>
        <Modal.Footer justify="end">
          <Button size="md" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            size="md"
            color="primary"
            onClick={() => {
              logOut();
              closeModal();
            }}
          >
            Log out
          </Button>
        </Modal.Footer>
      </Modal>
      <div tw="flex items-center gap-32">
        {isTabletOrSmaller && (
          <div tw="z-60">
            <DropdownMenu items={items} tw="left-0">
              <Bars3Icon tw="h-24 w-24 cursor-pointer text-text-secondary" />
            </DropdownMenu>
          </div>
        )}
        <TextContainer>
          {title && <Title>{title}</Title>}
          {subtitle && <SubTitle>{subtitle}</SubTitle>}
        </TextContainer>
      </div>
      {actionButton}
    </Container>
  );
};

const SidebarItem = styled.div<{ current: boolean; active: boolean }>`
  ${tw`flex items-start w-full`}
  color: ${(props) =>
    props.active
      ? theme`colors.white`
      : props.current
      ? theme`colors.text.primary`
      : theme`colors.text.secondary`};

  &:hover {
    color: unset;
    text-decoration: none;
  }
`;

export default Header;
