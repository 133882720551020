import { Flex, Text, Spacer } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";
import styled from "styled-components";

import { GradientText, ErrorPageWrapper } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { FourOFourAImg, FourOFourBImg, FourOFourCImg } from "src/static/img";

import { getPathname, getSimilar } from "./utils";

const FlexWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.medium`
    align-items: baseline;
  `}
`;

const Title = styled(GradientText)`
  font-size: 52px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 40px;
  `}
`;

const ItLooksLikeText = styled(Text)`
  font-size: 20px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 14px;
  `}
`;

const DidYouMean = styled(Text)`
  font-size: 16px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 14px;
  `}
`;

const StyledLink = styled(Link)`
  font-size: 16px !important;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 14px !important;
  `}
`;

const FourOFourImg = styled.img`
  width: 321px;
  height: 100%;
  margin: 0 15px;

  ${({ theme }) => theme.mediaQueries.medium`
    width: 186px;
  `};

  ${({ theme }) => theme.mediaQueries.largeMobile`
    width: 163px;
  `};
`;

const ImagesWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.largeMobile`
    flex-direction: column;
    align-items: baseline;
  `}
`;

const StyledErrorPageWrapper = styled(ErrorPageWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ROUTE_NAMES = Object.values(Route).filter((r) => r !== Route.NOT_FOUND);

const NotFound = () => {
  const path = getPathname();
  const mostSimilarRoute = getSimilar(path, ROUTE_NAMES) || "/";

  return (
    <StyledErrorPageWrapper>
      <ImagesWrapper>
        <FourOFourImg src={FourOFourAImg} alt="4" aria-hidden />
        <FourOFourImg src={FourOFourBImg} alt="0" aria-hidden />
        <FourOFourImg src={FourOFourCImg} alt="4" aria-hidden />
      </ImagesWrapper>
      <FlexWrapper column align="center">
        <Title gradient="gradientRedBlue172" mods="heading">
          Whoops!
        </Title>
        <Spacer height={30} />
        <ItLooksLikeText mods="heading">
          It looks like this page doesn’t exist!
        </ItLooksLikeText>
        <Spacer height={50} />
        <DidYouMean>Did you mean this page?</DidYouMean>
        <Spacer height={20} />
        <StyledLink href={mostSimilarRoute}>{mostSimilarRoute}</StyledLink>
      </FlexWrapper>
    </StyledErrorPageWrapper>
  );
};

export default NotFound;
