import { DateTime, DurationInput } from "luxon";

import { formatTimeRangeV2 } from "src/shared/utils/formatTime";
import { THackerAPIDatetime } from "src/shared/utils/hackerapi";
import { timesToOptionsExplicitV2 } from "src/shared/utils/react-select";

// TODO: Replace these, they are invalid
const TECH_TALK_TIMES: [THackerAPIDatetime, DurationInput][] = [
  [DateTime.utc(2022, 9, 17, 20, 0, 0).toISO(), { hours: 2 }],
  [DateTime.utc(2022, 9, 17, 20, 30, 0).toISO(), { hours: 2 }],
  [DateTime.utc(2022, 9, 17, 21, 0, 0).toISO(), { hours: 2 }],
  [DateTime.utc(2022, 9, 17, 21, 30, 0).toISO(), { hours: 2 }],
];

export const TIME_OPTIONS = timesToOptionsExplicitV2(
  formatTimeRangeV2,
  TECH_TALK_TIMES
);
