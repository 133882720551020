import { TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output } from "src/api/types.generated";

import { SponsorPerksData, SponsorPerkType } from "./types";

// NOTE: PERKS_TO_FIELDS should be kept in sync with the types below
export const PERKS_TO_FIELDS: Record<
  SponsorPerkType,
  (keyof TypedClaimsResolver_Hackthenorth2024_Sponsor_Perks_Output)[]
> = {
  [SponsorPerkType.WEBSITE_LOGO]: [
    "website_logo_file",
    "website_logo_website_link",
    "website_logo_status",
    "website_logo_due_date",
  ],
  [SponsorPerkType.WEBSITE_FEATURE]: [
    "website_feature_website_link",
    "website_feature_link_description",
    "website_feature_message",
    "website_feature_status",
    "website_feature_due_date",
  ],
  [SponsorPerkType.NEWSLETTER_OPPORTUNITY]: [
    "newsletter_opportunity_link",
    "newsletter_opportunity_name",
    "newsletter_opportunity_status",
    "newsletter_opportunity_due_date",
  ],
  [SponsorPerkType.NEWSLETTER_FEATURE]: [
    "newsletter_feature_image_file",
    "newsletter_feature_title",
    "newsletter_feature_website_link",
    "newsletter_feature_description",
    "newsletter_feature_status",
    "newsletter_feature_due_date",
  ],
  [SponsorPerkType.NEWSLETTER_SPOTLIGHT]: [
    "newsletter_spotlight_image_file",
    "newsletter_spotlight_title",
    "newsletter_spotlight_website_link",
    "newsletter_spotlight_description",
    "newsletter_spotlight_status",
    "newsletter_spotlight_due_date",
  ],
  [SponsorPerkType.TECH_TALK_PROPOSAL]: [
    "tech_talk_proposal_title_one",
    "tech_talk_proposal_title_two",
    "tech_talk_proposal_description_one",
    "tech_talk_proposal_description_two",
    "tech_talk_proposal_status",
    "tech_talk_proposal_due_date",
  ],
  [SponsorPerkType.TECH_TALK_DETAILS]: [
    "tech_talk_details_time_one",
    "tech_talk_details_time_two",
    "tech_talk_details_time_three",
    "tech_talk_details_representative",
    "tech_talk_details_representative_name",
    "tech_talk_details_representative_email",
    "tech_talk_details_accepted_hackers_only",
    "tech_talk_details_status",
    "tech_talk_details_due_date",
  ],
  [SponsorPerkType.API_PRIZE]: [
    "api_prize_title",
    "api_prize_description",
    "api_prize_judging_confirmation",
    "api_prize_status",
    "api_prize_due_date",
  ],
  [SponsorPerkType.API_WORKSHOP]: [
    "api_workshop_description",
    "api_workshop_time_one",
    "api_workshop_time_two",
    "api_workshop_time_three",
    "api_workshop_accepted_hackers_only",
    "api_workshop_representative",
    "api_workshop_representative_name",
    "api_workshop_representative_email",
    "api_workshop_status",
    "api_workshop_due_date",
  ],
  [SponsorPerkType.LIGHTNING_CHALLENGE]: [
    "lightning_challenge_description",
    "lightning_challenge_time_one",
    "lightning_challenge_time_two",
    "lightning_challenge_time_three",
    "lightning_challenge_representative",
    "lightning_challenge_representative_name",
    "lightning_challenge_representative_email",
    "lightning_challenge_status",
    "lightning_challenge_due_date",
  ],
  [SponsorPerkType.SPONSOR_AN_ACTIVITY]: [
    "sponsor_an_activity_selection_one",
    "sponsor_an_activity_selection_two",
    "sponsor_an_activity_selection_three",
    "sponsor_an_activity_status",
    "sponsor_an_activity_due_date",
  ],
  [SponsorPerkType.SPONSOR_A_SNACK]: [
    "sponsor_a_snack_name",
    "sponsor_a_snack_time",
    "sponsor_a_snack_status",
    "sponsor_a_snack_due_date",
  ],
  [SponsorPerkType.SPONSOR_A_MEETUP]: [
    "sponsor_a_meetup_name",
    "sponsor_a_meetup_description",
    "sponsor_a_meetup_time_one",
    "sponsor_a_meetup_time_two",
    "sponsor_a_meetup_time_three",
    "sponsor_a_meetup_status",
    "sponsor_a_meetup_due_date",
  ],
  [SponsorPerkType.SELECT_TABLE_LOCATION]: [
    "select_table_location_one",
    "select_table_location_two",
    "select_table_location_three",
    "select_table_location_status",
    "select_table_location_due_date",
  ],
  [SponsorPerkType.SOCIAL_MEDIA_PROMOTION]: [
    "social_media_promotion_description",
    "social_media_promotion_logo",
    "social_media_promotion_status",
    "social_media_promotion_due_date",
  ],
  [SponsorPerkType.NAME_AN_AREA]: [
    "name_an_area_due_date",
    "name_an_area_status",
    "name_an_area_one",
    "name_an_area_two",
  ],
  [SponsorPerkType.WELCOME_BOOKLET_BLURB]: [
    "welcome_booklet_blurb_description",
    "welcome_booklet_blurb_status",
    "welcome_booklet_blurb_due_date",
  ],
  [SponsorPerkType.SHIPPED_SWAG]: [
    "shipped_swag_description",
    "shipped_swag_status",
    "shipped_swag_due_date",
  ],
  [SponsorPerkType.OPENING_CEREMONIES_SLIDE]: [
    "opening_ceremonies_slide_file",
    "opening_ceremonies_slide_link",
    "opening_ceremonies_slide_status",
    "opening_ceremonies_slide_due_date",
  ],
  [SponsorPerkType.CLOSING_CEREMONIES_SLIDE]: [
    "closing_ceremonies_slide_file",
    "closing_ceremonies_slide_link",
    "closing_ceremonies_slide_status",
    "closing_ceremonies_slide_due_date",
  ],
  [SponsorPerkType.COFFEE_CHATS]: [
    "coffee_chats_start_time",
    "coffee_chats_duration",
    "coffee_chats_representatives",
    "coffee_chats_representatives_emails",
    "coffee_chats_representatives_pronouns",
    "coffee_chats_hackers",
    "coffee_chats_favorited_hackers",
    "coffee_chats_status",
    "coffee_chats_due_date",
  ],
};

export type WebsiteLogoData = Pick<
  SponsorPerksData,
  | "website_logo_file"
  | "website_logo_website_link"
  | "website_logo_status"
  | "website_logo_due_date"
>;

export type WebsiteFeatureData = Pick<
  SponsorPerksData,
  | "website_feature_website_link"
  | "website_feature_link_description"
  | "website_feature_message"
  | "website_feature_status"
  | "website_feature_due_date"
>;

export type NewsletterOpportunityData = Pick<
  SponsorPerksData,
  | "newsletter_opportunity_link"
  | "newsletter_opportunity_name"
  | "newsletter_opportunity_status"
  | "newsletter_opportunity_due_date"
>;

export type NewsletterFeatureData = Pick<
  SponsorPerksData,
  | "newsletter_feature_image_file"
  | "newsletter_feature_title"
  | "newsletter_feature_website_link"
  | "newsletter_feature_description"
  | "newsletter_feature_status"
  | "newsletter_feature_due_date"
>;

export type NewsletterSpotlightData = Pick<
  SponsorPerksData,
  | "newsletter_spotlight_image_file"
  | "newsletter_spotlight_title"
  | "newsletter_spotlight_website_link"
  | "newsletter_spotlight_description"
  | "newsletter_spotlight_status"
  | "newsletter_spotlight_due_date"
>;

export type TechTalkProposalData = Pick<
  SponsorPerksData,
  | "tech_talk_proposal_title_one"
  | "tech_talk_proposal_title_two"
  | "tech_talk_proposal_description_one"
  | "tech_talk_proposal_description_two"
  | "tech_talk_proposal_due_date"
  | "tech_talk_proposal_status"
>;

export type TechTalkDetailsData = Pick<
  SponsorPerksData,
  | "tech_talk_details_time_one"
  | "tech_talk_details_time_two"
  | "tech_talk_details_time_three"
  | "tech_talk_details_representative"
  | "tech_talk_details_representative_name"
  | "tech_talk_details_representative_email"
  | "tech_talk_details_accepted_hackers_only"
  | "tech_talk_details_status"
  | "tech_talk_details_due_date"
>;

export type ApiPrizeData = Pick<
  SponsorPerksData,
  | "api_prize_title"
  | "api_prize_description"
  | "api_prize_judging_confirmation"
  | "api_prize_status"
  | "api_prize_due_date"
>;
export type ApiWorkshopData = Pick<
  SponsorPerksData,
  | "api_workshop_description"
  | "api_workshop_time_one"
  | "api_workshop_time_two"
  | "api_workshop_time_three"
  | "api_workshop_accepted_hackers_only"
  | "api_workshop_representative"
  | "api_workshop_representative_name"
  | "api_workshop_representative_email"
  | "api_workshop_status"
  | "api_workshop_due_date"
>;

export type LightningChallengeData = Pick<
  SponsorPerksData,
  | "lightning_challenge_description"
  | "lightning_challenge_time_one"
  | "lightning_challenge_time_two"
  | "lightning_challenge_time_three"
  | "lightning_challenge_representative"
  | "lightning_challenge_representative_name"
  | "lightning_challenge_representative_email"
  | "lightning_challenge_status"
  | "lightning_challenge_due_date"
>;

export type SponsorActivityData = Pick<
  SponsorPerksData,
  | "sponsor_an_activity_selection_one"
  | "sponsor_an_activity_selection_two"
  | "sponsor_an_activity_selection_three"
  | "sponsor_an_activity_status"
  | "sponsor_an_activity_due_date"
>;

export type SponsorSnackData = Pick<
  SponsorPerksData,
  | "sponsor_a_snack_name"
  | "sponsor_a_snack_time"
  | "sponsor_a_snack_status"
  | "sponsor_a_snack_due_date"
>;

export type SponsorMeetupData = Pick<
  SponsorPerksData,
  | "sponsor_a_meetup_name"
  | "sponsor_a_meetup_description"
  | "sponsor_a_meetup_time_one"
  | "sponsor_a_meetup_time_two"
  | "sponsor_a_meetup_time_three"
  | "sponsor_a_meetup_status"
>;

export type SelectTableLocationData = Pick<
  SponsorPerksData,
  | "select_table_location_one"
  | "select_table_location_two"
  | "select_table_location_three"
  | "select_table_location_status"
  | "select_table_location_due_date"
>;

export type SocialMediaPromotionData = Pick<
  SponsorPerksData,
  | "social_media_promotion_description"
  | "social_media_promotion_logo"
  | "social_media_promotion_status"
  | "social_media_promotion_due_date"
>;

export type NameAnAreaData = Pick<
  SponsorPerksData,
  | "name_an_area_one"
  | "name_an_area_two"
  | "name_an_area_status"
  | "name_an_area_due_date"
>;

export type WelcomeBookletBlurbData = Pick<
  SponsorPerksData,
  | "welcome_booklet_blurb_description"
  | "welcome_booklet_blurb_status"
  | "welcome_booklet_blurb_due_date"
>;

export type ShippedSwagData = Pick<
  SponsorPerksData,
  "shipped_swag_description" | "shipped_swag_status" | "shipped_swag_due_date"
>;

export type OpeningCeremoniesSlideData = Pick<
  SponsorPerksData,
  | "opening_ceremonies_slide_file"
  | "opening_ceremonies_slide_link"
  | "opening_ceremonies_slide_due_date"
  | "opening_ceremonies_slide_status"
>;

export type ClosingCeremoniesSlideData = Pick<
  SponsorPerksData,
  | "closing_ceremonies_slide_file"
  | "closing_ceremonies_slide_link"
  | "closing_ceremonies_slide_due_date"
  | "closing_ceremonies_slide_status"
>;

export type CoffeeChatsData = Pick<
  SponsorPerksData,
  | "coffee_chats_start_time"
  | "coffee_chats_duration"
  | "coffee_chats_representatives"
  | "coffee_chats_representatives_emails"
  | "coffee_chats_representatives_pronouns"
  | "coffee_chats_favorited_hackers"
  | "coffee_chats_hackers"
  | "coffee_chats_due_date"
  | "coffee_chats_status"
>;
