import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import {
  notNull,
  notBlank,
  isPhoneNumber,
  isEmail,
  isWaterlooIDNumber,
} from "src/shared/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.LEGAL_NAME,
  Field.PREFERRED_NAME,
  Field.PRONOUNS,
  Field.PHONE_NUMBER,
  Field.WATERLOO_STUDENT_ID_NUMBER,
  Field.EMERGENCY_CONTACT_NAME,
  Field.EMERGENCY_CONTACT_RELATIONSHIP,
  Field.EMERGENCY_CONTACT_PHONE_NUMBER,
  Field.EMERGENCY_CONTACT_EMAIL,
  Field.ACCESSIBILITY_NEEDS,
  Field.SHIRT_TYPE,
  Field.SHIRT_SIZE,
  Field.SWAG_SHIRT_ACCOMODATIONS,
  Field.FOOD_DIETARY_RESTRICTIONS,
  Field.PERSONAL_RSVP_STAGE,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.LEGAL_NAME]: notBlank,
  [Field.PHONE_NUMBER]: isPhoneNumber,
  [Field.WATERLOO_STUDENT_ID_NUMBER]: isWaterlooIDNumber,
  [Field.EMERGENCY_CONTACT_NAME]: notBlank,
  [Field.EMERGENCY_CONTACT_RELATIONSHIP]: notBlank,
  [Field.EMERGENCY_CONTACT_PHONE_NUMBER]: isPhoneNumber,
  [Field.EMERGENCY_CONTACT_EMAIL]: isEmail,
  [Field.SHIRT_TYPE]: notNull,
  [Field.SHIRT_SIZE]: notNull,
};

// based on https://data.team.hackthenorth.com/question/152
export const DEFAULT_CITIES = [
  "Waterloo, Ontario, Canada",
  "Toronto, Ontario, Canada",
  "Vancouver, British Columbia, Canada",
  "Ottawa, Ontario, Canada",
  "London, Ontario, Canada",
  "Hamilton, Ontario, Canada",
  "Montreal, Quebec, Canada",
];
