/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type UpdatePlatformIdMutationVariables = Types.Exact<{
  slackId: Types.Scalars["String"];
}>;

export type UpdatePlatformIdMutation = {
  updatePlatformId: Pick<Types.User, "event_platform_user_id">;
};

export const UpdatePlatformIdDocument = gql`
  mutation updatePlatformId($slackId: String!) {
    updatePlatformId(event_platform_id: $slackId) {
      event_platform_user_id
    }
  }
`;

/**
 * __useUpdatePlatformIdMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformIdMutation, { data, loading, error }] = useUpdatePlatformIdMutation({
 *   variables: {
 *      slackId: // value for 'slackId'
 *   },
 * });
 */
export function useUpdatePlatformIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlatformIdMutation,
    UpdatePlatformIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePlatformIdMutation,
    UpdatePlatformIdMutationVariables
  >(UpdatePlatformIdDocument, baseOptions);
}
export type UpdatePlatformIdMutationHookResult = ReturnType<
  typeof useUpdatePlatformIdMutation
>;
export type UpdatePlatformIdMutationResult =
  ApolloReactCommon.MutationResult<UpdatePlatformIdMutation>;
export type UpdatePlatformIdMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdatePlatformIdMutation,
    UpdatePlatformIdMutationVariables
  >;
