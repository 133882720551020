import { Text } from "@hackthenorth/north";
import { Button, Spinner, TextInput } from "north.js";
import React, { useEffect, useMemo, useState } from "react";
import "twin.macro";
import styled from "styled-components";

import { Header, PageWrapper } from "src/shared/components";

import { useFindEventAttendeeByEmailLazyQuery } from "./graphql/findEventAttendeeByEmail.generated";
import { useRegisterBadgeCodeToEventAttendeeMutation } from "./graphql/registerBadgeCodeToEventAttendee.generated";

export const Registration: React.FC = () => {
  const [email, setEmail] = useState("");
  const [badge, setBadge] = useState("");
  const [editingBadge, setEditingBadge] = useState(false);

  const [findEventAttendeeByEmail, { data, loading, error, refetch }] =
    useFindEventAttendeeByEmailLazyQuery({ notifyOnNetworkStatusChange: true });

  const [
    updateAttendee,
    { loading: updateAttendeeLoading, error: updateAttendeeError },
  ] = useRegisterBadgeCodeToEventAttendeeMutation();

  const attendee = useMemo(() => {
    setEditingBadge(false);
    return data?.findEventAttendeeByEmail || null;
  }, [data]);

  useEffect(() => {
    if (attendee) setBadge(attendee.badgeCode);
  }, [attendee]);

  return (
    <>
      <Header
        title="Scanner Tool"
        subtitle="Scan hackers to check them in during registration"
      />
      <PageWrapper>
        <Text mods="bold">Enter email address here:</Text>
        <EmailForm>
          <EmailInput
            size="md"
            placeholder="person@hackthenorth.com"
            error={error ? error.message : undefined}
            value={email}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                findEventAttendeeByEmail({ variables: { email } });
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <SearchButton
            color="primary"
            disabled={!email}
            size="md"
            onClick={() => findEventAttendeeByEmail({ variables: { email } })}
          >
            Search
          </SearchButton>
        </EmailForm>
        {!error && (
          <EmailCaption>
            <Text mods="caption">
              Please ensure this matches the email used to sign up.
            </Text>
          </EmailCaption>
        )}
        {loading ? (
          <Spinner />
        ) : (
          attendee && (
            <div>
              <Text mods="heading h3">
                {attendee.registrationData.preferredName
                  ? attendee.registrationData.legalName
                    ? attendee.registrationData.preferredName.split(" ")[0] +
                      " (" +
                      attendee.registrationData.legalName.split(" ")[0] +
                      ") " +
                      attendee.registrationData.legalName.split(" ")[1]
                    : attendee.registrationData.preferredName
                  : attendee.registrationData.legalName}
              </Text>
              <HackerInfoRow>
                <Text mods="bold">Role:</Text>
                <Text>
                  {attendee.role +
                    (attendee.claimStage && " - " + attendee.claimStage)}
                </Text>
              </HackerInfoRow>
              {attendee.registrationData.shirtSize && (
                <HackerInfoRow>
                  <Text mods="bold">T-shirt:</Text>
                  <Text>
                    {attendee.registrationData.shirtType +
                      " - " +
                      attendee.registrationData.shirtSize}
                    {attendee.registrationData.shirtAccommodations &&
                      " (" +
                        attendee.registrationData.shirtAccommodations +
                        ")"}
                  </Text>
                </HackerInfoRow>
              )}
              <HackerInfoRow>
                <Text mods="bold">HAPI ID:</Text>
                <Text>{attendee.userId}</Text>
              </HackerInfoRow>
              {updateAttendeeLoading ? (
                <Spinner />
              ) : (
                <div>
                  <HackerInfoRow>
                    <Text mods="bold">Badge ID:</Text>
                    {editingBadge ? (
                      <BadgeInput
                        size="md"
                        placeholder="Enter badge ID here"
                        error={updateAttendeeError?.message || undefined}
                        value={badge}
                        onKeyDown={async (e) => {
                          if (e.key === "Enter") {
                            try {
                              await updateAttendee({
                                variables: {
                                  event_attendee_id: attendee.eventAttendeeId,
                                  user_id: attendee.userId,
                                  badge_code: badge,
                                },
                              });
                              if (refetch) refetch();
                              setEditingBadge(false);
                            } catch {
                              console.log(
                                "uh oh, ",
                                updateAttendeeError?.message
                              );
                            }
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.includes("/")) {
                            setBadge(
                              e.target.value.slice(
                                e.target.value.lastIndexOf("/") + 1
                              )
                            );
                          } else {
                            setBadge(e.target.value);
                          }
                        }}
                      />
                    ) : (
                      <Text>{attendee.badgeCode || "N/A"}</Text>
                    )}
                  </HackerInfoRow>
                  <BadgeButtons>
                    {editingBadge ? (
                      <EditingBadgeButtons>
                        <Button
                          color="secondary"
                          size="md"
                          onClick={() => setEditingBadge(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          size="md"
                          onClick={async () => {
                            try {
                              await updateAttendee({
                                variables: {
                                  event_attendee_id: attendee.eventAttendeeId,
                                  user_id: attendee.userId,
                                  badge_code: badge,
                                },
                              });
                              if (refetch) refetch();
                              setEditingBadge(false);
                            } catch {
                              console.log(
                                "uh oh, ",
                                updateAttendeeError?.message
                              );
                            }
                          }}
                        >
                          Confirm
                        </Button>
                      </EditingBadgeButtons>
                    ) : (
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => setEditingBadge(true)}
                      >
                        Edit badge ID
                      </Button>
                    )}
                  </BadgeButtons>
                </div>
              )}
            </div>
          )
        )}
      </PageWrapper>
    </>
  );
};

const EmailForm = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
  display: flex;
  width: fit-content;
`;

const EmailInput = styled(TextInput)`
  margin-right: 24px;
  width: 500px;
`;

const BadgeInput = styled(TextInput)`
  width: 394px !important;
`;

const EmailCaption = styled.div`
  margin-left: 16px !important;
  margin-bottom: 48px !important;
`;

const SearchButton = styled(Button)`
  height: 38px !important;
`;

const HackerInfoRow = styled.div`
  color: #6b7280 !important;
  display: flex;
  align-items: center;
  height: 48px;
  p:first-child {
    width: 108px;
  }
`;

const EditingBadgeButtons = styled.div`
  margin-left: 106px !important;
  display: flex;
  button:first-child {
    margin-right: 16px !important;
  }
`;

const BadgeButtons = styled.div`
  margin-top: 24px;
`;
