import { Flex, JWTRole, Text } from "@hackthenorth/north";
import { Button, Label } from "north.js";
import React from "react";
import styled from "styled-components";
import { useClipboard } from "use-clipboard-copy";

import { getBadgeColor } from "src/shared/components/Sidebar";
import { successToast } from "src/shared/components/Toast";
import {
  isValidDiscordTag,
  isValidSocialMediaURL,
} from "src/shared/utils/validation";

type ScannedExtendedProfile = {
  fields_HACKTHENORTH2023_EXTENDED_PROFILES?: {
    answers?: {
      interests?: {
        value?: string | null;
      };
    };
  };
};

interface LinkTreeProps {
  role: string;
  name: string;
  bio: string;
  pronouns: string[];
  school: string;
  facebookLink: string;
  instagramLink: string;
  twitterLink: string;
  linkedinLink: string;
  discordTag: string;
  githubLink: string;
  tiktokLink: string;
  extendedProfile?: ScannedExtendedProfile | null;
}

const LinkTreeView = ({
  role,
  name,
  bio,
  pronouns,
  facebookLink,
  instagramLink,
  twitterLink,
  linkedinLink,
  githubLink,
  discordTag,
  tiktokLink,
  extendedProfile,
}: LinkTreeProps) => {
  const clipboard = useClipboard();
  return (
    <ProfileWrapper>
      <Flex column>
        <RoleLabel color={getBadgeColor(role as JWTRole)} size="md">
          {role.charAt(0).toUpperCase() + role.slice(1)}
        </RoleLabel>
      </Flex>
      <NameWrapper>
        <Text mods="heading h1 bold">{name}</Text>
        {pronouns.length !== 0 && pronouns[0] !== "" && (
          <div>
            <Text>(</Text>
            {pronouns?.map((pronoun, index) => (
              <Text key={pronoun}>
                {pronoun}
                {index !== pronouns?.length - 1 && ", "}
              </Text>
            ))}
            <Text>)</Text>
          </div>
        )}
      </NameWrapper>
      <SubtitleText>{bio}</SubtitleText>
      <SubduedHeader>INTERESTS</SubduedHeader>
      <SubtitleText>
        {extendedProfile?.fields_HACKTHENORTH2023_EXTENDED_PROFILES?.answers?.interests?.value
          ?.split(",")
          .map((item) => item.trim())
          .join(", ") || "None entered"}
      </SubtitleText>
      <Links>
        {instagramLink && isValidSocialMediaURL(instagramLink, "instagram") && (
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              Instagram
            </LinkButton>
          </a>
        )}
        {facebookLink && isValidSocialMediaURL(facebookLink, "facebook") && (
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              Facebook
            </LinkButton>
          </a>
        )}
        {twitterLink && isValidSocialMediaURL(twitterLink, "twitter") && (
          <a href={twitterLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              Twitter
            </LinkButton>
          </a>
        )}
        {linkedinLink && isValidSocialMediaURL(linkedinLink, "linkedin") && (
          <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              LinkedIn
            </LinkButton>
          </a>
        )}
        {githubLink && isValidSocialMediaURL(githubLink, "github") && (
          <a href={githubLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              Github
            </LinkButton>
          </a>
        )}
        {discordTag && isValidDiscordTag(discordTag) && (
          <LinkButton
            color="primary"
            size="lg"
            onClick={() => {
              clipboard.copy(discordTag);
              successToast("Copied Discord tag to clipboard!");
            }}
          >
            Discord Tag
          </LinkButton>
        )}
        {tiktokLink && isValidSocialMediaURL(tiktokLink, "tiktok") && (
          <a href={tiktokLink} target="_blank" rel="noopener noreferrer">
            <LinkButton color="primary" size="lg" onClick={() => {}}>
              TikTok
            </LinkButton>
          </a>
        )}
      </Links>
    </ProfileWrapper>
  );
};

const NameWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  p:first-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 5%;
  margin: auto;
  width: 336px;
  @media (max-width: 768px) {
    padding: 20% 24px;
  }
`;

const RoleLabel = styled(Label)`
  height: fit-content;
`;

const LinkButton = styled(Button)`
  width: 336px !important;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
  a {
    width: min-content;
  }
`;

const SubduedHeader = styled.p`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin: 20px 0 5px 0;
`;

const SubtitleText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`;

export default LinkTreeView;
