import { OrganizerTools } from "@hackthenorth/north";
import React, { useState, useEffect } from "react";

import { useUserContext } from "src/shared/contexts/UserContext";
import { IS_PRODUCTION } from "src/shared/utils/env";

const QA: React.FC = () => {
  const [component, setComponent] = useState<React.ReactElement | null>(null);
  const { isOrganizer } = useUserContext();
  useEffect(() => {
    if (!IS_PRODUCTION || isOrganizer) {
      setComponent(
        <span>
          <OrganizerTools
            organizerOnly={false}
            bugnub={{
              repoName: "my.hackthenorth.com",
              token: "9RQYZyziWGRIfGFZEkLNhlpskAt9T24D",
            }}
          />
        </span>
      );
    }
  }, [isOrganizer]);

  return component;
};

export default QA;
