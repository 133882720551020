/** Styles for Blueprint */
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { log, useAnalyticsSpan } from "@hackthenorth/analytics";
import { AuthProvider, North } from "@hackthenorth/north";
import { JWTRole, Button } from "@hackthenorth/north";
import { Worker as PDFWorker } from "@phuocng/react-pdf-viewer";
import React, { memo, useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import {
  BrowserRouter,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import UAParser from "ua-parser-js";
import { QueryParamProvider } from "use-query-params";

import { client } from "src/api/client";
import { Route, Sidebar, QA, GlobalStyles, Toast } from "src/shared/components";
import { BaseRoute, Route as RouteName } from "src/shared/constants/route";
import {
  UserContextProvider,
  PermissionsContextProvider,
  DataProvider,
  MentorContextProvider,
  NetworkingContextProvider,
} from "src/shared/contexts";
import {
  Permission,
  useUserContext,
  usePermissionsContext,
  SidebarContextProvider,
  useSidebarContext,
} from "src/shared/contexts";
import { useDeviceSize } from "src/shared/hooks";
// import { hideSidebarStyles } from "src/shared/contexts/SidebarContext/styles";
import { APP_ENV, IS_PRODUCTION } from "src/shared/utils/env";
import themeDef from "src/theme";
import NotFound from "src/views/404";
import { DocumentRoutes } from "src/views/documents/routes";
import AwSnap from "src/views/error/AwSnap";
import { HackerHomeRoutes } from "src/views/hacker/home/routes";
import { HackerRoutes } from "src/views/hacker/routes";
import HackerRsvpHeader, {
  RSVPHEADER_HEIGHT,
  RSVPHEADER_TABLET_HEIGHT,
} from "src/views/hacker/rsvp/header";
import HackerRsvpHome from "src/views/hacker/rsvp/home";
import HackerRsvpSidebar from "src/views/hacker/rsvp/rsvp-sidebar";
import { HelpRoutes } from "src/views/help/routes";
import { HomePage, ScheduleHomeRoutes } from "src/views/home";
import { MentorRoutes } from "src/views/mentor/routes";
import { PlaygroundRoutes } from "src/views/playground/routes";
// import { ScheduleRoutes } from "src/views/schedule/routes";
import { SponsorHomeRoutes } from "src/views/sponsor/home/routes";
import { SponsorRoutes } from "src/views/sponsor/routes";
// import { TicketRoutes } from "src/views/ticket/routes";
import { VolunteerRoutes } from "src/views/volunteer/routes";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { LinkAccountRoutes } from "./views/link-account/routes";
import MentorHome from "./views/mentor/home";
import "north.js/dist/style.css";
import Networking from "./views/networking";
import NetworkingResult from "./views/networkingResult";
import Settings from "./views/settings";
import Question from "./views/signage-hunt/pages/Question";
import { SignageHuntRoutes } from "./views/signage-hunt/routes";

const queryClient = new QueryClient();

Bugsnag.start({
  apiKey: "fcc22e94006ad0b4c21cf5bd345065c5",
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const MainContent = styled.div<{
  showNavSidebar: boolean;
  showRsvpHeader: string | boolean;
  showRsvpSidebar: string | boolean;
  sidebarWidth: number;
  isTabletOrSmaller: boolean;
}>`
  position: relative;
  top: ${({ showRsvpHeader, isTabletOrSmaller }) =>
    !showRsvpHeader
      ? 0
      : isTabletOrSmaller
      ? RSVPHEADER_TABLET_HEIGHT
      : RSVPHEADER_HEIGHT}px;
  left: ${({ sidebarWidth }) => sidebarWidth}px;
  height: calc(
    100vh -
      ${({ showRsvpHeader, isTabletOrSmaller }) =>
        !showRsvpHeader
          ? 0
          : isTabletOrSmaller
          ? RSVPHEADER_TABLET_HEIGHT
          : RSVPHEADER_HEIGHT}px
  );
  width: calc(100% - ${({ sidebarWidth }) => sidebarWidth}px);
`;

const INDICATOR_COLOR: string = {
  production: "green",
  staging: "yellow",
  latest: "red",
  development: "grey",
}[APP_ENV];

const Indicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  z-index: 1000;
  background-color: ${INDICATOR_COLOR};
`;

const EnvIndicator = memo(function EnvIndicator() {
  return IS_PRODUCTION ? null : <Indicator />;
});

const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

const AppRoutes: React.FC = () => {
  const { hasPermissions } = usePermissionsContext();

  // TODO: Cleanup and separate SponsorHome page
  const Home = () => {
    if (hasPermissions([Permission.HACKER_EVENT_ACCESS])) {
      return <HackerHomeRoutes />;
    } else if (hasPermissions([Permission.HACKER_RSVP])) {
      return <HackerRsvpHome />;
    } else if (hasPermissions([Permission.MENTOR])) {
      return <MentorHome />;
    } else if (hasPermissions([Permission.SPONSOR])) {
      return <SponsorHomeRoutes />;
    } else if (
      hasPermissions([Permission.VOLUNTEER]) ||
      hasPermissions([Permission.WORKSHOP_LEAD])
    ) {
      return <ScheduleHomeRoutes />;
    } else {
      return <HomePage />;
    }
  };

  return (
    <Routes>
      <Route path={BaseRoute.HOME} element={<Home />} />
      {/* <Route
        path={BaseRoute.SCHEDULE}
        element={<ScheduleRoutes />}
        requirePermissions={[Permission.VIEW_FULL_SCHEDULE]}
      /> */}
      {/* <Route
        path={BaseRoute.TICKET}
        element={<TicketRoutes />}
        requirePermissions={[
          Permission.SPONSOR,
          Permission.MENTOR,
          Permission.VOLUNTEER,
          Permission.HACKER_EVENT_ACCESS,
        ]}
        requireAll={false}
      /> */}
      <Route
        path={`${BaseRoute.PLAYGROUND}/*`}
        element={<PlaygroundRoutes />}
      />
      <Route
        path={`${BaseRoute.CONNECT_SLACK_ACCOUNT}/*`}
        element={<LinkAccountRoutes />}
        requireAuth={true}
      />
      <Route path={`${BaseRoute.DOCUMENTS}/*`} element={<DocumentRoutes />} />
      <Route path={`${BaseRoute.HELP}/*`} element={<HelpRoutes />} />
      <Route
        path={`${BaseRoute.SPONSOR}/*`}
        element={<SponsorRoutes />}
        requirePermissions={[Permission.SPONSOR]}
      />
      <Route
        path={`${BaseRoute.HACKER}/*`}
        element={<HackerRoutes />}
        requirePermissions={[Permission.HACKER]}
      />
      <Route
        path={`${BaseRoute.VOLUNTEER}/*`}
        element={<VolunteerRoutes />}
        requirePermissions={[Permission.VOLUNTEER]}
      />
      <Route
        path={`${BaseRoute.MENTOR}/*`}
        element={
          <MentorContextProvider>
            <MentorRoutes />
          </MentorContextProvider>
        }
        requirePermissions={[Permission.MENTOR]}
      />
      <Route
        path={`${BaseRoute.NETWORKING_RESULT}/*`}
        element={<NetworkingResult />}
        requireAuth={true}
      />
      <Route
        path={`${BaseRoute.SETTINGS}/*`}
        element={
          <NetworkingContextProvider>
            <Settings />
          </NetworkingContextProvider>
        }
        requireAuth={true}
      />
      <Route
        path={`${BaseRoute.NETWORKING}/*`}
        element={
          <NetworkingContextProvider>
            <Networking />
          </NetworkingContextProvider>
        }
        requireAuth={true}
      />
      <Route
        path={`${BaseRoute.SIGNAGE_HUNT}/*`}
        element={<SignageHuntRoutes />}
        requireAuth={true}
      />
      <Route
        path="/questions/:id"
        element={<Question />}
        requireAuth={true}
        requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppContent = () => {
  const [sidebarWidth, setSidebarWidth] = useState(0);
  useEffect(() => {
    const userAgent = new UAParser();

    const deviceType = userAgent.getDevice().type;

    /**
     * Possible device types: console, mobile, tablet, smarttv, wearable, embedded
     */
    log({
      identifier: deviceType ?? "desktop",
      duration: 0,
      startTime: 0,
      stream: "platform",
    });
    if (!("Notification" in window)) {
      console.debug("This browser does not support notifications.");
    } else {
      log({
        identifier: Notification?.permission,
        duration: 0,
        startTime: 0,
        stream: "notifications",
      });
    }
  }, []);

  // Function to calculate and update the sidebar width
  const updateSidebarWidth = () => {
    setSidebarWidth(document.getElementById("sidebar")?.clientWidth ?? 257);
  };

  useEffect(() => {
    // on window resize
    const handleResize = () => {
      updateSidebarWidth();
    };

    window.addEventListener("resize", handleResize);
    updateSidebarWidth(); // Set sidebar width on initial render
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Call updateSidebarWidth on every render to keep it updated
  useEffect(() => {
    updateSidebarWidth();
  });

  const { isAuthenticated, roles, logOut, isOrganizer } = useUserContext();
  const isTabletOrSmaller = useDeviceSize("tablet");

  const { showSidebar } = useSidebarContext();
  const location = useLocation();
  useAnalyticsSpan(location.pathname, "navigation");

  const checkHackerPathname = () => {
    switch (location.pathname) {
      case RouteName.HACKER_PERSONAL:
        return "Personal Information";
      case RouteName.HACKER_TRAVEL:
        return "Travel";
      case RouteName.HACKER_EVENT:
        return "Event Information";
      case RouteName.HACKER_CAREER:
        return "Career";
      case RouteName.HACKER_CONFIRMATION:
        return "Confirmation";
      case RouteName.HACKER_TRAVEL_DETAILS:
        return "Travel Details";
      default:
        return false;
    }
  };

  const shouldShowRsvpSidebar = !isTabletOrSmaller && checkHackerPathname();
  const shouldShowRsvpHeader = checkHackerPathname();

  // show if not hidden or is not mobile, and no RSVP sidebar
  const shouldShowSidebar =
    showSidebar && !isTabletOrSmaller && !shouldShowRsvpSidebar;

  const isUnauthorized =
    isAuthenticated &&
    !roles.includes(JWTRole.SPONSOR) &&
    !roles.includes(JWTRole.HACKER) &&
    !roles.includes(JWTRole.MENTOR) &&
    !roles.includes(JWTRole.VOLUNTEER) &&
    !roles.includes(JWTRole.WORKSHOP_LEAD) &&
    !isOrganizer;

  return isUnauthorized ? (
    <AwSnap
      error={
        new Error(
          "The attendee dashboard is currently only available to sponsors, hackers and mentors."
        )
      }
    >
      <Button onClick={logOut}>Log out</Button>
    </AwSnap>
  ) : (
    <Container className="app">
      <Toast />

      {shouldShowRsvpHeader ? (
        <HackerRsvpHeader currentRoute={shouldShowRsvpHeader} />
      ) : null}
      {shouldShowRsvpSidebar && (
        <HackerRsvpSidebar currentRoute={shouldShowRsvpSidebar} />
      )}
      {shouldShowSidebar && <Sidebar />}
      <MainContent
        showNavSidebar={shouldShowSidebar}
        showRsvpSidebar={shouldShowRsvpSidebar}
        showRsvpHeader={shouldShowRsvpHeader}
        sidebarWidth={isTabletOrSmaller ? 0 : sidebarWidth}
        isTabletOrSmaller={isTabletOrSmaller}
      >
        <AppRoutes />
      </MainContent>
    </Container>
  );
};

const App: React.FC = () => (
  <North themeDefinition={themeDef}>
    <PDFWorker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
              <ErrorBoundary FallbackComponent={AwSnap}>
                <AuthProvider production={IS_PRODUCTION}>
                  <UserContextProvider>
                    <DataProvider>
                      <PermissionsContextProvider>
                        <EnvIndicator />
                        <SidebarContextProvider>
                          <GlobalStyles />
                          <AppContent />
                        </SidebarContextProvider>
                        {!IS_PRODUCTION ? <QA /> : null}
                      </PermissionsContextProvider>
                    </DataProvider>
                  </UserContextProvider>
                </AuthProvider>
              </ErrorBoundary>
            </QueryParamProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ApolloProvider>
    </PDFWorker>
  </North>
);

export default App;
