import { DateTime } from "luxon";

import { THackerAPIDatetime } from "src/shared/utils/hackerapi";

// Formatting Guideline: https://moment.github.io/luxon/docs/manual/formatting.html
export const getShortDateString = (date: Date): string => {
  return `${date.toLocaleString("default", {
    month: "long",
  })} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
};

/**
 *
 * @param value A HackerAPI datetime string
 * @returns A string in the format of YYYY-MM-DD
 */
export const hackerAPIDateToString = (value: THackerAPIDatetime | null) => {
  if (value === null) return "";

  // Format: https://moment.github.io/luxon/#/parsing?id=table-of-tokens
  const res = DateTime.fromISO(value).toLocal().toFormat("yyyy-MM-dd");

  return res;
};

/**
 *
 * @param value A string in the format of YYYY-MM-DD
 * @returns A HackerAPI datetime string
 */
export const stringToHackerAPIDate = (
  value: string | null
): THackerAPIDatetime | null => {
  if (value === null || value === "") return null;

  const parsedDate = value.split("-");

  if (parsedDate.length !== 3) {
    throw new Error("Invalid date found. Please enter a correct date.");
  }

  // NOTE: We're assuming the input contains a correct date
  const res = DateTime.fromObject({
    year: parseInt(parsedDate[0]),
    month: parseInt(parsedDate[1]),
    day: parseInt(parsedDate[2]),
  })
    .toUTC()
    .toISO();

  if (!res) {
    throw new Error("Invalid date found. Please enter a correct date.");
  }

  return res;
};

/*
 *
 * @param ISO formatted date string
 * @returns Date object
 */
export const stringToDate = (value: string | null) =>
  value ? DateTime.fromISO(value).toLocal().toJSDate() : null;

/*
 *
 * @param Date object
 * @returns ISO formatted date string
 */
export const dateToString = (value: Date) =>
  DateTime.fromJSDate(value).toUTC().toISO();

export const dateToFormattedString = (value: Date) => {
  const easternDate = new Date(
    value.toLocaleString("en-US", {
      timeZone: "America/Toronto",
    })
  );

  const dateString = `${
    easternDate.getMonth() + 1
  }/${easternDate.getDate()} ${easternDate.getHours()}:${easternDate.getMinutes()} EDT`;

  return dateString;
};

export const formatDateForSubmit = (dateString: string) => {
  let dateValue = dateString;
  if (!isNaN(Date.parse(dateString))) {
    const convertedDate = new Date(dateString);
    convertedDate.setFullYear(2023);
    dateValue = convertedDate.toISOString();
  }
  return dateValue;
};
