import { Text } from "@hackthenorth/north";
import { Link } from "north.js";
import React from "react";

import { PageWrapper } from "src/shared/components";
import { Route } from "src/shared/constants/route";

const MentorHome: React.FC = () => {
  return (
    <PageWrapper>
      <Text mods="h2 heading">
        Thanks for signing up as a mentor for Hack the North!
      </Text>
      <Text>
        Make sure to create your{" "}
        <Link href={Route.MENTOR_PROFILE}>Mentor Profile</Link> and check out{" "}
        <Link href={Route.MENTOR_REQUESTS}>Open Requests</Link> to get started.
      </Text>
    </PageWrapper>
  );
};

export default MentorHome;
