import { Spacer, Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import "twin.macro";
import { TWText } from "src/shared/styles";

export enum SponsorOnboardingStage {
  START = "start",
  TERMS_AND_CONDITIONS = "terms_and_conditions",
  COMPLETED = "completed",
}

export type TSponsorOnboardingStageData = {
  stage: SponsorOnboardingStage;
  title: string;
  description: (...rest: any[]) => React.ReactNode | string;
  confirmation: React.ReactNode | string | null;
  submitBtnText?: string;
};

const TextContainer = styled(Text)`
  ${TWText.body}
  margin-bottom: 20px !important;
  display: block !important;
  line-height: 28px !important;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary} !important;
`;

const Container = styled.div`
  & > * {
    color: ${({ theme }) =>
      theme.globalConstants.color.textSecondary} !important;
  }

  ul {
    font-size: 16px;
  }
`;

const BoldText = styled.div`
  font-weight: 600;
`;

export const OnboardingSteps: TSponsorOnboardingStageData[] = [
  {
    stage: SponsorOnboardingStage.START,
    title: "Welcome!",
    description: (sponsorRepName: string) => (
      <Container>
        <TextContainer>
          Hello, {sponsorRepName}!
          <Spacer height={8} />
          Welcome to the Sponsor Dashboard! Once you complete onboarding, you
          will gain access to the rest of the dashboard. This is where you can
          find important dates, submit information for your perks, and gain
          access to perks as they become available.
        </TextContainer>
      </Container>
    ),
    confirmation: null,
    submitBtnText: "Let's get started!",
  },
  {
    stage: SponsorOnboardingStage.TERMS_AND_CONDITIONS,
    title: "Terms and Conditions",
    description: () => (
      <Container>
        <TextContainer>
          Welcome to Hack the North 2024! We’re pleased that you’re joining us
          as a sponsor. Please read, understand, and agree to the Terms and
          Conditions as well as the Code of Conduct below in order to
          participate in the event.
        </TextContainer>
        <ul>
          <li>
            You acknowledge that your participation in any component of Hack the
            North 2024, including but not limited to workshops, activities,
            digital platforms, project submission and judging (collectively, the
            “Event”) constitutes your full and unconditional agreement to and
            acceptance of these Terms and Conditions. The term “you” refers to
            you, the “Participant”.
          </li>
          <BoldText>Ownership: </BoldText>
          <li>
            You acknowledge that during the course of the Event, you may obtain
            access to products, developments, information and other materials
            belonging to other participants of the Event, the other sponsors of
            the Event (“Sponsors”), and/or other third parties (“Third Party
            Materials”), and that nothing in these Terms and Conditions is
            deemed to transfer any ownership, right, title or interest in such
            Third Party Materials to you. The attendees of the Event (“Hackers”)
            will own any developments they make during the Event, and all
            rights, title, and interest in those developments, including but not
            limited to the intellectual property rights therein and any
            developments created using Sponsor APIs. Your only rights to the
            Third Party Materials shall be those expressly granted to you by the
            owner(s) of the Third Party Materials, including but not limited to
            any materials using APIs or other software provided by Sponsors.
          </li>
          <BoldText>Media Release: </BoldText>
          <li>
            You acknowledge that the organizers of the Event (“Organizers”), the
            University of Waterloo, and Techyon (collectively, the “Releasees”),
            will be recording the Event on video, photographs, audio recording
            and other media, and therefore, agree that:
            <ul>
              <li>
                The Releasees have the unrestricted right to use your likeness,
                image, voice, opinions, and appearance, and also any images of
                your projects, developments, materials and belongings made at or
                brought to the Event, captured through video, photographs and/or
                other media during the Event for the express purpose of creating
                promotional material (the “Images”), for the purposes of use in
                websites, promotional materials, publications and/or other media
                of any of the Releasees, whether in print or electronically (the
                “Materials”). The foregoing right includes permission to
                copyright, use, re-use, publish, and republish Images in which
                you may be included, intact or in part, composite or distorted
                in character or form, without restriction as to changes or
                transformations, in conjunction with your own or a fictitious
                name, reproduction in color or otherwise, made through any and
                all media now or hereafter known;
              </li>
              <li>
                The Releasees shall solely own the Materials in which you or
                your Images, in whole or in part, may appear, including
                copyright interests, and you have no ownership rights therein;
              </li>
              <li>
                You give all clearances, copyright and otherwise, for use of
                your Images, and waive any moral rights that you may have in the
                Materials in which you or your Images may appear. The rights
                granted to the Releasees herein are perpetual and worldwide. For
                greater certainty, your Images may continue to be used after the
                completion of the Event;
              </li>
              <li>
                You relinquish any right that you may have to examine or approve
                the Materials in which you or your Images may appear or the use
                to which they may be applied; and
              </li>
              <li>
                You hereby release, discharge and agree to save harmless each
                and all of the Releasees from any liability by virtue of any
                blurring, distortion, alteration, optical illusion, or use in
                the composite form of the Images whether intentional or
                otherwise, that may occur or be produced in the recording of the
                Images or in any subsequent processing thereof, as well as any
                publication thereof, including without limitation any claims for
                libel or invasion of privacy.
              </li>
            </ul>
          </li>
          <BoldText>Submissions: </BoldText>
          <li>
            All software and hardware programs, designs, presentations, original
            ideas and intellectual property submitted by the Hackers
            (“Submissions”) are subject to the terms specified under “Ownership”
            in addition to the following clauses.
            <ul>
              <li>
                You may not use the materials presented to you in Submissions
                without permission expressly granted to you by the owner(s) of
                the Submission; and
              </li>
              <li>
                You may not distribute Submission materials in any capacity,
                including but not limited to recording or emailing without
                permission expressly granted to you by the owner(s) of the
                Submission.
              </li>
            </ul>
          </li>
          <li>
            You acknowledge that if you provide the Organizers with false or
            misleading information (such as your identity or status as a
            student), or otherwise contravene the rules and obligations set out
            in this document, you forfeit your right to participate in the Event
            and/or obtain any prizes from the Event. You agree that in such a
            case, the Organizers may pursue any remedy in law or equity to
            reclaim any prize or recover any damages occurring as a result of
            your contravention or misrepresentation.
          </li>
          <BoldText>General: </BoldText>
          <li>
            You must comply with all facility and equipment rules and
            requirements during your participation in the Event, including all
            safety instructions and requirements. You are not to take
            unreasonable risks while using the facilities where the Event is
            located, including causing any other person using such facilities an
            unreasonable risk of harm.
          </li>
          <li>
            You assume full responsibility for any damage or injury caused by
            you in your participation in the Event (whether to persons or
            property, and whether to yourself or others) and release the
            University of Waterloo, Techyon, other sponsors of Hack the North
            2024, Hack the North volunteers, University of Waterloo staff,
            guests of Hack the North 2024 and all other participants in Hack the
            North 2024 from any liability therefore.
            <Spacer height={10} />
            <BoldText>
              YOU ARE AWARE THAT YOUR PARTICIPATION IN HACK THE NORTH 2024 IS
              SOLELY AT YOUR OWN RISK, AND THAT THE TERMS AND CONDITIONS ARE
              INTENDED TO REFLECT THAT UNDERSTANDING.
            </BoldText>
            <Spacer height={10} />
          </li>
          <li>
            Any Organizer of the Event reserves the right to individually, at
            their discretion and without limitation, remove any participant(s)
            from the Event with immediate effect, for any reason, including,
            without limitation, any real or perceived violation of these Terms
            and Conditions, the Hack the North Code of Conduct, any other
            applicable Codes of Conduct, or laws in any applicable jurisdiction.
          </li>
        </ul>
        <TextContainer>
          <BoldText>Release</BoldText>
        </TextContainer>
        <TextContainer>
          For valuable consideration, including permission to take part in the
          Event, the undersigned on behalf of him/herself, his or her personal
          representatives, assigns, heirs and next of kin:
        </TextContainer>
        <ul>
          <li>
            hereby covenants not to sue, and releases, waives, and discharges
            the Releasees, their owners, officers, agents, affiliates,
            employees, volunteers, and/or any other person or entity in any way
            associated with the Event, from liability for any injury to the
            person or property or death of the undersigned arising out of or
            related to use of the premises, equipment, or other facilities of
            the Releasees, whether caused by an act of negligence of the
            Releasees or otherwise; and
          </li>
          <li>
            hereby assumes full responsibility for any risk of bodily injury,
            death or property damage arising out of or related to their
            participation in the Event, whether occurring to the undersigned or
            to any other person or entity for whom the undersigned is
            responsible or with whom the undersigned is associated, and whether
            caused by an act of negligence of the Releasees or otherwise. The
            foregoing release includes, but is not limited to, any occurrences
            of personal injury, illness (including COVID-19, food-borne, or
            otherwise), and loss of belongings, whether by theft or otherwise.
            The undersigned further agrees that this instrument (the terms of
            which collectively are referred to as the Agreement) is intended to
            be as broad and inclusive as is permitted by the laws of the
            Province of Ontario and that if any portion thereof is held invalid,
            that portion shall be invalid only to the extent required by law,
            and the balance shall, notwithstanding, continue in full force and
            effect.
          </li>
        </ul>
        <TextContainer>
          <BoldText>
            I have completely read and understand the Terms and Conditions and
            Code of Conduct (the “Agreement”) and its terms, and agree to comply
            with all rules and obligations set forth in this Agreement. I
            understand that I have given up substantial rights by signing it,
            and have signed it freely and voluntarily without inducement,
            assurance or guarantee being made to me. I confirm that I have the
            right to enter into this Agreement, that I am not restricted by any
            commitments to any other parties, and that none of the Organizers
            have any financial commitment or obligations to me as a result of
            this Agreement or the use of my Images. Prior to signing this
            Agreement, I have had the opportunity to ask any questions about
            this Agreement and Hack the North 2024.
          </BoldText>
        </TextContainer>
      </Container>
    ),
    confirmation: (
      <Container>
        By checking this box, I agree to the terms and conditions that have been
        stated above.
      </Container>
    ),
    submitBtnText: "Submit",
  },
  {
    stage: SponsorOnboardingStage.COMPLETED,
    title: "Onboarding complete!",
    description: () => (
      <Container>
        <TextContainer>
          You’re all set!
          <Spacer height={8} />
          Head to the Perks page to view your available perks and submit
          information.
        </TextContainer>
      </Container>
    ),
    confirmation: null,
    submitBtnText: "Done",
  },
];
