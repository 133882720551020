/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpdateSponsorIndividualMutationVariables = Types.Exact<{
  first_name?: Types.Maybe<Types.Scalars["String"]>;
  last_name?: Types.Maybe<Types.Scalars["String"]>;
  preferred_name?: Types.Maybe<Types.Scalars["String"]>;
  role_in_company?: Types.Maybe<Types.Scalars["String"]>;
  email: Types.Scalars["String"];
}>;

export type UpdateSponsorIndividualMutation = {
  updateSponsorIndividual: Pick<Types.SponsorIndividual, "id">;
};

export const UpdateSponsorIndividualDocument = gql`
  mutation updateSponsorIndividual(
    $first_name: String
    $last_name: String
    $preferred_name: String
    $role_in_company: String
    $email: String!
  ) {
    updateSponsorIndividual(
      data: {
        first_name: { set: $first_name }
        last_name: { set: $last_name }
        preferred_name: { set: $preferred_name }
        role_in_company: { set: $role_in_company }
      }
      where: { email: $email }
    ) {
      id
    }
  }
`;

/**
 * __useUpdateSponsorIndividualMutation__
 *
 * To run a mutation, you first call `useUpdateSponsorIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsorIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsorIndividualMutation, { data, loading, error }] = useUpdateSponsorIndividualMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      preferred_name: // value for 'preferred_name'
 *      role_in_company: // value for 'role_in_company'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateSponsorIndividualMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSponsorIndividualMutation,
    UpdateSponsorIndividualMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSponsorIndividualMutation,
    UpdateSponsorIndividualMutationVariables
  >(UpdateSponsorIndividualDocument, baseOptions);
}
export type UpdateSponsorIndividualMutationHookResult = ReturnType<
  typeof useUpdateSponsorIndividualMutation
>;
export type UpdateSponsorIndividualMutationResult =
  ApolloReactCommon.MutationResult<UpdateSponsorIndividualMutation>;
export type UpdateSponsorIndividualMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateSponsorIndividualMutation,
    UpdateSponsorIndividualMutationVariables
  >;
