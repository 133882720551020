import { useState, useCallback, useRef } from "react";

import { DropzoneError } from "src/shared/components";
import { useGetFile } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import { HackerAPIFile } from "src/shared/utils/hackerapi";

export const useFileDropzone = (
  file: HackerAPIFile | undefined,
  setFile: (files: HackerAPIFile[] | undefined) => void
) => {
  const {
    uploadFile,
    mutationResult: { loading: isFileUploading, error: fileUploadError },
  } = useUploadFile();
  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState("");
  const dropzoneInputRef = useRef<HTMLInputElement>();
  const fetchedFile = useGetFile(file);

  const error = dropzoneErrorMessage || fileUploadError?.message;
  const loading = isFileUploading;

  const addFile = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const hapiFile = await uploadFile(file);

      if (hapiFile) setFile([hapiFile]);
    }
  };

  const deleteFile = () => {
    setFile(undefined);
    if (dropzoneInputRef.current) dropzoneInputRef.current.value = "";
  };

  const onFileError = useCallback((error: DropzoneError) => {
    switch (error) {
      case DropzoneError.MAX_AMOUNT:
        setDropzoneErrorMessage("Please upload a single file.");
        return;
      case DropzoneError.INVALID_FORMAT:
        setDropzoneErrorMessage(
          "Please upload a valid file format (SVG or PNG)."
        );
        return;
      default:
        setDropzoneErrorMessage("An error has occurred.");
    }
  }, []);

  return {
    dropzoneInputRef,
    fetchedFile,
    loading,
    error,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  };
};
