import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { WebsiteFeatureData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import { PerkContainer, PerkTextArea, PerkTextInput } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const WEBSITE_FEATURE_VALIDATION_FIELDS: (keyof WebsiteFeatureData)[] = [
  "website_feature_message",
  "website_feature_website_link",
  "website_feature_link_description",
];

export const WebsiteFeature: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => (
  <>
    <Header
      title="Website Feature"
      subtitle="This perk is for the information that will be displayed in the sponsorship showcase section of the Hack the North website."
      badge={
        <Label
          color={perkStatusToBadgeColor(
            (getState("website_feature_status") ?? []) as PerkStatus
          )}
          size="sm"
        >
          {getState("website_feature_status")}
        </Label>
      }
    />
    <PerkContainer
      description={<Description />}
      id={SponsorPerkType.WEBSITE_FEATURE}
      readOnly={isReadOnly}
    >
      <PerkTextInput
        title="Website Link*"
        value={getState("website_feature_website_link") ?? ""}
        placeholder="Your company's home or recruiting page"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("website_feature_website_link", e.target.value)
        }
        error={
          error?.website_feature_website_link && <>This field is required</>
        }
      />
      <Spacer height={32} />
      <PerkTextInput
        title="Description of Link (ex. Careers Page)*"
        value={getState("website_feature_link_description") ?? ""}
        placeholder="Ex. Careers page"
        isReadOnly={isReadOnly}
        onChange={(e) =>
          updateState("website_feature_link_description", e.target.value)
        }
        error={
          error?.website_feature_link_description && <>This field is required</>
        }
      />
      <Spacer height={32} />
      <PerkTextArea
        title="Sponsor Message* (max 150 words)"
        value={getState("website_feature_message") ?? ""}
        placeholder="Share a message with hackers"
        wordLimit={150}
        isReadOnly={isReadOnly}
        onChange={(e) => updateState("website_feature_message", e.target.value)}
        error={error?.website_feature_message && <>This field is required</>}
      />
      <Spacer height={48} />
    </PerkContainer>
  </>
);

const Description = () => (
  <TextDescription>
    Along with displaying your logo on our website, we will also feature your
    company in a special showcase area within{" "}
    <Link target="_blank" href="https://hackthenorth.com/#sponsors">
      hackthenorth.com/#sponsors
    </Link>
    . Past examples can be found at{" "}
    <Link target="_blank" href="https://2023.hackthenorth.com/#sponsors">
      https://2023.hackthenorth.com/#sponsors
    </Link>
    .
  </TextDescription>
);
