import {
  AcceptedFileType,
  ImageFileType,
  VideoFileType,
  PresentationFileType,
} from "./types";

export const IMAGE_FILE_TYPES: ImageFileType[] = [
  "svg",
  "png",
  "jpg",
  "jpeg",
  "pdf",
];
export const VIDEO_FILE_TYPES: VideoFileType[] = ["flac", "mp4", "ogg", "webm"];
export const PRESENTATION_FILE_TYPES: PresentationFileType[] = ["pptx", "pdf"];

export const ALL_FILE_TYPES: AcceptedFileType[] = [
  ...IMAGE_FILE_TYPES,
  ...VIDEO_FILE_TYPES,
  ...PRESENTATION_FILE_TYPES,
];
