import { Spacer } from "@hackthenorth/north";
import { Link, Label } from "north.js";
import React, { useState } from "react";

import { Dropzone, FileLink, Spinner } from "src/shared/components";
import { ClosingCeremoniesSlideData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkTextInput } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { useFileDropzone } from "../useFileDropzone";
import { PerkData } from "../usePerkState";

export const CLOSING_CEREMONIES_SLIDE_VALIDATION_FIELDS: (keyof ClosingCeremoniesSlideData)[] =
  [];

export const ClosingCeremoniesSlide: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const {
    dropzoneInputRef,
    fetchedFile,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(getState("closing_ceremonies_slide_file")?.[0], (files) =>
    updateState("closing_ceremonies_slide_file", files)
  );
  const [spinner, setSpinner] = useState(false);

  return (
    <>
      <Header
        title="Closing Ceremonies Slides"
        subtitle="This perk is for stage time during our closing ceremonies."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              getState("closing_ceremonies_slide_status") as PerkStatus
            )}
            size="sm"
          >
            {getState("closing_ceremonies_slide_status")}
          </Label>
        }
      />
      {spinner ? (
        <Spinner text="Uploading file" />
      ) : (
        <PerkContainer
          description={<Description />}
          id={SponsorPerkType.CLOSING_CEREMONIES_SLIDE}
          error={!!error}
          readOnly={isReadOnly}
        >
          {!isReadOnly && (
            <Dropzone
              max={1}
              onFilesUpload={async (e) => {
                setSpinner(true);
                await addFile(e);
                setSpinner(false);
              }}
              onError={onFileError}
              ref={dropzoneInputRef}
              onBlur={() => setDropzoneErrorMessage("")}
            />
          )}
          {fetchedFile && (
            <>
              {!isReadOnly && <Spacer height={32} />}
              <FileLink file={fetchedFile} deleteFile={deleteFile} />
            </>
          )}
          {fileError && <PerkError error={fileError} />}
          <Spacer height={32} />
          <PerkTextInput
            title={
              <>
                Closing Ceremonies Slide Link{" "}
                <Label color="secondary-light" size="sm">
                  Optional
                </Label>
              </>
            }
            value={getState("closing_ceremonies_slide_link") ?? ""}
            placeholder="Link to your slides here"
            isReadOnly={isReadOnly}
            onChange={(e) =>
              updateState("closing_ceremonies_slide_link", e.target.value)
            }
          />
        </PerkContainer>
      )}
    </>
  );
};

const Description = () => (
  <TextDescription>
    As a sponsor, you&apos;ll have the opportunity to briefly present on stage
    during our closing ceremony.{" "}
    <Link
      href="https://www.youtube.com/watch?v=NJbKtYZCk4U&feature=youtu.be"
      target="_blank"
    >
      Here
    </Link>{" "}
    is a recording of the Hack the North 2019 opening ceremony, containing many
    examples of previous sponsor presentations. We kindly ask that gold sponsors
    limit their stage time to a maximum of 3 minutes and silver to 1 minute.
    Please upload your slide deck in .pptx format here to be used during the
    closing ceremony. (optional)
  </TextDescription>
);
