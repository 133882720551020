import { Link } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

const TRUNCATE_LENGTH = 150;

type DescriptionTextProps = {
  text: string;
};

const DescriptionText: React.FC<DescriptionTextProps> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      {text.length > TRUNCATE_LENGTH ? (
        isExpanded ? (
          <HelperText>
            {text}{" "}
            <HelperTextLink inverted onClick={() => setIsExpanded(false)}>
              Read less
            </HelperTextLink>
          </HelperText>
        ) : (
          <HelperText>
            {text.substring(0, TRUNCATE_LENGTH)}
            ...
            <HelperTextLink inverted onClick={() => setIsExpanded(true)}>
              Read more
            </HelperTextLink>
          </HelperText>
        )
      ) : (
        <HelperText>{text}</HelperText>
      )}
    </>
  );
};

const HelperText = styled.p`
  margin-top: 4px;
  margin-left: 28px;
  font-size: 14px;
  font-weight: 400;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
`;

const HelperTextLink = styled(Link)`
  font-size: 14px !important;
  font-weight: 700;
  text-decoration: none;
  color: ${({ theme }) => theme.globalConstants.color.textPrimary} !important;
`;
export default DescriptionText;
