import { Flex } from "@hackthenorth/north";
import React from "react";
import "twin.macro";
import styled from "styled-components";

import Icon, { IconName } from "src/shared/components/Icon";

import { useSponsorPerksContext } from "../../SponsorPerksContext";
import { PerkStatus, PERKS_TO_DUE_DATES } from "../../types";
import { hackerAPIDateToDueDate, isInFiveDays } from "../Perk/helpers";

import { Label } from "north.js";
import "styled-components/macro";

interface Props {
  perksArr: {
    perkSlug: string;
    perkName: string;
    iconName: IconName;
    dueDate: string;
    status: PerkStatus;
  }[];
  updateSelectedPerk: (perk: string) => void;
  selectedPerk: string | null;
}

const PerkList: React.FC<Props> = ({
  perksArr,
  updateSelectedPerk,
  selectedPerk,
}) => {
  const { perks } = useSponsorPerksContext();
  return (
    <Container>
      <PerkListContainer>
        {perksArr.map(
          ({ perkSlug, perkName, iconName, dueDate, status }, idx) => {
            const dueDateSlug = PERKS_TO_DUE_DATES[perkSlug];
            const isUrgent = isInFiveDays(
              perks && perks[dueDateSlug as any]?.value
            );
            const dueDateLabel = hackerAPIDateToDueDate(
              perks && perks[dueDateSlug as any]?.value
            );
            const dueDateBadge = isUrgent ? `Due ${dueDateLabel}` : ``;
            return (
              <StyledButton
                onClick={() => updateSelectedPerk(perkSlug)}
                key={perkSlug}
                onKeyDown={() => updateSelectedPerk(perkSlug)}
                tabIndex={idx}
              >
                <PerkItem selected={perkSlug === selectedPerk}>
                  <Icon size="18px" name={iconName} tw="flex-shrink-0" />
                  {perkName}
                  {isUrgent && status === PerkStatus.INCOMPLETE && (
                    <Label color="danger-light" size="sm">
                      {dueDateBadge}
                    </Label>
                  )}
                </PerkItem>
              </StyledButton>
            );
          }
        )}
      </PerkListContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 25px;
  &:focus {
    outline: none;
  }
`;

const StyledButton = styled.button`
  &:focus {
    outline: none;
  }
`;

const PerkListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
`;

const PerkItem = styled(Flex).attrs({
  row: true,
  align: "center",
  justify: "start",
})<{ isSelected: boolean }>`
  padding: 8px 50px;
  color: ${({ theme, selected }) =>
    selected
      ? theme.globalConstants.color.textBody
      : theme.globalConstants.color.textSecondary};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.globalConstants.color.backgroundGrayDark
      : theme.globalConstants.color.white};
  font-size: 16px;
  font-weight: ${({ selected }) => (selected ? 500 : 400)};
  line-height: 22px;
  cursor: pointer;
  border: none !important;
  column-gap: 10px;
  &::-webkit-scrollbar {
    display: none !important;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default PerkList;
