import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";

const SHARE_ID = "8881b82d-2ef4-485a-b720-7e135d091f6e";

// TODO: Change to modal
const CodeOfConduct: React.FC = () => {
  return (
    <PageWrapper>
      <OutlineDocument shareId={SHARE_ID} />
    </PageWrapper>
  );
};
export default CodeOfConduct;
