import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { notBlank } from "src/shared/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.LOCATION,
  Field.TRAVEL_OPTION,
  Field.ENTRY_ELIGIBILITY_ACKNOWLEDGEMENT,
  Field.REQUIRES_VISA,
  Field.VISA_ACKNOWLEDGEMENT,
  Field.TRAVEL_RSVP_STAGE,
  Field.PERSONAL_RSVP_STAGE,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.LOCATION]: notBlank,
  [Field.TRAVEL_OPTION]: notBlank,
};

// based on https://data.team.hackthenorth.com/question/152
export const DEFAULT_CITIES = [
  "Waterloo, Ontario, Canada",
  "Toronto, Ontario, Canada",
  "Vancouver, British Columbia, Canada",
  "Ottawa, Ontario, Canada",
  "London, Ontario, Canada",
  "Hamilton, Ontario, Canada",
  "Montreal, Quebec, Canada",
];

export const TRAVEL_TYPE_OPTIONS = [
  "Flight",
  "Hack the North provided bus",
  "Drive",
  "Walk",
  "Other",
];

export const TRAVEL_TYPE_MAP = {
  Flight: "flight",
  "Hack the North provided bus": "bus",
  Drive: "drive",
  Walk: "walk",
  Other: "other",
};

export const TRAVEL_TYPE_MAP_REVERSE = {
  null: null,
  flight: "Flight",
  bus: "Hack the North provided bus",
  drive: "Drive",
  walk: "Walk",
  other: "Other",
};
