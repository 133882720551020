import { Button, Flex, JWTRole, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import moment from "moment";
import {
  Button as ButtonNew,
  Card,
  Checkbox,
  DropdownMenu,
  Label,
  RadioButton,
  Spinner,
  TextArea,
  TextInput,
} from "north.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Divider,
  Header,
  Icon,
  PageWrapper,
  TagInput,
} from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { getBadgeColor } from "src/shared/components/Sidebar";
import { errorToast } from "src/shared/components/Toast";
import { BaseRoute } from "src/shared/constants/route";
import {
  useHackerContext,
  useNetworkingContext,
  useUserContext,
} from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import * as Networking from "src/shared/contexts/NetworkingContext/types";

import LinkTreeView from "../networkingResult/LinkTreeView";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";
import { useFetchNetworkingScanLazyQuery } from "./graphql/fetchNetworkingScan.generated";
import { useGetUserNetworkingScansQuery } from "./graphql/getUserNetworkingScans.generated";

const Settings = () => {
  const navigate = useNavigate();
  const maxTagAmount = 7;
  let interestsSplit: string[] = [];

  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const {
    extendedProfile,
    updateExtendedProfile,
    isLoading: isExtendedProfileLoading,
    refetchExtendedProfile,
  } = useNetworkingContext();

  const [localExtendedProfile, setLocalExtendedProfile] =
    useState<Partial<Networking.ExtendedProfile> | null>(extendedProfile);
  if (localExtendedProfile?.interests) {
    interestsSplit = localExtendedProfile?.interests?.split(",");
  }
  const Options: string[] = interestsSplit;
  const [addTag, setAddTag] = useState(false);
  const [prevTags, setPrevTags] = useState<string[]>(interestsSplit);
  const [maxCharError, setMaxCharError] = useState<string[]>([]);

  const setExtendedProfile = (field: string, value: string) => {
    setLocalExtendedProfile((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const { roles } = useUserContext();
  const [isEditing, setIsEditing] = useState(false);

  const { data, loading, refetch } = useGetUserNetworkingScansQuery({
    variables: {},
  });

  useEffect(() => {
    const id = setInterval(refetch, 300000);
    return () => clearInterval(id);
  }, [refetch]);

  const [filter, setFilter] = useState<string[]>([]);
  const [directionFilter, setDirectionFilter] = useState("");

  const [showModal, setShowModal] = useState(false);

  const toggleFilter = (category: string) => {
    if (filter.includes(category)) {
      setFilter(filter.filter((cat) => cat !== category));
    } else {
      setFilter([...filter, category]);
    }
  };

  const [
    fetchNetworkingScan,
    { data: fetchScanData, loading: fetchScanLoading, error: fetchScanError },
  ] = useFetchNetworkingScanLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const scans = data?.getUserNetworkingScans
    .filter((scan) => !scan.is_duplicate)
    .filter((scan) =>
      filter.length && scan.other_attendee_role
        ? filter.includes(scan.other_attendee_role)
        : true
    )
    .filter((scan) =>
      directionFilter
        ? (directionFilter === "Scanned you" && scan.got_scanned) ||
          (directionFilter === "You scanned" && !scan.got_scanned)
        : true
    );

  const rolesfilter = [
    [
      {
        content: (
          <Checkbox
            checked={filter.includes("hacker")}
            onChange={() => toggleFilter("hacker")}
            label="Hacker"
          />
        ),
        action: () => {
          toggleFilter("hacker");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("volunteer")}
            onChange={() => toggleFilter("volunteer")}
            label="Volunteer"
          />
        ),
        action: () => {
          toggleFilter("volunteer");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("mentor")}
            onChange={() => toggleFilter("mentor")}
            label="Mentor"
          />
        ),
        action: () => {
          toggleFilter("mentor");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("workshop_lead")}
            onChange={() => toggleFilter("workshop_lead")}
            label="Workshop Lead"
          />
        ),
        action: () => {
          toggleFilter("workshop_lead");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("organizer")}
            onChange={() => toggleFilter("organizer")}
            label="Organizer"
          />
        ),
        action: () => {
          toggleFilter("organizer");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("sponsor")}
            onChange={() => toggleFilter("sponsor")}
            label="Sponsor"
          />
        ),
        action: () => {
          toggleFilter("sponsor");
        },
      },
    ],
  ];

  const toggleDirection = (dir: string) => {
    if (directionFilter === dir) setDirectionFilter("");
    else setDirectionFilter(dir);
  };

  const directions = [
    [
      {
        content: (
          <RadioButton
            checked={directionFilter === "You scanned"}
            name="direction"
            onChange={() => toggleDirection("You scanned")}
            label="You scanned"
          />
        ),
        action: () => {
          toggleDirection("You scanned");
        },
      },
      {
        content: (
          <RadioButton
            checked={directionFilter === "Scanned you"}
            name="direction"
            onChange={() => toggleDirection("Scanned you")}
            label="Scanned you"
          />
        ),
        action: () => {
          toggleDirection("Scanned you");
        },
      },
    ],
  ];

  const { updateResponses, isLoading } = useHackerContext();

  const isPageLoading = isLoading || isExtendedProfileLoading;

  useEffect(() => {
    if (!isPageLoading) {
      if (localExtendedProfile !== extendedProfile) {
        setLocalExtendedProfile(extendedProfile);
      }
    }
    // eslint-disable-next-line
  }, [extendedProfile]);

  const onSave = async () => {
    if (isValid) {
      try {
        const responses = {
          ...responsesState,
        };

        const { errors } = await updateResponses(responses);

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        const { errors: networkingDataError } = await updateExtendedProfile(
          localExtendedProfile
        );

        if (networkingDataError) {
          throw new Error(
            "We weren't able to submit your interests, please try again."
          );
        }
        refetchExtendedProfile?.();
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e.message);
        }
      }
    }
  };

  const splitPronouns = new RegExp("\\s*,\\s*");

  if (roles.includes(JWTRole.SPONSOR)) {
    return (
      <>
        <Modal
          isOpen
          title="Access denied"
          actions={
            <Button mods="primary" onClick={() => navigate(BaseRoute.HOME)}>
              Go home
            </Button>
          }
        >
          <Text>You are not permitted to view this page.</Text>
        </Modal>
        <PageWrapper pageTitle="" />
      </>
    );
  }

  const splitInterests = () => {
    let joinArrayOfInterests;
    if (localExtendedProfile?.interests) {
      const arrayOfInterests = localExtendedProfile.interests.split(",");
      joinArrayOfInterests = arrayOfInterests.join(", ");
    }
    return joinArrayOfInterests;
  };

  return (
    <>
      <Header
        title="Settings"
        subtitle="Edit your general or networking settings, as well as view what sponsors and hackers you've connected with."
      />
      <SettingsWrapper>
        <MobileModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title=""
        >
          {fetchScanLoading && <Spinner />}
          {fetchScanData?.fetchNetworkingScan.hackerNetworkingPayload && (
            <LinkTreeView
              {...fetchScanData.fetchNetworkingScan.hackerNetworkingPayload}
            />
          )}
        </MobileModal>
        <ProfileWrapper>
          <ProfileHeaderWrapper>
            <ProfileHeader>
              <Text mods="heading h3 bold">Profile information</Text>
              {!isEditing && (
                <ButtonNew
                  color="tertiary"
                  size="md"
                  onClick={() => setIsEditing(true)}
                >
                  Edit <Icon name="edit" size="h3" />
                </ButtonNew>
              )}
            </ProfileHeader>
            {isEditing && (
              <Text>This info will appear on your public profile.</Text>
            )}
          </ProfileHeaderWrapper>
          {isPageLoading ? (
            <Spinner />
          ) : (
            <>
              <InputRow>
                <HalfCol>
                  <Text mods="heading h4 bold">Preferred Name</Text>
                  {isEditing ? (
                    <FullInput
                      size="md"
                      placeholder={`Enter your preferred name`}
                      error={
                        !isValid ? "This field cannot be left empty" : undefined
                      }
                      value={responsesState.rsvp_preferred_name || ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setResponsesState(Field.PREFERRED_NAME, e.target.value);
                      }}
                    />
                  ) : (
                    <Text>{responsesState.rsvp_preferred_name}</Text>
                  )}
                </HalfCol>
                <HalfCol>
                  <Text mods="heading h4 bold">Pronouns</Text>
                  {isEditing ? (
                    <FullInput
                      size="md"
                      maxLength={50}
                      placeholder={`Enter your preferred pronouns`}
                      value={
                        responsesState.rsvp_preferred_pronouns?.join(", ") || ""
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setResponsesState(
                          Field.PRONOUNS,
                          e.target.value.trim().split(splitPronouns)
                        );
                      }}
                    />
                  ) : (
                    <Text>
                      {responsesState.rsvp_preferred_pronouns?.join(", ")}
                    </Text>
                  )}
                </HalfCol>
              </InputRow>
              <Row>
                <Text mods="heading h4 bold">Biography</Text>
                {isEditing ? (
                  <TextArea
                    fullWidth
                    maxLength={200}
                    placeholder={`Enter your biography`}
                    value={responsesState.networking_biography || ""}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setResponsesState(Field.BIOGRAPHY, e.target.value);
                    }}
                  />
                ) : (
                  <Text>{responsesState.networking_biography}</Text>
                )}
              </Row>
              <Row>
                <Text mods="heading h4 bold">Interests</Text>
                {isEditing ? (
                  <>
                    <Text>Add up to 7 interests.</Text>
                    <TagWrapper>
                      <TagInput
                        multi
                        max={maxTagAmount}
                        adding={addTag}
                        setAdding={setAddTag}
                        options={Options}
                        selectedOptions={Options}
                        setSelectedOptions={(newSelection) => {
                          if (
                            newSelection[newSelection.length - 1] &&
                            newSelection[newSelection.length - 1].length > 20
                          ) {
                            setMaxCharError([
                              "Please enter 20 characters or less.",
                            ]);
                          } else {
                            setMaxCharError([]);
                            const joinedInterests = newSelection.join(",");
                            if (interestsSplit) {
                              setPrevTags(interestsSplit);
                            }
                            setExtendedProfile(
                              Field.INTERESTS,
                              joinedInterests
                            );
                          }
                        }}
                        canAdd={Options.length < maxTagAmount}
                        errors={maxCharError}
                      />
                    </TagWrapper>
                    <TagDescriptionWrapper>
                      {maxCharError.length > 0 && (
                        <Text mods="error">{maxCharError}</Text>
                      )}
                      <Text mods="caption grey">
                        Ex. Building robots, playing basketball, hiking
                      </Text>
                    </TagDescriptionWrapper>
                  </>
                ) : (
                  <Text>{splitInterests()}</Text>
                )}
                {isEditing && (
                  <ButtonWrapper>
                    <ButtonNew
                      color="secondary"
                      size="lg"
                      onClick={() => {
                        setIsEditing(false);
                        setExtendedProfile(Field.INTERESTS, prevTags.join(","));
                      }}
                    >
                      Cancel
                    </ButtonNew>
                    <ButtonNew
                      color="primary"
                      size="lg"
                      onClick={() => {
                        onSave();
                        setIsEditing(false);
                        setPrevTags(Options);
                      }}
                      disabled={!isValid}
                    >
                      Confirm
                    </ButtonNew>
                  </ButtonWrapper>
                )}
              </Row>
            </>
          )}
        </ProfileWrapper>
        <Divider />
        <ConnectionHeader>
          <Text mods="heading h3 bold">Connection history</Text>
          <Text>
            People you&apos;ve connected with during Hack the North 2023
          </Text>
          <Filters>
            <StyledDropdown items={rolesfilter}>
              <FilterCard>
                <Text mods="heading h4 lighter" tw="mt-0! leading-normal!">
                  Filter by category
                </Text>
                <Icon name="filter" size="h3" />
              </FilterCard>
            </StyledDropdown>
            <StyledDropdown items={directions}>
              <FilterCard>
                <Icon
                  name={directionFilter ? "radio-selected" : "radio-empty"}
                  size="h3"
                />
                <Text mods="heading h4 lighter" tw="mt-0! leading-normal!">
                  {directionFilter || "You scanned"}
                </Text>
              </FilterCard>
            </StyledDropdown>
          </Filters>
        </ConnectionHeader>
        {loading && <Spinner />}
        {scans &&
          scans.map((scan, index) => (
            <LinkCard
              key={index}
              onClick={async () => {
                if (scan.other_attendee_role !== "sponsor") {
                  try {
                    fetchNetworkingScan({
                      variables: {
                        badge_scan_id: scan.id,
                      },
                    });
                  } catch {
                    console.log("uh oh, ", fetchScanError?.message);
                  } finally {
                    setShowModal(true);
                  }
                }
              }}
            >
              <LinkCardBody>
                <Flex>
                  <Text mods="bold">
                    {scan.other_attendee_name}
                    {scan.other_attendee_role === "sponsor" &&
                      " (" + scan.sponsor_scan?.company_name + ")"}
                  </Text>
                  {scan.other_attendee_role && (
                    <RoleLabel
                      color={getBadgeColor(scan.other_attendee_role as JWTRole)}
                      size="sm"
                    >
                      {scan.other_attendee_role}
                    </RoleLabel>
                  )}
                </Flex>
                <Text>{moment(scan.scanned_at).format("MMMM Do, h:mm A")}</Text>
              </LinkCardBody>
              {scan.other_attendee_role !== "sponsor" && (
                <Icon name="external-link" size="h3" />
              )}
            </LinkCard>
          ))}
      </SettingsWrapper>
    </>
  );
};

const SettingsWrapper = styled(PageWrapper)`
  max-width: 800px;
`;

const ProfileWrapper = styled.div`
  margin-bottom: 42px;
`;

const ProfileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 36px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 12px;
  }
`;

const Row = styled.div`
  display: block;
  margin: 24px 0 36px 0;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  background: #f3f4f6;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  padding-left: 8px;
`;

const TagDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HalfCol = styled.div`
  width: 45%;
  @media (max-width: 768px) {
    width: 100%;
    margin: 12px 0;
  }
`;

const FullInput = styled(TextInput)`
  width: 100%;
`;

const LinkCard = styled(Card)`
  margin: 16px 0;
  padding: 16px 24px !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkCardBody = styled.div`
  max-width: 85%;
  display: flex;
  flex-direction: column;
  p {
    overflow-wrap: break-word;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  column-gap: 10px;
`;

const ConnectionHeader = styled.div`
  margin-top: 42px;
`;

const Filters = styled.div`
  display: flex;
  max-width: 380px;
  justify-content: space-between;
`;

const StyledDropdown = styled(DropdownMenu)`
  border: 1px solid #d1d5db;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-width: 0;
  left: 0;
  width: 100% !important;
  top: -30px;
`;

const FilterCard = styled(Card)`
  display: flex;
  align-items: center;
  margin: 32px 0 16px 0;
  padding: 12px 16px !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  gap: 12px;
  p {
    font-weight: 300 !important;
    margin-bottom: 0 !important;
  }
`;

const MobileModal = styled(Modal)`
  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
    z-index: 100000 !important;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slideUp 0.2s;
    text-align: center;

    @keyframes slideUp {
      0% {
        bottom: -60vh;
      }
      100% {
        bottom: 0;
      }
    }
  }
`;

const RoleLabel = styled(Label)`
  height: fit-content;
  margin-left: 8px;
`;

export default Settings;
