import React from "react";
import { styled } from "twin.macro";

import { Icon } from "src/shared/components";

const Container = styled.div`
  padding: 36px 50px 0 50px;
  width: 100%;
`;

const Table = styled.table`
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

const Cell = styled.td`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const Legend: React.FC = () => {
  return (
    <Container>
      <Table>
        <thead>
          <tr>LEGEND</tr>
        </thead>
        <tbody>
          <tr>
            <Cell>
              <IconContainer>
                <Icon size="18px" name="perk-completed" />
              </IconContainer>
              Completed
            </Cell>
          </tr>
          <tr>
            <Cell>
              <IconContainer>
                <Icon size="18px" name="perk-under-review" />
              </IconContainer>
              Under review
            </Cell>
          </tr>
          <tr>
            <Cell>
              <IconContainer>
                <Icon size="18px" name="perk-in-progress" />
              </IconContainer>
              Incomplete
            </Cell>
          </tr>
          <tr>
            <Cell>
              <IconContainer>
                <Icon size="18px" name="perk-not-started" />
              </IconContainer>
              Not started
            </Cell>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default Legend;
