import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { VolunteerRoute } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts";
import NotFound from "src/views/404";

import { EventRedemption } from "./event-redemption";
import { Registration } from "./registration";

export const VolunteerRoutes = () => (
  <Routes>
    <Route
      path={VolunteerRoute.REGISTRATION}
      element={<Registration />}
      requirePermissions={[Permission.VOLUNTEER]}
    />
    <Route
      path={VolunteerRoute.EVENT_REDEMPTION}
      element={<EventRedemption />}
      requirePermissions={[Permission.VOLUNTEER]}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
