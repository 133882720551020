import React from "react";
import styled from "styled-components";
import "twin.macro";

import { InfoTag } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { HackerStage } from "src/shared/contexts/HackerContext/types";
// import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

// import { isTravelSubmitted, showTravel } from "./util";

const StatusBadge = styled(InfoTag)`
  padding: 4px 9px;
  margin: 0px 0px 4px 0px !important;
`;

const NeutralBadge = styled(StatusBadge)`
  color: ${({ theme }) => theme.globalConstants.color.textSecondary} !important;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundGray};
`;

const PositiveBadge = styled(StatusBadge)`
  color: ${({ theme }) => theme.globalConstants.color.textSuccess} !important;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundSuccessLight};
`;

const WarningBadge = styled(StatusBadge)`
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundDangerLight};
  color: ${({ theme }) => theme.globalConstants.color.textDanger} !important;
`;

const STAGE_BADGES = {
  [HackerStage.ACCEPTED]: <WarningBadge>RSVP not submitted</WarningBadge>,
  [HackerStage.AUTO_ACCEPTED]: <WarningBadge>RSVP not submitted</WarningBadge>,
  [HackerStage.WAITLISTED]: null,
  [HackerStage.WITHDRAWN]: <NeutralBadge>RSVP withdrawn</NeutralBadge>,
  [HackerStage.EXPIRED]: <NeutralBadge>RSVP Expired</NeutralBadge>,
  [HackerStage.CONFIRMED]: <PositiveBadge>RSVP confirmed</PositiveBadge>,
};

// const ACCEPTED_FIELDS = [
//   Field.REIMBURSEMENT_RECIPIENT_NAME,
//   Field.TRAVEL_RECEIPT,
//   Field.TRAVEL_OPTION,
//   Field.TRAVEL_PHONE_NUMBER,
// ];

export const Badges = () => {
  const { stage } = useHackerContext();
  // const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});

  // const isTravelling = !!showTravel(responsesState[Field.TRAVEL_OPTION]);
  // const travelConfirmed =
  //   isTravelling &&
  //   (responsesState[Field.TRAVEL_OPTION] === "flight"
  //     ? isTravelSubmitted(
  //         responsesState[Field.REIMBURSEMENT_RECIPIENT_NAME],
  //         responsesState[Field.TRAVEL_RECEIPT]
  //       )
  //     : responsesState[Field.TRAVEL_PHONE_NUMBER]);

  // const TravelBadge = () => {
  //   if (!isTravelling) {
  //     return null;
  //   }
  //   return travelConfirmed ? (
  //     <PositiveBadge>Travel plan submitted</PositiveBadge>
  //   ) : (
  //     <WarningBadge>Travel plan not submitted</WarningBadge>
  //   );
  // };

  return (
    <StartFlexSmallGap>
      {stage && STAGE_BADGES[stage]}
      {/* {stage !== HackerStage.WITHDRAWN && <TravelBadge />} */}
    </StartFlexSmallGap>
  );
};

const StartFlexSmallGap = styled.div`
  display: flex;
  justify-content: start;
  gap: 8px;
  margin-bottom: 12px;
`;
