import { Button, Card, Label, Text } from "north.js";
import React from "react";
import "twin.macro";

import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { formatTime } from "src/shared/utils/formatTime";

export interface ClaimedRequestCardProps {
  request: MentorshipRequest;
  type: "claimed" | "resolved";
  onResolve: (request: MentorshipRequest) => void;
  onUnclaim: (request: MentorshipRequest) => void;
  showActions?: boolean;
}

export const ClaimedRequestCard: React.FC<ClaimedRequestCardProps> = ({
  request,
  type,
  onResolve,
  onUnclaim,
}) => {
  return (
    <>
      <Card>
        <div tw="flex justify-between align-items[center]">
          <Text type="caption" tw="font-medium">
            <b>Requester:</b> {request.mentee_name}
          </Text>
          <Text type="caption" tw="font-medium">
            Submitted {formatTime(request.created_at, true)}
          </Text>
        </div>
        <div tw="h-16" />
        <Text type="button" tw="text-text-body">
          {request.title}
        </Text>
        <div tw="h-12" />
        <Text type="body1" tw="text-text-body font-medium ">
          {request.description}
        </Text>
        {(request?.category || []).length > 0 && (
          <>
            <div tw="h-16" />
            <div tw="flex">
              <Text type="caption" tw="font-medium text-text-secondary">
                Category
              </Text>
              <div tw="w-16" />
              <div tw="flex align-items[center] flex-wrap gap-8">
                {(request?.category || []).map((category) => (
                  <>
                    <Label
                      size="sm"
                      color="secondary-light"
                      tw="font-medium"
                      key={category}
                    >
                      {category}
                    </Label>
                  </>
                ))}
              </div>
            </div>
          </>
        )}
        <div tw="flex justify-end mt-24">
          {type === "claimed" ? (
            <>
              <Button color="secondary" onClick={() => onUnclaim(request)}>
                <Text type="button" tw="text-text-secondary">
                  Unclaim
                </Text>
              </Button>
              <div tw="w-16" />
              <Button
                color="primary"
                tw="font-medium"
                onClick={() => onResolve(request)}
              >
                <Text type="button" tw="text-text-light">
                  Resolve
                </Text>
              </Button>
            </>
          ) : (
            <Button color="success" tw="font-medium" disabled>
              <Text type="button" tw="text-text-light">
                Resolved
              </Text>
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};
