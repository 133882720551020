import tw from "twin.macro";

export const TWText = {
  // Unfixed : https://github.com/tailwindlabs/tailwindcss/issues/2920
  title: tw`font-heading font-weight[900] text-text-primary text-68 leading-normal tb:(text-48) lm:(text-44!) mb:(text-36!)`,
  h1: tw`font-heading font-weight[900] text-text-primary text-44 leading-normal tb:(text-36) lm:(text-32!)`,
  h2: tw`font-heading font-weight[900] text-text-primary text-36 leading-normal tb:(text-32) lm:(text-28! leading-relaxed)`,
  h3: tw`font-heading font-weight[900] text-text-primary text-28 leading-normal tb:(text-24 leading-relaxed) lm:(text-18!) mb:(text-16!)`,
  h4: tw`font-heading font-weight[900] text-text-primary text-24 leading-normal tb:(text-18 leading-relaxed) lm:(text-16! leading-loose)`,
  bodyLarge: tw`font-body font-weight[bold] text-text-primary text-18 leading-loose tb:(text-16 leading-extra)`,
  body: tw`font-body font-weight[normal] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  bodyBold: tw`font-body font-weight[bold] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  cta: tw`font-body font-weight[500] text-text-primary text-16 leading-loose tb:(text-14 leading-extra)`,
  link: tw`text-text-primary`,
  label: tw`font-body font-weight[normal] text-text-primary text-14 leading-extra tb:(text-12)`,
};
