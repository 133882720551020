import { Spacer } from "@hackthenorth/north";
import { Button, Checkbox, Label, Table } from "north.js";
import React, { useState } from "react";
import { styled } from "twin.macro";

import { Icon } from "src/shared/components";
import { DeleteIcon } from "src/static/img";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkTextArea,
  PerkTextInput,
  PerkError,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const SHIPPED_SWAG_VALIDATION_FIELDS: (keyof SponsorPerksData)[] = [
  "shipped_swag_description",
];

type TSHIPPED_SWAG_ITEM = {
  id: number;
  name: string;
  quantity: string;
  trackingInfo: string;
  includedInBag: boolean;
  additionalInfo: string;
  isReadyForSubmit: boolean;
};

const TABLE_COLS = [
  {
    id: "submit",
    name: "Submit",
  },
  {
    id: "name",
    name: "Name",
  },
  {
    id: "quantity",
    name: "Quantity",
  },
  {
    id: "trackingInfo",
    name: "tracking Info",
  },
  {
    id: "includedInBag",
    name: "Included in bag",
  },
  {
    id: "additionalInfo",
    name: "Additional Info",
  },
  {
    id: "edit",
    name: "Edit",
  },
  {
    id: "delete",
    name: "Delete",
  },
];

const INITIAL_SHIPPED_SWAG_ITEM: Omit<TSHIPPED_SWAG_ITEM, "id"> = {
  name: "",
  quantity: "",
  trackingInfo: "",
  includedInBag: false,
  additionalInfo: "",
  isReadyForSubmit: false,
};

export const ShippedSwag: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
}) => {
  const [selectedSwag, setSelectedSwag] = useState<TSHIPPED_SWAG_ITEM>();
  const [error, setError] = useState<string>();

  const swagItems = JSON.parse(
    getState("shipped_swag_description") ?? "[]"
  ) as TSHIPPED_SWAG_ITEM[];

  const tableData = swagItems.map((swag: TSHIPPED_SWAG_ITEM, i) => {
    const onSubmit = () => {
      if (swag.name !== "" && swag.quantity !== "") {
        updateState(
          "shipped_swag_description",
          JSON.stringify([
            ...swagItems.slice(0, i),
            {
              ...swag,
              isReadyForSubmit: true,
            },
            ...swagItems.slice(i + 1),
          ])
        );
        if (error) {
          setError(undefined);
        }
      } else {
        setError("Not all required fields are filled in");
      }
    };
    const onEdit = () => {
      setSelectedSwag({ ...swag, id: i });
    };
    const onDelete = () => {
      updateState(
        "shipped_swag_description",
        JSON.stringify([...swagItems.slice(0, i), ...swagItems.slice(i + 1)])
      );
    };
    return {
      submit: swag.isReadyForSubmit ? (
        <SubmitContainer>
          <Icon size="28px" name="perk-completed" />
        </SubmitContainer>
      ) : (
        <SubmitContainer>
          <Button color="secondary" size="sm" onClick={() => onSubmit()}>
            Submit
          </Button>
        </SubmitContainer>
      ),
      name: swag.name,
      quantity: swag.quantity,
      trackingInfo: swag.trackingInfo,
      includedInBag: swag.includedInBag ? "Yes" : "No",
      additionalInfo: swag.additionalInfo,
      edit: (
        <Button
          color="secondary"
          size="sm"
          disabled={swag.isReadyForSubmit}
          onClick={() => onEdit()}
        >
          Edit
        </Button>
      ),
      delete: (
        <Button color="secondary" size="sm" onClick={() => onDelete()}>
          <DeleteIcon width={"20px"} />
        </Button>
      ),
    };
  });

  return (
    <>
      <Header
        title="Swag"
        subtitle="This perk is for swag that will be given to hackers."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              getState("shipped_swag_status") as PerkStatus
            )}
            size="sm"
          >
            {getState("shipped_swag_status") ?? "Incomplete"}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.SHIPPED_SWAG}
        readOnly={isReadOnly}
      >
        {error && <PerkError error={error} />}
        <Spacer height={8} />
        {!selectedSwag ? (
          <>
            <Table
              columns={TABLE_COLS}
              data={tableData}
              displayStyle="divide-xy"
              horizontalPadding={16}
              verticalPadding={10}
              showFooter
            />
            <Spacer height={16} />

            <Button
              color="secondary"
              size="sm"
              onClick={() => {
                updateState(
                  "shipped_swag_description",
                  JSON.stringify([...swagItems, INITIAL_SHIPPED_SWAG_ITEM])
                );
                setSelectedSwag({
                  ...INITIAL_SHIPPED_SWAG_ITEM,
                  id: swagItems.length,
                });
              }}
            >
              Add Swag
            </Button>
          </>
        ) : (
          <>
            <PerkTextInput
              title="Name of item*"
              value={selectedSwag.name}
              placeholder=""
              isReadOnly={isReadOnly}
              onChange={(e) =>
                setSelectedSwag({ ...selectedSwag, name: e.target.value })
              }
            />
            <Spacer height={16} />
            <PerkTextInput
              title="Quantity being shipped*"
              value={selectedSwag.quantity}
              placeholder=""
              isReadOnly={isReadOnly}
              onChange={(e) =>
                setSelectedSwag({ ...selectedSwag, quantity: e.target.value })
              }
              type="number"
            />
            <Spacer height={16} />
            <PerkTextInput
              title={
                <>
                  Tracking Information{" "}
                  <Label color="secondary-light" size="sm">
                    Optional
                  </Label>
                </>
              }
              value={selectedSwag.trackingInfo}
              placeholder=""
              isReadOnly={isReadOnly}
              onChange={(e) =>
                setSelectedSwag({
                  ...selectedSwag,
                  trackingInfo: e.target.value,
                })
              }
            />
            <Spacer height={16} />
            <TextDescription>
              Upon registration, we usually distribute a bag containing swag to
              every participant. *Note a minimum quantity of 1,050 units is
              required as every participant will receive a welcome bag.
            </TextDescription>
            <Spacer height={16} />
            <Checkbox
              onChange={() =>
                setSelectedSwag({
                  ...selectedSwag,
                  includedInBag: !selectedSwag.includedInBag,
                })
              }
              checked={selectedSwag.includedInBag}
              label="Should this item be included in the welcome bag?"
              size={18}
            />
            <Spacer height={16} />
            <TextDescription>
              Is there any additional information our team should be aware of
              regarding your swag shipment? (optional)
            </TextDescription>
            <Spacer height={8} />
            <PerkTextArea
              title={
                <>
                  Additional Info{" "}
                  <Label color="secondary-light" size="sm">
                    Optional
                  </Label>
                </>
              }
              value={selectedSwag.additionalInfo}
              placeholder="Additional info"
              isReadOnly={isReadOnly}
              onChange={(e) =>
                setSelectedSwag({
                  ...selectedSwag,
                  additionalInfo: e.target.value,
                })
              }
              wordLimit={9999}
            />
            <Spacer height={16} />
            <Button
              size="md"
              onClick={() => {
                if (selectedSwag.id !== undefined) {
                  updateState(
                    "shipped_swag_description",
                    JSON.stringify([
                      ...swagItems.slice(0, selectedSwag.id),
                      selectedSwag,
                      ...swagItems.slice(selectedSwag.id + 1),
                    ])
                  );
                  setSelectedSwag(undefined);
                }
              }}
            >
              Save
            </Button>
          </>
        )}
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription style={{ marginBottom: "8px" }}>
      We&apos;d love to distribute your company swag to our hackers! Note, you
      can submit this form multiple times. You only need to submit this perk
      once you have submitted all of your swag items individually. To submit
      swag, please enter the fields below.
    </TextDescription>
    <TextDescription style={{ marginBottom: "8px" }}>
      Please notify your sponsor coordinator via email once you&apos;ve sent the
      swag out along with the anticipated arrival date! (Note: this is only for
      swag that you want to include in the welcome bags that hackers receive
      upon registration - for swag that you&apos;d like to hand out yourself at
      your sponsor booth throughout the weekend, you may choose to bring that
      yourself)
    </TextDescription>
    <TextDescription style={{ marginBottom: "8px" }}>
      Our shipping address is: Hack the North, c/o Kari Griffiths E7 7341,
      University of Waterloo, 200 University Ave W, Waterloo, ON N2L 3G1
    </TextDescription>
  </>
);

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
`;
