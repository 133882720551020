/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpdateSponsorCompanyMutationVariables = Types.Exact<{
  tier?: Types.Maybe<Types.EnumSponsorCompanyTierFieldUpdateOperationsInput>;
  stage?: Types.Maybe<Types.EnumSponsorCompanyStageFieldUpdateOperationsInput>;
  primary_contact_name?: Types.Maybe<Types.NullableStringFieldUpdateOperationsInput>;
  primary_contact_email?: Types.Maybe<Types.NullableStringFieldUpdateOperationsInput>;
  tc_accepted?: Types.Maybe<Types.BoolFieldUpdateOperationsInput>;
  coc_accepted?: Types.Maybe<Types.BoolFieldUpdateOperationsInput>;
  company_slug: Types.Scalars["String"];
}>;

export type UpdateSponsorCompanyMutation = {
  updateSponsorCompany: Pick<Types.SponsorCompany, "id">;
};

export const UpdateSponsorCompanyDocument = gql`
  mutation updateSponsorCompany(
    $tier: EnumSponsorCompanyTierFieldUpdateOperationsInput
    $stage: EnumSponsorCompanyStageFieldUpdateOperationsInput
    $primary_contact_name: NullableStringFieldUpdateOperationsInput
    $primary_contact_email: NullableStringFieldUpdateOperationsInput
    $tc_accepted: BoolFieldUpdateOperationsInput
    $coc_accepted: BoolFieldUpdateOperationsInput
    $company_slug: String!
  ) {
    updateSponsorCompany(
      data: {
        tier: $tier
        stage: $stage
        primary_contact_name: $primary_contact_name
        primary_contact_email: $primary_contact_email
        tc_accepted: $tc_accepted
        coc_accepted: $coc_accepted
      }
      where: { slug: $company_slug }
    ) {
      id
    }
  }
`;

/**
 * __useUpdateSponsorCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateSponsorCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSponsorCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSponsorCompanyMutation, { data, loading, error }] = useUpdateSponsorCompanyMutation({
 *   variables: {
 *      tier: // value for 'tier'
 *      stage: // value for 'stage'
 *      primary_contact_name: // value for 'primary_contact_name'
 *      primary_contact_email: // value for 'primary_contact_email'
 *      tc_accepted: // value for 'tc_accepted'
 *      coc_accepted: // value for 'coc_accepted'
 *      company_slug: // value for 'company_slug'
 *   },
 * });
 */
export function useUpdateSponsorCompanyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSponsorCompanyMutation,
    UpdateSponsorCompanyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSponsorCompanyMutation,
    UpdateSponsorCompanyMutationVariables
  >(UpdateSponsorCompanyDocument, baseOptions);
}
export type UpdateSponsorCompanyMutationHookResult = ReturnType<
  typeof useUpdateSponsorCompanyMutation
>;
export type UpdateSponsorCompanyMutationResult =
  ApolloReactCommon.MutationResult<UpdateSponsorCompanyMutation>;
export type UpdateSponsorCompanyMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateSponsorCompanyMutation,
    UpdateSponsorCompanyMutationVariables
  >;
