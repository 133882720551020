import React, { createContext, useContext } from "react";

import { useSponsorPerkClaim } from "./useSponsorPerkClaim";

export interface SponsorPerksContextState {
  perks: ReturnType<typeof useSponsorPerkClaim>["perks"];
  loadError: ReturnType<typeof useSponsorPerkClaim>["error"];
  loadingPerks: ReturnType<typeof useSponsorPerkClaim>["loading"];
  updatePerks: ReturnType<typeof useSponsorPerkClaim>["updatePerks"];
  refetchPerks: ReturnType<typeof useSponsorPerkClaim>["refetchPerks"];
  updatingPerks: ReturnType<typeof useSponsorPerkClaim>["updating"];
}

const noop = console.error("No parent SponsorPerksContextProvider found!");

const SponsorPerksContext: React.Context<SponsorPerksContextState> =
  createContext({
    perks: {},
    loadingPerks: false,
    updatePerks: noop,
    updatingPerks: false,
    refetchPerks: () => {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

SponsorPerksContext.displayName = "SponsorPerksContext";

export const SponsorPerksContextProvider: React.FC = ({ children }) => {
  const { perks, loading, error, updatePerks, refetchPerks, updating } =
    useSponsorPerkClaim();

  /**
   * Build state
   */
  const state: SponsorPerksContextState = {
    perks,
    loadingPerks: loading,
    loadError: error,
    updatePerks,
    refetchPerks,
    updatingPerks: updating,
  };

  return (
    <SponsorPerksContext.Provider value={state}>
      {children}
    </SponsorPerksContext.Provider>
  );
};

SponsorPerksContextProvider.displayName = "SponsorPerksContextProvider";

export const useSponsorPerksContext = () => useContext(SponsorPerksContext);
