import { TAnswer } from "src/shared/contexts/HackerContext/types";
import { Nullable } from "src/shared/utils/typescript";

export const showTravel = (travelMethod: Nullable<string>) => {
  if (travelMethod && travelMethod === "flight") {
    return "flight";
  }

  if (travelMethod && travelMethod === "bus") {
    return "Hack the North provided bus";
  }

  return null;
};

export const isTravelSubmitted = (
  reimbursementName: TAnswer,
  busReceipt: TAnswer
) => {
  return reimbursementName || busReceipt;
};

export const capitalizeFirstLetter = (
  inputString: string | null | undefined
) => {
  if (!inputString) return;
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};
