export const REIMBURSEMENT_MAPPING = {
  states: {
    "British Columbia": 135.54,
    California: 226,
    Alberta: 86,
    "New York": 115.86,
    Pennsylvania: 291,
    Texas: 284.32,
    Washington: 244,
    Massachusetts: 165.44,
    "New Jersey": 125.34,
    Minnesota: 263.04,
    Illinois: 189.92,
    Maryland: 173.52,
    Florida: 123,
    "North Carolina": 223.28,
    "Nova Scotia": 95.94,
    Manitoba: 136.62,
    "New Brunswick": 283.36,
    "Prince Edward Island": 95.94,
    Iowa: 243,
    Georgia: 210,
    Michigan: 210,
    Connecticut: 218,
    Ohio: 210,
    "Rhode Island": 170,
    Wisconsin: 231,
    "South Carolina": 133,
    Colorado: 137,
    "District of Columbia": 102,
    Saskatchewan: 250,
    Quebec: 88,
  },
  countries: {
    India: 1136.56,
    Algeria: 562.02,
    "United Kingdom": 257.36,
    Germany: 478,
    Netherlands: 220,
    Poland: 352.17,
    Spain: 276.64,
    Indonesia: 1347.58,
    Switzerland: 359.71,
    Malaysia: 1267.51,
    Mauritius: 1141.39,
    Nigeria: 1549.84,
    Ireland: 227.12,
    "United Arab Emirates": 768.76,
    Singapore: 1148.58,
    "South Korea": 1092.19,
  },
};

export const getReimbursementAmount = (location: string | undefined | null) => {
  if (!location) {
    return {
      amount: 0,
    };
  }
  const rsvpLocation = location.toLowerCase();
  const rsvpParsed = rsvpLocation.split(", ");
  if (rsvpParsed.length >= 2) {
    const stateParsed = rsvpParsed[1];
    for (const state of Object.keys(REIMBURSEMENT_MAPPING.states)) {
      if (stateParsed.includes(state.toLowerCase())) {
        const reimbursementAmount = REIMBURSEMENT_MAPPING.states[state];
        return {
          amount: reimbursementAmount,
        };
      }
    }
  }
  for (const country of Object.keys(REIMBURSEMENT_MAPPING.countries)) {
    if (rsvpLocation.includes(country.toLowerCase())) {
      const reimbursementAmount = REIMBURSEMENT_MAPPING.countries[country];
      return {
        amount: reimbursementAmount,
      };
    }
  }
  // if location not in mapping
  return { amount: -1 };
};
