import { DateTime, DurationInput } from "luxon";

import { formatTimeRangeV2 } from "src/shared/utils/formatTime";
import { THackerAPIDatetime } from "src/shared/utils/hackerapi";
import {
  answersToOptions,
  timesToOptionsExplicitV2,
} from "src/shared/utils/react-select";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.DateTime = DateTime;
// Sept 18, 2021 from [10am-12pm, 1pm-3pm, 4pm-6pm] EST
const COFFEE_CHAT_TIMES: [THackerAPIDatetime, DurationInput][] = [
  [DateTime.utc(2021, 9, 17, 14, 0, 0).toISO(), { hour: 2 }],
  [DateTime.utc(2021, 9, 17, 17, 0, 0).toISO(), { hour: 2 }],
  [DateTime.utc(2021, 9, 17, 20, 0, 0).toISO(), { hour: 2 }],
];

export const TIME_OPTIONS = timesToOptionsExplicitV2(
  formatTimeRangeV2,
  COFFEE_CHAT_TIMES
);

export const DURATION_OPTIONS = answersToOptions([
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "20 minutes",
  "25 minutes",
  "30 minutes",
]);
