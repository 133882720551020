import React from "react";
import { Navigate } from "react-router-dom";

import { Route } from "src/shared/components";
import { Route as RouteConstants } from "src/shared/constants/route";
import { BaseRoute } from "src/shared/constants/route";

export const SponsorHomeRoutes = () => (
  <Route
    path={BaseRoute.HOME}
    element={<Navigate to={RouteConstants.SPONSOR_PERKS} />}
  />
);
