import { Spacer } from "@hackthenorth/north";
import { Alert, Button, Card, Link } from "north.js";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import { Header, PageWrapper } from "src/shared/components";
import {
  Permission,
  usePermissionsContext,
  useUserContext,
} from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize } from "src/shared/hooks";
import { InfoIcon, RightArrowIcon, ExternalLink } from "src/static/icons";

import Schedule from "./schedule/Schedule";

const ACCEPTED_FIELDS = [Field.PREFERRED_NAME, Field.TRAVEL_OPTION];

const HackerHome: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/hacker/rsvp");
  });

  const { info } = useUserContext();
  const { hasPermission } = usePermissionsContext();
  const isMediumOrSmaller = useDeviceSize("medium");

  const isHacker = hasPermission(Permission.HACKER_EVENT_ACCESS);

  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const showWelcome = true;
  const showJudging = true;
  const isSubmissionLive = true;
  const isJudgingLive = true;
  const dismissed = localStorage.getItem("dismissed") === "true";

  const now = new Date();
  const hour = now.getHours();
  const date = now.getDate();
  const month = now.getMonth();
  const dayPart = hour > 18 ? "evening" : hour > 12 ? "afternoon" : "morning";

  const getCaption = () => {
    if (month !== 8) {
      return "";
    }
    switch (date) {
      case 16:
        return "Welcome to Day 1 of Hack the North! Are you ready for a day of hacking?";
      case 17:
        return "Welcome to Day 2 of Hack the North! Are you ready for another day of hacking?";
      case 18:
        return "Happy Day 3 of Hack the North! We can’t wait to see what the team created.";
      default:
        return "";
    }
  };
  const caption = getCaption();

  const getTravelMethod = () => {
    switch (responsesState[Field.TRAVEL_OPTION]) {
      case "flight":
        return "By flight ✈️";
      case "bus":
        return "By bus 🚌";
      case "walk":
        return "By walk 🚶";
      case "drive":
        return "By drive 🚙";
      default:
        return "By other 🚀";
    }
  };
  const travelMethod = getTravelMethod();

  const getTravelInfo = () => {
    switch (responsesState[Field.TRAVEL_OPTION]) {
      case "flight":
        return (
          <SubtitleText>
            Have a safe flight to the Hack the North! Please email{" "}
            <Link
              href="mailto:travel@hackthenorth.com"
              target="_blank"
              rel="noopener"
            >
              travel@hackthenorth.com
            </Link>{" "}
            if you have questions.
          </SubtitleText>
        );
      case "bus":
        return (
          <SubtitleText>
            Have a safe trip to the Hack the North! Please email{" "}
            <Link
              href="mailto:travel@hackthenorth.com"
              target="_blank"
              rel="noopener"
            >
              travel@hackthenorth.com
            </Link>{" "}
            if you have questions.
          </SubtitleText>
        );
      case "walk":
        return (
          <SubtitleText>
            Have a safe walk to the Hack the North! Please email{" "}
            <Link
              href="mailto:travel@hackthenorth.com"
              target="_blank"
              rel="noopener"
            >
              travel@hackthenorth.com
            </Link>{" "}
            if you have questions.
          </SubtitleText>
        );
      case "drive":
        return (
          <SubtitleText>
            Have a safe drive to the Hack the North! Please email{" "}
            <Link
              href="mailto:travel@hackthenorth.com"
              target="_blank"
              rel="noopener"
            >
              travel@hackthenorth.com
            </Link>{" "}
            if you have questions.
          </SubtitleText>
        );
      default:
        return (
          <SubtitleText>
            Have a safe trip the Hack the North! Please email{" "}
            <Link
              href="mailto:travel@hackthenorth.com"
              target="_blank"
              rel="noopener"
            >
              travel@hackthenorth.com
            </Link>{" "}
            if there’s any way we can help.
          </SubtitleText>
        );
    }
  };
  const travelInfo = getTravelInfo();
  const displayName = responsesState[Field.PREFERRED_NAME] ?? info?.name;

  return (
    <>
      <Header
        title="Home"
        subtitle="View important announcements, upcoming events, and travel information"
      />
      <HomePageWrapper stretchHeight={false}>
        <Container>
          <FlexContainer>
            <Heading1Text>
              {displayName && displayName !== "HACKERAPI_INTERNAL"
                ? `Good ${dayPart}, ${displayName} `
                : `Good ${dayPart}! `}
              👋
            </Heading1Text>
            {showWelcome && !isMediumOrSmaller && (
              <Link href="https://hackthenorth.com/welcome-booklet.pdf">
                <StyledButton color="primary">
                  <FlexContainer>
                    <ButtonText>View event booklet</ButtonText>
                    <Spacer width={8} />
                    <ExternalLink />
                  </FlexContainer>
                </StyledButton>
              </Link>
            )}
          </FlexContainer>
          <SubtitleText>{caption}</SubtitleText>
          {showWelcome && isMediumOrSmaller && (
            <>
              <Spacer height={16} />
              <Link href="https://hackthenorth.com/welcome-booklet.pdf">
                <StyledButton color="primary">
                  <FlexContainer>
                    <ButtonText>View event booklet</ButtonText>
                    <Spacer width={8} />
                    <ExternalLink />
                  </FlexContainer>
                </StyledButton>
              </Link>
            </>
          )}
          {isHacker && isJudgingLive && !dismissed && (
            <StyledAlert
              dismissable
              onDismiss={() => {
                localStorage.setItem("dismissed", "true");
              }}
              color="primary"
            >
              <Link
                href="https://docs.google.com/spreadsheets/d/1TjyUNuziSsfDFfc31CIII_kXxrCxTZPkNBI4tZKCDsU/edit?usp=sharing"
                target="_blank"
                rel="noopener"
              >
                <LinkText>
                  <StyledInfoIcon />
                  You can now find your judging info on Google Sheets
                  <StyledRightArrowIcon />
                </LinkText>
              </Link>
            </StyledAlert>
          )}
          {isHacker && (
            <CardContainer isMediumOrSmaller={isMediumOrSmaller}>
              <StyledCard>
                <CardWrapper>
                  <CaptionText>YOUR TRAVEL PLAN</CaptionText>
                  <Heading2Text>{travelMethod}</Heading2Text>
                  {travelInfo}
                </CardWrapper>
              </StyledCard>
              {showJudging && (
                <>
                  {isMediumOrSmaller ? (
                    <Spacer height={32} />
                  ) : (
                    <Spacer width={32} />
                  )}
                  <StyledCard>
                    <CardWrapper>
                      <>
                        <Heading2Text>Judging Info</Heading2Text>
                        <SubtitleText>
                          Submissions for Hack the North 2023 will be handled on
                          Devpost, please read the{" "}
                          <Link
                            href="https://hackthenorth2023.devpost.com/rules"
                            target="_blank"
                            rel="noopener"
                          >
                            Submission Instructions
                          </Link>{" "}
                          and{" "}
                          <Link
                            href="https://docs.google.com/document/d/1XDutA1gM_K1G5rWlgw6presMDWRQ4BdsTCbRFvPRnVU/edit?usp=sharing"
                            target="_blank"
                            rel="noopener"
                          >
                            Judging Instructions and FAQ
                          </Link>{" "}
                          before submitting.
                        </SubtitleText>
                        <Spacer height={16} />
                      </>
                      {isSubmissionLive ? (
                        <ButtonContainer>
                          {isJudgingLive && (
                            <a
                              href="https://docs.google.com/spreadsheets/d/1TjyUNuziSsfDFfc31CIII_kXxrCxTZPkNBI4tZKCDsU/edit?usp=sharing"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button size="md" color="secondary">
                                <ButtonText>Find your judging info</ButtonText>
                              </Button>
                            </a>
                          )}
                          <a
                            href="https://hackthenorth2023.devpost.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button size="md" color="secondary">
                              <ButtonText>Submit now on Devpost</ButtonText>
                            </Button>
                          </a>
                        </ButtonContainer>
                      ) : (
                        <>
                          <SubtitleText>
                            If you submitted a project in the new project
                            stream, check back here for your judging room and
                            time.
                          </SubtitleText>
                          <Spacer height={16} />
                          <SubtitleText>
                            Existing projects should still submit on devpost,
                            but you won’t need to worry about presentation
                            times, just be sure to show up to the project
                            showcase at the end to show off all your awesome
                            work.
                          </SubtitleText>
                        </>
                      )}
                    </CardWrapper>
                  </StyledCard>
                </>
              )}
            </CardContainer>
          )}
        </Container>
      </HomePageWrapper>
      <Schedule />
    </>
  );
};

const HomePageWrapper = styled(PageWrapper)`
  max-width: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
`;

const SubtitleText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 500;
  font-size: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  flex: none;
`;

const StyledAlert = styled(Alert)`
  margin-top: 24px;
  margin-bottom: 4px;
`;

const LinkText = styled.p`
  color: ${({ theme }) => theme.globalConstants.color.textPrimary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 700;
  font-size: 16px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: inline;
  margin-right: 12px;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  display: inline;
  margin-left: 12px;
`;

const CardContainer = styled.div<{ isMediumOrSmaller: boolean }>`
  ${({ isMediumOrSmaller }) => (isMediumOrSmaller ? "" : "display: flex;")}
  margin-top: 24px;
  margin-bottom: 32px;
`;

const StyledCard = styled(Card)`
  width: 100%;
`;

const CardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const CaptionText = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 400;
  font-size: 12px;
`;

const Heading2Text = styled.h2`
  margin-top: -4px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.globalConstants.color.textBody};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 26px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 22px;
  `}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 16px;
`;

const ButtonText = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-weight: 700;
  font-size: 16px;
`;

export default HackerHome;
