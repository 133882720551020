import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { Permission } from "src/shared/contexts";

import NotFound from "../404";

import SignageHunt from ".";

export const SignageHuntRoutes = () => (
  <Routes>
    <Route
      path={""}
      element={<SignageHunt />}
      requirePermissions={[Permission.HACKER_EVENT_ACCESS]}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
