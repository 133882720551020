import { Text, Spacer } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Link, RadioButton, Select, TextInput } from "north.js";
import React, { useState, ChangeEvent } from "react";
import { styled } from "twin.macro";

import {
  Divider,
  Dropzone,
  errorToast,
  FileLink,
  Icon,
  IMAGE_FILE_TYPES,
  successToast,
} from "src/shared/components";
import Label from "src/shared/components/Label";
import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { formatDateForSubmit } from "src/shared/utils/date";
import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import { isPhoneNumber, notBlank } from "src/shared/utils/validation";

import { useFileDropzone } from "../career/useFileDropzone";

import { BusComponent } from "./busComponent";
import {
  REIMBURSEMENT_SELECT_OPTIONS,
  ACCEPTED_FIELDS,
  VALIDATORS as BASE_VALIDATORS,
  RECIPIENT_DETAIL_FIELDS,
} from "./constants";
import { FlightDetails } from "./flightDetails";
import { Reimbursement } from "./reimbursement";
import TravelModal from "./travelModal";

interface FlightFormProps {
  isFlightClosed: boolean;
}

export const FlightForm: React.FC<FlightFormProps> = ({ isFlightClosed }) => {
  const { updateResponses, navigateNext } = useHackerContext();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    BASE_VALIDATORS
  );

  const [isBlocking, setIsBlocking] = useState(false);

  const isPhoneNumberValid = isPhoneNumber(
    responsesState[Field.TRAVEL_PHONE_NUMBER]
  );

  const VALIDATORS = {
    ...BASE_VALIDATORS,
    [Field.TRAVEL_PHONE_NUMBER]: notBlank,
    [Field.VISA_TRACKING]: responsesState[Field.REQUIRES_VISA]
      ? notBlank
      : null,
    [Field.REIMBURSEMENT_RECIPIENT_PAYPAL]: notBlank,
    [Field.REIMBURSEMENT_RECIPIENT_ETRANSFER]: notBlank,
    [Field.REIMBURSEMENT_RECIPIENT_NAME]: notBlank,
  };

  const [shareEmail, setShareEmail] = useState(
    !!responsesState[Field.SHARE_EMAIL]
  );

  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({
    [Field.TRAVEL_PHONE_NUMBER]: "",
    [Field.FLIGHT_CURRENCY]: "",
    [Field.FLIGHT_AMOUNT]: "",
    [Field.VISA_TRACKING]: "",
    [Field.SHARE_EMAIL]: "",
    [Field.FRIDAY_FLIGHT_AIRLINE]: "",
    [Field.ARRIVAL_AIRPORT]: "",
    [Field.FRIDAY_FLIGHT_NUMBER]: "",
    [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: "",
    [Field.SUNDAY_FLIGHT_AIRLINE]: "",
    [Field.DEPARTURE_AIRPORT]: "",
    [Field.SUNDAY_FLIGHT_NUMBER]: "",
    [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: "",
    [Field.REIMBURSEMENT_METHOD]: "",
    followup: "",
    [Field.REIMBURSEMENT_ACKNOWLEDGEMENT]: "",
  });
  const [didEdit, setDidEdit] = useState({
    currency: false,
    amount: false,
    visaTracking: false,
    shareEmail: false,
  });

  const ACCEPTED_FILE_TYPES = IMAGE_FILE_TYPES;

  const {
    dropzoneInputRef,
    fetchedFile,
    loading: isFileLoading,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(
    responsesState[Field.TRAVEL_RECEIPT]?.[0],
    (files) => {
      setResponsesState(Field.TRAVEL_RECEIPT, files);
    },
    ACCEPTED_FILE_TYPES
  );

  const ReceiptUpload = () => (
    <div>
      <Dropzone
        label={
          <span>
            <b>Upload a file</b> or drag and drop
          </span>
        }
        acceptedFileTypes={ACCEPTED_FILE_TYPES}
        onFilesUpload={addFile}
        onError={onFileError}
        ref={dropzoneInputRef}
        onBlur={() => setDropzoneErrorMessage("")}
      />
      {fetchedFile && (
        <>
          <Spacer height={8} />
          <FileLink file={fetchedFile} deleteFile={deleteFile} />
        </>
      )}
    </div>
  );

  const updateErrors = (response: object) => {
    setShowError(true);
    setDidEdit({
      amount: true,
      currency: true,
      shareEmail: true,
      visaTracking: true,
    });
    const newErrors = errors;
    ACCEPTED_FIELDS.forEach((responseField) => {
      const validator = VALIDATORS[responseField];
      const errorKey = RECIPIENT_DETAIL_FIELDS.includes(responseField)
        ? "followup"
        : responseField;
      if (validator) {
        newErrors[errorKey] =
          validator(response[responseField]) && !newErrors[errorKey]
            ? ""
            : "This field is required";
      } else if (Object.keys(newErrors).includes(errorKey)) {
        newErrors[errorKey] = "";
      }
    });
    setErrors(newErrors);
  };

  const convertResponseTypes = (responses: object) => {
    const fridayString = responses[Field.FRIDAY_FLIGHT_ARRIVAL_TIME] as string;
    const fridayVal = formatDateForSubmit(fridayString);

    const sundayString = responses[
      Field.SUNDAY_FLIGHT_DEPARTURE_TIME
    ] as string;
    const sundayVal = formatDateForSubmit(sundayString);

    return {
      ...responses,
      [Field.FRIDAY_FLIGHT_ARRIVAL_TIME]: fridayVal,
      [Field.SUNDAY_FLIGHT_DEPARTURE_TIME]: sundayVal,
      [Field.FLIGHT_AMOUNT]: parseFloat(responses[Field.FLIGHT_AMOUNT]),
    };
  };

  const customValid = () => {
    let valid = isValid;
    if (responsesState[Field.REQUIRES_VISA]) {
      const validator = VALIDATORS[Field.VISA_TRACKING];
      if (validator) {
        valid = valid && validator(responsesState[Field.VISA_TRACKING]);
      }
    }

    valid =
      valid &&
      (notBlank(responsesState[Field.REIMBURSEMENT_RECIPIENT_ETRANSFER]) ||
        notBlank(responsesState[Field.REIMBURSEMENT_RECIPIENT_NAME]) ||
        notBlank(responsesState[Field.REIMBURSEMENT_RECIPIENT_PAYPAL]));

    return valid;
  };

  const submit = async () => {
    const response = convertResponseTypes(responsesState);
    if (fetchedFile) {
      response[Field.TRAVEL_RECEIPT] = fetchedFile;
    }
    updateErrors(response);
    if (customValid() && !isFileLoading && !fileError) {
      try {
        const { errors } = await updateResponses(response);

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        successToast("Answers successfully saved!");
        navigateNext();
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast((e as Error).message);
        }
      }
    } else {
      window.scrollTo(0, 0);
      errorToast("Please fill out all the required fields.");
    }
  };

  const [showFlightModal, setShowFlightModal] = useState(false);

  if (isFlightClosed) {
    return <Text mods="bold h1">This form is now closed.</Text>;
  } else {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BusComponent isBlocking={isBlocking} setIsBlocking={setIsBlocking} />
          <Spacer height={36} />
          <Text mods="bold textBody">Phone number</Text>
          <Spacer height={12} />
          <Text>
            Please put a number that you will have access to the days of the
            event. If you&apos;re not from Canada or the United States, remember
            to also add your country&apos;s calling code.
          </Text>
          <Spacer height={12} />
          <InputWrapper
            maxLength={200}
            placeholder="Enter your phone number"
            value={responsesState[Field.TRAVEL_PHONE_NUMBER] ?? ""}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setResponsesState(
                Field.TRAVEL_PHONE_NUMBER,
                e.currentTarget.value
              ); // TODO: validate phone number
            }}
            size="md"
            error={
              showError &&
              !isPhoneNumberValid &&
              "Please enter a valid phone number"
            }
          />
          <HelperText>
            If you&apos;re running late, we&apos;ll use this number to contact
            you and make sure you&apos;re on your way so we can wait for you! If
            you don&apos;t have a phone, please indicate the best way to reach
            you quickly and reliably during the days of the event.
          </HelperText>
        </div>
        <Spacer height={42} />
        <Divider />
        <Spacer height={42} />
        <FormContainer>
          <FlightDetails
            showError={showError}
            day="Friday"
            fields={{
              airport: Field.ARRIVAL_AIRPORT,
              flightNo: Field.FRIDAY_FLIGHT_NUMBER,
              airline: Field.FRIDAY_FLIGHT_AIRLINE,
              arrivalTime: Field.FRIDAY_FLIGHT_ARRIVAL_TIME,
            }}
            errors={errors}
            setErrors={setErrors}
            setResponsesState={setResponsesState}
            responsesState={responsesState}
          />
          <FlightDetails
            showError={showError}
            day="Sunday"
            fields={{
              airport: Field.DEPARTURE_AIRPORT,
              flightNo: Field.SUNDAY_FLIGHT_NUMBER,
              airline: Field.SUNDAY_FLIGHT_AIRLINE,
              arrivalTime: Field.SUNDAY_FLIGHT_DEPARTURE_TIME,
            }}
            errors={errors}
            setErrors={setErrors}
            setResponsesState={setResponsesState}
            responsesState={responsesState}
          />
          <div>
            <SectionHeader>Flight cost</SectionHeader>
            <Spacer height={24} />
            <Row>
              <Label
                value="What currency did you pay for your flight in?"
                caption="This should be the currency shown on your receipts."
                error={
                  didEdit.currency || showError
                    ? errors[Field.FLIGHT_CURRENCY]
                    : null
                }
              >
                <StyledSelect
                  fullWidth
                  value={responsesState[Field.FLIGHT_CURRENCY] as string}
                  onChange={(e) => {
                    const validator = VALIDATORS[Field.FLIGHT_CURRENCY];
                    if (validator) {
                      setErrors({
                        ...errors,
                        [Field.FLIGHT_CURRENCY]: validator(e.target.value)
                          ? ""
                          : "Please select a valid currency.",
                      });
                    }
                    setDidEdit({ ...didEdit, currency: true });
                    setResponsesState(Field.FLIGHT_CURRENCY, e.target.value);
                  }}
                >
                  {optionsToNorthV2Options(REIMBURSEMENT_SELECT_OPTIONS)}
                </StyledSelect>
              </Label>
              <Spacer width={50} />
              <Label value="What is the total cost of your flight?">
                <StyledTextInput
                  value={responsesState[Field.FLIGHT_AMOUNT] as number}
                  placeholder="Enter your total cost"
                  hint="Please enter the amount shown on your receipts with taxes."
                  error={
                    didEdit.amount || showError
                      ? errors[Field.FLIGHT_AMOUNT]
                      : null
                  }
                  onChange={(e) => {
                    const validator = VALIDATORS[Field.FLIGHT_AMOUNT];
                    if (validator) {
                      setErrors({
                        ...errors,
                        [Field.FLIGHT_AMOUNT]: validator(e.target.value)
                          ? ""
                          : "Please enter a valid amount.",
                      });
                    }
                    setDidEdit({ ...didEdit, amount: true });
                    setResponsesState(Field.FLIGHT_AMOUNT, e.target.value);
                  }}
                />
              </Label>
            </Row>
            <Spacer height={36} />
            <Label
              value="Flight receipt upload"
              caption="Please be sure you upload receipts for all your travel purchases with the date and price clearly visible.. Your payments will not be reimbursed without receipts. "
            >
              <Text mods="big textBody">
                The receipts you upload should include the amount paid and
                flight information in one document, as well as the following
                information:
                <ul>
                  <li>
                    Your <b>full name</b> (please note on the receipt if the
                    ticket was purchased by someone else)
                  </li>
                  <li> Proof that the payment was processed</li>
                </ul>
              </Text>
              <ReceiptUpload />
            </Label>
          </div>
          {responsesState[Field.REQUIRES_VISA] && (
            <div>
              <SectionHeader>Visa tracking information</SectionHeader>
              <Spacer height={24} />
              <Label value="What is your visa tracking number?">
                <StyledTextInput
                  value={responsesState[Field.VISA_TRACKING] as string}
                  placeholder="Enter your tracking number"
                  hint={
                    <span>
                      You must apply for your visa and enter your visa tracking
                      number to confirm your spot at Hack the North.{" "}
                      <b>
                        Once you get your visa, email{" "}
                        <StyledLink href="mailto:travel@hackthenorth.com">
                          travel@hackthenorth.com
                        </StyledLink>{" "}
                        to let us know
                      </b>
                      .
                    </span>
                  }
                  error={
                    didEdit.visaTracking || showError
                      ? errors[Field.VISA_TRACKING]
                      : null
                  }
                  onChange={(e) => {
                    const validator = VALIDATORS[Field.VISA_TRACKING];
                    if (validator) {
                      setErrors({
                        ...errors,
                        [Field.VISA_TRACKING]: validator(e.target.value)
                          ? ""
                          : "Please enter a visa tracking number.",
                      });
                    }
                    setDidEdit({ ...didEdit, visaTracking: true });
                    setResponsesState(Field.VISA_TRACKING, e.target.value);
                  }}
                />
              </Label>
            </div>
          )}
          <div>
            <SectionHeader>Share email consent</SectionHeader>
            <Spacer height={24} />
            <Label
              value="Do you want your email to be shared with other hackers coming from your location?"
              caption="If you select yes, we'll connect you with fellow hackers from your location so you can coordinate or meet up with hackers from the same area."
              error={
                didEdit.shareEmail || showError
                  ? errors[Field.SHARE_EMAIL]
                  : null
              }
            >
              <RadioButton
                id="shareEmailYes"
                name="shareEmail"
                label="Yes"
                checked={shareEmail}
                onChange={(e: ChangeEvent<HTMLInputElement> | boolean) => {
                  const val = typeof e === "boolean" ? e : e.target.checked;
                  setShareEmail(val);
                  const validator = VALIDATORS[Field.SHARE_EMAIL];
                  if (validator) {
                    const valid = validator(val);
                    setErrors({
                      ...errors,
                      [Field.SHARE_EMAIL]: valid
                        ? ""
                        : "Please select an option.",
                    });
                  }
                  setDidEdit({ ...didEdit, shareEmail: true });
                  setResponsesState(Field.SHARE_EMAIL, val);
                }}
              />
              <Spacer height={6} />
              <RadioButton
                id="shareEmailNo"
                name="shareEmail"
                label="No"
                checked={!shareEmail}
                onChange={(e: ChangeEvent<HTMLInputElement> | boolean) => {
                  const val = typeof e === "boolean" ? e : e.target.checked;
                  setShareEmail(!val);
                  const validator = VALIDATORS[Field.SHARE_EMAIL];
                  if (validator) {
                    const valid = validator(val);
                    setErrors({
                      ...errors,
                      [Field.SHARE_EMAIL]: valid
                        ? ""
                        : "Please select an option.",
                    });
                  }
                  setDidEdit({ ...didEdit, shareEmail: true });
                  setResponsesState(Field.SHARE_EMAIL, !val);
                }}
              />
              <Spacer height={6} />
            </Label>
          </div>
          <div>
            <SectionHeader>Reimbursement method</SectionHeader>
            <Spacer height={24} />
            <Reimbursement
              showErrors={showError}
              errors={errors}
              setErrors={setErrors}
              responsesState={responsesState}
              setResponsesState={setResponsesState}
            />
          </div>
          <Row>
            <Button
              color="primary"
              trailingIcon={<Icon name="arrow-right" />}
              size="lg"
              onClick={() => {
                setShowError(true);
                if (
                  customValid() &&
                  !isFileLoading &&
                  !fileError &&
                  isPhoneNumberValid &&
                  responsesState[Field.TRAVEL_PHONE_NUMBER] !== null
                ) {
                  setShowFlightModal(true);
                } else {
                  window.scrollTo(0, 0);
                  errorToast("Please fill out all the required fields.");
                }
              }}
            >
              Submit travel plan
            </Button>
            <TravelModal
              isOpen={showFlightModal}
              onConfirm={submit}
              onCancel={() => setShowFlightModal(false)}
            />
          </Row>
        </FormContainer>
      </>
    );
  }
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 42px;
`;

export const SectionHeader = styled(Text).attrs({
  mods: "h3 brandSecondary bold",
})`
  margin-bottom: 24px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 36px;
`;

const StyledTextInput = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;

const StyledSelect = styled(Select).attrs({ size: "lg" })`
  width: 100%;
  color: rgba(31, 41, 55, 1) !important;
`;

const StyledLink = styled(Link)`
  font-size: 12px !important;
`;

const InputWrapper = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;

const HelperText = styled.p`
  margin-top: 4px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;
