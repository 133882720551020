/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetEventScheduleQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetEventScheduleQuery = {
  eventSchedule: Array<
    Pick<
      Types.ScheduleItem,
      | "id"
      | "end_time"
      | "start_time"
      | "location"
      | "description"
      | "banner_link"
      | "tags"
      | "title"
      | "links"
    >
  >;
};

export const GetEventScheduleDocument = gql`
  query GetEventSchedule {
    eventSchedule(
      where: { event: { is: { slug: { equals: "hackthenorth2023" } } } }
    ) {
      id
      end_time
      start_time
      location
      description
      banner_link
      tags
      title
      links
    }
  }
`;

/**
 * __useGetEventScheduleQuery__
 *
 * To run a query within a React component, call `useGetEventScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventScheduleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventScheduleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetEventScheduleQuery,
    GetEventScheduleQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetEventScheduleQuery,
    GetEventScheduleQueryVariables
  >(GetEventScheduleDocument, baseOptions);
}
export function useGetEventScheduleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetEventScheduleQuery,
    GetEventScheduleQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetEventScheduleQuery,
    GetEventScheduleQueryVariables
  >(GetEventScheduleDocument, baseOptions);
}
export type GetEventScheduleQueryHookResult = ReturnType<
  typeof useGetEventScheduleQuery
>;
export type GetEventScheduleLazyQueryHookResult = ReturnType<
  typeof useGetEventScheduleLazyQuery
>;
export type GetEventScheduleQueryResult = ApolloReactCommon.QueryResult<
  GetEventScheduleQuery,
  GetEventScheduleQueryVariables
>;
