import React from "react";
import styled from "styled-components";

import { useDeviceSize } from "src/shared/hooks";

import Stepper, { TStepperProps } from "./stepper";
import { TravelFormSidebar } from "./travelFormSidebar";

const RsvpSidebar: React.FC<TStepperProps> = ({
  currentRoute,
}: TStepperProps) => {
  const isTabletOrSmaller = useDeviceSize("tablet");

  const sidebarContent =
    currentRoute === "Travel Details" ? (
      <TravelFormSidebar />
    ) : (
      <Stepper currentRoute={currentRoute} />
    );

  return isTabletOrSmaller ? (
    sidebarContent
  ) : (
    <SidebarContainer>{sidebarContent}</SidebarContainer>
  );
};

export default RsvpSidebar;

const SidebarContainer = styled.div`
  position: fixed;
  top: 121px;
  padding-top: 42px;
  left: 0;
  height: calc(100vh - 121px);
  padding-bottom: 24px;

  z-index: 10;
  background: ${({ theme }) => theme.globalConstants.color.white};
  border-right: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};

  transition: width 0.3s ease-out, opacity 0.3s;
  width: 289px;
  opacity: 1;
`;
