/* eslint-disable @typescript-eslint/no-explicit-any */
// todo: fix type later
export const notNull = (val: any) => val !== null;
export const notBlank = (val: any) => notNull(val) && val !== "";
export const notFalse = (val: any) => val !== false;
export const isNumeric = (val: any) => {
  const numVal = parseFloat(val);
  return val === "" || (!isNaN(numVal) && !isNaN(val));
};

export const isNonNegative = (val: any) => {
  const numVal = parseFloat(val);
  return val === "" || (!isNaN(numVal) && !isNaN(val) && numVal >= 0);
};
export const isPhoneNumber = (val: any) =>
  /^([+]?[0-9]{1,3}[ -]?)?(\([0-9]{1,3}\)[ -]?)?[0-9]+[- ./x0-9]+[0-9]+$/im.test(
    val
  ); // thanks ethan
// /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(val); // from https://stackoverflow.com/a/29767609

export const isEmail = (val: any) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/im.test(val);

export const isWaterlooIDNumber = (val: any) => {
  const numVal = parseFloat(val);
  return (
    val === null ||
    val === "" ||
    (!isNaN(numVal) && !isNaN(val) && numVal >= 0 && val.length === 8)
  );
};

export const isDate = (val: any) => !isNaN(Date.parse(val));

// use url validator from hackerapps
export const isValidUrl = (val: any) => {
  if (!val) {
    return true;
  }
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(val);
};

export const isValidDiscordTag = (val: any) => {
  if (!val) {
    return false;
  }
  return true;
};

export const isValidSocialMediaURL = (val: any, domain: string) => {
  if (!val || !domain) {
    return true;
  }
  const pattern = new RegExp(
    "^(https?:\\/\\/)" + // protocol
      "(www\\.)?" +
      domain +
      "\\.com" + // domain name
      "(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(val);
};
