import { Flex, Text, Spinner } from "@hackthenorth/north";
import moment from "moment";
import {
  Label,
  Card,
  Button,
  TextInput,
  DropdownMenu,
  Checkbox,
  Link,
} from "north.js";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { QrReader } from "react-qr-reader";
import { styled } from "twin.macro";

import { Icon, Header } from "src/shared/components";
import { PageWrapper } from "src/shared/components";
import {
  QR,
  ViewFinder,
  GreenCheck,
  BlueCheck,
  RedExclamation,
} from "src/static/img";

import { useFindEventAttendeeByBadgeCodeLazyQuery } from "./graphql/findEventAttendeeByBadgeCode.generated";
import { useFindManyEventActivityQuery } from "./graphql/findManyEventActivity.generated";
import { useRedeemEventActivityScanMutation } from "./graphql/redeemEventActivityScan.generated";
// import { useFindUserRedemptionsForEventActivityLazyQuery } from "../graphql/findUserRedemptionsForEventActivity.generated";

type EventActivities = {
  id: number;
  title: string;
  start: moment.Moment;
  end: moment.Moment;
  category: string;
  banner_link: string;
  description: string;
  links: string[];
  location: string;
  displayedStart: string;
  displayedEnd: string;
};

const ViewFinderIndicator = styled(ViewFinder)`
  top: 0px;
  left: 0px;
  z-index: 100;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 180px;
  @media screen and (max-width: 1280px) {
    padding: 150px;
  }
  @media screen and (max-width: 768px) {
    padding: 100px;
  }
  margin-top: -50px;
`;

const formatEventActivitiesFromQuery = (events: any[]) => {
  return events.map((event) => {
    return {
      id: event.id,
      title: event.name,
      start: moment(event.start_time),
      end: moment(event.end_time),
      category: event.category,
      displayedStart: moment(event.start_time).format("h:mm A"),
      displayedEnd: moment(event.end_time).format("h:mm A"),
    };
  });
};

const ordinals = [
  "zeroth",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth",
];

export const EventRedemption: React.FC = () => {
  const [viewAllEvents, setViewAllEvents] = useState(false);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const [showCameraModal, setShowCameraModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [manualInput, setManualInput] = useState(false);
  const [badgeCode, setBadgeCode] = useState("");
  const [filter, setFilter] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState({
    id: 0,
    title: "",
    start: moment(),
    end: moment(),
    category: "",
    displayedStart: "",
    displayedEnd: "",
  });

  const now = moment();

  const {
    loading: fetchingEventActivities,
    data: eventActivitiesData,
    refetch: refetchEventActivities,
  } = useFindManyEventActivityQuery();

  const eventActivities = useMemo(
    () => eventActivitiesData?.findManyEventActivity ?? [],
    [eventActivitiesData]
  );

  const currentEventActivities = formatEventActivitiesFromQuery(
    eventActivities
      .filter(
        (event) =>
          event.start_time &&
          now >= moment(event.start_time).subtract(60, "minutes") &&
          now < moment(event.end_time)
        // show events that start within an hour)
      )
      .sort((e1, e2) =>
        moment(e1.start_time) < moment(e2.start_time)
          ? moment(e1.start_time) === moment(e2.start_time)
            ? 0
            : -1
          : 1
      )
  );

  const allEventActivities = formatEventActivitiesFromQuery(
    eventActivities
      .filter(
        (event) =>
          event.start_time &&
          event.category &&
          (filter.length ? filter.includes(event.category) : true)
      )
      .sort((e1, e2) =>
        moment(e1.start_time) < moment(e2.start_time)
          ? moment(e1.start_time) === moment(e2.start_time)
            ? 0
            : -1
          : 1
      )
  ).reduce((dates, event) => {
    const date = moment(event.start).startOf("day").toString();
    if (!dates[date]) dates[date] = [];
    dates[date].push(event);
    return dates;
  }, {});

  // Refetch events every 5 minutes
  useEffect(() => {
    const id = setInterval(refetchEventActivities, 300000);
    return () => clearInterval(id);
  }, [refetchEventActivities]);

  const [
    redeemEventActivityMutation,
    {
      data: redeemEventActivityData,
      loading: redeemEventActivityLoading,
      error: redeemEventActivityError,
    },
  ] = useRedeemEventActivityScanMutation();

  const [
    findEventAttendeeByBadgeCode,
    { data: attendeeData, loading: attendeeLoading },
  ] = useFindEventAttendeeByBadgeCodeLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const attendee = useMemo(() => {
    return attendeeData?.findEventAttendeeByBadgeCode || null;
  }, [attendeeData]);

  const toggleFilter = (category: string) => {
    if (filter.includes(category)) {
      setFilter(filter.filter((cat) => cat !== category));
    } else {
      setFilter([...filter, category]);
    }
  };

  const categories = [
    [
      {
        content: (
          <Checkbox
            checked={filter.includes("food")}
            onChange={() => toggleFilter("food")}
            label="Food"
          />
        ),
        action: () => {
          toggleFilter("food");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("activity")}
            onChange={() => toggleFilter("activity")}
            label="Activity"
          />
        ),
        action: () => {
          toggleFilter("activity");
        },
      },
      {
        content: (
          <Checkbox
            checked={filter.includes("workshop")}
            onChange={() => toggleFilter("workshop")}
            label="Workshop"
          />
        ),
        action: () => {
          toggleFilter("workshop");
        },
      },
    ],
  ];

  return (
    <>
      <CameraModal
        isOpen={showCameraModal}
        contentLabel="onRequestClose Example"
        onRequestClose={() => setShowScannerModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 100,
          },
        }}
      >
        <VideoHeader manualInput={manualInput}>
          <button
            onClick={() => {
              setShowCameraModal(false);
              setManualInput(false);
              setBadgeCode("");
            }}
          >
            <Icon
              name="chevron-left"
              size="title"
              color={manualInput ? "primary" : "white"}
            />
          </button>
          <div>
            <Text mods={manualInput ? "" : "white"}>
              Checking in for <strong>{selectedEvent.title}</strong>{" "}
            </Text>
            <Text mods={manualInput ? "" : "white"}>
              {selectedEvent.displayedStart +
                " - " +
                selectedEvent.displayedEnd}
            </Text>
          </div>
        </VideoHeader>
        <VideoWrapper>
          {manualInput ? (
            <ManualInputWrapper>
              <Text>Enter the attendee&apos;s badge ID:</Text>
              <BadgeInput
                size="md"
                placeholder="badge-id-goes-here"
                error={
                  redeemEventActivityError
                    ? redeemEventActivityError.message
                    : undefined
                }
                value={badgeCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBadgeCode(e.target.value)
                }
              />
              <ManualInputButtons>
                <Button
                  color="secondary"
                  size="md"
                  onClick={() => setManualInput(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="md"
                  onClick={async () => {
                    try {
                      findEventAttendeeByBadgeCode({
                        variables: {
                          badge_code: badgeCode,
                        },
                      });
                      await redeemEventActivityMutation({
                        variables: {
                          event_activity_id: selectedEvent.id,
                          badge_code: badgeCode,
                        },
                      });
                      setShowCameraModal(false);
                      setShowResultModal(true);
                    } catch {
                      console.log("uh oh, ", redeemEventActivityError?.message);
                    }
                  }}
                >
                  Confirm
                </Button>
              </ManualInputButtons>
            </ManualInputWrapper>
          ) : (
            <>
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={async (result, error) => {
                  if (result) {
                    try {
                      findEventAttendeeByBadgeCode({
                        variables: {
                          badge_code: result
                            .getText()
                            .slice(result.getText().lastIndexOf("/") + 1),
                        },
                      });
                      await redeemEventActivityMutation({
                        variables: {
                          event_activity_id: selectedEvent.id,
                          badge_code: result
                            .getText()
                            .slice(result.getText().lastIndexOf("/") + 1),
                        },
                      });
                      setShowCameraModal(false);
                      setShowResultModal(true);
                    } catch {
                      console.log("uh oh, ", redeemEventActivityError?.message);
                    } finally {
                      setShowCameraModal(false);
                      setShowResultModal(true);
                    }
                  }

                  if (error) {
                    console.info("Camera Error", error.message);
                  }
                }}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                videoContainerStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: "0",
                }}
                videoStyle={{
                  width: "100%",
                  objectFit: "cover",
                  height: "100%",
                  transform: "rotate(190deg)",
                  position: "relative",
                }}
                ViewFinder={ViewFinderIndicator}
              />
              <ManualButton
                color="primary"
                size="md"
                onClick={() => {
                  setManualInput(true);
                }}
              >
                Manually input code
              </ManualButton>
            </>
          )}
        </VideoWrapper>
      </CameraModal>

      {/* Modal that appear before scanning */}
      <ScannerModal
        isOpen={showScannerModal}
        contentLabel="onRequestClose Example"
        onRequestClose={() => setShowScannerModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 100,
          },
        }}
      >
        <ModalHeader>
          <Text mods="heading h2 bold">Scan QR Code</Text>
          <Text mods="heading h3 bold">Event: {selectedEvent.title} </Text>
          <Text>
            {selectedEvent.displayedStart + " - " + selectedEvent.displayedEnd}
          </Text>
        </ModalHeader>
        <QR />
        <ModalButtons>
          <ScanningButton
            color="primary"
            size="md"
            onClick={() => {
              setShowScannerModal(false);
              setShowCameraModal(true);
            }}
          >
            Start scanning ➜
          </ScanningButton>
          <BackButton onClick={() => setShowScannerModal(false)}>
            Back to event list
          </BackButton>
        </ModalButtons>
      </ScannerModal>

      {/* Modal that appear after scanning */}
      <ScannerModal
        isOpen={showResultModal}
        contentLabel="onRequestClose Example"
        onRequestClose={() => setShowScannerModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 51,
          },
        }}
      >
        {attendeeLoading || redeemEventActivityLoading ? (
          <Spinner />
        ) : (
          <Flex column align="center">
            <ModalHeader>
              {redeemEventActivityError ? (
                <Text mods="error heading h1 bold">Check-in failed.</Text>
              ) : redeemEventActivityData?.redeemEventActivityScan
                  .is_duplicate ? (
                <Text mods="heading h1 bold">Already checked in!</Text>
              ) : (
                <Flex>
                  <Text mods="heading h1 bold">Check-in&nbsp;</Text>
                  <Text mods="heading h1 success">success</Text>
                  <Text mods="heading h1">!</Text>
                </Flex>
              )}
              <Text mods="heading h3 bold">Event: {selectedEvent.title} </Text>
              <Text>
                {selectedEvent.displayedStart +
                  " - " +
                  selectedEvent.displayedEnd}
              </Text>
            </ModalHeader>
            {redeemEventActivityError ? (
              <RedExclamation />
            ) : redeemEventActivityData?.redeemEventActivityScan
                .is_duplicate ? (
              <BlueCheck />
            ) : (
              <GreenCheck />
            )}
            {redeemEventActivityError ? (
              <ErrorMessageWrapper>
                <Text mods="error">{redeemEventActivityError.message}</Text>
                <Text mods="error">
                  Please look to the help desk, or an organizer to help address
                  this issue.
                </Text>
              </ErrorMessageWrapper>
            ) : redeemEventActivityData?.redeemEventActivityScan
                .is_duplicate ? (
              <SuccessMessageWrapper>
                <Text>
                  {(attendee?.registrationData.legalName ||
                    attendee?.registrationData.preferredName) +
                    " has already been checked in."}
                </Text>
              </SuccessMessageWrapper>
            ) : (
              <SuccessMessageWrapper>
                <Text>
                  {(attendee?.registrationData.legalName ||
                    attendee?.registrationData.preferredName) +
                    " has been checked in for the "}
                  {
                    ordinals[
                      redeemEventActivityData
                        ? redeemEventActivityData.redeemEventActivityScan
                            .claim_number
                        : 0
                    ]
                  }
                  {" time"}
                </Text>
              </SuccessMessageWrapper>
            )}
            <ModalButtons>
              <ScanningButton
                color="primary"
                size="md"
                onClick={() => {
                  setShowResultModal(false);
                  setShowCameraModal(true);
                  setManualInput(false);
                  setBadgeCode("");
                }}
              >
                Scan another QR code ➜
              </ScanningButton>
              <BackButton
                onClick={() => {
                  setShowResultModal(false);
                  setManualInput(false);
                  setBadgeCode("");
                }}
              >
                Close scanner
              </BackButton>
            </ModalButtons>
          </Flex>
        )}
      </ScannerModal>

      <Header
        title="Event Redemption"
        subtitle="Check in hackers for food and events"
      />
      {!viewAllEvents ? (
        <PageWrapper>
          <Text mods="heading h1" tw="mt-0! leading-normal!">
            Tap on the event you want to scan for
          </Text>
          <Flex row align="center" justify="space-between">
            <Text mods="heading h3" tw="mt-0! leading-normal!">
              Upcoming events
            </Text>
            <Link onClick={() => setViewAllEvents(true)}>View all events</Link>
          </Flex>

          {fetchingEventActivities ? (
            <Spinner />
          ) : (
            <div>
              {currentEventActivities && currentEventActivities.length === 0 ? (
                <EmptyEventCard>
                  <Text mods="heading">No ongoing events</Text>
                  <Text mods="subtle">Check back later!</Text>
                </EmptyEventCard>
              ) : (
                currentEventActivities.map((event) => (
                  <div tw="px-10 h-full" key={event.id}>
                    <EventCard
                      onClick={() => {
                        setSelectedEvent(event);
                        setShowScannerModal(true);
                      }}
                    >
                      <EventCardHeader>
                        <Text mods="bold">{event.title}</Text>
                        {now < event.end && now >= event.start && (
                          <EventLabel color="primary-light" size="md">
                            Happening now!
                          </EventLabel>
                        )}
                      </EventCardHeader>
                      <Text>
                        {event.displayedStart + " - " + event.displayedEnd}
                      </Text>
                    </EventCard>
                  </div>
                ))
              )}
            </div>
          )}
        </PageWrapper>
      ) : (
        <PageWrapper>
          <button onClick={() => setViewAllEvents(false)}>
            <Flex row align="center">
              <Icon name="arrow-left" size={"20px"} color="textPrimary" />
              <Text mods="heading h4 blue" tw="my-0! ml-4! leading-normal!">
                Upcoming events
              </Text>
            </Flex>
          </button>

          <Text mods="heading h2" tw="mt-0! leading-normal!">
            Tap on the event you want to scan for
          </Text>

          <StyledDropdown items={categories}>
            <FilterCard>
              <Text mods="heading h4 lighter" tw="mt-0! leading-normal!">
                Filter by category
              </Text>
              <Icon name="filter" size="h3" />
            </FilterCard>
          </StyledDropdown>

          {fetchingEventActivities ? (
            <Spinner />
          ) : (
            <div>
              {!allEventActivities ? (
                <EmptyEventCard>
                  <Text mods="heading">No ongoing events</Text>
                  <Text mods="subtle">Check back later!</Text>
                </EmptyEventCard>
              ) : (
                Object.keys(allEventActivities).map((key) => (
                  <EventDayWrapper key={key}>
                    <Text mods="bold heading h3">
                      {moment(key).format("dddd, MMMM Do")}
                    </Text>
                    {allEventActivities[key].map((event: EventActivities) => (
                      <div tw="px-10 h-full" key={event.id}>
                        <EventCard
                          onClick={() => {
                            setSelectedEvent(event);
                            setShowScannerModal(true);
                          }}
                        >
                          <EventCardHeader>
                            <Text mods="bold">{event.title}</Text>
                            {now < event.end && now >= event.start && (
                              <EventLabel color="primary-light" size="md">
                                Happening now!
                              </EventLabel>
                            )}
                          </EventCardHeader>
                          <Text>
                            {event.displayedStart + " - " + event.displayedEnd}
                          </Text>
                        </EventCard>
                      </div>
                    ))}
                  </EventDayWrapper>
                ))
              )}
            </div>
          )}
        </PageWrapper>
      )}
    </>
  );
};

const EventCard = styled(Card)`
  margin: 16px 0;
  padding: 16px 24px !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
`;

const FilterCard = styled(Card)`
  display: flex;
  align-items: center;
  margin: 32px 0 16px 0;
  padding: 12px 16px !important;
  border: 1px solid #d1d5db !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  gap: 12px;
  p {
    font-weight: 300 !important;
    margin-bottom: 0 !important;
  }
`;

const EventLabel = styled(Label)`
  height: fit-content;
`;

const EventCardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const EmptyEventCard = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1.6px dashed #c8d9eb;
  border-radius: 10px;
  outline: none;
  min-height: 160px;
`;

const EventDayWrapper = styled.div`
  margin-bottom: 32px;
`;

const ScannerModal = styled(Modal)`
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: 0;
  background: white;
  z-index: 100000 !important;
  padding: 48px;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: slideUp 0.2s;
  text-align: center;

  @keyframes slideUp {
    0% {
      bottom: -60vh;
    }
    100% {
      bottom: 0;
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 42px;
  p {
    text-align: center;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
`;

const ScanningButton = styled(Button)`
  height: 42px !important;
  * button {
    color: #43afde !important;
  }
`;

const ManualButton = styled(Button)`
  --buttonWidth: 250px;
  position: fixed;
  width: var(--buttonWidth) !important;
  bottom: 78px;
  left: calc(50vw - var(--buttonWidth) / 2);
  z-index: 10000;
  height: 42px !important;
  * button {
    color: #43afde !important;
  }
`;

const BackButton = styled.button`
  color: #43afde;
  font-size: 16px;
  font-weight: bold;
  font-family: inherit;
  text-decoration: underline;
  margin-top: 18px;
`;

const VideoHeader = styled.div<{ manualInput: boolean }>`
  width: 100%;
  display: flex;
  background: #1f2937;
  ${({ manualInput }) =>
    manualInput ? `background: white;` : `background: #1f2937;`}
  padding: 29px 25px;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    padding-left: 31px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ManualInputWrapper = styled.div`
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
`;

const BadgeInput = styled(TextInput)`
  width: 100%;
  margin-top: 16px;
`;

const ManualInputButtons = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  button {
    width: 100%;
  }
`;

const ErrorMessageWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessMessageWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 18px;
  background: #eff8fa;
  border-radius: 4px;
`;

const CameraModal = styled(Modal)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const StyledDropdown = styled(DropdownMenu)`
  border: 1px solid #d1d5db;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-width: 0;
  left: 0;
  width: 100% !important;
  top: -30px;
`;
