import { Spacer, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Link, RadioButton, TextInput } from "north.js";
import React, { useState } from "react";
import styled from "styled-components";

import { errorToast, Icon, successToast } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { isPhoneNumber, notBlank } from "src/shared/utils/validation";

import { BusComponent } from "./busComponent";
import TravelModal from "./travelModal";

const ACCEPTED_FIELDS = [
  Field.TRAVEL_PHONE_NUMBER, // string
  Field.BUS_COORDINATOR_INTEREST, // boolean
];

const VALIDATORS = {
  [Field.TRAVEL_PHONE_NUMBER]: notBlank,
  [Field.BUS_COORDINATOR_INTEREST]: (value: TAnswer) =>
    value === true || value === false,
};

export const BusForm = () => {
  const [showError, setShowError] = useState(false);

  const [isBlocking, setIsBlocking] = useState(false);

  const [showBusModal, setShowBusModal] = useState(false);

  const { responsesState, setResponsesState } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const isPhoneNumberValid = isPhoneNumber(
    responsesState[Field.TRAVEL_PHONE_NUMBER]
  );

  const { updateResponses, navigateNext } = useHackerContext();

  const customValid = () => {
    const valid =
      notBlank(responsesState[Field.TRAVEL_PHONE_NUMBER]) ||
      notBlank(responsesState[Field.BUS_COORDINATOR_INTEREST]);

    return valid;
  };

  const submit = async () => {
    if (customValid()) {
      try {
        const { errors } = await updateResponses(responsesState);
        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }
        successToast("Answers successfully saved!");
        navigateNext();
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast((e as Error).message);
        }
      }
    }
  };

  // TODO: fix the prompt to actually show up

  return (
    <>
      <BusComponent isBlocking={isBlocking} setIsBlocking={setIsBlocking} />
      <Spacer height={36} />
      <Text mods="bold textBody">Phone number</Text>
      <Spacer height={12} />
      <Text>
        Please put a number that you will have access to the days of the event.
        If you&apos;re not from Canada or the United States, remember to also
        add your country&apos;s calling code.
      </Text>
      <Spacer height={12} />
      <InputWrapper
        maxLength={200}
        placeholder="Enter your phone number"
        value={responsesState[Field.TRAVEL_PHONE_NUMBER] ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setResponsesState(Field.TRAVEL_PHONE_NUMBER, e.currentTarget.value); // TODO: validate phone number
        }}
        size="md"
        error={
          showError &&
          !isPhoneNumberValid &&
          "Please enter a valid phone number"
        }
      />
      <HelperText>
        If you&apos;re running late, we&apos;ll use this number to contact you
        and make sure you&apos;re on your way so we can wait for you! If you
        don&apos;t have a phone, please indicate the best way to reach you
        quickly and reliably during the days of the event.
      </HelperText>
      <Spacer height={42} />
      <Text mods="h3 bold">Volunteer to Become a Bus Coordinator</Text>
      <Spacer height={16} />
      <Text>
        More details about the roles and responsibilities of a bus coordinator
        are{" "}
        <Link
          href="https://docs.google.com/document/d/19IuZLNGQc0vekSYrxYFZx-RvK4-1QV8kbqqczw3ij-Q/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ fontWeight: "bold" }}>here</span>
        </Link>
        .
      </Text>
      <Spacer height={24} />
      <Text mods="bold textBody">
        Would you be interested in being a bus coordinator?
      </Text>
      <Spacer height={12} />
      {[
        { option: "Yes", val: true },
        { option: "No", val: false },
      ].map(({ option, val }) => (
        <>
          <StyledRadioButton
            key={option}
            id={option}
            name="needsVisa"
            label={option}
            checked={responsesState[Field.BUS_COORDINATOR_INTEREST] === val}
            onChange={() => {
              setResponsesState(Field.BUS_COORDINATOR_INTEREST, val);
              setIsBlocking(true);
            }}
            showErrors={
              showError &&
              responsesState[Field.BUS_COORDINATOR_INTEREST] === null
            }
          />
          <Spacer height={12} />
        </>
      ))}
      <Spacer height={80} />
      <Button
        trailingIcon={<Icon name="arrow-right" />}
        color="primary"
        size="lg"
        onClick={() => {
          setShowError(true);
          if (
            responsesState[Field.TRAVEL_PHONE_NUMBER] !== null &&
            isPhoneNumberValid &&
            responsesState[Field.BUS_COORDINATOR_INTEREST] !== null
          ) {
            setShowBusModal(true);
          } else {
            window.scrollTo(0, 0);
            errorToast("Please fill out all the required fields.");
          }
        }}
      >
        Submit travel plan
      </Button>
      <TravelModal
        isOpen={showBusModal}
        onConfirm={submit}
        onCancel={() => setShowBusModal(false)}
      />
    </>
  );
};

const InputWrapper = styled(TextInput).attrs({ size: "md" })`
  width: 100%;
`;

const HelperText = styled.p`
  margin-top: 4px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

const StyledRadioButton = styled(RadioButton)<{
  showErrors: boolean;
}>`
  ${({ showErrors, theme }) =>
    showErrors
      ? `border: none; box-shadow: 0 0 0 2px ${theme.globalConstants.color.textDanger}`
      : ``}
`;
