/**
 * TODO: MAKE THIS INTO AN IndividualsContext or something so it can fetch information about any role
 */
/* eslint-disable @typescript-eslint/camelcase */
import React, { createContext, useContext } from "react";

import { useCareerSponsorState } from "./useCareerSponsorState";

export interface CareerSponsorContextState {
  techTalks: ReturnType<typeof useCareerSponsorState>["techTalks"];
  companies: ReturnType<typeof useCareerSponsorState>["companies"];
  loading: ReturnType<typeof useCareerSponsorState>["loading"];
}

const DEFAULT_STATE: CareerSponsorContextState = {
  techTalks: [],
  companies: [],
  loading: false,
};

const CareerSponsorContext: React.Context<CareerSponsorContextState> =
  createContext(DEFAULT_STATE);

export const useCareerSponsorContext = () => useContext(CareerSponsorContext);

export const CareerSponsorContextProvider: React.FC = ({ children }) => {
  const { techTalks, companies, loading } = useCareerSponsorState();

  return (
    <CareerSponsorContext.Provider value={{ techTalks, companies, loading }}>
      {children}
    </CareerSponsorContext.Provider>
  );
};
