import { TimePrecision, DateInput } from "@blueprintjs/datetime";
import { Spacer } from "@hackthenorth/north";
import React, { useMemo, useCallback } from "react";
import styled from "styled-components";

import { stringToDate, dateToString } from "src/shared/utils/date";

import { TextInputTitle } from "../../TextComponents";
import { PerkError } from "../PerkError";

type TPerkDatePickerProps = {
  title: string | React.ReactNode;
  value: string;
  isReadOnly: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  error?: React.ReactNode;
};

const StyledDateInput = styled(DateInput)<{ error: boolean }>`
  & input {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    border: 1px solid
      ${({ error }) =>
        error ? "rgba(203, 46, 46, 1)" : "rgba(209, 213, 219, 1)"};
    color: rgba(31, 41, 55, 1);
    --tw-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    padding: 20px 16px;
  }

  & input:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
    --tw-ring-offset-width: 0px;
    --tw-ring-color: rgba(117, 165, 238, 0.5);
    border: 1px solid rgba(117, 165, 238, 1);
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`;

export const PerkDatePicker: React.FC<TPerkDatePickerProps> = ({
  value,
  onChange,
  isReadOnly,
  error,
  title,
}) => {
  const date = useMemo(() => stringToDate(value), [value]);
  const internalOnChange = useCallback(
    (date: Date) => {
      if (!isReadOnly) onChange(dateToString(date));
    },
    [onChange, isReadOnly]
  );

  return (
    <>
      <TextInputTitle>{title}</TextInputTitle>
      <Spacer height={8} />
      <StyledDateInput
        value={date}
        onChange={internalOnChange}
        timePrecision={TimePrecision.SECOND}
        parseDate={stringToDate}
        formatDate={(d: Date) => d.toLocaleString()}
        //showActionsBar
        error={error as boolean}
      />
      {error && <PerkError error={error} />}
    </>
  );
};
