import { GlobalConstants } from "@hackthenorth/north";

export const globalConstants: GlobalConstants = {
  color: {
    // current
    white: "#FFFFFF",
    gray: "#E5E7EB",
    black: "#000000",
    focus: "#75A5EE",
    transparent: "transparent",
    brandPrimary: "#75A5EE",
    // brandSecondary: "#0E3460",
    // textDark: "#000000",
    textBody: "#1F2937",
    textSecondary: "#6B7280",
    textTertiary: "#9CA3AF",
    textLight: "#FFFFFF",
    textPrimary: "#43AFDE",
    textSuccess: "#54C152",
    textDanger: "#D95858",
    textWarning: "#D97706",
    backgroundPrimaryLight: "#CEEAF6",
    backgroundSuccessLight: "#CBECCA",
    backgroundDangerLight: "#F7DDDD",
    backgroundWarningLight: "#FEF3C7",
    backgroundPrimary: "#43AFDE",
    backgroundSuccess: "#54C152",
    backgroundDanger: "#D95858",
    backgroundWarning: "#D95858",
    // backgroundWhite: "#FFFFFF",
    backgroundLight: "#F9FAFB",
    backgroundGray: "#F3F4F6",
    backgroundGrayDark: "#E5E7EB",
    backgroundDark: "#111827",
    backgroundBlack: "#000000",
    borderLight: "#F6F8FA",
    borderGray: "#D1D5DB",
    borderGrayDark: "#374151",
    borderDark: "#1F2937",
    borderPrimary: "#2395C6",
    borderSuccess: "#3CA13A",
    borderDanger: "#CB2E2E",
    borderWarning: "#FCD34D",
    borderFocus: "#75A5EE",
    borderFocusLight: "#75A5EE80",

    // incoming

    redPrimary1: "#F4AC9E",
    redPrimary2: "#F3675A",
    redPrimary3: "#F8D7D0",

    bluePrimary1: "#0A446D",
    bluePrimary2: "#4779A4",
    bluePrimary3: "#7EC2E4",

    lightBlueSecondary: "#E9EEF2",
    lightBlueHover: "#DCE3E8",

    redSecondary: "#AD0423",
    greenSecondary: "#D1FAE5",
    navySecondary: "#004D85",
    tealSecondary: "#1DC1CC",
    tanSecondary: "#FFF6EE",
    greySecondary: "#EEF2F2",
    creamSecondary: "#FCEEE1",
    brandSecondary: "#0E3460",

    greyTooltip: "#525252",

    gradientBluePink: "linear-gradient(180deg, #D1DCEB 14.06%, #F9ECEC 100%)",

    gradientRedBlue135: "linear-gradient(135deg, #F3625B 0%, #024B85 100%)",
    gradientRedBlue172: "linear-gradient(172.17deg, #F3625B 0%, #024B85 100%)",
    gradientTan330: "linear-gradient(320.22deg, #FFEEDF 0%, #FFFFFF 100%)",

    backgroundWhite: "#FFFCFC",
    backgroundBlue: "#F5FCFF",
    offWhite: "#FAFCFF",

    // white: WHITE,
    // textLight: WHITE,
    textDark: "#0E3460",
    textGrey: "#828282",
    textLightGrey: "#C4C4C4",

    borderGrey: "rgba(136, 136, 136, 0.2)",
    redPrimary1Light: "rgba(244, 172, 158, 0.2)",
    bluePrimary3Light: "#E5F6FE",
    creamSecondaryDark: "#FFDAAE",

    assetBlue: "#1E5983",

    yellowPrimary: "#F7CE58",
    bluePrimary: "#1FA6FF",
    cyanPrimary: "#19FBFF",
    purplePrimary: "#896BFF",
    pinkPrimary: "#FF2CFB",
    orangePrimary: "#F09343",
  },
  fontFamily: {
    heading:
      "Castledown, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    body: "Satoshi, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    code: "Courier, monospace",
  },
  fontSize: {
    title: 42,
    h1: 28,
    h2: 26,
    h3: 20,
    subtitle: 16,
    button: 14,
    body: 16,
    caption: 12,
    titlemobile: 36,
    h1mobile: 28,
    h2mobile: 20,
    h3mobile: 14,
  },
  borderRadius: {
    small: 5,
    medium: 16,
    large: 50,
  },
  padding: {
    medium: "24px",
    link: "18px",
  },
  boxShadow: {
    halo: "3px 3px 30px #ebdcd9",
    regular: "3px 4px 10px rgba(0, 0, 0, 0.05)",
    dark: "3px 3px 40px rgba(153, 172, 189, 0.87)",
    light: "0px 0px 80px rgba(29, 70, 100, 0.02)",
  },
};

export default globalConstants;
