import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { optionsToNorthV2Options } from "src/shared/utils/react-select";
import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkSelect } from "../components";
import Header from "../Header";
import { capitalizeFirstLetter, perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

import { ACTIVITIES } from "./constants";

export const SPONSOR_AN_ACTIVITY_VALIDATION_FIELDS: (keyof SponsorPerksData)[] =
  [
    "sponsor_an_activity_selection_one",
    "sponsor_an_activity_selection_two",
    "sponsor_an_activity_selection_three",
  ];

export const SponsorAnActivity: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const snackItems = ACTIVITIES.map((activity) => {
    return {
      label: capitalizeFirstLetter(activity),
      value: activity,
    };
  });

  const sameChoiceError =
    getState("sponsor_an_activity_selection_one") ===
      getState("sponsor_an_activity_selection_two") ||
    getState("sponsor_an_activity_selection_two") ===
      getState("sponsor_an_activity_selection_three") ||
    getState("sponsor_an_activity_selection_three") ===
      getState("sponsor_an_activity_selection_one");

  return (
    <>
      <Header
        title="Sponsor an Activity"
        subtitle="This perk supports one of the many fun activities that our team will be hosting throughout the event."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("sponsor_an_activity_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("sponsor_an_activity_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.SPONSOR_AN_ACTIVITY}
        readOnly={isReadOnly}
      >
        {sameChoiceError && (
          <PerkError
            error={<>Make sure your activity selections are different</>}
          />
        )}
        <Spacer height={8} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("sponsor_an_activity_selection_one", e.target.value)
          }
          value={getState("sponsor_an_activity_selection_one") ?? ""}
          label="Activity 1*"
          isReadOnly={isReadOnly}
          error={
            error?.sponsor_an_activity_selection_one && (
              <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(snackItems)}
        </PerkSelect>
        <Spacer height={32} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("sponsor_an_activity_selection_two", e.target.value)
          }
          value={getState("sponsor_an_activity_selection_two") ?? ""}
          label="Activity 2*"
          isReadOnly={isReadOnly}
          error={
            error?.sponsor_an_activity_selection_two && (
              <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(snackItems)}
        </PerkSelect>
        <Spacer height={32} />
        <PerkSelect
          fullWidth
          size="lg"
          onChange={(e) =>
            updateState("sponsor_an_activity_selection_three", e.target.value)
          }
          value={getState("sponsor_an_activity_selection_three") ?? ""}
          label="Activity 3*"
          isReadOnly={isReadOnly}
          error={
            error?.sponsor_an_activity_selection_three && (
              <>This field is required</>
            )
          }
        >
          {optionsToNorthV2Options(snackItems)}
        </PerkSelect>
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription>
      Show hackers your fun side by sponsoring one of the many activities our
      team will be hosting throughout the event!
    </TextDescription>
  </>
);
