export const ACTIVITIES = [
  "fire noodle challenge",
  "s’mores",
  "friendship bracelets",
  "dalgona candy challenge",
  "silent disco",
  "map signing",
  "spikeball",
  "inflatables",
  "giant board games",
  "yoga",
];
