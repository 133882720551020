import { Flex } from "@hackthenorth/north";
import { Button } from "north.js";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "twin.macro";

import { IconName } from "src/shared/components/Icon";
import { ExternalLink } from "src/static/icons";
import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import {
  RELEASED_PERKS,
  PerkStatus,
  PERK_TO_STATUS_SLUGS,
  SponsorPerkType,
  PERKS_TO_DUE_DATES,
  PERKS_TO_LABELS,
} from "src/views/sponsor/perks/types";

import Header, { TOPNAVBAR_HEIGHT } from "../../../shared/components/Header";
import Onboarding from "../home/Onboarding";

import { PerkList, PerksProgressTracker, Legend } from "./components";
import Perk from "./components/Perk";

const SponsorPerksPage = () => {
  const { perks, refetchPerks } = useSponsorPerksContext();
  const [selectedPerk, setSelectedPerk] = useState<string | null>(null);
  const availablePerks = useMemo(
    () => perks?.available_perks.value ?? ([] as SponsorPerkType[]),
    [perks]
  );

  const filteredPerks = useMemo(
    () =>
      availablePerks.filter((p: any) =>
        Object.values(RELEASED_PERKS).includes(p as SponsorPerkType)
      ) ?? ([] as SponsorPerkType[]),
    [availablePerks]
  );

  useEffect(() => setSelectedPerk(filteredPerks[0]), [filteredPerks]);

  const perksByStatus = useMemo(
    () =>
      Object.values(PerkStatus).reduce(
        (obj, status) => ({
          ...obj,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [status]: availablePerks.filter((type: any) => {
            // Group perks by status and only include them if they've been released
            const perkStatus = perks?.[PERK_TO_STATUS_SLUGS[type]]?.value;
            return (
              perkStatus === status &&
              RELEASED_PERKS.includes(type as SponsorPerkType)
            );
          }),
        }),
        {}
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availablePerks]
  );

  const formattedPerks = useMemo(() => {
    return filteredPerks.map((perk: string) => {
      let iconName: IconName;
      const status = perks?.[PERK_TO_STATUS_SLUGS[perk]]?.value;
      if (status === PerkStatus.APPROVED) iconName = "perk-completed";
      else if (status === PerkStatus.SUBMITTED) iconName = "perk-under-review";
      else iconName = "perk-in-progress";

      return {
        perkSlug: perk,
        perkName: PERKS_TO_LABELS[perk],
        iconName,
        dueDate: perks?.[PERKS_TO_DUE_DATES[perk]]?.value,
        status: status as PerkStatus,
      };
    });
  }, [filteredPerks, perks]);

  return (
    <>
      <Onboarding />
      <Header
        title="Perks"
        subtitle="Submit information to get the most out of your sponsorship experience"
        actionButton={
          <Button
            leadingIcon={<ExternalLink />}
            color="primary"
            onClick={() =>
              window.open(
                "https://docs.google.com/document/d/12N5R-MyunHAOpKGAxkpviLCCTW4f9xpi5eAUMLeb-_I/edit?usp=sharing",
                "_blank"
              )
            }
          >
            Sponsor FAQ
          </Button>
        }
      />
      <TwoColumnContainer TOPNAVBAR_HEIGHT={TOPNAVBAR_HEIGHT}>
        <SecondaryCol>
          <SecondaryColContainer>
            <PerksProgressTracker
              completed={
                perksByStatus
                  ? perksByStatus["Submitted"]?.length +
                    perksByStatus["Approved"]?.length
                  : 0
              }
              totalPerks={filteredPerks.length || 1}
            />
            <PerkList
              perksArr={formattedPerks}
              updateSelectedPerk={(perk: string) => {
                setSelectedPerk(perk);
                refetchPerks();
              }}
              selectedPerk={selectedPerk}
            />
            <Legend />
          </SecondaryColContainer>
        </SecondaryCol>
        <PrimaryCol>
          {selectedPerk && (
            <Perk key={selectedPerk} type={selectedPerk as SponsorPerkType} />
          )}
        </PrimaryCol>
      </TwoColumnContainer>
    </>
  );
};

const TwoColumnContainer = styled.div<{
  TOPNAVBAR_HEIGHT: number;
}>`
  width: 100%;
  height: calc(100vh - ${TOPNAVBAR_HEIGHT}px);
  display: flex;
`;

const SecondaryCol = styled.div`
  width: 500px;
  border-right: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGray};
  height: calc(100vh - ${TOPNAVBAR_HEIGHT}px);
`;

const PrimaryCol = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.globalConstants.color.white};
  height: calc(100vh - ${TOPNAVBAR_HEIGHT}px);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SecondaryColContainer = styled(Flex).attrs({
  align: "center",
  column: true,
  justify: "space-between",
})`
  height: 100%;
  padding: 36px 0 24px 0;
`;

export default SponsorPerksPage;
