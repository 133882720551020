import { Flex, Spacer, Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { TSignageQuestionProps } from "../types";

import { AnsweredBadge, NotAnsweredBadge, QuestionBadge } from "./Badges";

const QuestionCard: React.FC<TSignageQuestionProps> = ({
  idx,
  question_id,
  question_name,
  is_answered,
  workshop_name,
}) => {
  const handleClick = () => {
    window.open(
      `${window.location.href.split("/")[0]}/questions/` + question_id,
      "_self"
    );
  };

  return (
    <>
      <QuestionWrapper onClick={handleClick}>
        <PanelButton>
          <Flex
            row
            style={{
              marginBottom: "10px",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <QuestionBadge labelText={"Q" + idx} />
            <Spacer width={5} />
            {is_answered ? <AnsweredBadge /> : <NotAnsweredBadge />}
          </Flex>
          <Text mods="heading grey">{question_name}</Text>
        </PanelButton>
      </QuestionWrapper>
    </>
  );
};

const QuestionWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--north-border-gray, #d1d5db);
  background: var(--north-background-white, #fff);

  /* North/Shadow/base */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  margin: 16px 0;
  cursor: pointer;
`;

const PanelButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-align: left;
`;

export default QuestionCard;
