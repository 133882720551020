import { UnstyledButton } from "@hackthenorth/north";
import { Alert, Button, Link, TextArea } from "north.js";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";

import { Divider, Icon } from "src/shared/components";
import { CheckCircleInvertedGreen, InfoIcon } from "src/static/icons";

import { AnsweredBadge, NotAnsweredBadge } from "../Badges";
import { useAnswerSignageQuestionMutation } from "../graphql/answerSignageQuestion.generated";
// import { useGetSignageQuestionAnswersQuery } from "../graphql/getSignageQuestionAnswers.generated";
import { useGetSignageQuestionByIdQuery } from "../graphql/getSignageQuestionById.generated";

const Question = () => {
  const [response, setResponse] = useState("");
  const [submitted, setSubmitted] = useState(false); // a NEW submission, not previous response
  const [isEditing, setIsEditing] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  const { data: questionInfo } = useGetSignageQuestionByIdQuery({
    variables: {
      question_id: parseInt(id),
    },
    onCompleted: (data) => {
      if (
        data.getSignageQuestionById.signage_answers &&
        data.getSignageQuestionById.signage_answers.length !== 0
      ) {
        setResponse(data.getSignageQuestionById.signage_answers[0].value);
        setIsEditing(false);
      }
    },
  });

  // const { data: questionResponses } = useGetSignageQuestionAnswersQuery({
  //   variables: {
  //     question_id: parseInt(id),
  //     limit: 3,
  //     refresh: true,
  //   },
  // });

  const [answerSignageQuestion] = useAnswerSignageQuestionMutation();

  const answerQuestion = useCallback(async () => {
    await answerSignageQuestion({
      variables: {
        question_id: parseInt(id),
        answer: response,
      },
    });
    setSubmitted(true);
    setIsEditing(false);
  }, [answerSignageQuestion, id, response]);

  return (
    <Wrapper>
      <UnstyledButton onClick={() => navigate(-1)}>
        <Icon name="x" width="12px" height="12px" strokeWidth="1.2px" />
      </UnstyledButton>
      <SectionContainer>
        <BadgeContainer>
          {questionInfo?.getSignageQuestionById.signage_answers?.length === 0 &&
          !submitted ? (
            <NotAnsweredBadge />
          ) : (
            <AnsweredBadge />
          )}
        </BadgeContainer>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Heading>{questionInfo?.getSignageQuestionById.title}</Heading>
          <CharacterLimit>(150 character limit)</CharacterLimit>
        </div>
        {!isEditing ? (
          <p style={{ fontSize: "16px", fontWeight: 500, color: "#1F2937" }}>
            {response}
          </p>
        ) : (
          <TextArea
            autoResize
            fullWidth
            maxChars={150}
            placeholder="Enter your response"
            value={response}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setResponse(e.target.value);
            }}
            style={{ height: "17vh", fontSize: "16px" }}
          />
        )}
        {submitted ? (
          // NEW submission
          <Alert
            color="success"
            icon={<CheckCircleInvertedGreen />}
            style={{ fontWeight: 500 }}
          >
            Your response has been shared on{" "}
            <a
              href="https://charts.hackthenorth.com/"
              style={{
                fontWeight: 700,
                textDecoration: "underline",
              }}
            >
              charts.hackthenorth.com
            </a>
          </Alert>
        ) : questionInfo?.getSignageQuestionById.signage_answers &&
          questionInfo?.getSignageQuestionById.signage_answers.length === 0 ? (
          // no answer
          <Alert
            color="primary"
            icon={<InfoIcon />}
            style={{ fontWeight: 500 }}
          >
            Your response will be displayed on{" "}
            <Link href="https://charts.hackthenorth.com/">
              <span style={{ fontWeight: 700 }}>charts.hackthenorth.com</span>
            </Link>{" "}
            once submitted
          </Alert>
        ) : (
          // OLD submission
          !isEditing && (
            <Alert
              color="primary"
              icon={<InfoIcon />}
              style={{ fontWeight: 500 }}
            >
              Your response is displayed on{" "}
              <a
                href="https://charts.hackthenorth.com/"
                style={{ fontWeight: 700, textDecoration: "underline" }}
              >
                charts.hackthenorth.com
              </a>
            </Alert>
          )
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          {submitted && (
            <p style={{ color: "#6B7280", padding: "10px 20px" }}>Submitted!</p>
          )}
          {!isEditing ? (
            <Button
              color="secondary"
              size="md"
              onClick={() => setIsEditing(true)}
            >
              <span style={{ fontWeight: 500 }}>Edit</span>
            </Button>
          ) : (
            <Button
              color="primary"
              size="md"
              disabled={response.length === 0 || response.length > 150}
              onClick={() => answerQuestion()}
            >
              <span style={{ fontWeight: 500 }}>Submit</span>
            </Button>
          )}
        </div>
      </SectionContainer>
      <Divider />
      {/* <SectionContainer>
        <Heading>Other hackers said...</Heading>
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            gap: "12px",
          }}
        >
          {questionResponses?.getSignageQuestionAnswers.map((response, i) => (
            <div key={i} style={{ display: "flex" }}>
              <ResponseCard
                response={response.value}
                name={response.first_name}
                time={response.answered_at}
                isAnon={
                  questionInfo?.getSignageQuestionById.title.includes(
                    "This will be anonymous"
                  ) || false
                }
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "100px",
          }}
        >
          <Button color="secondary" size="md">
            <a
              href="https://charts.hackthenorth.com/"
              style={{
                fontWeight: 500,
                textDecoration: "none",
                color: "#000000",
              }}
            >
              See all responses
            </a>
          </Button>
        </div>
      </SectionContainer> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  gap: 32px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const Heading = styled.h3`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: 20px;
  font-weight: 900;
`;

const CharacterLimit = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.globalConstants.color.textSecondary};
`;

export default Question;
