import { Text, Spacer } from "@hackthenorth/north";
import { InlineError, InlineHint } from "north.js";
import React from "react";

type LabelProps = {
  value: React.ReactNode;
  children?: React.ReactNode;
  caption?: React.ReactNode;
  error?: React.ReactNode;
};
const Label: React.FC<LabelProps> = ({ value, children, caption, error }) => {
  return (
    <div>
      <Text mods="big bold textBody">{value}</Text>
      <Spacer height={12} />
      {children}
      {!!caption && !error && <InlineHint>{caption}</InlineHint>}
      {!!error && <InlineError>{error}</InlineError>}
    </div>
  );
};

export default Label;
