/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spacer, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Link, RadioButton, Spinner, TextInput } from "north.js";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";

import "twin.macro";

import {
  PageWrapper,
  Dropzone,
  IMAGE_FILE_TYPES,
  FileLink,
} from "src/shared/components";
import { successToast, errorToast } from "src/shared/components/Toast";
import { useHackerContext } from "src/shared/contexts";
import {
  Field,
  HackerStage,
  PageStage,
} from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize, useIsMounted } from "src/shared/hooks";
import { Nullable } from "src/shared/utils/typescript";
import { ArrowRight, WaiverDownload } from "src/static/icons";

import { useFileDropzone } from "../career/useFileDropzone";
import RsvpModal from "../home/modals/rsvpModal";
import RsvpSidebar from "../rsvp-sidebar";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";

const isEmpty = (s: Nullable<string>) => s === "" || s === null;

const ConfirmationPage = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isMounted = useIsMounted();
  const {
    updateResponses,
    isLoading,
    navigateNext,
    updateStage,
    navigateBack,
  } = useHackerContext();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const [showErrors, setShowErrors] = useState(false);
  const [showRsvpModal, setShowRsvpModal] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const isAgeConsentValid = responsesState[Field.AGE_18] !== null;
  const isGuardianNameValid =
    responsesState[Field.AGE_18] !== false ||
    !isEmpty(responsesState[Field.GUARDIAN_NAME]);
  const isGuardianContactValid =
    responsesState[Field.AGE_18] !== false ||
    !isEmpty(responsesState[Field.GUARDIAN_CONTACT]);
  const isWaiverValid =
    responsesState[Field.AGE_18] !== false ||
    responsesState[Field.WAIVER_CONSENT] !== null;

  const ACCEPTED_FILE_TYPES = IMAGE_FILE_TYPES;

  const {
    dropzoneInputRef,
    fetchedFile,
    loading: isFileLoading,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(
    responsesState[Field.WAIVER_CONSENT]?.[0],
    (files) => {
      setResponsesState(Field.WAIVER_CONSENT, files);
    },
    ACCEPTED_FILE_TYPES
  );

  useEffect(() => {
    if (
      isValid &&
      isAgeConsentValid &&
      isGuardianNameValid &&
      isGuardianContactValid &&
      isWaiverValid &&
      isMounted
    ) {
      navigateNext();
    }
    if (responsesState[Field.CAREER_RSVP_STAGE] !== PageStage.COMPLETED) {
      navigateBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {});
  });

  useEffect(() => {
    if (isValid && isMounted) {
      navigateNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  const onSave = async () => {
    if (
      isValid &&
      isAgeConsentValid &&
      isGuardianNameValid &&
      isGuardianContactValid &&
      isWaiverValid
    ) {
      try {
        const responses = {
          ...responsesState,
          [Field.CONFIRMATION_RSVP_STAGE]: PageStage.COMPLETED,
        };

        if (fetchedFile && !isFileLoading && !fileError) {
          responses[Field.WAIVER_CONSENT] = fetchedFile;
        }

        const { errors } = await updateResponses(responses);

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        setShowRsvpModal(true);
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e.message);
        }
      }
    } else {
      window.scrollTo(0, 0);
      errorToast("Please fill out all the required fields.");
      setShowErrors(true);
    }
  };

  const onConfirm = async () => {
    try {
      const { errors } = await updateStage(HackerStage.CONFIRMED);

      if (errors) {
        throw new Error(
          "We weren't able to submit your answers, please try again."
        );
      }

      successToast("Answers successfully submitted!");
      setSubmitting(true);
      setShowRsvpModal(false);
    } catch (e) {
      if (typeof e === "object") {
        errorToast((e as GraphQLError)?.message);
      } else {
        errorToast(e.message);
      }
    }
  };

  return (
    <PageWrapper>
      <Prompt
        when={isBlocking && !submitting}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      {isTabletOrSmaller && <RsvpSidebar currentRoute="Confirmation" />}

      <Heading1Text>Confirmation</Heading1Text>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>
      <RsvpModal
        isOpen={showRsvpModal}
        onConfirm={onConfirm}
        onCancel={() => setShowRsvpModal(false)}
      />

      <Spacer height={42} />
      <Text mods="bold textBody">
        Will you be 18 years of age or older as of September 13, 2024?
      </Text>
      <Spacer height={12} />
      <StyledRadioButton
        id="yes"
        name="age"
        label="Yes"
        checked={responsesState[Field.AGE_18] === true}
        onChange={() => {
          setIsBlocking(true);
          setResponsesState(Field.AGE_18, true);
        }}
        isValid={
          responsesState[Field.AGE_18] === true ||
          responsesState[Field.AGE_18] === false
        }
        showErrors={showErrors}
      />
      <Spacer height={12} />
      <StyledRadioButton
        id="no"
        name="age"
        label="No"
        checked={responsesState[Field.AGE_18] === false}
        onChange={() => {
          setIsBlocking(true);
          setResponsesState(Field.AGE_18, false);
        }}
        isValid={
          responsesState[Field.AGE_18] === true ||
          responsesState[Field.AGE_18] === false
        }
        showErrors={showErrors}
      />

      {responsesState[Field.AGE_18] === false && (
        <div>
          <Spacer height={24} />
          <Text mods="grey">
            Hackers under 18 will be required to have a parent or guardian read
            and sign Hack the North’s Terms and Conditions and Code of Conduct.
          </Text>

          <Spacer height={24} />
          <Text mods="bold textBody">Parent/Guardian’s full name</Text>
          <Container isValid={isGuardianNameValid} showErrors={showErrors}>
            <InputWrapper
              type="text"
              placeholder="Enter your parent or guardian’s full name"
              value={responsesState[Field.GUARDIAN_NAME] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(Field.GUARDIAN_NAME, e.currentTarget.value);
              }}
            />
          </Container>

          <Spacer height={24} />
          <Text mods="bold textBody">
            Parent/Guardian’s email or phone number
          </Text>
          <Container isValid={isGuardianContactValid} showErrors={showErrors}>
            <InputWrapper
              type="text"
              placeholder="Enter an email or phone number"
              value={responsesState[Field.GUARDIAN_CONTACT] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(
                  Field.GUARDIAN_CONTACT,
                  e.currentTarget.value
                );
              }}
            />
          </Container>
          <Spacer height={24} />
          <Text mods="bold textBody">
            Terms and Conditions and Code of Conduct
          </Text>
          <div>
            <DownloadWaiver
              download
              href={`${window.location.origin}/waiver.pdf`}
            >
              <Button size="md">
                <FlexRow>
                  <WaiverDownload />
                  <Spacer width={10} />
                  Download waiver
                </FlexRow>
              </Button>
            </DownloadWaiver>
          </div>

          <Spacer height={12} />
          <p>
            Please download the above waiver and upload a copy signed by a
            parent or guardian.
          </p>
          <Spacer height={12} />
          <Dropzone
            label={
              <span>
                <b>Upload a file</b> or drag and drop
              </span>
            }
            acceptedFileTypes={ACCEPTED_FILE_TYPES}
            onFilesUpload={addFile}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
          {fetchedFile && (
            <>
              <Spacer height={8} />
              <FileLink file={fetchedFile} deleteFile={deleteFile} />
            </>
          )}
        </div>
      )}

      <Spacer height={100} />
      <FlexRow>
        <Button color="primary" disabled={isLoading} onClick={onSave} size="md">
          {isLoading ? (
            <Spinner />
          ) : (
            <FlexRow>
              <p>Submit RSVP</p>
              <Spacer width="8px" />
              <ArrowRight width={16} />
            </FlexRow>
          )}
        </Button>
        <Spacer width="32px" />
      </FlexRow>
      <Spacer height={24} />
    </PageWrapper>
  );
};

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const InputWrapper = styled(TextInput)`
  width: 100%;
`;

const Container = styled.div<{
  isValid: boolean;
  showErrors: boolean;
}>`
  border-radius: 6px;
  ${({ isValid, showErrors, theme }) =>
    !isValid && showErrors
      ? `border: none; box-shadow: 0 0 0 2px ${theme.globalConstants.color.textDanger}`
      : ``}
`;

const StyledRadioButton = styled(RadioButton)<{
  isValid: boolean;
  showErrors: boolean;
}>`
  ${({ isValid, showErrors, theme }) =>
    !isValid && showErrors
      ? `border: none; box-shadow: 0 0 0 2px ${theme.globalConstants.color.textDanger}`
      : ``}
`;

const FlexRow = styled.div`
  align-items: center;
  display: flex;
`;

const DownloadWaiver = styled(Link)`
  text-decoration: none !important;
  margin-top: 12px;
`;

export default ConfirmationPage;
