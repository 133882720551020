import React, { useMemo } from "react";
import { useTheme } from "styled-components";

import {
  CopyIcon,
  XMarkIcon,
  UploadIcon,
  MaximizeIcon,
  EyeIcon,
  EyeOffIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  StarIcon,
  StarOutlineIcon,
  ClockIcon,
  LogOutIcon,
  EditIcon,
  FileIcon,
  LockIcon,
  CheckIcon,
  WhiteCheckIcon,
  BlueCheckmarkIcon,
  GreyCheckmarkIcon,
  WhiteCheckmarkIcon,
  EmptyCheckmarkIcon,
  UsersIcon,
  SolidArrowIcon,
  CalendarIcon,
  ArrowPathIcon,
  MentorshipIcon,
  HardwareIcon,
  QuestionIcon,
  FileCloudIcon,
  BuildIcon,
  DateRangeIcon,
  FaceIcon,
  HelpOutlineIcon,
  HomeIcon,
  ProfileBadgeIcon,
  SendIcon,
  NotesIcon,
  FlagIcon,
  InfoIcon,
  FilterIcon,
  FileDownloadIcon,
  PeopleIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MediumIcon,
  TwitterIcon,
  ExpandIcon,
  SpeakerIcon,
  PlusIcon,
  JudgingIcon,
  ProfileIcon,
  CheckedDocumentIcon,
  DocumentIcon,
  SidebarUserInfoIcon,
  BlueGroupPeopleIcon,
  BlueStarsIcon,
  GreyGroupPeopleIcon,
  GreyStarsIcon,
  TikTokIcon,
  NetworkingIcon,
  KebabIcon,
  PerkCompletedIcon,
  PerkInProgressIcon,
  PerkNotStartedIcon,
  PerkUnderReviewIcon,
  ErrorCircleIcon,
  HorizontalKebabIcon,
  CheckmarkIcon,
  ErrorExclamationIcon,
  InboxInIcon,
  ArrowRight,
  NotSelectedStar,
  SelectedStar,
  MagnifyingGlassIcon,
  SponPlusIcon,
  HamburgerIcon,
  ArrowLeft,
  RadioEmptyIcon,
  RadioSelectedIcon,
  CheckCircle,
  SponDownloadIcon,
  DownArrow,
  ExternalLink,
  CheckCircleInverted,
  CheckCircleInvertedGreen,
} from "src/static/icons";

export type IconName =
  | "copy"
  | "x"
  | "upload"
  | "maximize"
  | "eye"
  | "eye-off"
  | "chevron"
  | "chevron-left"
  | "chevron-right"
  | "clock"
  | "log-out"
  | "edit"
  | "file"
  | "lock"
  | "check"
  | "white-check"
  | "blue-checkmark"
  | "grey-checkmark"
  | "white-checkmark"
  | "empty-checkmark"
  | "calendar"
  | "star"
  | "star-outline"
  | "users"
  | "solid-arrow"
  | "refresh"
  | "mentorship"
  | "hardware"
  | "question"
  | "file-cloud"
  | "build"
  | "date-range"
  | "face"
  | "help-outline"
  | "home"
  | "profile-badge"
  | "send"
  | "notes"
  | "flag"
  | "info"
  | "expand"
  | "filter"
  | "file-download"
  | "people"
  | "facebook"
  | "instagram"
  | "linkedin"
  | "medium"
  | "twitter"
  | "judging"
  | "profile"
  | "document"
  | "checked-document"
  | "speaker"
  | "plus"
  | "sidebar-user-info"
  | "blue-group-people"
  | "blue-stars"
  | "grey-group-people"
  | "grey-stars"
  | "tiktok"
  | "networking"
  | "kebab"
  | "perk-completed"
  | "perk-in-progress"
  | "perk-not-started"
  | "perk-under-review"
  | "error-circle"
  | "horizontal-kebab"
  | "checkmark"
  | "error-exclamation"
  | "inbox-in"
  | "arrow-right"
  | "not-selected-star"
  | "selected-star"
  | "search"
  | "spon-plus"
  | "hamburger"
  | "arrow-left"
  | "radio-empty"
  | "radio-selected"
  | "check-circle"
  | "check-circle-inverted"
  | "check-circle-inverted-green"
  | "spon-download"
  | "down-arrow"
  | "external-link";

export interface IconProps extends React.ComponentPropsWithoutRef<"svg"> {
  name: IconName;
  color?: string;
  /**
   * Pass in the name of a globalConstant fontSize value or a css value. Defaults to `body` size.
   */
  size?: string;
}

const NAME_TO_ICON_MAP: Record<
  IconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  copy: CopyIcon,
  x: XMarkIcon,
  upload: UploadIcon,
  maximize: MaximizeIcon,
  eye: EyeIcon,
  "eye-off": EyeOffIcon,
  chevron: ChevronDownIcon,
  "chevron-left": ChevronLeftIcon,
  "chevron-right": ChevronRightIcon,
  clock: ClockIcon,
  "log-out": LogOutIcon,
  edit: EditIcon,
  file: FileIcon,
  lock: LockIcon,
  check: CheckIcon,
  calendar: CalendarIcon,
  "white-check": WhiteCheckIcon,
  "blue-checkmark": BlueCheckmarkIcon,
  "grey-checkmark": GreyCheckmarkIcon,
  "white-checkmark": WhiteCheckmarkIcon,
  "empty-checkmark": EmptyCheckmarkIcon,
  star: StarIcon,
  "star-outline": StarOutlineIcon,
  users: UsersIcon,
  "solid-arrow": SolidArrowIcon,
  refresh: ArrowPathIcon,
  mentorship: MentorshipIcon,
  hardware: HardwareIcon,
  question: QuestionIcon,
  "file-cloud": FileCloudIcon,
  build: BuildIcon,
  "date-range": DateRangeIcon,
  face: FaceIcon,
  "help-outline": HelpOutlineIcon,
  home: HomeIcon,
  "profile-badge": ProfileBadgeIcon,
  send: SendIcon,
  notes: NotesIcon,
  flag: FlagIcon,
  info: InfoIcon,
  expand: ExpandIcon,
  filter: FilterIcon,
  "file-download": FileDownloadIcon,
  people: PeopleIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  medium: MediumIcon,
  twitter: TwitterIcon,
  speaker: SpeakerIcon,
  plus: PlusIcon,
  judging: JudgingIcon,
  profile: ProfileIcon,
  "checked-document": CheckedDocumentIcon,
  document: DocumentIcon,
  "sidebar-user-info": SidebarUserInfoIcon,
  "blue-group-people": BlueGroupPeopleIcon,
  "blue-stars": BlueStarsIcon,
  "grey-group-people": GreyGroupPeopleIcon,
  "grey-stars": GreyStarsIcon,
  tiktok: TikTokIcon,
  networking: NetworkingIcon,
  kebab: KebabIcon,
  "perk-completed": PerkCompletedIcon,
  "perk-in-progress": PerkInProgressIcon,
  "perk-not-started": PerkNotStartedIcon,
  "perk-under-review": PerkUnderReviewIcon,
  "error-circle": ErrorCircleIcon,
  "horizontal-kebab": HorizontalKebabIcon,
  checkmark: CheckmarkIcon,
  "error-exclamation": ErrorExclamationIcon,
  "inbox-in": InboxInIcon,
  "arrow-right": ArrowRight,
  "arrow-left": ArrowLeft,
  hamburger: HamburgerIcon,
  "radio-empty": RadioEmptyIcon,
  "radio-selected": RadioSelectedIcon,
  "check-circle": CheckCircle,
  "check-circle-inverted": CheckCircleInverted,
  "check-circle-inverted-green": CheckCircleInvertedGreen,
  "not-selected-star": NotSelectedStar,
  "selected-star": SelectedStar,
  search: MagnifyingGlassIcon,
  "spon-plus": SponPlusIcon,
  "spon-download": SponDownloadIcon,
  "down-arrow": DownArrow,
  "external-link": ExternalLink,
};

const Icon: React.FC<IconProps> = ({
  name,
  size = "body",
  color = "white",
  ...rest
}) => {
  const { globalConstants } = useTheme();

  const internalSize = globalConstants.fontSize[size]
    ? `${globalConstants.fontSize[size]}px`
    : size;
  const internalColor = globalConstants.color[color] || color;
  const IconSVG = useMemo(() => NAME_TO_ICON_MAP[name], [name]);

  if (!IconSVG) console.warn(`Invalid icon name: ${name}`);

  return IconSVG ? (
    <IconSVG
      width={internalSize}
      height={internalSize}
      fill={internalColor}
      {...rest}
    />
  ) : null;
};

export default Icon;
