import { Spacer } from "@hackthenorth/north";
import { Label } from "north.js";
import React from "react";

import { Dropzone, FileLink } from "src/shared/components";
import { WebsiteLogoData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import { PerkContainer, PerkError, PerkTextInput } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { useFileDropzone } from "../useFileDropzone";
import { PerkData } from "../usePerkState";

export const WEBSITE_LOGO_VALIDATION_FIELDS: (keyof WebsiteLogoData)[] = [
  "website_logo_file",
  "website_logo_website_link",
];

export const WebsiteLogo: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const {
    dropzoneInputRef,
    fetchedFile,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(getState("website_logo_file")?.[0], (files) =>
    updateState("website_logo_file", files)
  );

  return (
    <>
      <Header
        title="Website Logo"
        subtitle="This perk is for your company logo that will be displayed on the Hack the North website."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("website_logo_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("website_logo_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.WEBSITE_LOGO}
        readOnly={isReadOnly}
      >
        {!isReadOnly && (
          <Dropzone
            max={1}
            acceptedFileTypes={["png", "svg"]}
            onFilesUpload={addFile}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFile && (
          <>
            {!isReadOnly && <Spacer height={32} />}
            <FileLink file={fetchedFile} deleteFile={deleteFile} />
          </>
        )}
        {error?.website_logo_file && (
          <PerkError error="This field is required" />
        )}
        {fileError && <PerkError error={fileError} />}
        <Spacer height={32} />
        <PerkTextInput
          title="Website Link*"
          value={getState("website_logo_website_link") ?? ""}
          placeholder="Ex. Home Page"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("website_logo_website_link", e.target.value)
          }
          error={
            error?.website_logo_website_link && <>This field is required</>
          }
        />
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <TextDescription>
    Submit your company logo in SVG or PNG form (SVG is preferred). If you are
    submitting a PNG, please ensure the background is transparent and the image
    has a minimum height of 400px, so we can showcase your logo clearly!
  </TextDescription>
);
