import React from "react";
import styled from "styled-components";
import "twin.macro";

import { ProgressBar } from "north.js";

type TProps = {
  completed: number;
  totalPerks: number;
};

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.backgroundGrayDark};
  border-radius: 9999px;
  height: 0.625rem;
  margin-bottom: 5px;
`;

// const ProgressBar = styled.div<{ percentageComplete: number }>`
//   background-color: ${({ theme }) => theme.globalConstants.color.textTertiary};
//   height: 0.625rem;
//   border-radius: 9999px;
//   width: ${({ percentageComplete }) => percentageComplete}%;
// `;

const TextContainer = styled.p`
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-size: 13px;
  line-height: 16px;
`;

const Container = styled.div`
  width: 100%;
  padding: 0 45px;
`;

const PerksProgressTracker: React.FC<TProps> = ({ completed, totalPerks }) => {
  const percentCompleted = 100 * (completed / totalPerks);

  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBar color="secondary" percentComplete={percentCompleted} />
      </ProgressBarContainer>
      <TextContainer>
        {Math.round(percentCompleted * 10) / 10}% Completed
      </TextContainer>
    </Container>
  );
};

export default PerksProgressTracker;
