export const LOCATION_OPTIONS = [
  { label: "Tent (Meals, registration)", value: "Tent" },
  {
    label: "1st Floor IDEAS Lab (Hardware Hacking Hub)",
    value: "1st Floor IDEAS Lab",
  },
  {
    label:
      "3rd Floor Innovation Wing (2 large classrooms, ~150 hackers each during workshops)",
    value: "3rd Floor Innovation Wing",
  },
];
