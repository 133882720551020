import { LinkIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useClipboard } from "use-clipboard-copy";

import { SidebarItem } from "src/shared/components/Sidebar";
import { useSponsorContext } from "src/shared/contexts";
import { IS_PRODUCTION } from "src/shared/utils/env";

import { successToast } from "../../Toast";

const CopySponsorInvite: React.FC = () => {
  const { company } = useSponsorContext();

  const clipboard = useClipboard();

  const inviteLink = `https://auth.hackthenorth.com/signup?redirect=${
    !IS_PRODUCTION
      ? "release-staging--my-hackthenorth-com.netlify.app"
      : "my.hackthenorth.com"
  }&r=sponsor&sc=${company?.invite_code}${!IS_PRODUCTION ? "&staging" : ""}`;

  return (
    <SidebarItem
      current={false}
      onClick={() => {
        clipboard.copy(inviteLink);
        successToast("Copied invite link to clipboard!");
      }}
    >
      <LinkIcon style={{ height: 20, width: 20 }} />
      <span style={{ fontWeight: "bold" }}>Copy Invite Link</span>
    </SidebarItem>
  );
};

export default CopySponsorInvite;
