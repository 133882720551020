import { Spacer } from "@hackthenorth/north";
import moment from "moment";
import { Label } from "north.js";
import React from "react";

import { SponsorSnackData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkDatePicker,
  PerkError,
  PerkTextInput,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const SPONSOR_A_SNACK_VALIDATION_FIELDS: (keyof SponsorSnackData)[] = [
  "sponsor_a_snack_name",
  "sponsor_a_snack_time",
];

export const SponsorASnack: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  return (
    <>
      <Header
        title="Sponsor a Snack"
        subtitle="This perk is for meals that are provided to hackers."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("sponsor_a_snack_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("sponsor_a_snack_status")}
          </Label>
        }
      />
      <PerkContainer
        description={
          <Description
            snackName={getState("sponsor_a_snack_name") ?? ""}
            snackDate={getState("sponsor_a_snack_time") ?? ""}
          />
        }
        id={SponsorPerkType.SPONSOR_A_SNACK}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          title="Snack Name"
          value={getState("sponsor_a_snack_name") ?? ""}
          placeholder="Ex. Bubble Tea with (your company)"
          isReadOnly={isReadOnly}
          onChange={(e) => updateState("sponsor_a_snack_name", e.target.value)}
          error={error?.sponsor_a_snack_name && <>This field is required</>}
        />
        <Spacer height={32} />
        <PerkDatePicker
          title="Snack Time*"
          value={getState("sponsor_a_snack_time") ?? "YYYY-MM-DD"}
          onChange={(date) => {
            if (date) updateState("sponsor_a_snack_time", date);
          }}
          isReadOnly={isReadOnly}
        />
        {error?.sponsor_a_snack_time && (
          <PerkError error="This field is required" />
        )}
      </PerkContainer>
    </>
  );
};

const Description = ({
  snackName,
  snackDate,
}: {
  snackName: string;
  snackDate: string;
}) => (
  <>
    <TextDescription>
      The following meals are provided to our participants throughout the
      hackathon.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      {snackName && snackDate && (
        <>
          Your sponsored snack is {snackName}, which will be served at{" "}
          {moment(snackDate).format("MM/DD/YYYY hh:mm A")}
        </>
      )}
    </TextDescription>
  </>
);
