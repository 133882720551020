import { Spacer } from "@hackthenorth/north";
import { Label, Link } from "north.js";
import React from "react";

import { Dropzone, FileLink } from "src/shared/components";
import { NewsletterSpotlightData } from "src/views/sponsor/perks/sync";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import {
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

import Preview from "./Preview";
import { useFileDropzone } from "./useFileDropzone";

export const NEWSLETTER_SPOTLIGHT_VALIDATION_FIELDS: (keyof NewsletterSpotlightData)[] =
  [
    "newsletter_spotlight_image_file",
    "newsletter_spotlight_title",
    "newsletter_spotlight_website_link",
    "newsletter_spotlight_description",
  ];

export const NewsletterSpotlight: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  const {
    dropzoneInputRef,
    fetchedFile,
    error: fileError,
    addFile,
    deleteFile,
    onFileError,
    setDropzoneErrorMessage,
  } = useFileDropzone(
    getState("newsletter_spotlight_image_file")?.[0],
    (files) => updateState("newsletter_spotlight_image_file", files)
  );

  return (
    <>
      <Header
        title="Newsletter: Spotlight"
        subtitle="This perk is for sponsor spotlights in our monthly newsletter."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              getState("newsletter_spotlight_status") as PerkStatus
            )}
            size="sm"
          >
            {getState("newsletter_spotlight_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.NEWSLETTER_SPOTLIGHT}
        readOnly={isReadOnly}
      >
        {!isReadOnly && (
          <Dropzone
            max={1}
            acceptedFileTypes={["png", "svg"]}
            onFilesUpload={addFile}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <FileLink file={fetchedFile} deleteFile={deleteFile} />
          </>
        )}
        {error?.newsletter_spotlight_image_file && (
          <PerkError error="This field is required" />
        )}
        {fileError && <PerkError error={fileError} />}
        <Spacer height={32} />
        <PerkTextInput
          title="Title*"
          value={getState("newsletter_spotlight_title") ?? ""}
          placeholder="Your company's home or recruiting page"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("newsletter_spotlight_title", e.target.value)
          }
          error={
            error?.newsletter_spotlight_title && <>This field is required</>
          }
        />
        <Spacer height={32} />
        <PerkTextInput
          title="Website Link*"
          value={getState("newsletter_spotlight_website_link") ?? ""}
          placeholder="Ex. Home page"
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("newsletter_spotlight_website_link", e.target.value)
          }
          error={
            error?.newsletter_spotlight_website_link && (
              <>This field is required</>
            )
          }
        />
        <Spacer height={32} />
        <PerkTextArea
          title="Description* (max 120 words)"
          value={getState("newsletter_spotlight_description") ?? ""}
          placeholder="Share a message with hackers"
          wordLimit={120}
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("newsletter_spotlight_description", e.target.value)
          }
          error={
            error?.newsletter_spotlight_description && (
              <>This field is required</>
            )
          }
        />
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <Preview
              image={fetchedFile}
              title={getState("newsletter_spotlight_title") ?? ""}
              link={getState("newsletter_spotlight_website_link") ?? ""}
              description={getState("newsletter_spotlight_description") ?? ""}
            />
          </>
        )}
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <>
    <TextDescription>
      The Hack the North newsletter is sent out to hackers in the middle of
      every month.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      The Spotlight will appear at the top of the newsletter email. Due to
      limited space, we may not be able to highlight your Spotlight until a
      later newsletter. Gold sponsors are given priority for this perk as there
      is only one Spotlight per newsletter. If you have an item you would really
      like to share for a specific month, please email us with the subject line
      &quot;[Company Name] Newsletter Spotlight Request&quot;.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      If your submission is selected for the upcoming month&apos;s newsletter,
      you will see this perk under the Complete section. Please allow us at
      least a week following your submission for our team to review.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Check out our{" "}
      <Link
        href="https://hackthenorth.com/sponsorship-newsletter-template.pdf"
        target="_blank"
      >
        sample newsletter template here
      </Link>{" "}
      to see an example of the layout.
    </TextDescription>
    <Spacer height={16} />
    <TextDescription>
      Please submit an image in SVG or PNG form (SVG is preferred).
    </TextDescription>
  </>
);
