import { Permission, usePermissionsContext } from "src/shared/contexts";

import { SECTIONS, TSidebarSection } from "./sections";

export const useSidebarContent = () => {
  const { hasPermission } = usePermissionsContext();

  const sections: TSidebarSection[] = SECTIONS.map((section) => {
    return {
      ...section,
      content: section.content.filter((item) => {
        let canAccess = false;
        if (item.permissions) {
          canAccess =
            item.permissions.length === 0 ||
            item.permissions.some((permission: Permission) =>
              hasPermission(permission)
            );
        }
        if (item.antiPermissions) {
          const shouldHide = item.antiPermissions.some(
            (permission: Permission) => hasPermission(permission)
          );
          if (shouldHide) {
            return false;
          }
        }
        return !item.permissions || canAccess;
      }),
    };
  }).filter((section) => section.content.length > 0);

  return { sections };
};
