import React from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";

import StatusComponent from "./status";

const RSVPHomePageWrapper = styled(PageWrapper)`
  max-width: 100%;
`;

export default () => (
  <RSVPHomePageWrapper>
    <StatusComponent />
  </RSVPHomePageWrapper>
);
