import { Spacer, Text } from "@hackthenorth/north";
import { GraphQLError } from "graphql";
import { Button, Checkbox, Spinner, TextInput } from "north.js";
import "north.js/dist/style.css";
import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import "twin.macro";

import { PageWrapper, TagInput } from "src/shared/components";
import { successToast, errorToast } from "src/shared/components/Toast";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useDeviceSize, useIsMounted } from "src/shared/hooks";
import { ArrowRight } from "src/static/icons";

import RSVPSidebar from "../rsvp-sidebar";

import {
  ACCEPTED_FIELDS,
  MEETUP_MAX,
  MEETUP_OPTIONS,
  TECH_TALK_OPTIONS,
} from "./constants";
import DescriptionText from "./DescriptionText";

const EventPage: React.FC = () => {
  const isTabletOrSmaller = useDeviceSize("tablet");
  const isMounted = useIsMounted();
  const {
    updateResponses,
    isLoading: isHackerLoading,
    // isReadOnly,
    navigateNext,
    navigateBack,
  } = useHackerContext();

  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    {}
  );

  const [showErrors, setShowErrors] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [addingMeetup, setAddingMeetup] = useState(false);
  const [userMeetup, setUserMeetup] = useState<string[]>([]);

  useEffect(() => {
    setUserMeetup(
      (responsesState[Field.MEETUPS] ?? []).filter(
        (meetup) => !MEETUP_OPTIONS.includes(meetup)
      )
    );
  }, [responsesState]);

  const isLoading = isHackerLoading;

  const MAX_TECH_TALKS = 3;

  useEffect(() => {
    window.addEventListener("beforeunload", () => {});
  });

  useEffect(() => {
    if (isValid && isMounted) {
      navigateNext();
    }
    if (responsesState[Field.TRAVEL_RSVP_STAGE] !== PageStage.COMPLETED) {
      navigateBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  const onSave = async () => {
    if (isValid) {
      try {
        const { errors } = await updateResponses({
          ...responsesState,
          [Field.EVENT_RSVP_STAGE]: PageStage.COMPLETED,
        });

        if (errors) {
          throw new Error(
            "We weren't able to submit your answers, please try again."
          );
        }

        successToast("Answers successfully saved!");
        setSubmitting(true);
      } catch (e) {
        if (typeof e === "object") {
          errorToast((e as GraphQLError)?.message);
        } else {
          errorToast(e);
        }
      }
    } else {
      window.scrollTo(0, 0);
      errorToast("Please fill out all the required fields.");
      setShowErrors(true);
    }
  };

  return (
    <PageWrapper>
      <Prompt
        when={isBlocking && !submitting}
        message="You have unsaved changes, are you sure you want to move away from this page?"
      />
      {isTabletOrSmaller && <RSVPSidebar currentRoute="Event Information" />}

      <Heading1Text>Event Information</Heading1Text>
      <Text mods="grey">
        All questions are required unless stated otherwise.
      </Text>

      <Heading3Wrapper>
        <Text mods="h3 heading">Hackathon Host</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text mods="textBody">
        Would you/your team like to be paired up with a hackathon host?
      </Text>
      <Spacer height={16} />
      <Checkbox
        label="Yes, I'd like to be paired up with a hackathon host."
        checked={!!responsesState[Field.HACKATHON_HOST_REQUESTED]}
        onChange={(e: any) => {
          setIsBlocking(true);
          setResponsesState(
            Field.HACKATHON_HOST_REQUESTED,
            e.currentTarget.checked
          );
        }}
      />
      <DescriptionText
        text={
          "Hackathon hosts are mentors who work together with our beginner hackers, to develop a project from the ideation stage to a working prototype! If you have already formed a team, please only submit one hackathon host request per team! And don't worry - you'll have an opportunity to be paired up with a hackathon host at the event if you're still unsure!"
        }
      />

      <Divider />
      <Heading3Wrapper>
        <Text mods="h3 heading">Tech Talks</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text mods="bold textBody">
        Select up to 3 of the following Tech Talks you are interested in.
      </Text>
      <Spacer height={12} />
      {TECH_TALK_OPTIONS.map(({ label, description }, index) => (
        <div key={index}>
          <Spacer height={8} />
          <Checkbox
            label={label}
            checked={(responsesState[Field.TECH_TALKS] ?? []).includes(label)}
            disabled={
              (responsesState[Field.TECH_TALKS] ?? []).length ===
                MAX_TECH_TALKS &&
              !(responsesState[Field.TECH_TALKS] ?? []).includes(label)
            }
            onChange={(e: any) => {
              setIsBlocking(true);
              if (e.currentTarget.checked) {
                setResponsesState(Field.TECH_TALKS, [
                  ...(responsesState[Field.TECH_TALKS] ?? []),
                  label,
                ]);
              } else {
                setResponsesState(
                  Field.TECH_TALKS,
                  (responsesState[Field.TECH_TALKS] ?? []).filter(function (
                    item
                  ) {
                    return item !== label;
                  })
                );
              }
            }}
          />
          <DescriptionText text={description} />
        </div>
      ))}

      <Divider />
      <Heading3Wrapper>
        <Text mods="h3 heading">Meetups</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text mods="bold textBody">What are some meetups that interest you?</Text>
      <br />
      <Text>
        Please select up to 3 meetups you&rsquo;re interested in; feel free to
        suggest any!
      </Text>
      <Spacer height={16} />
      <TagInput
        multi
        max={MEETUP_MAX}
        adding={addingMeetup}
        setAdding={setAddingMeetup}
        options={MEETUP_OPTIONS}
        userOptions={userMeetup}
        setUserOptions={setUserMeetup}
        selectedOptions={responsesState[Field.MEETUPS] ?? []}
        setSelectedOptions={(newMeetups) => {
          setIsBlocking(true);
          setResponsesState(Field.MEETUPS, newMeetups);
        }}
        canAdd={(responsesState[Field.MEETUPS] ?? []).length < MEETUP_MAX}
      />

      <Divider />
      <Heading3Wrapper>
        <Text mods="h3 heading">Hacker Networking</Text>
        <OptionalText>Optional</OptionalText>
      </Heading3Wrapper>
      <Text>
        Connecting with other hackers and making new friends is one of the most
        rewarding aspects of attending Hack the North. To help make forming
        lasting connections easier, we&rsquo;ll store your social media links in
        the QR code on your event attendee badge that you&rsquo;ll receive when
        you check in at the event. You will be able to share your socials with
        other attendees by scanning their QR code.
      </Text>
      <br />
      <Text>
        These links are not finalized and you will be able to change them during
        the event.
      </Text>
      <Spacer height={42} />
      {isTabletOrSmaller ? (
        <div>
          <Text mods="bold textBody">Profile Links</Text>
          <FlexColumn>
            <Text mods="bold textBody">Facebook</Text>
            <InputWrapper
              type="text"
              placeholder="Enter full URL"
              value={responsesState[Field.FACEBOOK] ?? ""}
              onChange={(e: any) => {
                setResponsesState(Field.FACEBOOK, e.currentTarget.value);
              }}
            />
          </FlexColumn>
          <FlexColumn>
            <Text mods="bold textBody">LinkedIn</Text>
            <InputWrapper
              type="text"
              placeholder="Enter full URL"
              value={responsesState[Field.LINKEDIN] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(Field.LINKEDIN, e.currentTarget.value);
              }}
            />
          </FlexColumn>
          <FlexColumn>
            <Text mods="bold textBody">Instagram</Text>
            <InputWrapper
              type="text"
              placeholder="Enter full URL"
              value={responsesState[Field.INSTAGRAM] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(Field.INSTAGRAM, e.currentTarget.value);
              }}
            />
          </FlexColumn>
          <FlexColumn>
            <Text mods="bold textBody">Twitter</Text>
            <InputWrapper
              type="text"
              placeholder="Enter full URL"
              value={responsesState[Field.TWITTER] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(Field.TWITTER, e.currentTarget.value);
              }}
            />
          </FlexColumn>
          <FlexColumn>
            <Text mods="bold textBody">Discord Tag</Text>
            <InputWrapper
              type="text"
              placeholder="Enter full tag"
              value={responsesState[Field.DISCORD_TAG] ?? ""}
              onChange={(e: any) => {
                setIsBlocking(true);
                setResponsesState(Field.DISCORD_TAG, e.currentTarget.value);
              }}
            />
          </FlexColumn>
        </div>
      ) : (
        <div>
          <Text mods="bold textBody">Profile Links</Text>
          <FlexRow>
            <FlexColumn>
              <Text mods="bold textBody">Facebook</Text>
              <InputWrapper
                type="text"
                placeholder="Enter full URL"
                value={responsesState[Field.FACEBOOK] ?? ""}
                onChange={(e: any) => {
                  setIsBlocking(true);
                  setResponsesState(Field.FACEBOOK, e.currentTarget.value);
                }}
              />
            </FlexColumn>
            <Spacer width={50} />
            <FlexColumn>
              <Text mods="bold textBody">LinkedIn</Text>
              <InputWrapper
                type="text"
                placeholder="Enter full URL"
                value={responsesState[Field.LINKEDIN] ?? ""}
                onChange={(e: any) => {
                  setIsBlocking(true);
                  setResponsesState(Field.LINKEDIN, e.currentTarget.value);
                }}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <Text mods="bold textBody">Instagram</Text>
              <InputWrapper
                type="text"
                placeholder="Enter full URL"
                value={responsesState[Field.INSTAGRAM] ?? ""}
                onChange={(e: any) => {
                  setIsBlocking(true);
                  setResponsesState(Field.INSTAGRAM, e.currentTarget.value);
                }}
              />
            </FlexColumn>
            <Spacer width={50} />
            <FlexColumn>
              <Text mods="bold textBody">Twitter</Text>
              <InputWrapper
                type="text"
                placeholder="Enter full URL"
                value={responsesState[Field.TWITTER] ?? ""}
                onChange={(e: any) => {
                  setIsBlocking(true);
                  setResponsesState(Field.TWITTER, e.currentTarget.value);
                }}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            <FlexColumn>
              <Text mods="bold textBody">Discord Tag</Text>
              <InputWrapper
                type="text"
                placeholder="Enter full tag"
                value={responsesState[Field.DISCORD_TAG] ?? ""}
                onChange={(e: any) => {
                  setIsBlocking(true);
                  setResponsesState(Field.DISCORD_TAG, e.currentTarget.value);
                }}
              />
            </FlexColumn>
            <Spacer width={50} />
            <FlexColumn />
          </FlexRow>
        </div>
      )}

      <Spacer height={100} />
      <FlexRow>
        <Button color="primary" disabled={isLoading} onClick={onSave} size="md">
          {isLoading ? (
            <Spinner />
          ) : (
            <FlexRow>
              <p>
                {responsesState[Field.EVENT_RSVP_STAGE] === PageStage.COMPLETED
                  ? "Save"
                  : "Next"}
              </p>
              <Spacer width="8px" />
              <ArrowRight width={16} />
            </FlexRow>
          )}
        </Button>
        <Spacer width="32px" />
        {showErrors && (
          <ErrorText>
            Please fill out all the mandatory fields to proceed to the next form
            page.
          </ErrorText>
        )}
      </FlexRow>
      <Spacer height={24} />
    </PageWrapper>
  );
};

const Heading1Text = styled.h1`
  margin-top: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-weight: 900;
  font-size: 28px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    font-size: 24px;
  `}
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 20px;
  `}
`;

const Heading3Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 42px;
  margin-bottom: 24px;
`;

const OptionalText = styled.p`
  font-size: 12px;
  margin-left: 16px;
  color: ${({ theme }) => theme.globalConstants.color.textTertiary};
  font-style: italic;
  line-height: 42px;
  text-align: center;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.globalConstants.color.textDanger};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 42px;
  background-color: ${({ theme }) => theme.globalConstants.color.borderGray};
`;

const InputWrapper = styled(TextInput).attrs({ size: "md" })`
  margin-top: 12px;
  width: 100%;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;

export default EventPage;
