/* eslint-disable @typescript-eslint/camelcase */
import { NetworkStatus } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Alert, Button, Link, Spinner, Text } from "north.js";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { styled } from "twin.macro";

import { Header, PageWrapper } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { useMentorContext, useUserContext } from "src/shared/contexts";
import { useGetClaimedRequestsQuery } from "src/shared/contexts/MentorContext/graphql/getClaimedRequests.generated";
import { MentorshipRequestStage } from "src/shared/contexts/MentorshipRequestContext/constants";
import { MentorshipRequest } from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import { ArrowPathIcon } from "src/static/icons";

import { ClaimedRequestCard } from "./ClaimedRequestCard";

export const MentorClaimedRequests = () => {
  const { profile, updateRequest } = useMentorContext();
  const { id, info } = useUserContext();
  const navigate = useNavigate();
  const {
    data: requestData,
    loading,
    refetch,
    networkStatus,
  } = useGetClaimedRequestsQuery({
    variables: {
      mentorId: id?.toString() ?? "",
      stages: [MentorshipRequestStage.CLAIMED, MentorshipRequestStage.RESOLVED],
    },
    notifyOnNetworkStatusChange: true,
  });
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const isRefetching =
    networkStatus === NetworkStatus.refetch && triggerRefresh;

  const resolveRequest = useCallback(
    (request: MentorshipRequest) => {
      updateRequest({
        ...request,
        stage_slug: MentorshipRequestStage.RESOLVED,
      });
      refetch();
    },
    [updateRequest, refetch]
  );

  const unclaimRequest = useCallback(
    (request: MentorshipRequest) => {
      updateRequest({
        ...request,
        reopened: true,
        stage_slug: MentorshipRequestStage.OPEN,
      });
      refetch();
    },
    [updateRequest, refetch]
  );

  const mentorshipRequests: MentorshipRequest[] =
    requestData?.claims?.map((request) => ({
      id: request.id,
      title: getFieldAnswer(request.fields, "title"),
      description: getFieldAnswer(request.fields, "description"),
      category: getFieldAnswer(request.fields, "category"),
      priority: getFieldAnswer(request.fields, "priority"),
      created_at: request.created_at,
      reopened: getFieldAnswer(request.fields, "reopened"),
      mentee_name: getFieldAnswer(request.fields, "mentee_name"),
      mentee_id: getFieldAnswer(request.fields, "mentee_id"),
      mentor_id: getFieldAnswer(request.fields, "mentor_id"),
      stage_slug: request.stage.slug,
    })) ?? [];

  const claimedRequests = mentorshipRequests.filter(
    (request) => request.stage_slug === MentorshipRequestStage.CLAIMED
  );
  const resolvedRequests = mentorshipRequests.filter(
    (request) =>
      request.stage_slug === MentorshipRequestStage.RESOLVED ||
      request.stage_slug === MentorshipRequestStage.CLOSED
  );

  const profileNotCreated = !profile && !loading;
  const slackNotConnected = !info?.event_platform_user_id;

  return (
    <>
      <Header
        title="Claimed Requests"
        subtitle="View all the mentorship requests you have claimed and resolved"
        actionButton={
          !slackNotConnected && (
            <Button
              color="secondary"
              leadingIcon={
                isRefetching ? (
                  <div tw="w-20 h-20 flex items-center justify-center">
                    <Spinner tw="w-12! h-12! mr-2" />
                  </div>
                ) : (
                  <ArrowPathIcon tw="h-20 w-20 text-text-secondary!" />
                )
              }
              onClick={async () => {
                setTriggerRefresh(true);
                await refetch();
                setTriggerRefresh(false);
              }}
              disabled={isRefetching}
            >
              Refresh list
            </Button>
          )
        }
      />
      <PageWrapper>
        {profileNotCreated ? (
          <>
            <Alert
              color="warning"
              icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
            >
              Oops! Looks like we haven&apos;t made a profile for you yet!
            </Alert>

            <div tw="h-24" />
            <Button
              size="lg"
              color="secondary"
              onClick={async () => {
                navigate(Route.MENTOR_PROFILE);
              }}
            >
              <Text type="button">Make Profile</Text>
            </Button>
          </>
        ) : slackNotConnected ? (
          <>
            <Alert
              color="warning"
              icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
            >
              <Link
                href="https://docs.google.com/document/d/1oh2IM-mRHjKx17uLrImOEcXKbg6SQIHI6ITLfgx9qNE"
                tw="text-text-warning!"
              >
                You&apos;re almost there! Please link your slack account to view
                and claim requests{" "}
                <span role="img" aria-label="happy emoji">
                  😊
                </span>
              </Link>
            </Alert>
          </>
        ) : (
          <>
            <div>
              <Text type="h3">Claimed requests</Text>
              <div tw="mb-24" />
              <Grid tw="mb-48">
                {claimedRequests.length > 0 ? (
                  claimedRequests.map((request) => (
                    <div key={request.id}>
                      <ClaimedRequestCard
                        type="claimed"
                        request={request}
                        key={request.id}
                        onResolve={resolveRequest}
                        onUnclaim={unclaimRequest}
                        showActions
                      />
                    </div>
                  ))
                ) : (
                  <Text type="body1" tw="text-text-secondary font-medium">
                    You have no claimed requests yet.
                  </Text>
                )}
              </Grid>
              <Text type="h3">Resolved requests</Text>
              <div tw="mb-24" />

              <Grid tw="mb-48">
                {resolvedRequests.length > 0 ? (
                  resolvedRequests.map((request) => (
                    <div key={request.id}>
                      <ClaimedRequestCard
                        type="resolved"
                        request={request}
                        onResolve={resolveRequest}
                        onUnclaim={unclaimRequest}
                      />
                    </div>
                  ))
                ) : (
                  <Text type="body1" tw="text-text-secondary font-medium">
                    You have no resolved requests yet.
                  </Text>
                )}
              </Grid>
            </div>
          </>
        )}
      </PageWrapper>
    </>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, calc(50% - 12px)));
  grid-gap: 24px;
`;
