import { Text, Spacer, Spinner, Flex } from "@hackthenorth/north";
import { Link } from "north.js";
import React, { Fragment } from "react";
import tw, { styled } from "twin.macro";

import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
  Card,
  Tooltip,
} from "src/shared/components";
import { perkSlugToTitle } from "src/views/sponsor/perks/components/Perk/utils";
import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import { PerkStatus, SponsorPerkType } from "src/views/sponsor/perks/types";

import Perk from "../Perk";

interface PerksSectionProps {
  status: PerkStatus;
  perkTypes: SponsorPerkType[];
  totalPerks: number;
}

const PerksSection: React.FC<PerksSectionProps> = ({ status, perkTypes }) => {
  const { loadingPerks, loadError } = useSponsorPerksContext();

  const sectionHasTooltip =
    status === PerkStatus.SUBMITTED || status === PerkStatus.APPROVED;

  const tooltipDelay = status === PerkStatus.APPROVED ? 2 : 0;

  return (
    <>
      <Accordion
        isDefaultOpen={true}
        heading={
          <AccordionHeading>
            <Flex align="center">
              <Text mods="heading h3 brandPrimary" tw="mb-0!">
                {perkSlugToTitle(status)}
              </Text>
              <Spacer width={8} />

              {sectionHasTooltip && (
                <Tooltip
                  id={status}
                  indicatorColor="brandPrimary"
                  wrapperOptions={{ eventDelay: tooltipDelay }}
                >
                  {status === PerkStatus.SUBMITTED ? (
                    <Text mods="white">
                      Your perk details have been submitted and will be reviewed
                      shortly by our sponsorship team! Once approved, the perk
                      will be moved to the APPROVED section.
                    </Text>
                  ) : (
                    <Text mods="white">
                      The following perks have been finalized! If you need to
                      make any changes, please contact{" "}
                      <Link
                        tw="text-white!"
                        href="mailto:sponsor@hackthenorth.com"
                      >
                        sponsor@hackthenorth.com
                      </Link>
                      .
                    </Text>
                  )}
                </Tooltip>
              )}
            </Flex>
            <Spacer width={20} />
            <StyledAccordionArrow />
          </AccordionHeading>
        }
        showOverflow={true}
      >
        <Spacer height={24} />

        {loadingPerks ? (
          <Spinner />
        ) : loadError ? (
          <Text mods="error">{loadError}</Text>
        ) : // TODO: Change this to map through perks.available_perks
        perkTypes.length > 0 ? (
          <PerkGrid>
            {perkTypes.map((perk) => (
              <Fragment key={perk}>
                <Perk type={perk} />
              </Fragment>
            ))}
          </PerkGrid>
        ) : (
          <Card>
            <Text mods="big">{`No ${perkSlugToTitle(
              status.toLowerCase()
            )} submissions.`}</Text>
          </Card>
        )}
      </Accordion>
      <Spacer height={48} />
    </>
  );
};

const PerkGrid = styled(Card)`
  display: grid;
  ${tw`bg-background-gray`}
  grid-template-columns: 0.5fr 0.5fr;
  grid-auto-rows: 1fr;
  grid-gap: 1rem;

  ${({ theme }) => theme.mediaQueries.medium`
    grid-template-columns: 1fr;
  `};
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  cursor: pointer;
  svg {
    pointer-events: none;
  }
`;

const AccordionHeading = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  &:focus {
    outline: none;
  }
`;

export default PerksSection;
