import { CSSProperties } from "react";
import { Props } from "react-select";
import { useTheme } from "styled-components";

// styles that are based on dropdown state, all other styles in CSS
export const useDynamicDropdownStyles = () => {
  const theme = useTheme();

  return {
    option: (_: CSSProperties, state: Props) => ({
      background:
        state.isSelected || state.isFocused
          ? theme.globalConstants.color.brandPrimary
          : theme.globalConstants.color.white,
    }),
    dropdownIndicator: (base: CSSProperties, state: Props) => ({
      ...base,
      color: state.isFocused
        ? theme.globalConstants.color.brandPrimary
        : theme.globalConstants.color.brandSecondary,
      "&:hover": {
        color: state.isFocused
          ? theme.globalConstants.color.brandPrimary
          : theme.globalConstants.color.brandSecondary,
      },
    }),
  };
};
