export const HUB_LOCATION_VALUES = [
  { label: "Hub 1", value: "hub1" },
  { label: "Hub 2", value: "hub2" },
  { label: "Hub 3", value: "hub3" },
];

export const TABLE_LOCATION_VALUES = [
  { label: "Table 1", value: "table1" },
  { label: "Table 2", value: "table2" },
  { label: "Table 3", value: "table3" },
  { label: "Table 4", value: "table4" },
  { label: "Table 5", value: "table5" },
  { label: "Table 6", value: "table6" },
  { label: "Table 7", value: "table7" },
  { label: "Table 8", value: "table8" },
  { label: "Table 9", value: "table9" },
  { label: "Table 10", value: "table10" },
  { label: "Table 11", value: "table11" },
  { label: "Table 12", value: "table12" },
  { label: "Table 13", value: "table13" },
  { label: "Table 14", value: "table14" },
  { label: "Table 15", value: "table15" },
  { label: "Table 16", value: "table16" },
  { label: "Table 17", value: "table17" },
  { label: "Table 18", value: "table18" },
  { label: "Table 19", value: "table19" },
  { label: "Table 20", value: "table20" },
  { label: "Table 21", value: "table21" },
  { label: "Table 22", value: "table22" },
  { label: "Table 23", value: "table23" },
  { label: "Table 24", value: "table24" },
  { label: "Table 25", value: "table25" },
  { label: "Table 26", value: "table26" },
  { label: "Table 27", value: "table27" },
  { label: "Table 28", value: "table28" },
  { label: "Table 29", value: "table29" },
  { label: "Table 30", value: "table30" },
  { label: "Table 31", value: "table31" },
  { label: "Table 32", value: "table32" },
  { label: "Table 33", value: "table33" },
  { label: "Table 34", value: "table34" },
  { label: "Table 35", value: "table35" },
  { label: "Table 36", value: "table36" },
];
