import { Label } from "north.js";
import React from "react";
import styled from "styled-components";

const StatusBadge = styled(Label)`
  font-weight: 500;
`;

export const NotAnsweredBadge = () => (
  <StatusBadge color="secondary-light">Not Answered</StatusBadge>
);

export const AnsweredBadge = () => (
  <StatusBadge color="primary-light">Answered</StatusBadge>
);

export const StickerBadge = () => (
  <StatusBadge color="warning-light">Get a sticker</StatusBadge>
);
