/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type GetSponsorPerksQueryVariables = Types.Exact<{
  sponsor_company_id: Types.Scalars["String"];
}>;

export type GetSponsorPerksQuery = {
  claims: Array<
    Pick<Types.Claim, "id"> & {
      fields_HACKTHENORTH2024_SPONSOR_PERKS: {
        answers: {
          sponsor_company_id: Pick<
            Types.NumberFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          available_perks: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_logo_file: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          website_logo_website_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_logo_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_logo_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_feature_website_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_feature_link_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_feature_message: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_feature_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          website_feature_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_opportunity_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_opportunity_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_opportunity_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_opportunity_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_feature_image_file: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          newsletter_feature_title: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_feature_website_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_feature_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_feature_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_feature_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_spotlight_image_file: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          newsletter_spotlight_title: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_spotlight_website_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_spotlight_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_spotlight_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          newsletter_spotlight_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_title_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_description_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_title_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_description_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_proposal_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_time_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_time_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_time_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_representative: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_representative_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_representative_email: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_accepted_hackers_only: Pick<
            Types.BooleanFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          tech_talk_details_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_prize_title: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_prize_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_prize_judging_confirmation: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_prize_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_prize_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_time_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_time_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_time_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_accepted_hackers_only: Pick<
            Types.BooleanFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_representative: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_representative_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_representative_email: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          api_workshop_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_time_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_time_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_time_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_representative: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_representative_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_representative_email: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          lightning_challenge_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_an_activity_selection_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_an_activity_selection_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_an_activity_selection_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_an_activity_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_an_activity_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_snack_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_snack_time: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_snack_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_snack_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_name: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_time_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_time_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_time_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          sponsor_a_meetup_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          select_table_location_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          select_table_location_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          select_table_location_three: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          select_table_location_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          select_table_location_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          social_media_promotion_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          social_media_promotion_logo: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          social_media_promotion_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          social_media_promotion_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          name_an_area_one: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          name_an_area_two: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          name_an_area_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          name_an_area_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          welcome_booklet_blurb_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          welcome_booklet_blurb_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          welcome_booklet_blurb_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          shipped_swag_description: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          shipped_swag_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          shipped_swag_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          opening_ceremonies_slide_file: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          opening_ceremonies_slide_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          opening_ceremonies_slide_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          opening_ceremonies_slide_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          closing_ceremonies_slide_file: Pick<
            Types.FileFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug"
          > & {
            value?: Types.Maybe<
              Array<
                Pick<
                  Types.FileFieldAnswerType,
                  "id" | "name" | "mime_type" | "expiry" | "uri"
                >
              >
            >;
          };
          closing_ceremonies_slide_link: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          closing_ceremonies_slide_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          closing_ceremonies_slide_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_start_time: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_duration: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_representatives: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_representatives_emails: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_representatives_pronouns: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_hackers: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_favorited_hackers: Pick<
            Types.StringArrayFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_status: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
          coffee_chats_due_date: Pick<
            Types.StringFieldAnswerTypeWrapper,
            "id" | "field_type" | "slug" | "value"
          >;
        };
      };
    }
  >;
};

export const GetSponsorPerksDocument = gql`
  query getSponsorPerks($sponsor_company_id: String!) {
    claims(
      where: {
        pipeline: { is: { slug: { equals: "hackthenorth2024-sponsor-perks" } } }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "sponsor_company_id" } } } }
              { value: { equals: $sponsor_company_id } }
            ]
          }
        }
      }
      take: 1
    ) {
      id
      fields_HACKTHENORTH2024_SPONSOR_PERKS {
        answers {
          sponsor_company_id {
            id
            field_type
            slug
            value
          }
          available_perks {
            id
            field_type
            slug
            value
          }
          website_logo_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          website_logo_website_link {
            id
            field_type
            slug
            value
          }
          website_logo_status {
            id
            field_type
            slug
            value
          }
          website_logo_due_date {
            id
            field_type
            slug
            value
          }
          website_feature_website_link {
            id
            field_type
            slug
            value
          }
          website_feature_link_description {
            id
            field_type
            slug
            value
          }
          website_feature_message {
            id
            field_type
            slug
            value
          }
          website_feature_status {
            id
            field_type
            slug
            value
          }
          website_feature_due_date {
            id
            field_type
            slug
            value
          }
          newsletter_opportunity_link {
            id
            field_type
            slug
            value
          }
          newsletter_opportunity_name {
            id
            field_type
            slug
            value
          }
          newsletter_opportunity_status {
            id
            field_type
            slug
            value
          }
          newsletter_opportunity_due_date {
            id
            field_type
            slug
            value
          }
          newsletter_feature_image_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          newsletter_feature_title {
            id
            field_type
            slug
            value
          }
          newsletter_feature_website_link {
            id
            field_type
            slug
            value
          }
          newsletter_feature_description {
            id
            field_type
            slug
            value
          }
          newsletter_feature_status {
            id
            field_type
            slug
            value
          }
          newsletter_feature_due_date {
            id
            field_type
            slug
            value
          }
          newsletter_spotlight_image_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          newsletter_spotlight_title {
            id
            field_type
            slug
            value
          }
          newsletter_spotlight_website_link {
            id
            field_type
            slug
            value
          }
          newsletter_spotlight_description {
            id
            field_type
            slug
            value
          }
          newsletter_spotlight_status {
            id
            field_type
            slug
            value
          }
          newsletter_spotlight_due_date {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_title_one {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_description_one {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_title_two {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_description_two {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_status {
            id
            field_type
            slug
            value
          }
          tech_talk_proposal_due_date {
            id
            field_type
            slug
            value
          }
          tech_talk_details_time_one {
            id
            field_type
            slug
            value
          }
          tech_talk_details_time_two {
            id
            field_type
            slug
            value
          }
          tech_talk_details_time_three {
            id
            field_type
            slug
            value
          }
          tech_talk_details_representative {
            id
            field_type
            slug
            value
          }
          tech_talk_details_representative_name {
            id
            field_type
            slug
            value
          }
          tech_talk_details_representative_email {
            id
            field_type
            slug
            value
          }
          tech_talk_details_accepted_hackers_only {
            id
            field_type
            slug
            value
          }
          tech_talk_details_status {
            id
            field_type
            slug
            value
          }
          tech_talk_details_due_date {
            id
            field_type
            slug
            value
          }
          api_prize_title {
            id
            field_type
            slug
            value
          }
          api_prize_description {
            id
            field_type
            slug
            value
          }
          api_prize_judging_confirmation {
            id
            field_type
            slug
            value
          }
          api_prize_status {
            id
            field_type
            slug
            value
          }
          api_prize_due_date {
            id
            field_type
            slug
            value
          }
          api_workshop_description {
            id
            field_type
            slug
            value
          }
          api_workshop_time_one {
            id
            field_type
            slug
            value
          }
          api_workshop_time_two {
            id
            field_type
            slug
            value
          }
          api_workshop_time_three {
            id
            field_type
            slug
            value
          }
          api_workshop_accepted_hackers_only {
            id
            field_type
            slug
            value
          }
          api_workshop_representative {
            id
            field_type
            slug
            value
          }
          api_workshop_representative_name {
            id
            field_type
            slug
            value
          }
          api_workshop_representative_email {
            id
            field_type
            slug
            value
          }
          api_workshop_status {
            id
            field_type
            slug
            value
          }
          api_workshop_due_date {
            id
            field_type
            slug
            value
          }
          lightning_challenge_description {
            id
            field_type
            slug
            value
          }
          lightning_challenge_time_one {
            id
            field_type
            slug
            value
          }
          lightning_challenge_time_two {
            id
            field_type
            slug
            value
          }
          lightning_challenge_time_three {
            id
            field_type
            slug
            value
          }
          lightning_challenge_representative {
            id
            field_type
            slug
            value
          }
          lightning_challenge_representative_name {
            id
            field_type
            slug
            value
          }
          lightning_challenge_representative_email {
            id
            field_type
            slug
            value
          }
          lightning_challenge_status {
            id
            field_type
            slug
            value
          }
          lightning_challenge_due_date {
            id
            field_type
            slug
            value
          }
          sponsor_an_activity_selection_one {
            id
            field_type
            slug
            value
          }
          sponsor_an_activity_selection_two {
            id
            field_type
            slug
            value
          }
          sponsor_an_activity_selection_three {
            id
            field_type
            slug
            value
          }
          sponsor_an_activity_status {
            id
            field_type
            slug
            value
          }
          sponsor_an_activity_due_date {
            id
            field_type
            slug
            value
          }
          sponsor_a_snack_name {
            id
            field_type
            slug
            value
          }
          sponsor_a_snack_time {
            id
            field_type
            slug
            value
          }
          sponsor_a_snack_status {
            id
            field_type
            slug
            value
          }
          sponsor_a_snack_due_date {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_name {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_description {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_time_one {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_time_two {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_time_three {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_status {
            id
            field_type
            slug
            value
          }
          sponsor_a_meetup_due_date {
            id
            field_type
            slug
            value
          }
          select_table_location_one {
            id
            field_type
            slug
            value
          }
          select_table_location_two {
            id
            field_type
            slug
            value
          }
          select_table_location_three {
            id
            field_type
            slug
            value
          }
          select_table_location_status {
            id
            field_type
            slug
            value
          }
          select_table_location_due_date {
            id
            field_type
            slug
            value
          }
          social_media_promotion_description {
            id
            field_type
            slug
            value
          }
          social_media_promotion_logo {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          social_media_promotion_status {
            id
            field_type
            slug
            value
          }
          social_media_promotion_due_date {
            id
            field_type
            slug
            value
          }
          name_an_area_one {
            id
            field_type
            slug
            value
          }
          name_an_area_two {
            id
            field_type
            slug
            value
          }
          name_an_area_status {
            id
            field_type
            slug
            value
          }
          name_an_area_due_date {
            id
            field_type
            slug
            value
          }
          welcome_booklet_blurb_description {
            id
            field_type
            slug
            value
          }
          welcome_booklet_blurb_status {
            id
            field_type
            slug
            value
          }
          welcome_booklet_blurb_due_date {
            id
            field_type
            slug
            value
          }
          shipped_swag_description {
            id
            field_type
            slug
            value
          }
          shipped_swag_status {
            id
            field_type
            slug
            value
          }
          shipped_swag_due_date {
            id
            field_type
            slug
            value
          }
          opening_ceremonies_slide_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          opening_ceremonies_slide_link {
            id
            field_type
            slug
            value
          }
          opening_ceremonies_slide_status {
            id
            field_type
            slug
            value
          }
          opening_ceremonies_slide_due_date {
            id
            field_type
            slug
            value
          }
          closing_ceremonies_slide_file {
            id
            field_type
            slug
            value {
              id
              name
              mime_type
              expiry
              uri
            }
          }
          closing_ceremonies_slide_link {
            id
            field_type
            slug
            value
          }
          closing_ceremonies_slide_status {
            id
            field_type
            slug
            value
          }
          closing_ceremonies_slide_due_date {
            id
            field_type
            slug
            value
          }
          coffee_chats_start_time {
            id
            field_type
            slug
            value
          }
          coffee_chats_duration {
            id
            field_type
            slug
            value
          }
          coffee_chats_representatives {
            id
            field_type
            slug
            value
          }
          coffee_chats_representatives_emails {
            id
            field_type
            slug
            value
          }
          coffee_chats_representatives_pronouns {
            id
            field_type
            slug
            value
          }
          coffee_chats_hackers {
            id
            field_type
            slug
            value
          }
          coffee_chats_favorited_hackers {
            id
            field_type
            slug
            value
          }
          coffee_chats_status {
            id
            field_type
            slug
            value
          }
          coffee_chats_due_date {
            id
            field_type
            slug
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetSponsorPerksQuery__
 *
 * To run a query within a React component, call `useGetSponsorPerksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorPerksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorPerksQuery({
 *   variables: {
 *      sponsor_company_id: // value for 'sponsor_company_id'
 *   },
 * });
 */
export function useGetSponsorPerksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorPerksQuery,
    GetSponsorPerksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorPerksQuery,
    GetSponsorPerksQueryVariables
  >(GetSponsorPerksDocument, baseOptions);
}
export function useGetSponsorPerksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorPerksQuery,
    GetSponsorPerksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorPerksQuery,
    GetSponsorPerksQueryVariables
  >(GetSponsorPerksDocument, baseOptions);
}
export type GetSponsorPerksQueryHookResult = ReturnType<
  typeof useGetSponsorPerksQuery
>;
export type GetSponsorPerksLazyQueryHookResult = ReturnType<
  typeof useGetSponsorPerksLazyQuery
>;
export type GetSponsorPerksQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorPerksQuery,
  GetSponsorPerksQueryVariables
>;
