import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { SponsorRoute } from "src/shared/constants/route";
// import { Permission } from "src/shared/contexts";
import NotFound from "src/views/404";

import SponsorPerksPage from "./perks";
import { SponsorPerksContextProvider } from "./perks/SponsorPerksContext";
// import SponsorRecruitment from "./recruitment";

export const SponsorRoutes = () => (
  <SponsorPerksContextProvider>
    <Routes>
      <Route path={SponsorRoute.PERKS} element={<SponsorPerksPage />} />

      {/* <Route
        path={SponsorRoute.RECRUITMENT}
        element={<SponsorRecruitment />}
        requirePermissions={[Permission.SPONSOR_RECRUITMENT_ACCESS]}
      /> */}
      {/* <Route
      path={SponsorRoute.PLATFORM_ONBOARDING}
      element={<SponsorPlatformOnboarding />}
    /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </SponsorPerksContextProvider>
);
