import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Alert, Text, Button } from "north.js";
import React from "react";

import "twin.macro";
import { Header, PageWrapper } from "src/shared/components";
import { useMentorContext } from "src/shared/contexts";
import { useUserContext } from "src/shared/contexts";

import ProfileForm from "./Form";

const Profile: React.FC = () => {
  const { info } = useUserContext();
  const { profile, updateProfile, createProfile, isLoading } =
    useMentorContext();

  return (
    <>
      <Header
        title="Mentor profile"
        subtitle="Fill in your information so hackers know who you are!"
      />
      <PageWrapper>
        {!isLoading && !profile && (
          <>
            <Alert
              color="warning"
              icon={<ExclamationTriangleIcon tw="w-20 h-20" />}
            >
              Oops! Looks like we haven&apos;t made a profile for you yet!
            </Alert>

            <div tw="h-24" />
            <Button
              size="lg"
              color="secondary"
              onClick={async () => {
                await createProfile();
                window.location.reload();
              }}
            >
              <Text type="button">Make Profile</Text>
            </Button>
          </>
        )}
        {info && profile && (
          <ProfileForm
            info={info}
            loading={isLoading}
            profile={profile}
            updateProfile={updateProfile}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default Profile;
