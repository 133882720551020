import { Label } from "north.js";
import React from "react";

import {
  PerkStatus,
  SponsorPerksData,
  SponsorPerkType,
} from "src/views/sponsor/perks/types";

import { PerkContainer, PerkTextArea } from "../components";
import Header from "../Header";
import { perkStatusToBadgeColor } from "../helpers";
import { TextDescription } from "../TextComponents";
import { PerkData } from "../usePerkState";

export const WELCOME_BOOKLET_BLURB_VALIDATION_FIELDS: (keyof SponsorPerksData)[] =
  ["welcome_booklet_blurb_description"];

export const WelcomeBookletBlurb: React.FC<PerkData> = ({
  getState,
  updateState,
  isReadOnly,
  error,
}) => {
  return (
    <>
      <Header
        title="Welcome Booklet Blurb"
        subtitle="This perk is for information that will be displayed in our welcome booklet."
        badge={
          <Label
            color={perkStatusToBadgeColor(
              (getState("welcome_booklet_blurb_status") ?? []) as PerkStatus
            )}
            size="sm"
          >
            {getState("welcome_booklet_blurb_status")}
          </Label>
        }
      />
      <PerkContainer
        description={<Description />}
        id={SponsorPerkType.WELCOME_BOOKLET_BLURB}
        readOnly={isReadOnly}
      >
        <PerkTextArea
          title="Description* (max 250 words)"
          value={getState("welcome_booklet_blurb_description") ?? ""}
          placeholder="Share a message with hackers"
          wordLimit={250}
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updateState("welcome_booklet_blurb_description", e.target.value)
          }
          error={
            error?.welcome_booklet_blurb_description && (
              <>This field is required</>
            )
          }
        />
      </PerkContainer>
    </>
  );
};

const Description = () => (
  <TextDescription>
    We&apos;d like to feature you in our welcome booklet! Please enter a short
    description about your company for hackers to read in our welcome booklet.
  </TextDescription>
);
