/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type UnregisterSelfFromBusMutationVariables = Types.Exact<{
  busId: Types.Scalars["Float"];
}>;

export type UnregisterSelfFromBusMutation = {
  unregisterSelfFromBus: Pick<Types.Bus, "id">;
};

export const UnregisterSelfFromBusDocument = gql`
  mutation UnregisterSelfFromBus($busId: Float!) {
    unregisterSelfFromBus(busId: $busId) {
      id
    }
  }
`;

/**
 * __useUnregisterSelfFromBusMutation__
 *
 * To run a mutation, you first call `useUnregisterSelfFromBusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterSelfFromBusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterSelfFromBusMutation, { data, loading, error }] = useUnregisterSelfFromBusMutation({
 *   variables: {
 *      busId: // value for 'busId'
 *   },
 * });
 */
export function useUnregisterSelfFromBusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnregisterSelfFromBusMutation,
    UnregisterSelfFromBusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UnregisterSelfFromBusMutation,
    UnregisterSelfFromBusMutationVariables
  >(UnregisterSelfFromBusDocument, baseOptions);
}
export type UnregisterSelfFromBusMutationHookResult = ReturnType<
  typeof useUnregisterSelfFromBusMutation
>;
export type UnregisterSelfFromBusMutationResult =
  ApolloReactCommon.MutationResult<UnregisterSelfFromBusMutation>;
export type UnregisterSelfFromBusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UnregisterSelfFromBusMutation,
    UnregisterSelfFromBusMutationVariables
  >;
