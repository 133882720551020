import { Text, Spacer } from "@hackthenorth/north";
import { Button } from "north.js";
import React from "react";

import Modal from "src/shared/components/Modal";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import { BUS_DEADLINE, FLIGHT_DEADLINE } from "./constants";

interface TravelModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const TravelModal: React.FC<TravelModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  const { responsesState } = useHackerState([Field.TRAVEL_OPTION], {});

  const travelDeadline =
    responsesState[Field.TRAVEL_OPTION] === "flight"
      ? FLIGHT_DEADLINE
      : BUS_DEADLINE;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Ready to submit your form?"
      actions={
        <>
          <Button color="tertiary" size="lg" onClick={onCancel}>
            No, return to form
          </Button>
          <Spacer width="16px" />
          <Button color="primary" size="lg" onClick={onConfirm}>
            Yes, submit
          </Button>
        </>
      }
    >
      <Text>
        Please confirm all your information is accurate before submitting your
        RSVP. You will be able to edit it up until <b>{travelDeadline}</b>.
      </Text>
    </Modal>
  );
};

export default TravelModal;
