import { Flex, Text } from "@hackthenorth/north";
import { Spinner } from "north.js";
import React, { useEffect } from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";
import { BlueCheck, GreenCheck, RedExclamation } from "src/static/img";

import { useHandleNetworkingScanMutation } from "./graphql/handleNetworkingScan.generated";
import LinkTreeView from "./LinkTreeView";

const NetworkingResult = () => {
  const url = window.location.href;
  const badgeCode = url.slice(url.lastIndexOf("/") + 1);

  const [handleNetworkingScan, { data, loading, error }] =
    useHandleNetworkingScanMutation({
      variables: {
        badge_code: badgeCode,
      },
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    handleNetworkingScan({
      variables: {
        badge_code: badgeCode,
      },
    });
  }, [badgeCode, handleNetworkingScan]);

  if (error) {
    return (
      <PageWrapper>
        <Flex column align="center">
          <ModalHeader>
            <Text mods="error heading h1 bold">Uh-oh!</Text>
            <Text mods="error">{error.message}</Text>
          </ModalHeader>
          <RedExclamation />
          <ErrorMessageWrapper>
            <Text>Return to your native camera app to try again.</Text>
          </ErrorMessageWrapper>
        </Flex>
      </PageWrapper>
    );
  }

  if (loading) return <Spinner />;

  if (data) {
    return (
      <PageWrapper>
        {data?.handleNetworkingScan.sponsorNetworkingPayload && (
          <Flex column align="center">
            <ModalHeader>
              {data.handleNetworkingScan.sponsorNetworkingPayload
                .alreadyScanned ? (
                <Text mods="heading h1 blue">Hello again!</Text>
              ) : (
                <Flex>
                  <Text mods="heading h1 bold">Sign-up&nbsp;</Text>
                  <Text mods="heading h1 success">success</Text>
                  <Text mods="heading h1">!</Text>
                </Flex>
              )}
            </ModalHeader>
            {data.handleNetworkingScan.sponsorNetworkingPayload
              .alreadyScanned ? (
              <BlueCheck />
            ) : (
              <GreenCheck />
            )}
            <SuccessMessageWrapper>
              <Text>
                <strong>
                  {
                    data?.handleNetworkingScan.sponsorNetworkingPayload
                      .attendeeName
                  }
                </strong>
                {" has been"}
                {data.handleNetworkingScan.sponsorNetworkingPayload
                  .alreadyScanned && " already"}
                {" added to the "}
                <strong>
                  {
                    data?.handleNetworkingScan.sponsorNetworkingPayload
                      .sponsorCompanyName
                  }
                </strong>
                {" email list."}
              </Text>
            </SuccessMessageWrapper>
            <ErrorMessageWrapper>
              <Text>
                Return to your native camera app to continue scanning
                hackers&apos; QR codes
              </Text>
            </ErrorMessageWrapper>
          </Flex>
        )}
        {data.handleNetworkingScan.hackerNetworkingPayload && (
          <LinkTreeView
            {...data.handleNetworkingScan.hackerNetworkingPayload}
          />
        )}
      </PageWrapper>
    );
  }

  return null;
};

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  padding: 36px;
  p {
    text-align: center;
  }
`;

const ErrorMessageWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 36px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessMessageWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 24px;
  background: #eff8fa;
  border-radius: 4px;
  text-align: center;
`;

export default NetworkingResult;
