import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";

const SHARE_ID = "abdd7ea4-cb4a-4817-aedf-cf1b0bcbe114";

// TODO: Change to modal
const TermsAndConditions: React.FC = () => {
  return (
    <PageWrapper>
      <OutlineDocument shareId={SHARE_ID} />
    </PageWrapper>
  );
};
export default TermsAndConditions;
