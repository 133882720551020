import React from "react";

import { Route } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";

import LinkAccount from ".";

export const LinkAccountRoutes = () => (
  <Route path={BaseRoute.CONNECT_SLACK_ACCOUNT} element={<LinkAccount />} />
);
