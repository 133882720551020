import { Label } from "north.js";
import React from "react";
import styled from "styled-components";

const StatusBadge = styled(Label)`
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
`;

export const QuestionBadge = ({ labelText }: any) => (
  <StatusBadge color="secondary">
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {labelText}
    </div>
  </StatusBadge>
);

export const NotAnsweredBadge = () => (
  <StatusBadge color="secondary-light">Not answered</StatusBadge>
);

export const AnsweredBadge = () => (
  <StatusBadge color="primary-light">Answered</StatusBadge>
);

export const StickerBadge = () => (
  <StatusBadge color="warning-light">Get a sticker</StatusBadge>
);
