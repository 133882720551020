import React from "react";
import styled from "styled-components";
import "twin.macro";

import { FittedImg, UnisexImg } from "src/static/img";

const LIGHTEST_GREY = "#E9F5FA";

const UNISEX_SIZES = [
  ["XS", "Extra Small", "31” - 34”"],
  ["S", "Small", "34” - 37”"],
  ["M", "Medium", "38” - 41”"],
  ["L", "Large", "42” - 45”"],
  ["XL", "Extra Large", "46” - 49”"],
  ["2XL", "Double Extra Large", "50” - 53”"],
];

const FITTED_SIZES = [
  ["S", "Small", "2 - 4"],
  ["M", "Medium", "6 - 8"],
  ["L", "Large", "8 - 10"],
  ["XL", "Extra Large", "10 - 14"],
];

const Chart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 350px;
  width: max(calc(50% - 40px), 330px);
  padding: 14px;
  border: 1px solid #bdc0ca;
`;

const ChartLabels = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 4px;

  p {
    font-size: 16px;
  }
`;

const Row = styled.div<{ oddIndex: boolean }>`
  height: 30px;
  background-color: ${({ oddIndex }) => (oddIndex ? "white" : LIGHTEST_GREY)};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  .size-symbol {
    font-weight: 700;
  }

  .size-number {
    font-weight: 500;
  }
`;

const Text = styled.p`
  font-weight: 900;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  line-height: 1.5;
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
`;

const TitleText = styled(Text)`
  font-size: 24px;
  margin-bottom: 8px;
`;

const TableHeading = styled(Text)`
  font-size: 18px;
`;

const TextWrapper = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  color: ${({ theme }) => theme.globalConstants.color.brandSecondary};
  ${({ theme }) => theme.mediaQueries.mobile`
    font-size: 13px;
  `}
`;

const TextWrapperSize = styled(TextWrapper)`
  width: 15%;
  text-align: left;
  font-weight: 700;
`;

const TextWrapperSizeFull = styled(TextWrapper)`
  width: 60%;
  text-align: left;
  font-weight: 400;
`;

const TextWrapperSizeChest = styled(TextWrapper)`
  width: 25%;
  text-align: right;
  font-weight: 500;
`;

type TSizeChartProps = {
  unisex?: boolean;
};

const SizeChart: React.FC<TSizeChartProps> = ({ unisex }) => {
  const [Image, sizeLabel, title, availableSizes] = unisex
    ? // eslint-disable-next-line react/jsx-key
      [<UnisexImg />, "Chest to Fit", "Unisex", UNISEX_SIZES]
    : // eslint-disable-next-line react/jsx-key
      [<FittedImg />, "Size", "Fitted", FITTED_SIZES];

  return (
    <Chart>
      <TitleText>{title}</TitleText>
      {Image}
      <ChartLabels>
        <TableHeading>Available Sizes</TableHeading>
        <TableHeading>{sizeLabel}</TableHeading>
      </ChartLabels>
      {availableSizes.map((size, index) => (
        <Row oddIndex={index % 2 === 1} key={size[0]}>
          <TextWrapperSize>{size[0]}</TextWrapperSize>
          <TextWrapperSizeFull>{size[1]}</TextWrapperSizeFull>
          <TextWrapperSizeChest>{size[2]}</TextWrapperSizeChest>
        </Row>
      ))}
    </Chart>
  );
};

export default SizeChart;
