/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../api/types.generated";
export type FetchNetworkingScanQueryVariables = Types.Exact<{
  badge_scan_id: Types.Scalars["Float"];
}>;

export type FetchNetworkingScanQuery = {
  fetchNetworkingScan: {
    hackerNetworkingPayload?: Types.Maybe<
      Pick<
        Types.HackerNetworkingPayload,
        | "name"
        | "role"
        | "bio"
        | "pronouns"
        | "school"
        | "facebookLink"
        | "instagramLink"
        | "twitterLink"
        | "linkedinLink"
        | "discordTag"
        | "githubLink"
        | "tiktokLink"
      >
    >;
    sponsorNetworkingPayload?: Types.Maybe<
      Pick<
        Types.SponsorNetworkingPayload,
        | "attendeeName"
        | "sponsorCompanyName"
        | "sponsorCompanyId"
        | "alreadyScanned"
      >
    >;
  };
};

export const FetchNetworkingScanDocument = gql`
  query FetchNetworkingScan($badge_scan_id: Float!) {
    fetchNetworkingScan(badge_scan_id: $badge_scan_id) {
      hackerNetworkingPayload {
        name
        role
        bio
        pronouns
        school
        facebookLink
        instagramLink
        twitterLink
        linkedinLink
        discordTag
        githubLink
        tiktokLink
      }
      sponsorNetworkingPayload {
        attendeeName
        sponsorCompanyName
        sponsorCompanyId
        alreadyScanned
      }
    }
  }
`;

/**
 * __useFetchNetworkingScanQuery__
 *
 * To run a query within a React component, call `useFetchNetworkingScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchNetworkingScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchNetworkingScanQuery({
 *   variables: {
 *      badge_scan_id: // value for 'badge_scan_id'
 *   },
 * });
 */
export function useFetchNetworkingScanQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FetchNetworkingScanQuery,
    FetchNetworkingScanQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FetchNetworkingScanQuery,
    FetchNetworkingScanQueryVariables
  >(FetchNetworkingScanDocument, baseOptions);
}
export function useFetchNetworkingScanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FetchNetworkingScanQuery,
    FetchNetworkingScanQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FetchNetworkingScanQuery,
    FetchNetworkingScanQueryVariables
  >(FetchNetworkingScanDocument, baseOptions);
}
export type FetchNetworkingScanQueryHookResult = ReturnType<
  typeof useFetchNetworkingScanQuery
>;
export type FetchNetworkingScanLazyQueryHookResult = ReturnType<
  typeof useFetchNetworkingScanLazyQuery
>;
export type FetchNetworkingScanQueryResult = ApolloReactCommon.QueryResult<
  FetchNetworkingScanQuery,
  FetchNetworkingScanQueryVariables
>;
